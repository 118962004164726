import SingleLoading from "components/Loading/SingleLoading";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import StatisticsService from "services/StatisticsService";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Universidades",
    },
  },
};

export default function Graphs4({ filters }) {
  const user = useSelector((state) => state.user.user);
  const [universities, setUniversities] = useState(null);

  const getWeek = (date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  };

  const countUniversities = (services) => {
    console.log({ services, filters });
    const unis = {};

    services.forEach((element) => {
      if (element.university) {
        const uni = element.university;
        const uniYear = new Date(
          element.date_inserted_at_sales_excel
        ).getFullYear();
        const uniWeek = getWeek(new Date(element.date_inserted_at_sales_excel));

        if (!unis[uniYear]) unis[uniYear] = {};
        if (!unis[uniYear][uniWeek]) unis[uniYear][uniWeek] = {};
        if (!unis[uniYear][uniWeek][uni]) {
          unis[uniYear][uniWeek][uni] = { count: 1 };
        } else {
          unis[uniYear][uniWeek][uni].count++;
        }
      }
    });

    const data = {
      labels: [],
      datasets: [
        {
          label: "Andes",
          data: [],
          borderColor: "rgb(252, 3, 3)",
          backgroundColor: "rgba(252, 3, 3, 0.5)",
        },
        {
          label: "Antonio Nariño",
          data: [],
          borderColor: "rgb(252, 3, 3)",
          backgroundColor: "rgba(252, 3, 3, 0.5)",
        },
        {
          label: "Católica",
          data: [],
          borderColor: "rgb(252, 65, 3)",
          backgroundColor: "rgba(252, 65, 3, 0.5)",
        },
        {
          label: "Sergio Arboleda",
          data: [],
          borderColor: "rgb(252, 65, 3)",
          backgroundColor: "rgba(252, 65, 3, 0.5)",
        },
        {
          label: "Javeriana",
          data: [],
          borderColor: "rgb(252, 161, 3)",
          backgroundColor: "rgba(252, 161, 3, 0.5)",
        },
        {
          label: "Sabana",
          data: [],
          borderColor: "rgb(252, 161, 3)",
          backgroundColor: "rgba(252, 161, 3, 0.5)",
        },
        {
          label: "Salle",
          data: [],
          borderColor: "rgb(194, 252, 3)",
          backgroundColor: "rgba(194, 252, 3, 0.5)",
        },
        {
          label: "Rosario",
          data: [],
          borderColor: "rgb(194, 252, 3)",
          backgroundColor: "rgba(194, 252, 3, 0.5)",
        },
        {
          label: "Uniminuto",
          data: [],
          borderColor: "rgb(45, 252, 3)",
          backgroundColor: "rgba(45, 252, 3, 0.5)",
        },
        {
          label: "El Bosque",
          data: [],
          borderColor: "rgb(45, 252, 3)",
          backgroundColor: "rgba(45, 252, 3, 0.5)",
        },
        {
          label: "Distrital",
          data: [],
          borderColor: "rgb(3, 252, 177)",
          backgroundColor: "rgba(3, 252, 177, 0.5)",
        },
        {
          label: "ECCI",
          data: [],
          borderColor: "rgb(3, 252, 1772)",
          backgroundColor: "rgba(3, 252, 177, 0.5)",
        },
        {
          label: "ECI",
          data: [],
          borderColor: "rgb(3, 161, 252)",
          backgroundColor: "rgba(3, 161, 252, 0.5)",
        },
        {
          label: "EAFIT",
          data: [],
          borderColor: "rgb(3, 161, 252)",
          backgroundColor: "rgba(3, 161, 252, 0.5)",
        },
        {
          label: "EIA",
          data: [],
          borderColor: "rgb(86, 3, 252)",
          backgroundColor: "rgba(86, 3, 252, 0.5)",
        },
        {
          label: "Militar",
          data: [],
          borderColor: "rgb(86, 3, 252)",
          backgroundColor: "rgba(86, 3, 252, 0.5)",
        },
        {
          label: "Soy_Ingeniero",
          data: [],
          borderColor: "rgb(210, 3, 252)",
          backgroundColor: "rgba(210, 3, 252, 0.5)",
        },
        {
          label: "Piloto",
          data: [],
          borderColor: "rgb(210, 3, 252)",
          backgroundColor: "rgba(210, 3, 252, 0.5)",
        },
        {
          label: "UNAL",
          data: [],
          borderColor: "rgb(252, 3, 169)",
          backgroundColor: "rgba(252, 3, 169, 0.5)",
        },
        {
          label: "UNAD",
          data: [],
          borderColor: "rgb(252, 3, 169)",
          backgroundColor: "rgba(252, 3, 169, 0.5)",
        },
        {
          label: "Otra",
          data: [],
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      ],
    };
    if (filters.year === "todo") {
      for (const prop in unis) {
        let aux = 1;
        for (const propWeek in unis[prop]) {
          data.labels.push(prop + "-w-" + propWeek);
          for (const propUni in unis[prop][propWeek]) {
            const index = data.datasets.findIndex((el) => el.label === propUni);
            if (index === -1) {
              data.datasets[data.datasets.length - 1].data.push(
                unis[prop][propWeek][propUni].count
              );
              continue;
            }
            data.datasets[index].data.push(unis[prop][propWeek][propUni].count);
          }
          data.datasets.forEach((el) => {
            if (el.data.length !== aux) el.data.push(0);
          });
          aux++;
        }
      }
      console.log({ unis, data });
      setUniversities(data);
    } else {
      for (const prop in unis) {
        if (parseInt(prop) === filters.year) {
          let aux = 1;
          for (const propWeek in unis[prop]) {
            data.labels.push("w-" + propWeek);
            for (const propUni in unis[prop][propWeek]) {
              const index = data.datasets.findIndex(
                (el) => el.label === propUni
              );
              if (index === -1) {
                data.datasets[data.datasets.length - 1].data.push(
                  unis[prop][propWeek][propUni].count
                );
                continue;
              }
              data.datasets[index].data.push(
                unis[prop][propWeek][propUni].count
              );
            }
            data.datasets.forEach((el) => {
              if (el.data.length !== aux) el.data.push(0);
            });
            aux++;
          }
        }
      }
    }

    setUniversities(data);
  };

  const getData = async () => {
    const res = await StatisticsService.countByWeek({
      token: user.token,
      year: filters.year,
    });
    if (res.data) countUniversities(res?.data?.data?.universities);
  };

  useEffect(() => {
    getData();
  }, [filters]); // eslint-disable-line

  if (universities === null) return <SingleLoading />;

  return <div>{<Line options={options} data={universities} />}</div>;
}
