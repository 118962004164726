import {
  Box,
  Button,
  Modal,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import SingleLoading from "components/Loading/SingleLoading";
import SimpleModal from "components/Modal/SimpleModal";
import FormatMoney from "components/Money/FormatMoney";
import { Pay_date } from "functions/Pay_date";
import { splitText } from "functions/comun";
import { mapDateFormat } from "functions/comun";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PartialService from "services/PartialService";
import TallerService from "services/TallerService";
import ClearIcon from "@material-ui/icons/Clear";
import TutorService from "services/TutorService";

const getMonth = (month) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return months[month];
};

const TeacherPayments = () => {
  const [modal, setModal] = useState(false);
  // const [partials, setPartials] = useState([]);
  // const [partial, setPartial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  // const [tutorships, setTutorships] = useState([]);
  // const [homeworks, setHomeworks] = useState([]);
  const [modalInfo, setModalInfo] = useState({});
  const [allData, setAllData] = useState([]);
  const [show, setShow] = useState(false);

  const user = useSelector((state) => state.user.user);

  const openModal = (rowData) => {
    setModal(true);
    // setPartial(rowData);
    setModalInfo(rowData);
  };

  const getPartials = async () => {
    const res = await PartialService.findPartialsForPayment({
      token: user.token,
    });
    if (res?.data?.data) {
      let ps = res?.data?.data?.map((p) => {
        return { ...p, typeRequest: "Parcial" };
      });
      return ps.filter((p) => {
        const now = new Date();
        const eventDate = new Date(p.event_date);
        return eventDate.getTime() + 3024600000 > now;
      });
    }
  };

  const getTutorships = async () => {
    const res = await TutorService.findTutorshipsForPayment({
      token: user.token,
    });
    if (res?.data?.data) {
      let ts = res?.data?.data?.map((t) => {
        return { ...t, typeRequest: "Tutoria" };
      });
      return ts.filter((t) => {
        const now = new Date();
        const eventDate = new Date(t.event_date);
        return eventDate.getTime() + 3024600000 > now;
      });
    }
  };

  const getHomeworks = async () => {
    const res = await TallerService.findHomeworksForPayment({
      token: user.token,
    });
    if (res?.data?.data) {
      // return res?.data?.data?.map((t) => {
      //   return { ...t, typeRequest: "Taller" };
      // });
      let hs = res?.data?.data?.map((h) => {
        return { ...h, typeRequest: "Taller" };
      });
      return hs.filter((h) => {
        const now = new Date();
        const eventDate = new Date(h.event_date);
        return eventDate.getTime() + 3024600000 > now;
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    // //console.log("===> ", modalInfo.typeRequest);
    switch (modalInfo.typeRequest) {
      case "Parcial":
        if (
          modalInfo?.ready ||
          new Date(modalInfo.updated_at).getTime() <
            new Date("11/02/2023 13:00").getTime()
        ) {
          const resPartial = await PartialService.sendPayment({
            token: user.token,
            typeObject: "PARTIAL",
            idObject: modalInfo._id,
            amount: modalInfo.debt_to_teacher,
            comment,
          });
          if (resPartial?.data?.status) {
            setModal(false);
            toast.success("Cobro de parcial enviado correctamente!");
            getAllData();
            // setPartial(null);
            setModalInfo(null);
          } else toast.error(resPartial?.data?.message);
        }
        break;
      case "Tutoria":
        if (
          modalInfo?.ready ||
          new Date(modalInfo.updated_at).getTime() <
            new Date("11/02/2023 13:00").getTime()
        ) {
          const resTutorship = await PartialService.sendPayment({
            token: user.token,
            typeObject: "TUTORSHIP",
            idObject: modalInfo._id,
            amount: modalInfo.debt_to_teacher,
            comment,
          });
          if (resTutorship?.data?.status) {
            setModal(false);
            toast.success("Cobro de tutoria enviado correctamente!");
            getAllData();
            // setPartial(null);
            setModalInfo(null);
          } else toast.error(resTutorship?.data?.message);
        }
        break;
      case "Taller":
        if (
          modalInfo?.ready ||
          new Date(modalInfo.updated_at).getTime() <
            new Date("11/02/2023 13:00").getTime()
        ) {
          if (
            new Date().getTime() >
            new Date(modalInfo.event_date).getTime() + 14400000
          ) {
            console.log("ok");
            const resHomework = await PartialService.sendPayment({
              token: user.token,
              typeObject: "HOMEWORK",
              idObject: modalInfo._id,
              amount: modalInfo.debt_to_teacher,
              comment,
            });
            if (resHomework?.data?.status) {
              setModal(false);
              toast.success("Cobro de taller enviado correctamente!");
              getAllData();
              // setPartial(null);
              setModalInfo(null);
            } else toast.error(resHomework?.data?.message);
          }
        }
        break;
      default:
        break;
    }
    setLoading(false);
  };

  const getAllData = async () => {
    setLoading(true);

    const partials = await getPartials();
    const tutorships = await getTutorships();
    const homeworks = await getHomeworks();
    //console.log([...partials, ...tutorships, ...homeworks]);
    setAllData(
      [...partials, ...tutorships, ...homeworks]
        .sort((a, b) => {
          let aDate = new Date(a.event_date);
          let bDate = new Date(b.event_date);
          if (aDate < bDate) {
            return 1;
          }
          if (aDate > bDate) {
            return -1;
          }
          return 0;
        })
        .filter((item) => {
          if (item.typeRequest === "Taller") return true;
          const date = new Date(item.event_date).getTime();
          const now = new Date().getTime();
          if (item.typeRequest === "Tutoria") {
            if (date + item.event_time * 3600000 < now) return true;
          }
          if (date + item.event_time * 60000 < now) return true;
          return false;
        })
    );
    setLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []); // eslint-disable-line

  const showImg = () => {
    setShow(!show);
  };

  const handleClose = () => setShow(false);

  const whatsappPago = () => {
    const url = "https://wa.link/5v2n2g";
    /*const url = `https://api.whatsapp.com/send?phone=${
        partial.phone
      }&text=🍀 Hola ${
        partial.client
      }, te escribimos de CopyWrite.%0D%0D*Materia:* ${
        partial.subject
      }.%0D*Fecha:* ${mapDate(partial.event_date)}.%0D*Duración del parcial:* ${
        partial.event_time
      } minutos.%0D*Costo:* $${
        partial.cost
      }.%0D%0D🌀¿Esta es la información de tu parcial?`;*/
    window.open(url, "pago anticipado");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "60vh",
    maxWidth: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  };

  if (loading) return <SingleLoading />;

  return (
    <div>
      <div
        className="good_font"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          fontSize: 12,
          marginTop: 15,
        }}
      >
        <p>
          <b>Próxima fecha de pago:</b> {Pay_date(new Date().getMonth())}
          <br />
          <b>Periodo a facturar:</b> 16 de {getMonth(moment().month() - 1)} a 15
          de {getMonth(moment().month())}
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="good_font"
          style={{
            backgroundColor: "#fcbc04",
            border: "none",
            borderRadius: "20px",
            padding: "10px 15px",
            cursor: "pointer",
          }}
          onClick={showImg}
        >
          ¿Necesitas un pago anticipado?
        </button>
      </div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              backgroundColor: "transparent",
              border: "none",
              paddingRight: "10px",
              paddingTop: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <ClearIcon fontSize="small" />
          </button>
          <img
            style={{
              objectFit: "contain",
              width: "100%",
            }}
            src={require("../../assets/img/pago_anticipado.jpeg")}
          />
          <button
            className="good_font"
            style={{
              backgroundColor: "#25d365",
              border: "none",
              borderRadius: "20px",
              padding: "10px 15px",
              cursor: "pointer",
            }}
            onClick={whatsappPago}
          >
            Click acá para solicitarlo
          </button>
        </Box>
      </Modal>
      <div
        class="good_font"
        style={{ fontSize: 16, marginBottom: 15, fontWeight: "bolder" }}
      >
        Lista de cobros pendientes por solicitar
      </div>
      {allData?.length === 0 && (
        <div>
          <span style={{ color: "red" }}>
            Aún no tienes cobros por solicitar
          </span>
        </div>
      )}
      {allData?.map((item) => (
        <DetailItem
          key={item?._id}
          openModal={() => openModal(item)}
          title={`${item?.typeRequest} - ${item?.subject}`}
          eventDate={moment(item?.event_date)}
          amount={item?.debt_to_teacher}
        />
      ))}

      <SimpleModal open={modal} setOpen={setModal}>
        <div style={{ textAlign: "start" }}>
          <h2 className="tittle-modal-teacher-payment good_font">
            Solicitar cobro
          </h2>
          <h3 style={{ fontSize: "1.3rem" }} className="good_font">
            <b>{`${modalInfo?.typeRequest}`}</b>
            {` - ${modalInfo?.subject}`}
          </h3>
          <h4>
            <b>Valor del cobro: </b>
            <FormatMoney
              value={modalInfo?.debt_to_teacher}
              country={{ label: "Colombia" }}
              fontSize={18}
              fontColor="#3c4858;"
            />
          </h4>
          <TextareaAutosize
            style={{
              width: "100%",
              borderRadius: 10,
              textAlign: "justify",
              border: ".5px solid #2b2527",
              padding: 13,
              margin: "20px 0px",
            }}
            aria-label="minimum height"
            minRows={4}
            maxRows={8}
            placeholder="Escribe alguna información adicional"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          {modalInfo?.typeRequest === "Taller" && (
            <div style={{ textAlign: "center" }}>
              {new Date().getTime() <
                new Date(modalInfo?.event_date).getTime() + 14400000 && (
                <span style={{ color: "red", textAlign: "center" }}>
                  Este Taller aún NO está disponible para cobro
                </span>
              )}
            </div>
          )}
          {modalInfo?.ready
            ? false
            : !(
                new Date(modalInfo.updated_at).getTime() <
                new Date("11/02/2023 13:00").getTime()
              ) && (
                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "red", textAlign: "center" }}>
                    Aún se esta verificando el pago del estudiante
                  </span>
                </div>
              )}
          <div style={{ display: "grid" }}>
            <Button
              disabled={
                modalInfo?.typeRequest === "Taller"
                  ? new Date().getTime() <
                      new Date(modalInfo?.event_date).getTime() + 14400000 &&
                    !(
                      modalInfo?.ready ||
                      new Date(modalInfo.updated_at).getTime() <
                        new Date("11/02/2023 13:00").getTime()
                    )
                  : modalInfo?.ready
                  ? false
                  : !(
                      new Date(modalInfo.updated_at).getTime() <
                      new Date("11/02/2023 13:00").getTime()
                    )
              }
              style={{
                backgroundColor: (
                  modalInfo?.typeRequest === "Taller"
                    ? new Date().getTime() <
                        new Date(modalInfo?.event_date).getTime() + 14400000 &&
                      !(
                        modalInfo?.ready ||
                        new Date(modalInfo.updated_at).getTime() <
                          new Date("11/02/2023 13:00").getTime()
                      )
                    : modalInfo?.ready
                    ? false
                    : !(
                        new Date(modalInfo.updated_at).getTime() <
                        new Date("11/02/2023 13:00").getTime()
                      )
                )
                  ? "#FF87AC"
                  : "#f9004d",
                color: "white",
                borderRadius: "10px",
                textTransform: "none",
                justifySelf: "center",
                padding: "12px 20px",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Solicitar
            </Button>
          </div>
        </div>
      </SimpleModal>
    </div>
  );
};

const DetailItem = ({ openModal, title, eventDate, amount }) => {
  return (
    <button
      style={{ cursor: "pointer" }}
      className="next-partial-btn"
      onClick={openModal}
    >
      <div
        className="container__payment-btn"
        style={{ backgroundColor: "#aec4c1" }}
      >
        <div style={{ display: "grid", width: "100%", textAlign: "start" }}>
          <h4
            style={{ fontWeight: "bold", color: "black" }}
            className="good_font"
          >
            {splitText(title, 30)}
          </h4>
          <div className="payment_sub-item">
            <div>
              <span
                style={{ fontSize: "1rem" }}
                className="good_font black_font"
              >
                {mapDateFormat(eventDate, "ddd DD MMM YYYY, hh:mm A")}
              </span>
            </div>
            <div>
              <FormatMoney
                value={amount}
                fontSize={16}
                country={{ label: "Colombia" }}
                fontColor="black"
              />
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default TeacherPayments;
