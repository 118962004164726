import axios from "axios";
const { URL_API } = require("service/config");

export default class ConfigVarService {
  static async getTRM() {
    const config = {
      method: "get",
      url: URL_API + "config-var/trm",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios(config);
      if (res?.data?.data?.number_value) {
        return res.data;
      }
      //console.log("ok_temp", res?.data);
      return { message: "ok_temp", data: { number_value: 4657.21 } };
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async getObjetive() {
    const config = {
      method: "get",
      url: URL_API + "config-var/objetive",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios(config);
      if (res?.data?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }

  static async setObjetive(value) {
    const config = {
      method: "post",
      url: URL_API + "config-var/objetive",
      headers: {
        "Content-Type": "application/json",
      },
      data: value,
    };
    try {
      const res = await axios(config);
      if (res?.data?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
}
