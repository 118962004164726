import { mapDate } from "functions/comun";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaymentService from "services/PaymentService";
import Button from "components/CustomButtons/Button";
import { toast } from "react-toastify";
import GridContainer from "components/Grid/GridContainer";
import GoBack from "components/Navigation/GoBack";
import GridItem from "components/Grid/GridItem";
import FormatMoney from "components/Money/FormatMoney";
import { IconButton, InputLabel, MenuItem, Select } from "@material-ui/core";
import { isSuperAdmin } from "variables/superAdminEmails";
import TeacherService from "services/TeacherService";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import moment from "moment";

const MakePayments = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [idTeacher, setIdTeacher] = useState("");
  const [name, setName] = useState("");
  const [debTutor, setDebtTutor] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);

  const user = useSelector((state) => state.user.user);

  const dataForPayment = React.useRef([]);

  const getData = async () => {
    setLoading(true);

    if (idTeacher?.value) {
      const res = await PaymentService.findAll2({
        token: user.token,
        state: "APPROVED",
        idTeacher: idTeacher?.value,
      });
      if (res?.data?.data) {
        setData(res?.data?.data);
        extractName();
        let total = 0;
        for (var debtT in res?.data?.data) {
          total = total + res?.data?.data[debtT].amount;
        }
        setDebtTutor(total);
      }
    } else {
      const res = await PaymentService.findAll({
        token: user.token,
        state: "APPROVED",
      });
      if (res?.data?.data) {
        setData(res?.data?.data);
        let total = 0;
        for (var debt in res?.data?.data) {
          total = total + res?.data?.data[debt].amount;
        }
        setName("");
        setDebtTutor(0);
        setTotalDebt(total);
      }
      const res2 = await PaymentService.findTeacherWithApprovedPayments({
        token: user.token,
      });
      if (res2?.data?.data) {
        setTeachers(
          res2?.data?.data?.filter((item) => {
            for (let i of res?.data?.data) {
              // console.log(item._id, i);
              if (item._id === i.id_teacher) {
                return true;
              }
            }
            return false;
          })
        );
        // setTeachers(res2?.data?.data);
      }
    }

    setLoading(false);
  };

  const handleClick = () => {
    if (dataForPayment.current && dataForPayment.current.length > 0) {
      let isValid = true;
      const validOne = dataForPayment.current[0].id_teacher;
      dataForPayment.current.forEach((element) => {
        if (element.id_teacher !== validOne) {
          isValid = false;
        }
      });
      if (isValid) {
        setPayments(dataForPayment.current);
      } else {
        toast.error("Estan enviando cobros a más de un tutor a la vez");
      }
    } else {
      toast.error("Selecciona al menos 1 cobro");
    }
  };

  useEffect(() => {
    getData();
  }, [idTeacher]); // eslint-disable-line

  const extractName = () => {
    const t = teachers.filter((teach) => teach._id === idTeacher.value);
    setName(t[0].names);
  };

  if (payments) {
    return (
      <ConfirmPayment
        payments={payments}
        setPayments={setPayments}
        getData={getData}
        idTeacher={idTeacher.value}
      />
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      <GridContainer>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <h3 style={{ color: "#f9004d" }}>Deuda total:</h3>
          <FormatMoney
            country={{ label: "Colombia" }}
            value={totalDebt}
            fontSize={"30px"}
          ></FormatMoney>
        </GridItem>
        <GridItem xs={6} md={6} sm={6} lg={6}>
          <InputLabel>Tutor</InputLabel>
          <Select
            value={idTeacher?.value}
            onChange={(e) => {
              setIdTeacher(e.target);
              dataForPayment.current = null;
            }}
            style={{ width: "50%" }}
          >
            <MenuItem value="">Todos</MenuItem>
            {teachers.map((i) => (
              <MenuItem key={i._id} value={i._id}>
                {i.names}
              </MenuItem>
            ))}
          </Select>
          <h3>Seleccione los cobros que quieres pagar</h3>
          <Button
            style={{
              backgroundColor: "rgb(75,181,67)",
              color: "white",
            }}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            loading={loading}
          >
            Hacer pago
          </Button>
        </GridItem>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <h3 style={{ color: "#f9004d" }}>Deuda de: {name}</h3>
          <FormatMoney
            country={{ label: "Colombia" }}
            value={debTutor}
            fontSize={"25px"}
          ></FormatMoney>
        </GridItem>
      </GridContainer>
      <br />
      <MaterialTable
        isLoading={loading}
        title={"Cobros aprobados"}
        columns={[
          { title: "Tipo objeto", field: "type_object" },
          { title: "Tutor", field: "teacher.names" },
          // { title: "Estado", field: "state" },
          { title: "Valor", field: "amount" },
          { title: "Evento", field: "event_date_f" },
          { title: "Creado", field: "created_at" },
        ]}
        data={data.map((i) => ({
          ...i,
          created_at: mapDate(i.created_at),
          event_date_f: mapDate(i?.object?.event_date),
          // db_state: i.state,
          // state: paymentsStateTranslate(i.state),
        }))}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: () => getData(),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSizeOptions: [5, 10, 20, 50, 100],
          pageSize: 10,
          selection: true,
        }}
        onSelectionChange={(rows) => (dataForPayment.current = rows)}
      />
      <h2>Historial de Pagos</h2>
      <BillHistory />
    </div>
  );
};

export const ConfirmPayment = ({
  payments,
  setPayments,
  getData,
  idTeacher,
}) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [teacherData, setTeacherData] = useState({});

  const getTeacher = async () => {
    if (idTeacher) {
      // console.log(idTeacher.value);
      const res = await TeacherService.getAll({ user });
      setTeacherData(res?.data?.find((item) => item._id === idTeacher));
    } else {
      // console.log(payments[0].id_teacher);
      const res = await TeacherService.getAll({ user });
      setTeacherData(
        res?.data?.find((item) => item._id === payments[0].id_teacher)
      );
    }
  };

  useEffect(() => {
    getTeacher();
  }, []); // eslint-disable-next-line

  const back = () => {
    getData();
    setPayments(null);
  };

  const totalAmount = payments.reduce((acc, next) => acc + next.amount, 0);
  const idPayments = payments.map((item) => item._id);

  const handleClick = async () => {
    setLoading(true);
    const res = await PaymentService.confirmPayments({
      token: user.token,
      idPayments,
      totalAmount,
      idTeacher: idTeacher || payments[0].id_teacher,
    });
    if (res?.data?.data) {
      toast.success("Datos guardados correctamente!");
      back();
    }
    setLoading(false);
  };

  const datePayment = () => {
    const date = new Date();
    if (date.getDate() >= 15) {
      date.setDate(15);
      // console.log(mapDate(date));
      return mapDate(date);
    }
    date.setDate(15);
    if (date.getMonth() === 0) {
      date.setMonth(11);
      date.setFullYear(date.getFullYear() - 1);
      // console.log(mapDate(date));
      return mapDate(date);
    }
    date.setMonth(date.getMonth() - 1);
    // console.log(mapDate(date));
    return mapDate(date);
  };

  const openWhatsApp = (teacherData) => {
    const text = `🚀 ¡Hola ${
      teacherData.names
    }!, acabamos de hacerte un pago por tus servicios\n\n*Concepto:* Pago Copywrite hasta ${
      datePayment().split(" ")[1]
    } de ${
      datePayment().split(" ")[2]
    }\n*Valor:* ${totalAmount} COP\n*Fecha de Pago:* ${mapDate(
      moment()
    )}\n*Banco:* ${teacherData.bank_name}\n*Numero de Cuenta:* ${
      teacherData.account_number
    }\n\n🌀¡Gracias por tu servicio!`;

    const url = `https://api.whatsapp.com/send?phone=${
      teacherData.phone
    }&text=${encodeURIComponent(text)}`;
    window.open(url, teacherData._id);
  };

  return (
    <GridContainer>
      <GoBack back={back} />
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <ul>
          {payments.map((item) => (
            <li key={item._id}>
              {`${item.type_object} - `}
              <FormatMoney
                country={{ label: "Colombia" }}
                value={item.amount}
              />
            </li>
          ))}
        </ul>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3 className="good_font">
          Confirmo que se pago{" "}
          <FormatMoney country={{ label: "Colombia" }} value={totalAmount} /> a{" "}
          <b className="good_font">{teacherData?.names}</b>, banco:{" "}
          <b className="good_font">{teacherData?.bank_name}</b>, cuenta:{" "}
          <b className="good_font">{teacherData?.account_number}</b>
        </h3>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Button
          style={{
            backgroundColor: "rgb(75,181,67)",
            color: "white",
          }}
          variant="contained"
          color="secondary"
          onClick={handleClick}
          loading={loading}
        >
          Confirmar pago
        </Button>
        <IconButton
          style={{ marginLeft: 10 }}
          onClick={() => openWhatsApp(teacherData)}
        >
          <WhatsappIcon />
        </IconButton>
      </GridItem>
    </GridContainer>
  );
};

export const BillHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);

  const findbills = async () => {
    setLoading(true);
    const res = await PaymentService.findbills({
      token: user.token,
    });
    if (res?.data?.data) setData(res?.data?.data);
    console.log(res.data);
    setLoading(false);
  };

  useEffect(() => {
    findbills();
  }, []); // eslint-disable-line

  // //console.log(data);
  return (
    <div>
      <MaterialTable
        isLoading={loading}
        title={"Pagos"}
        columns={[
          { title: "Tutor", field: "teacher.names" },
          { title: "Cant. pagos", field: "numPayments" },
          { title: "Valor", field: "amount" },
          { title: "Creado", field: "created_at" },
        ]}
        data={data.map((i) => ({
          ...i,
          created_at: mapDate(i.created_at),
          numPayments: i.id_payments.length,
        }))}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: () => findbills(),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSizeOptions: [5, 10, 20, 50, 100],
          pageSize: 10,
        }}
      />
    </div>
  );
};

export const MakePaymentsHOC = (props) => {
  const user = useSelector((state) => state.user.user);

  if (isSuperAdmin(user.email)) {
    return <MakePayments />;
  }

  return (
    <div>
      <h3 style={{ color: "#f0ad4e", textAlign: "center" }}>
        ¡No tienes permiso para visitar este modulo!
      </h3>
    </div>
  );
};

export default MakePaymentsHOC;
