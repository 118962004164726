import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class WompiService {
  static async getSignature(data) {
    return GlobalService.sentRequest({
      method: "post",
      url: `${URL_API}wompi/signature-integrity`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
    });
  }

  static async getById({ idPartial, user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}wompi/find/idPartial/${idPartial}`,
      headers: {
        Authorization: user.token,
      },
    });
  }
  static async getByIdTutorship({ idTutorship, user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}wompi/find/idTutorship/${idTutorship}`,
      headers: {
        Authorization: user.token,
      },
    });
  }
  static async createPaymentLink({ data, user }) {
    return GlobalService.sentRequest({
      method: "post",
      url: `${URL_API}wompi/create-payment-link`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  }
}
