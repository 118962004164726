import { scrollToTop } from "functions/scrollToTop";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PartialService from "services/PartialService";
import TutorService from "services/TutorService";

const useDashboard = () => {
  const { user } = useSelector((state) => state.user);
  const [partials, setPartials] = useState([]);
  const [acceptedPartials, setAcceptedPartials] = useState([]);
  const [tutorships, setTutorships] = useState([]);
  const [acceptedTutorships, setAcceptedTutorships] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollToTop();
    getAllData();
  }, []); // eslint-disable-line

  const getAllData = async () => {
    setLoading(true);
    await Promise.all([
      getPartials(),
      getAcceptedPartials(),
      getTutorships(),
      getAcceptedTutorships(),
    ]);
    setLoading(false);
  };

  const getPartials = async () => {
    const res = await PartialService.findAllMarketplace({ user });
    if (res?.data?.data) {
      setPartials(res?.data?.data);
    }
  };

  const getAcceptedPartials = async () => {
    const res = await PartialService.findByTeacher({ user });
    if (res?.data?.data) {
      setAcceptedPartials(res?.data?.data);
    }
  };

  const getTutorships = async () => {
    const res = await TutorService.findAllMarketplace({ user });
    if (res?.data?.data) {
      setTutorships(res?.data?.data);
    }
  };

  const getAcceptedTutorships = async () => {
    const res = await TutorService.findByTeacher({ user });
    if (res?.data?.data) {
      setAcceptedTutorships(res?.data?.data);
    }
  };

  return {
    partials,
    acceptedPartials,
    tutorships,
    acceptedTutorships,
    user,
    loading,
  };
};

export default useDashboard;
