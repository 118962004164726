import axios from "axios";

export default class GlobalService {
    static async sentRequest(config) {
        try {
            const res = await axios(config);
            return res;
        } catch (error) {
            console.error(error.message);
            return null;
        }
    }
}
