import React, { useState } from 'react'
import { Button, Container } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { mapDate } from "functions/comun";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../publicStyle.css"

const PaymentDoneTutor = ({data}) => {

  const [open, setOpen] = useState(false);

  return (
    <Container style={{ display: "flex", marginTop: "30px", flexDirection: "column", alignItems: "center", paddingBottom: 20 }}>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center", marginBottom: "20px" }}>
        <img src={"/icon.png"} style={{ width: "30%", padding: 10 }} alt="" />
        <h3 style={{ color: "#D5004D", fontWeight: "bold" }} className="textPurple">
          Listo {data.client}
        </h3>
        <h3 style={{ textAlign: "center" }} className="textBoldYellow">
          ¡Pagaste exitosamente tu tutoria!
        </h3>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "left" }}>
        <h5 className="noMargin">
          <b>Materia:</b> {data.subject}
        </h5>
        <h5 className="noMargin">
          <b>Valor:</b> <b style={{ color: "green" }}>{data.cost} COP</b>
        </h5>
        <h5 className="noMargin">
          <b>Fecha:</b> {mapDate(data.event_date).split(",")[0]}
        </h5>
        <h5 className="noMargin">
          <b>Hora:</b> {mapDate(data.event_date).split(",")[1]}
        </h5>
        <h5 className="noMargin">
          <b>Duración:</b> {data.event_time} min
        </h5>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "justify" }}>
        <small style={{ display: "block", fontSize: "120%", marginTop: 20 }} >
          <b className="justText">Estamos verificando tu comprobante de pago. Nos contactáremos pronto vía SMS o Whatsapp.</b>
        </small>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center", marginTop: "20px" }}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)} classes={{ label: 'buttonLabel' }}>
          Ver comprobante
        </Button>
      </GridItem>

      <Modal open={open} onClose={() => setOpen(false)} center>
        <img
          src={data.url_payment}
          width="100%"
          alt=""
          onClick={() => window.open(data.url_payment, data._id)}
          style={{ padding: 20, borderRadius: 30 }}
        />
      </Modal>
    </Container>
  );
}

export default PaymentDoneTutor