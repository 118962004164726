import React from "react";
import GridContainer from "components/Grid/GridContainer";
import { useForm, Controller } from "react-hook-form";
import { Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GoBack from "components/Navigation/GoBack";
import PhoneInput from "react-phone-input-2";
import Button from "components/CustomButtons/Button.js";
import useCountry from "hooks/useCountry";
import TeacherService from "services/TeacherService";
import swal from "sweetalert";

const InfoTeacher = ({ token, loading, teacher, setTeacher }) => {
  const { register, control, handleSubmit } = useForm();
  const { paises, states, cities, onClickCountry, onClickState } = useCountry();

  const onSubmit = async (data) => {
    const idTeacher = teacher._id;
    data.phone = "+" + data.phone;
    const res = await TeacherService.updateTeacher({ token, data, idTeacher });
    if (res.data.message !== "OK") {
      //console.log(res.data.message);
    }
    swal("Ok", "Profesor actualizado con exito", "success");
  };

  return (
    <GridContainer>
      <GoBack back={() => setTeacher(null)} />
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
        <h2>{teacher.names}</h2>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
        <h4>
          <strong>Email: </strong>
          {teacher.email}
        </h4>
        <h4>
          <strong>Celular: </strong>
          {teacher.phone}
        </h4>
        <h4>
          <strong>Localidad: </strong>
          {teacher.id_location}
        </h4>
        <h4>
          <strong>Nombre de Banco: </strong>
          {teacher.bank_name}
        </h4>
        <h4>
          <strong>Tipo de Cuenta: </strong>
          {teacher.account_type}
        </h4>
        <h4>
          <strong>Numero de Cuenta: </strong>
          {teacher.account_number}
        </h4>
        {console.log(teacher)}
        {teacher.temp_password && (
          <h4>
            <strong>Temp password: </strong>
            {teacher.temp_password}
          </h4>
        )}
        <h4>
          <strong>Fecha de creación: </strong>
          {teacher.created_at}
        </h4>
      </GridItem>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          padding: "4rem 5rem",
        }}
      >
        <GridContainer>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel>Nombre</InputLabel>
            <Controller
              name="names"
              control={control}
              //defaultValue={organizer.urlPlayStore}
              render={({ field }) => (
                <Input {...field} type="text" required fullWidth />
              )}
            />
          </GridItem>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel>Email</InputLabel>
            <Controller
              name="email"
              control={control}
              //defaultValue={organizer.urlPlayStore}
              render={({ field }) => (
                <Input {...field} type="email" required fullWidth />
              )}
            />
          </GridItem>
          <GridItem
            xs={4}
            md={4}
            sm={4}
            lg={4}
            style={{ marginBottom: "1rem" }}
          >
            <InputLabel style={{ marginBottom: 7 }}>Celular</InputLabel>
            <Controller
              name="phone"
              control={control}
              //defaultValue={organizer.urlPlayStore}
              // render={({ field }) => <Input {...field} type="cel" required fullWidth />}
              render={({ field }) => (
                <PhoneInput required country={"co"} {...field} />
              )}
            />
          </GridItem>
          <GridItem
            xs={4}
            md={4}
            sm={4}
            lg={4}
            style={{ marginBottom: "1rem" }}
          >
            <InputLabel>Banco</InputLabel>
            <Controller
              name="bank_name"
              control={control}
              //defaultValue={organizer.urlPlayStore}
              render={({ field }) => (
                <Input {...field} type="text" required fullWidth />
              )}
            />
          </GridItem>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel>Tipo de Cuenta</InputLabel>
            <Controller
              name="account_type"
              control={control}
              //defaultValue={organizer.urlPlayStore}
              render={({ field }) => (
                <Input {...field} type="text" required fullWidth />
              )}
            />
          </GridItem>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel>Numero de Cuenta</InputLabel>
            <Controller
              name="account_number"
              control={control}
              //defaultValue={organizer.urlPlayStore}
              render={({ field }) => (
                <Input {...field} type="text" required fullWidth />
              )}
            />
          </GridItem>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel style={{ marginBottom: 7 }}>Pais: </InputLabel>
            <Select
              {...register}
              onChange={onClickCountry}
              style={{ width: "100%" }}
              defaultValue="AF"
            >
              {paises.map((data) => (
                <MenuItem key={data.value} value={data.label}>
                  {data.value}
                </MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel style={{ marginBottom: 7 }}>Estado: </InputLabel>
            <Select
              {...register}
              onChange={onClickState}
              style={{ width: "100%" }}
              defaultValue="none"
            >
              <MenuItem value="none">--- Seleccionar ---</MenuItem>
              {states.map((data) => (
                <MenuItem key={data.value} value={data.label}>
                  {data.value}
                </MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem xs={4} md={4} sm={4} lg={4}>
            <InputLabel style={{ marginBottom: 7, marginTop: 5 }}>
              Ciudad:{" "}
            </InputLabel>
            <Select
              {...register("id_location")}
              style={{ width: "100%" }}
              defaultValue="none"
            >
              <MenuItem value="none">--- Seleccionar ---</MenuItem>
              {cities.map((data) => (
                <MenuItem key={data.value} value={data.label}>
                  {data.value}
                </MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            customPadding="10px 0px 10px 0px"
          >
            <Button type="submit" color="success" loading={loading}>
              {"Actualizar"}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    </GridContainer>
  );
};

export default InfoTeacher;
