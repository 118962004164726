import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Divider, Input, InputLabel } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { getRowsCount } from "functions/comun";
import { mapDate } from "functions/comun";
import ColorService from "services/ColorService";

const Colors = () => {
  const { control, handleSubmit } = useForm();
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = React.useState(false);
  const [colors, setColors] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, []); //eslint-disable-line

  const getData = async () => {
    setLoading(true);
    const res = await ColorService.findAll({ user });
    if (res?.data?.data) setColors(res?.data?.data);
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    //console.log(JSON.stringify(data));

    const res = await ColorService.create({ data, user });
    if (res?.data?.data) {
      getData();
    }
    setLoading(false);
  };

  const onRowDeleteColor = async (oldData) => {
    setLoading(true);
    const res = await ColorService.delete({ user, oldData });
    if (res.data.message !== "OK") {
      //console.log(res.data.message)
    }
    swal("Ok", "Color borrado con exito", "success");
    setLoading(false);
    getData();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <InputLabel>Nombre</InputLabel>
          <Controller
            name="name"
            id="name"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            render={({ field }) => (
              <Input {...field} type="text" required fullWidth />
            )}
          />
        </GridItem>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <InputLabel>Color</InputLabel>
          <Controller
            name="color"
            control={control}
            defaultValue="#ffffff"
            render={({ field }) => (
              <Input {...field} type="color" required fullWidth />
            )}
          />
        </GridItem>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <Button type="submit" color="success" loading={loading}>
            {"Guardar"}
          </Button>
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <br />
          <Divider />
          <br />
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <MaterialTable
            title={"Colores"}
            columns={[
              { title: "Nombre", field: "name" },
              { title: "Color", field: "color" },
              { title: "Color", field: "color_view" },
              { title: "Creado", field: "created_at", editable: "never" },
            ]}
            key={colors.length}
            data={colors.map((item) => ({
              ...item,
              color_view: (
                <div
                  style={{
                    backgroundColor: item.color,
                    height: 30,
                    width: 30,
                    borderRadius: 10,
                  }}
                />
              ),
              created_at: mapDate(item.created_at),
            }))}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Recargar",
                isFreeAction: true,
                onClick: getData,
              },
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar",
                isFreeAction: false,
                onClick: () => onRowDeleteColor(rowData),
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: getRowsCount(colors),
            }}
            isLoading={loading}
          />
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default Colors;
