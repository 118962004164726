import { Button, Grid, IconButton } from "@material-ui/core";
import GoBack from "components/Navigation/GoBack";
import React, { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import HiringService from "services/HiringService";
import SubjectService from "services/SubjectService";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { toast } from "react-toastify";
import StartStep from "../info/startStep";

const isImage = (string) => {
  switch (string) {
    case "img":
      return true;
    case "jpeg":
      return true;
    case "jpg":
      return true;
    case "png":
      return true;
    default:
      return false;
  }
};

function Test() {
  const [display, setDisplay] = useState(null);
  const id = useParams("id");
  const [test, setTest] = useState({});
  const { user } = useSelector((state) => state.user);
  const [subjects, setSubjects] = useState([]);
  const [answer, setAnswer] = useState("");
  const [checks, setChecks] = useState([]);
  const [check, setCheck] = useState(false);

  const selectSubejct = (e) => {
    setDisplay(e);
    getAnswer(e);
  };

  const uploadMultipleFiles = async (e, idSubject) => {
    e.preventDefault();
    if (e.target.files) {
      const res = await HiringService.saveAnswer({
        file: e.target.files,
        idSubject,
        idApplicant: test._id,
      });
      setAnswer(res?.data?.data);
    }
  };

  const getSubjects = async (s) => {
    const res = await SubjectService.findAll({ user });
    if (res?.data) {
      setSubjects(
        s.map((e) => {
          for (const r of res.data) {
            if (r._id === e) return r;
          }
        })
      );
    }
  };

  const getTest = async () => {
    const res = await HiringService.getTest(id.id);
    if (res?.data?.message === "OK") {
      startTest();
      getSubjects(res.data.data.subjects);
      checkAnswers(res.data.data, res.data.data.subjects);
    }
  };

  const deleteAnswer = async (id) => {
    if (id) {
      const res = await HiringService.deleteAnswer({ id });
      if (res?.data?.message) {
        toast.success("Archivo borrado correctamente");
        setAnswer("");
        getTest();
      } else {
        toast.error("Ha ocurrido un error");
      }
    }
  };

  const getAnswer = async (e) => {
    const res = await HiringService.validateAnswer(e._id, test._id);
    if (res?.data?.data) setAnswer(res?.data?.data);
  };

  const checkAnswers = async (t, s) => {
    const localAns = [];
    for (const subject of s) {
      if (subject._id) {
        const res = await HiringService.validateAnswer(subject._id, t._id);
        if (res?.data?.data?.answer) {
          localAns.push(true);
        } else {
          localAns.push(false);
        }
      } else {
        const res = await HiringService.validateAnswer(subject, t._id);
        if (res?.data?.data?.answer) {
          localAns.push(true);
        } else {
          localAns.push(false);
        }
      }
    }
    setChecks(localAns);
  };

  const startTest = async () => {
    const res = await HiringService.startTest(id.id);
    if (res?.data?.message === "OK") {
      setTest(res.data.data);
    }
  };

  useEffect(() => {
    getTest();
  }, []);

  useEffect(() => {
    if (new Date(test?.end_test)?.getTime() <= new Date().getTime()) {
      swal(
        "¡Oops!",
        "Parece que el tiempo para resolver la prueba ha terminado",
        "error"
      );
    }
  }, [check]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#ffbd00",
          padding: "1rem",
          display: "flex",
        }}
        className="good_font encabezado"
      >
        <div style={{ textAlign: "start" }}>
          <img src={"/icon.png"} style={{ width: 70 }} alt="" />
        </div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: 700,
            textAlign: "center",
            width: "100%",
            color: "white",
            display: "grid",
            placeContent: "center",
          }}
        >
          Prueba de admisíon
        </div>
      </div>
      <div
        className="good_font"
        style={{
          marginBottom: "1rem",
          fontSize: "1rem",
          marginTop: "1rem",
          padding: "1rem 3rem",
        }}
      >
        <div
          style={{
            textAlign: "start",
            width: "100%",
            color: "#ff044c",
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}
        >
          ¡Hola {test?.names}!
        </div>{" "}
        <br />
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "11fr 1fr",
            justifyItems: "end",
            gap: "5px",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              width: "100%",
              fontWeight: "bold",
              textAlign: "end",
            }}
          >
            Tiempo restante:
          </div>{" "}
          <div
            style={{ fontSize: "12px", width: "100%", fontWeight: "bolder" }}
          >
            <Countdown
              time={test?.end_test}
              test={test}
              check={check}
              setCheck={setCheck}
            />
          </div>
        </div>
      </div>
      <div
        className="good_font"
        style={{
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          padding: "0 10%",
        }}
      >
        {display ? (
          <>
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <GoBack
                  back={() => {
                    checkAnswers(test, subjects);
                    setDisplay(null);
                    setAnswer("");
                  }}
                />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <div
                  className="good_font"
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "1rem 0",
                    fontSize: "1.5rem",
                  }}
                >
                  <b style={{ color: "#ff044c" }}>
                    Prueba de admisión de {display.label}
                  </b>{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    textAlign: "start",
                    marginBottom: "3rem",
                    marginTop: "1rem",
                  }}
                >
                  Descarga el archivo en formato PDF o PNG. Luego, dedica tiempo
                  a resolver el ejercicio planteado. Una vez lo hayas
                  solucionado, selecciona el archivo correspondiente y súbelo
                  haciendo clic en el botón <b>Seleccionar archivo.</b>
                  <br />
                  <div style={{ color: "grey", fontSize: "0.8rem" }}>
                    <b>
                      NOTA: si tu prueba requiere de subir mas de un archivo por
                      favor comprimelo en un archivo zip o rar para que peudas
                      enviar el resultado de tu prueba.
                    </b>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    width:
                      display?.test?.split(".")[
                        display?.test.split(".").length - 1
                      ] === "pdf"
                        ? "40%"
                        : "100%",
                    margin:
                      display?.test?.split(".")[
                        display?.test.split(".").length - 1
                      ] === "pdf"
                        ? "3rem 30%"
                        : "0",
                  }}
                  className="imgModalFile"
                >
                  {display?.test &&
                    (display?.test?.split(".")[
                      display?.test?.split(".").length - 1
                    ] === "pdf" ? (
                      <a href={display?.test} target="_blank">
                        <div
                          style={{
                            cursor: "pointer",
                            borderRadius: "10px",
                            backgroundColor: "#ff044c",
                            padding: "0.5rem",
                            width: "100%",
                            color: "white",
                            display: "grid",
                            gridTemplateColumns: "1fr 3fr",
                          }}
                        >
                          <PictureAsPdfIcon />
                          <b>Ver pdf</b>
                        </div>
                      </a>
                    ) : (
                      <a href={display?.test} target="_blank">
                        <img
                          style={{ width: "100%", objectFit: "contain" }}
                          src={display?.test}
                          alt=""
                        />
                      </a>
                    ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {answer.answer ? (
                  <div
                    className="steptwo--btn"
                    style={{ marginTop: "1rem", padding: "0" }}
                  >
                    <div>
                      <Button
                        onClick={() => deleteAnswer(answer._id)}
                        style={{ backgroundColor: "#dc3545", color: "white" }}
                        classes={{ label: "buttonLabel" }}
                      >
                        Eliminar Respuesta
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="steptwo--btn"
                    style={{ marginTop: "1rem", padding: "0" }}
                  >
                    <div>
                      <h4>Seleccionar Archivo</h4>{" "}
                      <input
                        type="file"
                        className="custom-file-input"
                        onChange={(e) => {
                          uploadMultipleFiles(e, display._id);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div>
                  {" "}
                  {answer.answer && (
                    <div
                      style={{
                        width: isImage(
                          answer.answer?.split(".")[
                            answer.answer?.split(".").length - 1
                          ]
                        )
                          ? "100%"
                          : "40%",
                        margin: isImage(
                          answer.answer?.split(".")[
                            answer.answer?.split(".").length - 1
                          ]
                        )
                          ? "0"
                          : "3rem 30%",
                      }}
                      className="imgModalFile"
                    >
                      {answer.answer &&
                        (!isImage(
                          answer.answer?.split(".")[
                            answer.answer?.split(".").length - 1
                          ]
                        ) ? (
                          <a href={answer.answer} target="_blank">
                            <div
                              style={{
                                cursor: "pointer",
                                borderRadius: "10px",
                                backgroundColor: "#ff044c",
                                padding: "0.5rem",
                                width: "100%",
                                color: "white",
                                display: "grid",
                                gridTemplateColumns: "1fr 3fr",
                              }}
                            >
                              <PictureAsPdfIcon />
                              <b>Ver archivo</b>
                            </div>
                          </a>
                        ) : (
                          <a href={answer.answer} target="_blank">
                            <img
                              style={{ width: "100%", objectFit: "contain" }}
                              src={answer.answer}
                              alt=""
                            />
                          </a>
                        ))}
                    </div>
                    // <div style={{ width: "100%", padding: "0 1rem" }}>
                    //   <a target="_blank" href={answer.answer}>
                    //     <img
                    //       style={{ width: "100%", objectFit: "contain" }}
                    //       src={answer.answer}
                    //       alt=""
                    //     />
                    //   </a>
                    // </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <div style={{ textAlign: "start", marginBottom: "3rem" }}>
              Aquí puedes encontrar la lista de las materias que has inscrito
              para tu prueba. Haz clic en cada materia para acceder al ejercicio
              que debes resolver. Sube la solución propuesta para cada materia
              y, finalmente, haz clic en <b>Finalizar y Enviar</b> para
              completar el proceso.
            </div>
            {subjects.map((e, i) => {
              return (
                <div
                  key={i}
                  style={{
                    cursor: "pointer",
                    border: checks[i]
                      ? "#4caf50 0.1rem solid"
                      : "#ff044c 0.1rem solid",
                    borderRadius: "10px",
                    backgroundColor: checks[i] ? "#4caf50" : "#ff044c",
                    padding: "1rem",
                    width: "100%",
                    marginBottom: "1rem",
                    color: "white",
                  }}
                  onClick={() => selectSubejct(e)} //deberia de enviar el id
                >
                  <b>Asignatura:</b> {e.label} -{" "}
                  {checks[i] ? "Listo" : "Pendiente"}
                </div>
              );
            })}
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  new Date(test?.end_test)?.getTime() <= new Date().getTime()
                }
                className="good_font"
                classes={{ label: "buttonLabel" }}
                onClick={async () => {
                  const res = await HiringService.updateTest(test._id);
                  if (res?.data?.message === "OK") {
                    //timeout
                    swal(
                      "¡Excelente!",
                      "Hemos recibido tu prueba de admisión. El próximo viernes te enviaremos la respuesta a tu solicitud. Te contactaremos a través de WhatsApp o correo electrónico.",
                      "success"
                    ).then(() => {
                      window.location.href = "https://www.copywritecol.com";
                    });
                  }
                }}
              >
                Finalizar y enviar
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const Countdown = ({ time, test, check, setCheck }) => {
  const [hours, setHours] = useState(time?.hours);
  const [mins, setMinutes] = useState(time?.minutes);
  const [secs, setSeconds] = useState(time?.seconds);

  useEffect(() => {
    const now = new Date().getTime();
    const target = new Date(time).getTime();

    const h = Math.trunc((target - now) / 3600000);

    const m = Math.trunc(((target - now) / 3600000 - h) * 60);

    const s = Math.trunc(((target - now) / 3600000 - h) * 60 - m) * 60;

    setHours(h);
    setMinutes(m);
    setSeconds(s);
  }, [test]);

  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (secs > 0) {
        setSeconds(secs - 1);
      }
      if (secs === 0) {
        if (mins === 0) {
          if (hours === 0) {
            clearInterval(sampleInterval);
            setCheck(true);
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(mins - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(sampleInterval);
    };
  }, [secs]);

  return (
    <div>
      {!(hours || mins || secs) ? (
        "00:00:00"
      ) : (
        <p>
          {" "}
          {hours < 10 ? `0${hours}` : hours}:{mins < 10 ? `0${mins}` : mins}:
          {secs < 10 ? `0${secs}` : secs}
        </p>
      )}
    </div>
  );
};

export default Test;
