import SingleLoading from "components/Loading/SingleLoading";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TutorService from "services/TutorService";
import NextPartialButton from "components/CustomButtons/NextPartialButton";
import { mapDateFormat } from "functions/comun";
import GoBack from "components/Navigation/GoBack";

const NextTutorships = () => {
  const { user } = useSelector((state) => state.user);

  //console.log(user);
  const [tutorships, setTutorships] = useState([]);
  const [acceptedTutorships, setAcceptedTutorships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [tutorshipsInCalendar, setTutorshipsInCalendar] = useState([]);

  const getTutorships = async () => {
    setLoading(true);
    const res = await TutorService.findAllMarketplace({ user });
    if (res?.data?.data) {
      setTutorships(
        res?.data?.data.filter((item) => {
          if (!item.assigned_to) {
            return filterByDate(item);
          }
          return false;
        })
      );
      setLoading(false);
    }
  };

  const getAcceptedTutorships = async () => {
    setLoading(true);
    const res = await TutorService.findByTeacher({ user });
    if (res?.data?.data) {
      setAcceptedTutorships(
        res?.data?.data.filter(
          (item) => filterByDate(item) && !item.is_inserted_at_sales_excel
        )
      );
      setTutorshipsInCalendar(
        res?.data?.data.filter(
          (item) => filterByDate(item) && item.is_inserted_at_sales_excel
        )
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getTutorships();
    getAcceptedTutorships();
  }, []); // eslint-disable-line

  const filterByDate = (item) => {
    const now = new Date().getTime();
    const EventDate = new Date(item.event_date).getTime();
    if (now > EventDate) {
      return false;
    }
    return true;
  };

  if (loading) return <SingleLoading size={50} />;

  switch (status) {
    case "disponibles":
      return <Disponibles setStatus={setStatus} tutorships={tutorships} />;
    case "aceptados":
      return (
        <Aceptados
          setStatus={setStatus}
          acceptedTutorships={acceptedTutorships}
        />
      );
    case "agendados":
      return (
        <Agendados
          setStatus={setStatus}
          tutorshipsInCalendar={tutorshipsInCalendar}
        />
      );
    default:
      return (
        <DefaultMenu
          setStatus={setStatus}
          tutorships={tutorships}
          acceptedTutorships={acceptedTutorships}
          tutorshipsInCalendar={tutorshipsInCalendar}
        />
      );
  }
};

const DefaultMenu = ({
  setStatus,
  tutorships,
  acceptedTutorships,
  tutorshipsInCalendar,
}) => {
  return (
    <>
      <div>
        <div className="dashboard--items">
          <div
            className="item1"
            style={{ cursor: "pointer" }}
            onClick={() => setStatus("disponibles")}
          >
            <div className="mobile-item">
              <h3 style={{ fontSize: "0.9rem" }}>Disponibles</h3>
              <h2>{tutorships.length}</h2>
            </div>
          </div>
          <div
            className="item2"
            style={{ cursor: "pointer" }}
            onClick={() => setStatus("aceptados")}
          >
            <div className="mobile-item">
              <h3 style={{ fontSize: "0.9rem" }}>Aceptadas</h3>
              <h2>{acceptedTutorships.length}</h2>
            </div>
          </div>
          <div
            className="item3"
            style={{ cursor: "pointer" }}
            onClick={() => setStatus("agendados")}
          >
            <div className="mobile-item">
              <h3 style={{ fontSize: "0.9rem" }}>Agendadas</h3>
              <h2>{tutorshipsInCalendar.length}</h2>
            </div>
          </div>
        </div>
        <div className="next-partial-text">
          <strong>Disponibles</strong>
          <br />
          Solicitudes que puedes aceptar ó rechazar.
          <br />
          <br />
          <strong>Aceptadas</strong>
          <br />
          Solicitudes que tú aceptaste. Puede que se confirmes ó no.
          <br />
          <br />
          <strong>Agendadas</strong>
          <br />
          Solicitudes que deberás atender
          <br />
          Debes tenerlos agendados en Calendar.
        </div>
      </div>
    </>
  );
};

const Disponibles = ({ setStatus, tutorships }) => {
  const history = useHistory();
  return (
    <>
      <GoBack
        back={() => setStatus("")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className="container--nextpartials">
        <h3 className="npartials--title">Tutorias Disponibles</h3>
        {tutorships.length === 0 && (
          <span className="npartials--span">No hay tutorias disponibles</span>
        )}
        {tutorships.map((item) => (
          <div key={item._id}>
            <NextPartialButton
              backgroundColor="#f1d67b"
              onHandleClick={() =>
                history.push(`/teacher/tutorias/${item._id}`)
              }
              state={item.state}
              id={item._id}
              subject={item.subject}
              durationInMinutes={item.event_time}
              costInCOP={item.cost}
              date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
              time="Horas"
            />
            <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
          </div>
        ))}
      </div>
    </>
  );
};

const Aceptados = ({ setStatus, acceptedTutorships }) => {
  const history = useHistory();
  return (
    <>
      <GoBack
        back={() => setStatus("")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className="container--nextpartials">
        <h3 className="npartials--title">Tutorias Aceptados</h3>
        {acceptedTutorships.length === 0 && (
          <span className="npartials--span">
            Aún no has aceptado ninguna tutoría
          </span>
        )}
        {acceptedTutorships.map((item) => (
          <div key={item._id}>
            <NextPartialButton
              backgroundColor="#aec4c1"
              onHandleClick={() =>
                history.push(`/teacher/tutorias/${item._id}`)
              }
              state={item.state}
              id={item._id}
              subject={item.subject}
              durationInMinutes={item.event_time}
              costInCOP={item.cost}
              date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
              time="Horas"
            />
            <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
          </div>
        ))}
      </div>
    </>
  );
};

const Agendados = ({ setStatus, tutorshipsInCalendar }) => {
  const history = useHistory();
  return (
    <>
      <GoBack
        back={() => setStatus("")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className="container--nextpartials">
        <h3 className="npartials--title">Parciales Agendados</h3>
        {tutorshipsInCalendar.length === 0 && (
          <span className="npartials--span">No hay parciales disponibles</span>
        )}
        {tutorshipsInCalendar.map((item) => (
          <div key={item._id}>
            <NextPartialButton
              backgroundColor="#f1d67b"
              onHandleClick={() =>
                history.push(`/teacher/tutorias/${item._id}`)
              }
              state={item.state}
              id={item._id}
              subject={item.subject}
              durationInMinutes={item.event_time}
              costInCOP={item.cost}
              date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
              time="Horas"
            />
            <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
          </div>
        ))}
      </div>
    </>
  );
};

export default NextTutorships;
