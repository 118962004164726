import axios from "axios";
import { CITIES_API } from "service/config";

export default class CountriesService {
  static async getCountries() {
    const resp = await axios.get(
      "https://api.countrystatecity.in/v1/countries",
      { headers: { "X-CSCAPI-KEY": CITIES_API } }
    );
    return resp.data.map(({ name, iso2 }) => {
      return { value: name, label: iso2 };
    });
  }

  static async getStates({ country }) {
    const resp = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${country}/states`,
      { headers: { "X-CSCAPI-KEY": CITIES_API } }
    );
    return resp.data.map(({ name, iso2 }) => {
      return { value: name, label: iso2 };
    });
  }

  static async getCities({ country, state }) {
    const resp = await axios.get(
      `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
      { headers: { "X-CSCAPI-KEY": CITIES_API } }
    );
    return resp.data.map(({ name, id }) => {
      return { value: name, label: id };
    });
  }
}
