import React, { useEffect } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";
import { useSelector } from "react-redux";
import { useState } from "react";
import swal from "sweetalert";
import TeacherService from "services/TeacherService";
import { isValidPasswordDetail } from "utils/passwordValidator";
import GridItem from "components/Grid/GridItem";
import SingleLoading from "components/Loading/SingleLoading";

const PerfilTeacher = () => {
  const { token } = useSelector((state) => state.user.user);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [teacher, setTeacher] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTeacher();
  }, []); // eslint-disable-line

  const isRenderLoading = loading || Object.entries(teacher).length === 0;

  const getTeacher = async () => {
    setLoading(true);
    const res = (await TeacherService.getOne({ token })).data;
    setTeacher(res);
    setLoading(false);
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (password !== repeatPassword) {
      swal("Error", "Las contraseñas no coinciden", "error");
      return;
    }

    const errors = isValidPasswordDetail(password);
    if (errors.length > 0 && errors !== "OK") {
      const arrayErrors = [];
      errors.forEach((error) => {
        arrayErrors.push(error);
      });
      arrayErrors.push("Deben ser iguales las contraseñas");
      swal("Error", `${arrayErrors.map((error) => ` ${error}`)}`, "error");
      return;
    }

    setLoading(true);
    const res = await TeacherService.updatePasswordTeacher({
      token,
      password,
    });
    if (res) {
      swal("Genial", "Contraseña actualizada correctamente", "success");
      setPassword("");
      setRepeatPassword("");
    }
    setLoading(false);
  };

  return (
    <GridContainer>
      <GridItem>
        <form onSubmit={changePassword}>
          <h3
            style={{ color: "#d5004d", fontWeight: "bold" }}
            className="good_font"
          >
            Cambiar contraseña
          </h3>
          {isRenderLoading ? (
            <SingleLoading size={50} />
          ) : (
            <>
              <h3 className="good_font">{teacher.names} - Tutor</h3>
              <h4 className="good_font">
                <strong>Celular: </strong>
                {teacher.phone}
              </h4>
              <h4 className="good_font">
                <strong>Email: </strong>
                {teacher.email}
              </h4>
            </>
          )}
          <CustomInput
            labelText="Password..."
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              autoComplete: "off",
              onChange: (e) => setPassword(e.target.value),
              value: password,
              required: true,
            }}
          />
          <CustomInput
            labelText="Repeat password..."
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              autoComplete: "off",
              onChange: (e) => setRepeatPassword(e.target.value),
              value: repeatPassword,
              required: true,
            }}
          />
          <Button
            type="submit"
            color="primary"
            size="lg"
            block
            loading={loading}
            style={{ fontWeight: "bold" }}
          >
            {"Cambiar contraseña"}
          </Button>
        </form>
      </GridItem>
    </GridContainer>
  );
};

export default PerfilTeacher;
