import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class AdminService {
  static async updatePasswordAdmin({ token, password }) {
    return GlobalService.sentRequest({
      method: "put", 
      url: `${URL_API}admin`,
      data: JSON.stringify({
        password,
      }),
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      }
    })
  }
  static async getAdmin({token}){
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}admin`,
      headers: { Authorization: token }
    })
  }
}

