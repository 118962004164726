import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class StatisticsService {
  static async mainStats({ token, week }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/main-stats/week/${week}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async countByWeek({ token, year }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/count-by-week?year=${year}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async getStudents({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/students-count`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async getTeachers({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/teachers-count`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async countriesCount({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/countries-count`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async detailStats({ user, filters }) {
    const { week, year, request, month } = filters;
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/detail-stats/?request=${request}&year=${year}&week=${week}&month=${month}`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async adminStats({ token, week }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/admin-stats/week/${week}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async adminStatsByMonth({ token, month }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/admin-stats/month/${month}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async getReturns({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/returns`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async sellsTimeLine({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/sells-time-line`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async getTopTutors({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}statistics/top-tutors`,
      headers: {
        Authorization: user.token,
      },
    });
  }
}
