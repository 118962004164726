import axios from "axios";
const { URL_API } = require("service/config");

export default class ColorService {
  static async findAll({ user }) {
    var config = {
      method: "get",
      url: URL_API + "color",
      headers: {
        Authorization: user.token,
      },
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async create({ data, user }) {
    var config = {
      method: "post",
      url: URL_API + "color",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async delete({ user, oldData }) {
    const resp = axios.delete(URL_API + "color/" + oldData._id, {
      headers: { Authorization: user.token },
    });
    return resp;
  }
}
