import { InputLabel, Slider, TextareaAutosize } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import GoBack from "components/Navigation/GoBack";
import React from "react";

const Step1 = ({ results, setResults, setStep, step, next }) => {
  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  const questions = [
    {
      text: "¿Obtuviste una buena nota?",
    },
    {
      text:
        "¿La línea de atención al cliente te respondió de manera amable y eficiente?",
    },
    {
      text:
        "¿Sientes confianza y respaldo al contratar con la marca Copywrite?",
    },
    {
      text: "¿Nuestros precios son accesibles?",
    },
  ];

  return (
    <>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Feedback Copywrite
      </h3>
      <GoBack back={() => setStep(step - 1)} />
      {questions.map((q, index) => {
        return (
          <div key={index} style={{ textAlign: "left", padding: "10px 20px" }}>
            <InputLabel className={`good_font`}>
              <b>{q.text}</b>
            </InputLabel>
            <br />
            <div style={{ padding: "0px 30px", textAlign: "center" }}>
              <Slider
                // onChangeCommitted={(event, value) => setMinutes(value)}
                onChange={(event, value) =>
                  setResults({ ...results, ["q" + index]: value })
                }
                defaultValue={1}
                getAriaValueText={(value) => `${value} NOSE`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                //valueLabelFormat={valueLabelFormat}
                step={1}
                marks={marks}
                min={1}
                max={5}
                // scale={(x) => x * 20}
              />
            </div>
          </div>
        );
      })}
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          style={{ marginBottom: 30 }}
          variant="contained"
          color="primary"
          onClick={next}
        >
          <b>Continuar</b>
        </Button>
      </div>
    </>
  );
};

export default Step1;
