import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDatePicker from "components/DatePicker/CustomDatePicker";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormatMoney from "components/Money/FormatMoney";
import CustomReactSelect from "components/Select/CustomReactSelect";
import { paymentsState } from "functions/comun";
import { paymentsStateTranslate } from "functions/comun";
import { mapDate } from "functions/comun";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaymentService from "services/PaymentService";
import TeacherService from "services/TeacherService";
import DetailAdminPayment from "./DetailAdminPayment";
import Parcial from "views/Pages/Parcial";
import Tutoria from "views/Pages/Tutoria";
import Taller from "views/Pages/Taller";

const AdminPayments = () => {
  const [state, setState] = useState("ASKED");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(null);
  const [tutors, setTutors] = useState([]);
  const [filter, setFilter] = useState({ tutor: "", date: "" });
  const [debtsTutor, setDebtsTutor] = useState(0);
  const [debtTutorSelected, setDebtTutorSelected] = useState(0);
  const [service, setService] = useState(null);
  const [partial, setPartial] = useState(null);
  const [tutorship, setTutorship] = useState(null);
  const [homework, setHomework] = useState(null);

  const user = useSelector((state) => state.user.user);

  const getDebtsTutor = async () => {
    const resAsked = await PaymentService.findAll({
      token: user.token,
      state: "ASKED",
    });
    const resApproved = await PaymentService.findAll({
      token: user.token,
      state: "APPROVED",
    });

    let totalAmount = 0;
    const payments = [...resAsked?.data?.data, ...resApproved?.data?.data];
    for (let i = 0; i < payments.length; i++) {
      totalAmount += payments[i]?.amount;
    }

    let tutorDebt = 0;
    if (filter.tutor) {
      const dataToFilter = payments.filter((item) => {
        if (item?.teacher?.names === filter.tutor) {
          return true;
        }
        return false;
      });
      for (let i = 0; i < dataToFilter.length; i++) {
        tutorDebt += dataToFilter[i]?.amount;
      }
    }
    setDebtTutorSelected(tutorDebt);
    // for (let i = 0; i < payments.length; i++) {
    //   totalAmount += payments[i]?.amount;
    // }
    // console.log(totalAmount);
    setDebtsTutor(totalAmount);
  };

  const getData = async (stateParam = "ASKED") => {
    setLoading(true);
    const res = await PaymentService.findAll({
      token: user.token,
      state: stateParam,
    });
    let dataToFilter = [];
    if (res?.data?.data) {
      // setData(res?.data?.data);
      dataToFilter = res?.data?.data;
    }
    // console.log(res?.data?.data);
    if (filter.tutor) {
      dataToFilter = dataToFilter.filter((item) => {
        if (item?.teacher?.names === filter.tutor) {
          return true;
        }
        return false;
      });
    }
    if (filter.date) {
      dataToFilter = dataToFilter?.filter((item) => {
        if (new Date(item?.object?.event_date).getTime() <= filter.date) {
          return true;
        }
        return false;
      });
    }
    setData(dataToFilter);
    // console.log(res?.data?.data);
    setLoading(false);
  };

  const getTeachers = async () => {
    const res = await TeacherService.getAll({ user });
    const res2 = await PaymentService.findAll({
      token: user.token,
      state: "ASKED",
    });

    setTutors(
      res?.data?.filter((i) => {
        if (res2?.data?.data?.find((j) => j.id_teacher === i._id)) {
          return true;
        }
        return false;
      })
    );
  };

  const changeStateColor = async (rowData) => {
    setLoading(true);
    if (rowData.revision_pending === "#fff") {
      const res = await PaymentService.changeStateColor({
        user,
        color: "rgb(255 133 133)",
        _id: rowData._id,
      });
      if (res.data?.message === "OK") getData();
    } else {
      const res = await PaymentService.changeStateColor({
        user,
        color: "#fff",
        _id: rowData._id,
      });
      if (res.data?.message === "OK") getData();
    }
    setLoading(false);
  };

  useEffect(() => {
    getData(state);
    getDebtsTutor();
  }, [state, filter, payment, service]); // eslint-disable-line

  useEffect(() => {
    getTeachers();
  }, []); // eslint-disable-line

  if (payment)
    return (
      <DetailAdminPayment
        getData={getData}
        payment={payment}
        setPayment={setPayment}
        setService={setService}
      />
    );

  if (service) {
    switch (service.type) {
      case "Tutoría":
        return (
          <Tutoria
            tutorship={tutorship || service.data}
            setTutorship={setTutorship}
            // getData={getData}
            setService={setService}
            payment={service.payment}
            setPayment={setPayment}
          />
        );
      case "Taller":
        return (
          <Taller
            homework={homework || service.data}
            setHomework={setHomework}
            // getData={getData}
            setService={setService}
            payment={service.payment}
            setPayment={setPayment}
          />
        );
      case "Parcial":
        return (
          <Parcial
            partial={partial || service.data}
            setPartial={setPartial}
            // getData={updateDataTable}
            // subjectOptions={subjectOptions}
            setService={setService}
            payment={service.payment}
            setPayment={setPayment}
          />
        );
      default:
        break;
    }
  }

  const translateType = (type) => {
    if (type === "TUTORSHIP") return "Tutoría";
    if (type === "HOMEWORK") return "Taller";
    if (type === "PARTIAL") return "Parcial";
  };

  return (
    <div>
      <GridContainer>
        <GridItem style={{ fontSize: "1.1rem" }} xs={12} md={6} sm={6} lg={6}>
          <b className="good_font">Total cobros: </b>
          <FormatMoney value={debtsTutor} country={{ label: "Colombia" }} />
        </GridItem>
        {filter.tutor ? (
          <GridItem style={{ fontSize: "1.1rem" }} xs={12} md={6} sm={6} lg={6}>
            <b className="good_font">Total cobros de {filter.tutor} es </b>
            <FormatMoney
              value={debtTutorSelected}
              country={{ label: "Colombia" }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} md={6} sm={6} lg={6}></GridItem>
        )}
        <GridItem
          xs={12}
          md={4}
          sm={4}
          lg={4}
          style={{ alignItems: "center", display: "flex" }}
        >
          {/* <InputLabel>Estado del cobro</InputLabel> */}
          {/* <Autocomplete
            options={paymentsState.map((i) => ({
              value: i,
              label: paymentsStateTranslate(i),
            }))}
            defaultValue={{
              value: "ASKED",
              label: paymentsStateTranslate("ASKED"),
            }}
            onChange={(e, option) => {
              try {
                setState(option.value);
              } catch (error) {
                console.log(error);
              }
            }}
            getOptionLabel={(option) => option.label}
            // style={{ height: 50 }}
            size="small"
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado del cobro"
                variant="outlined"
              />
            )}
          /> */}
          <div style={{ width: "100%" }}>
            <CustomReactSelect
              placeholder={"Estado del cobro..."}
              options={paymentsState.map((i) => ({
                value: i,
                label: paymentsStateTranslate(i),
              }))}
              onChange={(e) => setState(e.value)}
            />
          </div>
          {/* <Select
            value={state}
            onChange={(e) => setState(e.target.value)}
            style={{ width: "50%" }}
          >
            {paymentsState.map((i, key) => (
              <MenuItem key={`${key}-${i}`} value={i}>
                {paymentsStateTranslate(i)}
              </MenuItem>
            ))}
          </Select> */}
        </GridItem>
        <GridItem
          xs={12}
          md={4}
          sm={4}
          lg={4}
          style={{ alignItems: "center", display: "flex" }}
        >
          {/* <Autocomplete
            fullWidth
            options={tutors}
            size="small"
            value={filter.tutor}
            onChange={(e, option) => {
              try {
                setFilter({ ...filter, tutor: option });
              } catch (error) {
                console.log(error);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tutor" variant="outlined" />
            )}
          /> */}
          {/* <InputLabel>Tutor</InputLabel> */}
          <div style={{ width: "100%" }}>
            <CustomReactSelect
              placeholder={"Tutor..."}
              options={[
                { value: "", label: "Todos" },
                ...tutors?.map((i) => ({ value: i._id, label: i.names })),
              ]}
              onChange={(e) => {
                if (e.label === "Todos") {
                  setFilter({ ...filter, tutor: "" });
                } else {
                  setFilter({ ...filter, tutor: e.label });
                }
              }}
            />
          </div>
          {/* <Select
            // value={tutors?.value}
            onChange={(e) => {
              const tutorSelected = tutors?.find(
                (i) => i._id === e.target.value
              );
              setFilter({ ...filter, tutor: tutorSelected.names });
            }}
          >
            <MenuItem value="">Todos</MenuItem>
            {tutors?.map((i) => (
              <MenuItem key={i._id} value={i._id}>
                {i.names}
              </MenuItem>
            ))}
          </Select> */}
        </GridItem>
        <GridItem xs={12} md={4} sm={4} lg={4}>
          <label className="good_font">Cobros hasta:</label>
          <CustomDatePicker
            value={filter.date}
            onChange={(e) => {
              setFilter({ ...filter, date: new Date(e?._d).setHours(23, 59) });
            }}
          ></CustomDatePicker>
        </GridItem>
      </GridContainer>
      <br /> <br />
      <MaterialTable
        onRowClick={(e, rowData) => setPayment(rowData)}
        isLoading={loading}
        title={"Cobros"}
        columns={[
          { title: "Tipo objeto", field: "type_object" },
          { title: "Tutor", field: "teacher.names" },
          { title: "Estado", field: "state" },
          { title: "Valor", field: "amount" },
          { title: "Fecha de ejecución", field: "event_date_f" },
          { title: "Fecha de cobro", field: "created_at" },
        ]}
        data={data
          .map((i) => ({
            ...i,
            type_object: translateType(i.type_object),
            created_at: mapDate(i.created_at),
            event_date_f: mapDate(i?.object?.event_date),
            db_state: i.state,
            state: paymentsStateTranslate(i.state),
          }))
          .sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          })}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: () => getData(),
          },
          (rowData) => ({
            icon: "warning",
            tooltip: "Cambiar estado",
            isFreeAction: false,
            onClick: () => changeStateColor(rowData),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSizeOptions: [5, 10, 20, 50, 100],
          rowStyle: (rowData) => ({
            backgroundColor: rowData.revision_pending,
          }),
        }}
      />
    </div>
  );
};

export default AdminPayments;
