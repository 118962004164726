import { css } from "@emotion/css";
import React from "react";
import { isMobile } from "react-device-detect";

const styles = isMobile
  ? css`
      .pdf--container {
        margin: 1rem 2rem;
        border-radius: 1rem;
        padding-bottom: 1rem;
        background-color: #e3e3e3;
        display: grid;
        grid-template-columns: minmax(300px, 2fr);
        gap: 1rem;
      }

      .img-pdf {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `
  : css`
      .pdf--container {
        margin: 1rem 2rem;
        border-radius: 1rem;
        padding-bottom: 1rem;
        background-color: #e3e3e3;
        display: grid;
        grid-template-columns: minmax(300px, 2fr) minmax(300px, 2fr);
        gap: 1rem;
      }

      .img-pdf {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `;

function StepRules({ addMaterial = false, origen }) {
  return (
    <div className={styles}>
      {addMaterial && (
        <Material origen={origen} />
        // <div className="pdf--container">
        //   <img
        //     className="img-pdf"
        //     alt=""
        //     src={require("../../../assets/img/rules/material_preparacion.png")}
        //   />
        // </div>
      )}
      <Instrucciones origen={origen} />
    </div>
  );
}

function Instrucciones({ origen }) {
  switch (origen) {
    case "Homework":
      return (
        <>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/homework/reglamento1.png")}
            />
            {/* </div> */}
            {/* <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/homework/reglamento2.png")}
            />
            {/* </div> */}
            {/* <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/homework/instrucciones.png")}
            />
          </div>
        </>
      );
    case "Tutorship":
      return (
        <>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/tutorship/reglamento1.png")}
            />
            {/* </div> */}
            {/* <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/tutorship/reglamento2.png")}
            />
            {/* </div> */}
            {/* <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/tutorship/instrucciones.png")}
            />
          </div>
        </>
      );

    default:
      return (
        <>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/reglamento_1.png")}
            />
            {/* </div>
          <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/reglamento_2.png")}
            />
            {/* </div>
          <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/reglamento_3.png")}
            />
            {/* </div>
          <div className="pdf--container"> */}
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/instrucciones.png")}
            />
          </div>
        </>
      );
  }
}

function Material({ origen }) {
  switch (origen) {
    case "Homework":
      return (
        <div className="pdf--container">
          <img
            className="img-pdf"
            alt=""
            src={require("../../../assets/img/rules/homework/material.png")}
          />
        </div>
      );

    case "Tutorship":
      return (
        <div className="pdf--container">
          <img
            className="img-pdf"
            alt=""
            src={require("../../../assets/img/rules/tutorship/material.png")}
          />
        </div>
      );

    default:
      return (
        <div className="pdf--container">
          <img
            className="img-pdf"
            alt=""
            src={require("../../../assets/img/rules/material_preparacion.png")}
          />
        </div>
      );
  }
}

export default StepRules;
