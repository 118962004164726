import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import SingleLoading from "components/Loading/SingleLoading";
import StatisticsService from "services/StatisticsService";
import { useSelector } from "react-redux";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Solicitudes copywrite",
    },
  },
};

export const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Ventar por solicitud",
    },
  },
};

export const options3 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Dinero venido",
    },
  },
};

export default function Graphs2({ filters }) {
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [timeLine, setTimeLine] = useState("2");

  const user = useSelector((state) => state.user.user);

  const getData = async () => {
    const res = await StatisticsService.countByWeek({
      token: user.token,
      year: filters.year,
    });
    if (res?.data?.data) {
      const filtredData = sortData(res?.data?.data?.data1);
      setData(filtredData);
    }
    const res2 = await StatisticsService.sellsTimeLine({ user });
    if (res2?.data?.data2) {
      const filtredData2 = sortData(res2?.data?.data2);
      setData2(filtredData2);

      const filtredData3 = sortData(res2?.data?.data3);
      setData3(filtredData3);
    }
  };

  const sortData = (array) => {
    const index1 = [];

    return {
      ...array,
      labels: array.labels?.filter((el, i) => {
        if (parseInt(el.substring(el.length - 4)) === filters.year) {
          index1.push(i);
          return el;
        }
        return false;
      }),
      datasets: array.datasets?.map((el) => {
        return {
          ...el,
          data: el.data.slice(index1[0], index1[index1.length - 1] + 1),
        };
      }),
    };
  };

  useEffect(() => {
    getData();
  }, [filters]); // eslint-disable-line

  if (data2 === null || data3 === null || data === null)
    return <SingleLoading />;
  return (
    <div>
      <div className="semana-select">
        Gráfica
        <select
          style={{ marginLeft: "5px" }}
          // value={timeLine}
          // defaultValue={"2"}
          onChange={(e) => setTimeLine(e.target.value)}
        >
          <option value="1">Solicitadas</option>
          <option value="2" selected>
            Vendidas
          </option>
          <option value="3">Ventas ($)</option>
        </select>
      </div>
      {timeLine === "1" && <Line options={options} data={data} />}
      {timeLine === "2" && <Line options={options2} data={data2} />}
      {timeLine === "3" && <Line options={options3} data={data3} />}
    </div>
  );
}
