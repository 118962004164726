import { useEffect, useState } from "react";

export const FakeLoading = ({ ms = 0, onLoading, onLoad }) => {
  const [out, setOut] = useState(false);

  useEffect(() => {
    setTimeout(() => setOut(true), ms);
  }, []); // eslint-disable-line

  return out ? onLoad : onLoading;
};
