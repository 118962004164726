import GridContainer from "components/Grid/GridContainer";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import Button from "@material-ui/core/Button";
import TeacherService from "services/TeacherService";
import PartialService from "services/PartialService";

const Pending = ({
  partial,
  loading,
  setLoading,
  user,
  onTriggerReload,
  getData,
}) => {
  const [newTeacher, setNewTeacher] = useState("");
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    getTeachers();
  }, []); //eslint-disable-line

  const getTeachers = async () => {
    setLoading(true);
    const res = await TeacherService.getAll({ user });
    if (res.message !== "OK") {
      //console.log(res.message);
    }
    setLoading(false);
    setTeachers(res.data);
  };

  const sendTeacherRequest = () => {
    if (newTeacher) {
      swal({
        title: "¿Seguro?",
        text: "Se enviará un SMS al profesor",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (ok) => {
        if (ok) {
          setLoading(true);

          const data = JSON.stringify({
            idPartial: partial._id,
            idTeacher: newTeacher,
          });

          const res = await PartialService.sendTeacherRequest({ user, data });
          if (res.data.message !== "OK") {
            //console.log("Hubo un error al enviar mensaje");
          } else {
            swal("Genial", "Solicitud enviada", "success");
            // setPartial(null);
            getData();
            setNewTeacher("");
            if (onTriggerReload) onTriggerReload();
          }

          setLoading(false);
        }
      });
    } else {
      swal("Error", "Seleccione un profe", "error");
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3>Elige un profesor y enviale una solicitud</h3>
      </GridItem>
      <GridItem xs={6} md={6} sm={6} lg={6}>
        <InputLabel>Profesores</InputLabel>
        <Select
          value={newTeacher}
          onChange={(e) => setNewTeacher(e.target.value)}
          fullWidth
        >
          {teachers.map((item, key) => (
            <MenuItem value={item._id} key={key}>
              {item.names}
            </MenuItem>
          ))}
        </Select>
      </GridItem>
      <GridItem xs={6} md={6} sm={6} lg={6}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            color="primary"
            variant={"contained"}
            size="large"
            onClick={sendTeacherRequest}
          >
            {"Enviar solicitud"}
          </Button>
        )}
      </GridItem>
      <GridItem xs={6} md={6} sm={6} lg={6}></GridItem>
    </GridContainer>
  );
};

export default Pending;
