import axios from "axios";
import { URL_API } from "service/config";

export default class FilesService {
  static async getData({ user }) {
    const config = {
      method: "get",
      url: URL_API + "subject",
      headers: {
        Authorization: user.token,
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async getMaterials({ user }) {
    const config = {
      method: "get",
      url: URL_API + "files",
      headers: {
        Authorization: user.token,
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }
}
