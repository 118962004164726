import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Icon, InputAdornment } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import "../../assets/css/styles.css";
import LoginService from "services/LoginService";
import swal from "@sweetalert/with-react";
import { useHistory, useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

const NewPassPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleClick = async () => {
    if (password !== repeatPassword) {
      swal("Error", "Las contraseñas deben ser iguales", "error");
      setPassword("");
      setRepeatPassword("");
    } else {
      setLoading(true);
      const resp = await LoginService.newPass({ token, password });
      if (resp.data.message !== "OK") {
        swal("Error", resp?.data?.message, "error");
        setPassword("");
        setRepeatPassword("");
      } else {
        swal("Success", "Se cambio la contraseña correctamente", "success");
        history.push("/auth");
      }
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card className={classes[cardAnimaton]}>
            <CardHeader style={{ textAlign: "center" }}>
              <img src={"/icon.png"} alt="" style={{ width: 100 }} />
            </CardHeader>
            <CardBody>
              <h4 className="title-forgotpass">Ingrese nueva contraseña: </h4>
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        {"lock_outline"}
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                  onChange: (e) => setPassword(e.target.value),
                  value: password,
                  required: true,
                }}
              />
              <CustomInput
                labelText="Repeat password"
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        {"lock_outline"}
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                  onChange: (e) => setRepeatPassword(e.target.value),
                  value: repeatPassword,
                  required: true,
                }}
              />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                type="button"
                color="success"
                loading={loading}
                onClick={handleClick}
              >
                {"Guardar"}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default NewPassPage;
