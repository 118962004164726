import { URL_API } from "service/config";
import GlobalService from "./GlobalService";

export default class PaymentService {
  static async findAll({ token, state }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-all?state=${state}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findAll2({ token, state, idTeacher }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-all?state=${state}&id_teacher=${idTeacher}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findbills({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-bills`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findByTeacher({ token, state }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-by-teacher?state=${state}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findComments({ token, idPayment }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-comments/${idPayment}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findTeacherWithApprovedPayments({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-teacher-with-approved-payments`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findBillsByTeacher({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-bills-by-teacher`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async findStateLogs({ token, idPayment }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `payment/find-state-log/${idPayment}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async addComment({ token, idPayment, text }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `payment/add-comment`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPayment,
        text,
      }),
    });
  }

  static async updateAmountByReturn({ token, objectID, newValue, typeObject }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `payment/update-amount-by-return`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idObject: objectID,
        typeObject,
        newValue,
      }),
    });
  }

  static async updateAmount({ token, idPayment, newAmount }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `payment/update-amount`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPayment,
        newAmount,
      }),
    });
  }

  static async chageState({ token, idPayment, newState }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `payment/change-state`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPayment,
        newState,
      }),
    });
  }

  static async confirmPayments({ token, idPayments, totalAmount, idTeacher }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `payment/confirm-payments`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPayments,
        totalAmount,
        idTeacher,
      }),
    });
  }

  static async changeStateColor({ user, color, _id }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `payment/change-state-color`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        color,
        _id,
      }),
    });
  }
}
