import React, { useEffect, useState } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Button from "components/CustomButtons/Button.js";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import {
  TextareaAutosize,
  InputLabel,
  Divider,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import SMSService from "services/SMSService";
import SmsIcon from "@material-ui/icons/Sms";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MaterialTable from "material-table";
import { getRowsCount } from "functions/comun";
import SmsTemplateService from "services/SmsTemplateService";
import { toast } from "react-toastify";
import { scrollToTop } from "functions/scrollToTop";
import Select from "react-select";
import SubjectService from "services/SubjectService";
import CustomDateTimePicker from "components/DatePicker/CustomDateTimePicker";
import { mapDateFormat } from "functions/comun";

const useStyles = makeStyles({
  root: (base) => ({
    ...base,
    display: "grid",
    alignContent: "center",
    height: "100%",
  }),
  control: (base) => ({
    ...base,
    fontFamily: "Poppins, sans-serif !important",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "Poppins, sans-serif !important",
  }),
});

const SMS = () => {
  const user = useSelector((state) => state.user.user);

  const [form, setForm] = useState({
    phoneNumber: "",
    message: "",
    messageTemplate: "",
    nameTemplate: "",
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [subjects, setSubjects] = useState();
  const [filter, setFilter] = useState();
  const [timer, setTimer] = useState(0);
  const [sms_s, setSms_s] = useState([]);
  const [join, setJoin] = useState(false);

  const handleSubmit = async (event) => {
    const message = form.message;
    const phoneNumber = form.phoneNumber.replaceAll(" ", "");

    event.preventDefault();

    if (!form.phoneNumber || form.phoneNumber.length < 10) {
      swal("Error", "Ingrese un numero de celular valido", "error");
      return;
    }

    if (!form.message) {
      swal("Error", "Ingrese un contenido del mensaje", "error");
      return;
    }

    swal("Mensajes enviados", "success");
    //console.log(form);
    setForm({
      ...form,
      phoneNumber: "",
      message: "",
    });

    setLoading(true);
    const res = await SMSService.sendSMS({ user, message, phoneNumber });
    if (res.message !== "OK") {
      swal("Error", res.message, "error");
      setLoading(false);
    } else {
      swal("Ok", "Se envio el SMS correctamente", "success");
      setForm({
        ...form,
        phoneNumber: "",
        message: "",
      });
      setLoading(false);
    }
  };

  const handleSubmitTemplate = async (event) => {
    event.preventDefault();

    setLoading(true);

    const data = {
      message: form.messageTemplate,
      name: form.nameTemplate,
    };
    const res = await SmsTemplateService.create({
      token: user.token,
      data,
    });
    if (res?.data?.message === "OK") {
      toast.success("Plantilla creada correctamente");
      setForm({
        ...form,
        messageTemplate: "",
        nameTemplate: "",
      });
      getData();
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const getData = async () => {
    setLoading(true);
    const res = await SmsTemplateService.findAll({ token: user.token });
    if (res?.data?.data) {
      setData(res.data.data);
    }
    setLoading(false);
  };

  const getSms_s = async () => {
    const data = await SMSService.getAllSMS({ user });
    // //console.log(data?.data);
    setSms_s(data?.data);
  };

  useEffect(() => {
    getData();
    getSubjects();
  }, []); // eslint-disable-line

  useEffect(() => {
    getSms_s();
  }, [timer]); //eslint-disable-line

  const onRowDelete = async (oldData) => {
    setLoading(true);
    const res = await SmsTemplateService.delete({
      token: user.token,
      id: oldData._id,
    });
    if (res.data.message === "DELETED") {
      swal("Ok", "Plantilla borrada correctamente", "success");
    }
    setLoading(false);
    getData();
  };

  const copyTemplate = (rowData) => {
    //console.log(rowData);
    scrollToTop();
    setForm({
      ...form,
      message: rowData.message,
    });
  };

  const classes = useStyles();

  const getSubjects = async () => {
    setLoading(true);
    const res = await SubjectService.findAll({ user });
    setLoading(false);
    setSubjects(res?.data);
  };

  const getFiltredData = async () => {
    setLoading(true);
    const res = await SMSService.findBySubject({ filter, user });
    if (res?.message === "data-filtred") {
      const phoneNumberNoRepeat = [];
      const phoneNumbers = res?.data.map((d) => {
        return d.phone;
      });
      for (let i = 0; i < phoneNumbers.length; i++) {
        if (phoneNumberNoRepeat.indexOf(phoneNumbers[i]) === -1)
          phoneNumberNoRepeat.push(phoneNumbers[i]);
      }
      if (join) {
        setForm({
          ...form,
          phoneNumber: form.phoneNumber
            ? form.phoneNumber + "," + phoneNumberNoRepeat.join(",")
            : "" + phoneNumberNoRepeat.join(","),
        });
      } else {
        setForm({
          ...form,
          phoneNumber: phoneNumberNoRepeat.join(","),
        });
      }
      if (phoneNumberNoRepeat.length === 0) toast.error("No hay coincidencias");
    }
    setLoading(false);
  };

  const sendTimer = async (event) => {
    const message = form.message;
    const phoneNumber = form.phoneNumber.replaceAll(" ", "");

    event.preventDefault();

    if (!form.phoneNumber || form.phoneNumber.length < 10) {
      swal("Error", "Ingrese un numero de celular valido", "error");
      return;
    }

    if (!form.message) {
      swal("Error", "Ingrese un contenido del mensaje", "error");
      return;
    }

    if (timer === null) {
      swal("Error", "Ingrese un fecha valida", "error");
      return;
    }

    swal("Mensajes programados para enviar", "success");
    // //console.log(form);
    setForm({
      ...form,
      phoneNumber: "",
      message: "",
    });

    setLoading(true);
    const res = await SMSService.sendTimer({
      user,
      message,
      phoneNumber,
      timer,
    });
    if (res.message !== "OK") {
      swal("Error", res.message, "error");
      setLoading(false);
    } else {
      swal("Ok", "Se envio el SMS correctamente", "success");
      setForm({
        ...form,
        phoneNumber: "",
        message: "",
      });
      getSms_s();
      setTimer(0);

      setLoading(false);
    }
  };

  const deleteSmsTimer = async ({ _id }) => {
    setLoading(true);
    const res = await SMSService.deleteTimer({ _id, user });
    if (res.message === "DELETED") {
      getSms_s();
    }
    setLoading(false);
  };

  const joinChange = (e) => {
    setJoin(e.target.checked);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <label className="good_font">Materia para filtrar telefonos</label>
        </GridItem>
        <GridItem xs={10} md={10} sm={10} lg={10}>
          <Select
            className={classes.root}
            options={subjects?.map((subject) => {
              return { label: subject.label, value: subject._id };
            })}
            onChange={(value) => {
              setFilter(value);
            }}
            value={filter}
          ></Select>
        </GridItem>
        <GridItem xs={2} md={2} sm={2} lg={2}>
          <Button color="success" fullWidth onClick={getFiltredData}>
            Filtrar
          </Button>
        </GridItem>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <Checkbox
            checked={join}
            onChange={joinChange}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          <label className="good_fonr">Juntar números de celular</label>
        </GridItem>
      </GridContainer>
      <br />
      <form onSubmit={handleSubmit}>
        <InputLabel>
          Ingrese números de celular separados por coma (,) <br />
          <br />
          Todos los números deben tener su prefijo (+57 por ejemplo)
        </InputLabel>
        <br />
        <TextareaAutosize
          required
          name="phoneNumber"
          value={form.phoneNumber}
          onChange={handleChange}
          margin="normal"
          rows={10}
          label="Números de celular"
          placeholder="Números de celular"
          style={{ width: "100%", borderRadius: 10 }}
        />
        <br />
        <TextareaAutosize
          required
          name="message"
          value={form.message}
          onChange={handleChange}
          margin="normal"
          rows={5}
          label="Mensaje"
          placeholder="Mensaje"
          style={{ width: "100%", borderRadius: 10 }}
          maxLength={1760}
        />
        <div
          style={{
            textAlign: "right",
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <span
            style={{
              paddingRight: 3,
              color: form.message.length > 0 ? "#1690FF" : "gray",
              fontWeight: "bold",
            }}
          >
            {Math.ceil(form.message.length / 160)}
          </span>
          <SmsIcon
            fontSize="small"
            style={{
              color: form.message.length > 0 ? "#1690FF" : "gray",
            }}
          />
          <span style={{ paddingLeft: 10 }}>
            {form.message.length} caracteres
          </span>
        </div>
        <Button type="submit" color="success" fullWidth loading={loading}>
          {"Enviar"}
        </Button>
      </form>

      <br />
      <GridContainer>
        <GridItem xs={8} md={8} sm={8} lg={8}>
          <CustomDateTimePicker
            setValue={(value) => {
              setTimer(value);
            }}
            componentWidth="100%"
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <Button
            onClick={sendTimer}
            color="success"
            fullWidth
            loading={loading}
          >
            {"Programar"}
          </Button>
        </GridItem>
      </GridContainer>
      <br />
      <MaterialTable
        title="Mensajes Programados"
        columns={[
          { title: "Mensaje", field: "text" },
          { title: "Números de celular", field: "phones" },
          { title: "Fecha de envío", field: "send_time" },
        ]}
        data={sms_s?.map((s) => {
          return {
            ...s,
            phones: s.phones?.replaceAll(",", ", "),
            send_time: mapDateFormat(s.send_time, "DD MMM YYYY, hh:mm A"),
          };
        })}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: getSms_s,
          },
          (rowData) => ({
            icon: "delete",
            tooltip: "Cancelar envío",
            onClick: (event, rowData) => {
              deleteSmsTimer(rowData);
            },
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          filtering: false,
          grouping: false,
        }}
      />

      <br />
      <Divider />
      <br />
      <form onSubmit={handleSubmitTemplate}>
        <GridContainer>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <h3>Plantillas</h3>
          </GridItem>
          <GridItem
            xs={6}
            md={4}
            sm={4}
            lg={4}
            style={{ placeSelf: "center", textAlign: "-webkit-center" }}
          >
            <input
              type="text"
              label="Nombre"
              placeholder="Nombre"
              name="nameTemplate"
              value={form.nameTemplate}
              onChange={handleChange}
              style={{ borderRadius: 5, padding: 6 }}
              required
            />
          </GridItem>
          <GridItem
            xs={6}
            md={4}
            sm={4}
            lg={4}
            style={{ placeSelf: "center", textAlign: "-webkit-center" }}
          >
            <TextareaAutosize
              required
              name="messageTemplate"
              value={form.messageTemplate}
              onChange={handleChange}
              margin="normal"
              rows={5}
              label="Mensaje"
              placeholder="Mensaje"
              style={{ width: "100%", borderRadius: 10 }}
              maxLength={1760}
            />
          </GridItem>
          <GridItem
            xs={6}
            md={4}
            sm={4}
            lg={4}
            style={{ placeSelf: "center", textAlign: "-webkit-center" }}
          >
            <Button type="submit" color="success" fullWidth loading={loading}>
              {"Guardar"}
            </Button>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <MaterialTable
            title={"Plantillas guardadas"}
            columns={[
              { title: "Nombre", field: "name" },
              { title: "Mensaje", field: "message" },
            ]}
            key={data.length}
            data={data}
            options={{
              actionsColumnIndex: -1,
              pageSize: getRowsCount(data),
              filtering: false,
              grouping: false,
              search: false,
            }}
            isLoading={loading}
            editable={{
              onRowDelete: onRowDelete,
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Recargar",
                isFreeAction: true,
                onClick: getData,
              },
              (rowData) => ({
                icon: FileCopyIcon,
                tooltip: "Copiar",
                isFreeAction: false,
                onClick: () => copyTemplate(rowData),
              }),
            ]}
          />
        </GridItem>
      </form>
    </div>
  );
};

export default SMS;
