import { Container } from "@material-ui/core";
import ScheduleButton from "components/Buttons/ScheduleButton";
import GridItem from "components/Grid/GridItem";
import React from "react";

const EndStep = () => {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "60px",
      }}
    >
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "ledt", marginBottom: "20px" }}
      >
        <h3
          style={{ textAlign: "center", fontSize: "1.5rem" }}
          className="textBoldPurple"
        >
          ¡Eso sería todo!
        </h3>
      </GridItem>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "left" }}
        className="stepone--text"
      >
        <h5 className="noMargin">
          <b>
            Recuerda que el material de preparación te lo pediremos recién hagas
            el pago.
          </b>
        </h5>
      </GridItem>
      <br />
      <GridItem>
        <br />
        <span
          style={{ display: "block", fontSize: "1rem" }}
          className="textBoldYellow"
        >
          Cualquier duda escríbenos a nuestro WhatsApp
        </span>
      </GridItem>
      <br />
      <br />
      <GridItem>
        <ScheduleButton
          text="Agendar algo más"
          link="https://www.copywritecol.com/"
        ></ScheduleButton>
      </GridItem>
    </Container>
  );
};

export default EndStep;
