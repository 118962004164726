import axios from "axios";
import { URL_API } from "service/config";
import GlobalService from "./GlobalService";

export default class TutorService {
  static async getAll({ user, state }) {
    let config = {
      method: "get",
      url: URL_API + "tutorship",
      headers: { Authorization: user.token },
    };
    if (state === undefined) {
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    } else {
      config.url = URL_API + `tutorship/filter?state=${state}`;
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    }
  }

  static async getFilterColor({ user, state, color }) {
    const colorH = color.slice(1);
    var config = {
      method: "get",
      url: URL_API + `tutorship/filter?color=${colorH}`,
      headers: { Authorization: user.token },
    };
    if (state === "ALL") {
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    } else {
      const colorH = color.slice(1);
      config = {
        method: "get",
        url: URL_API + `tutorship/filter?state=${state}&color=${colorH}`,
        headers: { Authorization: user.token },
      };
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    }
  }

  static async findByIdPublic({ idTutorship }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "tutorship/" + idTutorship,
      headers: {},
    });
  }

  static async findAllMarketplace({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}tutorship/find-marketplace`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async findByTeacher({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "tutorship/find-by-teacher",
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async findByIdTeacherDetail({ idTutorship, user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "tutorship/teacher-detail/" + idTutorship,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async acceptTutorshipTeacher({ user, data }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "tutorship/accept-tutorship",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static async rejectTutorshipTeacher({ user, data }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "tutorship/reject-tutorship",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static async delete({ token, id }) {
    return GlobalService.sentRequest({
      method: "delete",
      url: `${URL_API}tutorship/delete/${id}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async addToSalesExcel({ user, idTutorship, debtToTeacher,uSelected }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `tutorship/add-to-sales-excel`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        adminUser: user.names,
        idTutorship,
        uSelected: uSelected.label,
        debtToTeacher,
      }),
    });
  }

  static async sendTutorRequests({ token, idTutorship }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `tutorship/send-teacher-requests`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idTutorship,
      }),
    });
  }

  static async getTeacherRequestByTutorship({ token, idTutorship }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `partial/teacher-request/tutorship-id/${idTutorship}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async assignTeacherManual({ token, idTutorship, idTeacher }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `tutorship/assign-teacher-manually`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idTutorship,
        idTeacher,
      }),
    });
  }

  static async findTeacherById({ idTeacher, token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}teacher/find/idTeacher/${idTeacher}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async editValue({ idTutorship, newValue, user }) {
    return GlobalService.sentRequest({
      method: "put",
      url: `${URL_API}tutorship`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idTutorship,
        newValue,
      }),
    });
  }

  static async updateCost({ token, newCost, idTutorship }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `tutorship/update-cost`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        newCost,
        idTutorship,
      }),
    });
  }

  static findTutorshipsForPayment({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `tutorship/payment/find-tutorships-for-payment`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async savePayment({ idTutorship, file, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file.item(0));
    data.append("idTutorship", idTutorship);
    var config = {
      method: "post",
      url: `${URL_API}tutorship/save-payment`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async savePayment2({ file, idTutorship, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file);
    data.append("idTutorship", idTutorship);
    var config = {
      method: "post",
      url: `${URL_API}tutorship/save-payment2`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async updateDate({ token, event_date, event_time, _id }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `tutorship/update-date`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        event_date,
        _id,
        event_time,
      }),
    });
  }

  static async addReturn({ user, textReturn, idTutorship, date }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `tutorship/add-return`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        textReturn,
        idTutorship,
        date,
      }),
    });
  }

  static async findByIdAdmin({ id }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "tutorship/" + id,
      headers: {},
    });
  }

  static async uploadMaterial({ file, idTutorship }) {
    //console.log("uploadMaterial");
    var FormData = require("form-data");

    //console.log("FormData:", FormData);

    var data = new FormData();

    //console.log("data:", data);

    data.append("file", file);
    data.append("idTutorship", idTutorship);

    //console.log("data:", data);

    var config = {
      method: "post",
      url: `${URL_API}tutorship/client/upload-material`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async updateTextMaterial({ text, idTutorship }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `tutorship/client/update-text-material-tutorship`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idTutorship,
        text,
      }),
    });
  }

  static async getMaterials({ id }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `tutorship/client/find-material-by-tutorship/${id}`,
      headers: {},
    });
  }

  static async updateName({ user, idTutorship, name }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `tutorship/update-name`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: { idTutorship, name },
    });
  }

  static async updateUniversity({ token, university, idTutorship }){
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `tutorship/update-university`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idTutorship,
        university
      }),
    });
  }
}
