import React from "react";
import Select from "react-select";

const stylesLocal = {
  control: (base) => ({
    ...base,
    fontFamily: "Poppins, sans-serif !important",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "Poppins, sans-serif !important",
  }),
};

const CustomReactSelect = (props) => {
  return <Select {...props} styles={{ ...stylesLocal, ...props?.styles }} />;
};

export default CustomReactSelect;
