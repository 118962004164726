import axios from "axios";
import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class LoginService {
  static async login({ username, password }) {
    var config = {
      method: "post",
      url: URL_API + "auth/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        username,
        password,
      }),
    };
    try {
      const response = await axios(config);
      return response;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }
  static async forgotPass({ email }) {
    const data = JSON.stringify({ email });
    return GlobalService.sentRequest({
      method: "put",
      url: `${URL_API}auth/forgot-password`,
      data,
      headers: { "Content-Type": "application/json" },
    });
  }
  static async newPass({ token, password }) {
    const data = JSON.stringify({ token, password });
    return GlobalService.sentRequest({
      method: "put",
      url: `${URL_API}auth/new-password`,
      data,
      headers: { "Content-Type": "application/json" },
    });
  }
}
