import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import PartialService from "services/PartialService";
import { toast } from "react-toastify";
import TutorService from "services/TutorService";
import TallerService from "services/TallerService";

export const SearchById = ({ setService, context }) => {
  const [idForSearch, setIdForSearch] = useState();
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    switch (context) {
      case "partial":
        if (idForSearch) {
          setLoading(true);
          const res = await PartialService.findByIdAdmin({
            idPartial: idForSearch,
          });
          if (res?.data?.data) {
            setService(res?.data?.data);
            // scrollToTop();
          } else {
            toast.error("Parcial no encontrado.");
          }
          setLoading(false);
        }
        break;
      case "tutorship":
        if (idForSearch) {
          setLoading(true);
          const res = await TutorService.findByIdAdmin({
            id: idForSearch,
          });
          if (res?.data?.data) {
            setService(res?.data?.data);
            // scrollToTop();
          } else {
            toast.error("Tutoía no encontrada.");
          }
          setLoading(false);
        }
        break;
      case "homework":
        if (idForSearch) {
          setLoading(true);
          const res = await TallerService.findByIdAdmin({
            id: idForSearch,
          });
          if (res?.data?.data) {
            setService(res?.data?.data);
            // scrollToTop();
          } else {
            toast.error("Taller no encontrado.");
          }
          setLoading(false);
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h4>Buscar por ID:</h4>
        <input
          type="text"
          value={idForSearch}
          onChange={(e) => setIdForSearch(e.target.value)}
        />
        <br />
        <Button
          style={{
            backgroundColor: "#f9004d",
            color: "white",
            borderRadius: "10px",
          }}
          variant="contained"
          onClick={handleSearch}
          loading={loading}
        >
          Buscar
        </Button>
      </GridItem>
    </>
  );
};
