import { Button, Grid } from "@material-ui/core";
import FormatMoney from "components/Money/FormatMoney";
import { partialStateTranslate } from "functions/comun";
import { mapDate } from "functions/comun";
import useQuery from "hooks/useQuery";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PartialService from "services/PartialService";
import TallerService from "services/TallerService";
import TutorService from "services/TutorService";
import ConfirmService from "services/confirmService";
import swal from "sweetalert";

function ConfirmPay() {
  //630852ed880f6039bab0dac1 Edwin
  const user = useSelector((state) => state.user.user); //63090b9c8d2f4667e9c5ec09  Julian
  const { type, id } = useParams();
  const test = useQuery();
  const [data, setData] = useState({});
  const [service, setService] = useState("");
  const payMetod = test.get("payMetod");

  const getData = async () => {
    switch (type) {
      case "partial":
        const partialRes = await PartialService.findByIdPublic({
          idPartial: id,
        });
        if (partialRes?.data?.data) {
          setData(partialRes?.data?.data);
          setService("Parcial");
        }
        break;
      case "tutorship":
        const tutorshipRes = await TutorService.findByIdPublic({
          idTutorship: id,
        });
        if (tutorshipRes?.data?.data) {
          setData(tutorshipRes?.data?.data);
          setService("Tutoría");
        }
        break;
      case "homework":
        const homeworkRes = await TallerService.findByIdAdmin({ id });
        if (homeworkRes?.data?.data) {
          setData(homeworkRes?.data?.data);
          setService("Taller");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const style = { padding: "1.5rem 0rem" };

  const confirmButton = () => {
    swal("¿Deseas continar co la confimación de este servicio?", {
      buttons: {
        cancel: "Cancelar",
        Confirmar: true,
      },
    }).then(async (value) => {
      switch (value) {
        case "Confirmar":
          const res = await ConfirmService.confirm({
            user,
            typeObject: type,
            idObject: id,
          });
          console.log(res?.data?.data);
          if (res?.data?.data?.ready) swal("Servicio confirmado correctamente");
          break;

        default:
          break;
      }
    });
  };

  if (user.id !== "63090b9c8d2f4667e9c5ec09")
    return <Redirect from="/admin" to="/admin/dashboard" />;
  //if (user.id !== "630852ed880f6039bab0dac1")
  //return <Redirect from="/admin" to="/admin/dashboard" />;

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12} sm={12} lg={12} style={{ style }}>
          <div style={{ textAlign: "center" }}>
            <h1>{data?.subject}</h1>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <div>
            ID: <b>{data?._id}</b>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Cliente:</b>
          {data?.client}
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Celular: </b>
          {data?.phone}
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Recibido: </b>
          {data?.inserted_at}
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Valor: </b>
          <FormatMoney value={data?.cost} country={{ label: "Colombia" }} />
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Fecha parcial: </b>
          {mapDate(data?.event_date)}
        </Grid>
        {type !== "homework" && (
          <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
            <>
              <b>Duración: </b>
              {`${data?.event_time} ${type === "partial" ? "min" : "hora/s"}`}
            </>
          </Grid>
        )}
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Estado: </b>
          {partialStateTranslate(data?.state)}
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Servicio: </b>
          {service}
        </Grid>
        <Grid item xs={12} md={6} sm={3} lg={3} style={{ style }}>
          <b>Método de pago: </b>
          {payMetod}
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div style={{ textAlign: "center" }}>
            <table style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }}>
                <td style={{ textAlign: "center" }}>
                  {data?.paymentBill && (
                    <>
                      <a
                        href={data.paymentBill}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Ver comprobante principal{" -"}
                      </a>
                    </>
                  )}
                </td>
              </th>
              {data?.paymentBill2?.map((item, index, arr) => {
                return (
                  <th style={{ textAlign: "center" }}>
                    <td style={{ textAlign: "center" }}>
                      <a
                        href={item}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Comprobate adicional {index + 1}
                        {arr.length > index + 1 && " -"}
                      </a>
                    </td>
                  </th>
                );
              })}
            </table>
          </div>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              paddingRight: "1rem",
            }}
          >
            {!data?.ready ? (
              <Button
                classes={{ label: "buttonLabel" }}
                color="primary"
                variant="contained"
                onClick={confirmButton}
              >
                Confirmar
              </Button>
            ) : (
              <Button
                classes={{ label: "buttonLabel" }}
                color="black"
                variant="contained"
                onClick={() => toast.warning("Este servicio ya fue confirmado")}
              >
                Servicio ya confirmado
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConfirmPay;
