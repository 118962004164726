import { css } from "@emotion/css";
import React from "react";
import { isMobile } from "react-device-detect";

function StepMaterial({ origen }) {
  const styles = isMobile
    ? css`
        .pdf--container {
          margin: 1rem 2rem;
          border-radius: 1rem;
          padding-bottom: 1rem;
          background-color: #e3e3e3;
          display: grid;
          grid-template-columns: minmax(300px, 2fr);
          gap: 1rem;
        }

        .img-pdf {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      `
    : css`
        .pdf--container {
          margin: 1rem 2rem;
          border-radius: 1rem;
          padding-bottom: 1rem;
          background-color: #e3e3e3;
          display: grid;
          grid-template-columns: minmax(300px, 2fr) minmax(300px, 2fr);
          gap: 1rem;
        }

        .img-pdf {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      `;

  switch (origen) {
    case "Homework":
      return (
        <div className={styles}>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/homework/material.png")}
            />
          </div>
        </div>
      );

    case "Tutorship":
      return (
        <div className={styles}>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/tutorship/material.png")}
            />
          </div>
        </div>
      );

    default:
      return (
        <div className={styles}>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/material_preparacion.png")}
            />
          </div>
        </div>
      );
  }
}

export default StepMaterial;
