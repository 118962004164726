import React, { useState } from "react";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { mapDate } from "functions/comun";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TutorService from "services/TutorService";
import WhatsappIconYellow from "assets/customIcons/WhatsappIconYellow";

const getTeacherAssigned = (data) => {
  if (data?.names) return data?.names;
  return "SIN TUTOR";
};

const openWhatsApp = (teacher, tutorship) => {
  const text = `🚀 ¡Hola ${
    teacher.names
  }!, tenemos esta tutoría en *Copywrite App*\n\n*Materia:* ${
    tutorship.subject
  }.\n*Fecha:* ${mapDate(tutorship.event_date)}.\n*Duración de la tutoría:* ${
    tutorship.event_time
  } horas.\n*Material de Preparación:* ${
    window.location.origin
  }/teacher/tutorias/${tutorship._id}.\n\n🌀¿Puedes tomarla?`;

  const url = `https://api.whatsapp.com/send?phone=${
    teacher.phone
  }&text=${encodeURIComponent(text)}`;
  window.open(url, teacher._id);
};

const openWhatsAppOff = (teacher, tutorship) => {
  const text = `👋 ¡Hola ${
    teacher.names
  }!, tenemos esta *Tutoría en Oferta* en *Copywrite App.*\n\n*Materia:* Probabilidad y Estadística 2 (Con R ó Excel).\n*Fecha:* ${mapDate(
    tutorship.event_date
  )} .\n*Duración de la tutoría:* ${
    tutorship.event_time
  } horas.\n*Material de Preparación:* ${
    window.location.origin
  }/teacher/tutorias/${
    tutorship._id
  }.\n\n✅ Por ser *Tutoría de Oferta se pagan 5000 COP/Hora adicionales*\n\n🎈 ¿Puedes tomarla?`;

  const url = `https://api.whatsapp.com/send?phone=${
    teacher.phone
  }&text=${encodeURIComponent(text)}`;
  window.open(url, teacher._id);
};

export const MiniComponentTeacherAssigned = ({ teacherData, tutorship }) => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const sendTutorRequests = () => {
    swal({
      title: "¿Seguro?",
      text: `Se enviarán solicitudes a todos los tutores asignados en ${tutorship.subject}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        setLoading(true);
        const res = await TutorService.sendTutorRequests({
          token: user.token,
          idTutorship: tutorship._id,
        });
        if (res?.data) {
          toast.success("Solicitudes enviadas!");
          const reloadBtn = document.getElementById(
            `handle_reload_teacher_requests_tutorship_${tutorship._id}`
          );
          if (reloadBtn && reloadBtn.click) reloadBtn.click();
        }
        setLoading(false);
      }
    });
  };

  return (
    <div>
      Profesor asignado: {getTeacherAssigned(teacherData)}
      {teacherData?._id ? (
        <>
          <IconButton onClick={() => openWhatsApp(teacherData, tutorship)}>
            <WhatsappIcon />
          </IconButton>
          <IconButton onClick={() => openWhatsAppOff(teacherData, tutorship)}>
            <WhatsappIconYellow />
          </IconButton>
        </>
      ) : (
        <div>
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <Button
              size="small"
              style={{ backgroundColor: "rgb(220, 0, 78)", color: "white" }}
              variant="contained"
              color="secondary"
              onClick={sendTutorRequests}
            >
              <small>Enviar solicitudes</small>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
