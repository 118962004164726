import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { mapDate } from "functions/comun";
import { getRowsCount } from "functions/comun";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import NewTeacher from "./NewTeacher";
import TeacherService from "services/TeacherService";
import swal from "sweetalert";
import InfoTeacher from "views/Pages/InfoTeacher";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";

const Tutores = () => {
  const user = useSelector((state) => state.user.user);

  const [teacher, setTeacher] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    getData();
  }, []); //eslint-disable-line

  const getData = async () => {
    setLoading(true);
    const res = await TeacherService.getAll({ user });
    setLoading(false);
    setTeachers(res.data);
  };

  const onRowClick = (e, rowData) => setTeacher(rowData);

  // const onRowUpdateTeacher = async (newData, oldData) => {
  //   const { phone } = newData;
  //   newData.phone = Number(phone);
  //   const res = await TeacherService.updateTeacher({ user, newData });
  //   if (res.data.message !== "OK") {
  //     //console.log(res.data.message);
  //   }
  //   swal("Ok", "Profesor actualizado con exito", "success");
  //   getData();
  // };

  const onRowDeleteTeacher = async (oldData) => {
    const res = await TeacherService.deleteTeacher({ user, oldData });
    if (res.data.message !== "OK") {
      //console.log(res.data.message);
    }
    swal("Ok", "Profesor borrado con exito", "success");
    getData();
  };

  const openWapp = (teacher) => {
    if (teacher && teacher?.phone) {
      const url = `https://api.whatsapp.com/send?phone=${teacher?.phone}&text=Hola ${teacher?.names}, te escribimos de CopyWrite.`;
      window.open(url, teacher._id);
    }
  };

  if (teacher !== null)
    return (
      <InfoTeacher
        loading={loading}
        token={user.token}
        teacher={teacher}
        setTeacher={setTeacher}
      />
    );

  return (
    <GridContainer>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <NewTeacher
          user={user}
          loading={loading}
          setLoading={setLoading}
          getData={getData}
        />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <hr></hr>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <MaterialTable
          onRowClick={onRowClick}
          title={"Tutores"}
          columns={[
            { title: "Nombres", field: "names" },
            { title: "Celular", field: "phone" },
            { title: "Correo", field: "email" },
            { title: "Temp Password", field: "temp_password" },
            { title: "Creado", field: "created_at", editable: "never" },
          ]}
          key={teachers.length}
          data={teachers.map((item) => ({
            ...item,
            created_at: mapDate(item.created_at),
          }))}
          localization={{
            toolbar: {
              searchPlaceholder: "Buscar",
            },
          }}
          editable={{
            // onRowUpdate: onRowUpdateTeacher,|
            onRowDelete: onRowDeleteTeacher,
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Recargar",
              isFreeAction: true,
              onClick: getData,
            },
            (rowData) => ({
              icon: WhatsappIcon,
              tooltip: "WhatsApp",
              isFreeAction: false,
              onClick: () => openWapp(rowData),
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: getRowsCount(teachers),
            //filtering: true,
            //grouping: true,
          }}
          isLoading={loading}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Tutores;
