import NextPartialButton from "components/CustomButtons/NextPartialButton";
import SingleLoading from "components/Loading/SingleLoading";
import React, { useState, useEffect } from "react";
import TallerService from "services/TallerService";
import { mapDateFormat } from "functions/comun";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TalleresPage = () => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [homeworks, setHomeworks] = useState([]);
  const history = useHistory();

  const getHomeworks = async () => {
    setLoading(true);
    const res = await TallerService.getByTeacherId({ user });
    setHomeworks(
      res?.data?.data.filter(
        (item) => filterByDate(item) && item.is_inserted_at_sales_excel
      )
    );
    setLoading(false);
  };

  useEffect(() => {
    getHomeworks();
  }, []); // eslint-disable-line

  const filterByDate = (item) => {
    const now = new Date().getTime();
    const EventDate = new Date(item.event_date).getTime();
    if (now > EventDate) {
      return false;
    }
    return true;
  };

  if (loading) return <SingleLoading size={50} />;

  return (
    <div className="container--nextpartials">
      <h3 className="npartials--title">Talleres Agendados</h3>
      {homeworks.length === 0 && (
        <span className="npartials--span">No hay talleres disponibles</span>
      )}
      {homeworks.map((item) => (
        <div key={item._id}>
          <NextPartialButton
            backgroundColor="#f1d67b"
            onHandleClick={() => history.push(`/teacher/talleres/${item._id}`)}
            state={item.state}
            id={item._id}
            subject={item.subject}
            durationInMinutes={item.event_time}
            costInCOP={item.cost}
            date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
          />
          <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
        </div>
      ))}
    </div>
    // <div style={{ color: "#ffbc01", textAlign: "center" }}>
    //   <h2>
    //     <b>!Pronto estará disponible!</b>
    //   </h2>
    //   <img
    //     src="http://torcasa.cr/images/load.gif"
    //     style={{ width: 100 }}
    //     alt=""
    //   />
    // </div>
  );
};

export default TalleresPage;
