import React from "react";
import GridItem from "components/Grid/GridItem";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IconButton } from "@material-ui/core";

const GoBack = ({ back, gridItemStyle }) => {
    return (
        <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "left", ...gridItemStyle }}>
            <IconButton onClick={back}>
                <KeyboardBackspaceIcon fontSize="large" />
            </IconButton>
        </GridItem>
    );
};

export default GoBack;
