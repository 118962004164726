import { getTokenSession } from "utils/cookieUtil";
import {
  LOGIN,
  LOGOUT,
  LOADING_UPDATE_USER,
  SUCCESS_UPDATE_USER,
  FAILURE_UPDATE_USER,
  LOADING_UPDATE_PASSWORD,
  SUCCESS_UPDATE_PASSWORD,
  FAILURE_UPDATE_PASSWORD,
} from "./type";

const parseJsonLocalStorage = () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  } catch (error) {
    return {};
  }
};

const isCookie = () => {
  const token = getTokenSession();
  if (token) {
    return localStorage.getItem("user") !== null;
  }
  localStorage.removeItem("user");
  return false;
};

const initialState = {
  isLogged: isCookie(),
  user: isCookie() ? parseJsonLocalStorage() : {},
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogged: true,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLogged: false,
        user: {},
      };
    case LOADING_UPDATE_USER:
    case LOADING_UPDATE_PASSWORD:
      return {
        ...state,
        loading: action.payload,
      };
    case FAILURE_UPDATE_USER:
    case FAILURE_UPDATE_PASSWORD:
      console.error(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUCCESS_UPDATE_USER:
    case SUCCESS_UPDATE_PASSWORD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
