import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { useDispatch } from "react-redux";
import { removeUserAction } from "redux/user/userActions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const { rtlActive } = props;
    const wrapper = classNames({
        [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
        [classes.managerClasses]: true,
    });

    const dispatch = useDispatch();
    const removeUser = () => dispatch(removeUserAction());
    const logOut = () => removeUser();

    return (
        <div className={wrapper}>
            <div className={managerClasses}>
                <Button
                    color="transparent"
                    justIcon
                    aria-label="Salir"
                    aria-haspopup="true"
                    onClick={logOut}
                    className={classes.buttonLink}
                    muiClasses={{
                        label: "",
                    }}
                    title="Salir"
                >
                    <ExitToAppIcon title="Salir" className={classes.headerLinksSvg + " " + classes.links} />
                    <Hidden title="Salir" mdUp implementation="css">
                        <span onClick={logOut} className={classes.linkText}>
                            {"Salir"}
                        </span>
                    </Hidden>
                </Button>
            </div>
        </div>
    );
}

HeaderLinks.propTypes = {
    rtlActive: PropTypes.bool,
};
