import React from "react";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import "./btn-next-partial.css";

const NextPartialButton = ({
  state,
  id,
  subject,
  durationInMinutes,
  date,
  onHandleClick,
  backgroundColor,
  time,
}) => {
  return (
    <button
      style={{ cursor: "pointer" }}
      className="next-partial-btn"
      onClick={onHandleClick}
    >
      <div className="container__next-partial-btn" style={{ backgroundColor }}>
        <div className="partial-btn__container">
          <h4 className="partial-btn__subject">{subject}</h4>
          <h4 className="partial-btn__date">
            <strong>Fecha: </strong>
            {date}
          </h4>
          <h4 className="partial-btn__duration">
            {durationInMinutes ? (
              <>
                <strong>Duración: </strong>
                {`${durationInMinutes} ${time}`}
              </>
            ) : (
              ""
            )}
            {/* <strong>Duración: </strong>
            {durationInMinutes? durationInMinutes + "Horas" : ""} */}
          </h4>
          <div>
            {isPartialPayed(state) && (
              <AttachMoneyIcon fontSize="large" style={{ color: "green" }} />
            )}
          </div>
        </div>
      </div>
    </button>
  );
};

const isPartialPayed = (state) => {
  if (!state) return false;
  return (
    state === "PAYMENT_ACCEPTED" || state === "PAYMENT_ACCEPTED_NO_TEACHER"
  );
};

export default NextPartialButton;
