import { css } from "@emotion/css";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import EndStep from "./endStep";
import StartStep from "./startStep";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 0px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const styles = css`
  .pdf--container {
    margin: 1rem 2rem;
    border-radius: 1rem;
    padding-bottom: 1rem;
    background-color: #e3e3e3;
    display: grid;
    grid-template-columns: minmax(300px, 2fr);
  }

  .img-pdf {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const HomeworkInfo = () => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const steps = ["Start", "Garantía", "Reglas", "Material", "Instr"];

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <StartStep />;
      case 1:
        return (
          <div className={styles}>
            <div className="pdf--container">
              <img
                className="img-pdf"
                alt=""
                src={require("../../../../assets/img/rules/garantia.png")}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles}>
            <div className="pdf--container">
              <img
                className="img-pdf"
                alt=""
                src={require("../../../../assets/img/rules/homework/reglamento1.png")}
              />
            </div>
            <div className="pdf--container">
              <img
                className="img-pdf"
                alt=""
                src={require("../../../../assets/img/rules/homework/reglamento2.png")}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles}>
            <div className="pdf--container">
              <img
                className="img-pdf"
                alt=""
                src={require("../../../../assets/img/rules/homework/material.png")}
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles}>
            <div className="pdf--container">
              <img
                className="img-pdf"
                alt=""
                src={require("../../../../assets/img/rules/homework/instrucciones.png")}
              />
            </div>
          </div>
        );
      case 5:
        return <EndStep />;
      default:
        break;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      {activeStep < 5 && (
        <Box sx={{ width: "100%" }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={css`
              padding: 10px !important;
            `}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>
                  <small className="justText">
                    <b>{label}</b>
                  </small>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <div>{getStepContent()}</div>
      <div
        style={{
          textAlign: "center",
          marginTop: "50px",
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 20,
        }}
      >
        {activeStep !== 0 && (
          <Button
            onClick={handleBack}
            className={classes.backButton}
            classes={{ label: "buttonLabel" }}
          >
            Atrás
          </Button>
        )}
        {activeStep === steps.length ? null : (
          <Button
            variant="contained"
            color="primary"
            classes={{ label: "buttonLabel" }}
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}
      </div>
    </div>
  );
};

export default HomeworkInfo;
