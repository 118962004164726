import SingleLoading from "components/Loading/SingleLoading";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StatisticsService from "services/StatisticsService";

import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Divider } from "@material-ui/core";
import moment from "moment";

const Graphs1 = () => {
  const [loading, setLoading] = useState(false);
  const [dataPie1, setDataPie1] = useState();
  const [dataPie2, setDataPie2] = useState();

  const [week, setWeek] = useState("ALL");

  const user = useSelector((state) => state.user.user);

  const getMainStats = async (weekParam) => {
    setLoading(true);
    const res = await StatisticsService.mainStats({
      token: user.token,
      week: weekParam,
    });
    if (res?.data?.data) {
      const dataToSet = res.data.data;
      setDataPie1({
        labels: [
          "Parciales " + dataToSet.percentage.partialsByWeek?.toFixed(2) + "%",
          "Tutorias " + dataToSet.percentage.tutorshipsByWeek?.toFixed(2) + "%",
          "Talleres " + dataToSet.percentage.homeworksByWeek?.toFixed(2) + "%",
        ],
        datasets: [
          {
            label: "Solicitudes totales",
            data: [
              dataToSet.partialsByWeek,
              dataToSet.tutorshipsByWeek,
              dataToSet.homeworksByWeek,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
    const res2 = await StatisticsService.adminStats({
      token: user.token,
      week: weekParam,
    });

    if (res2?.data) {
      const labels = [];
      const data = [];
      for (let i of res2.data) {
        labels.push(i.name);
      }
      for (let i of res2.data) {
        data.push(i.count);
      }
      setDataPie2({
        labels,
        datasets: [
          {
            label: "Ventas por agentes",
            data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba( 99,255, 132, 0.2)",
              "rgba(54, 235,162,  0.2)",
              "rgba( 206,255, 86, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba( 99,255, 132, 1)",
              "rgba(54,  235,162, 1)",
              "rgba(, 206,255 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getMainStats(week);
  }, []); // eslint-disable-line

  const handleChange = (e) => {
    const weekToSet = e.target.value;
    setWeek(weekToSet);
    getMainStats(weekToSet);
  };

  if (loading || !dataPie1) return <SingleLoading />;

  const options = Array.from(
    { length: moment().week() },
    (_, i) => i + 1
  ).reverse();

  return (
    <div>
      <div className="semana-select">
        Número de semana
        <select
          style={{ marginLeft: "5px" }}
          value={week}
          onChange={handleChange}
        >
          <option value="ALL">Todo</option>
          {options.map((i) => (
            <option value={i} key={i}>
              Semana {i}
            </option>
          ))}
        </select>
      </div>
      <Divider />
      <br />
      <GridContainer className={"container-pie"}>
        <GridItem xs={12} md={6} sm={6} lg={6}>
          <Pie
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                  labels: { color: "black" },
                },
                title: {
                  display: true,
                  text: "Solicitudes totales",
                },
              },
            }}
            data={dataPie1}
          />
        </GridItem>
        <GridItem xs={12} md={6} sm={6} lg={6}>
          {dataPie2?.datasets[0]?.data?.length > 0 ? (
            <Pie
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: { color: "black" },
                  },
                  title: {
                    display: true,
                    text: "Ventas por agente",
                  },
                },
              }}
              data={dataPie2}
            />
          ) : (
            <div
              style={{ display: "flex", width: "100%", placeContent: "center" }}
            >
              <b style={{ placeSelf: "center" }} className="good_font">
                NO INFO
              </b>
            </div>
          )}
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <br />
          <Divider />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Graphs1;
