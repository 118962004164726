import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import CustomReactSelect from "components/Select/CustomReactSelect";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilesService from "services/FilesService";
import Button from "../../components/CustomButtons/Button";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Checkbox, InputLabel, MenuItem, Select } from "@material-ui/core";
import { mapDateFormat } from "functions/comun";

const nameZip = (dataState, getNameSubject, checkedBoxes) => {
  let cortes = "";
  if (checkedBoxes.corte1 || checkedBoxes.corte2 || checkedBoxes.corte3) {
    cortes = "-Cortes";
    if (checkedBoxes.corte1) cortes = cortes + " 1";
    if (checkedBoxes.corte2) cortes = cortes + " 2";
    if (checkedBoxes.corte3) cortes = cortes + " 3";
  }
  if (dataState === "") {
    return "Materials" + cortes + ".zip";
  } else {
    return getNameSubject(dataState) + cortes + ".zip";
  }
};

const Files = () => {
  const user = useSelector((state) => state.user.user);

  const files = React.useRef([]);

  const [dataState, setDataState] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState({
    corte1: false,
    corte2: false,
    corte3: false,
  });

  const compare = (a, b) => {
    if (a.label === "Pruebas") return -1;
    if (b.label === "Pruebas") return -1;
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  const downloadURI = async (uri, filename, files) => {
    if (files.length > 1) {
      const zip = JSZip();
      files.forEach(async (element, i) => {
        const response = await fetch(element.url);
        const blob = await response.blob();
        if (blob) {
          zip.file(element.name, blob);
        }
        if (i === files.length - 1) {
          zip
            .generateAsync({ type: "blob" })

            .then(function (content) {
              // Descargar el archivo Zip

              saveAs(content, nameZip(dataState, getNameSubject, checkedBoxes));
            });
        }
      });
    } else {
      try {
        const response = await fetch(files[0].url);
        const blob = await response.blob();
        if (blob) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = files[0].name;
          link.click();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getNameSubject = (id) => {
    const name = subjects.filter((s) => s._id === id);
    return name[0].label;
  };

  const getSubjects = async () => {
    const response = await FilesService.getData({ user });
    if (response?.data)
      setSubjects(response?.data?.sort((a, b) => compare(a, b)));
  };

  const filterMaterial = async () => {
    const dataFiltered = allData.filter(
      (d) => d.partials.subject === getNameSubject(dataState)
    );
    console.log(dataFiltered);
    setData(checkedFilter(dataFiltered));
  };

  const getMaterials = async () => {
    const res = await FilesService.getMaterials({ user });
    setData(res?.data);
    setAllData(res?.data);
    setDataState("");
    // setCheckedBoxes({
    //   corte1: false,
    //   corte2: false,
    //   corte3: false,
    // });
  };

  const onRowClick = (e, rowData) => {
    window.open(rowData.url);
  };

  const handleChange = (e) => {
    setCheckedBoxes({ ...checkedBoxes, [e.target.value]: e.target.checked });
  };

  const checkedFilter = (prop) => {
    let dataReturn = []; //array de retorno
    //filtro para el corte 1
    if (checkedBoxes.corte1)
      dataReturn = [
        ...dataReturn,
        ...prop.filter((d) => {
          const dateEvent = {
            month: new Date(d.partials.event_date).getMonth() + 1,
            day: new Date(d.partials.event_date).getDate(),
          };
          //semestre 1
          // entre enero (1) y marzo (3)
          if (1 <= dateEvent.month && dateEvent.month <= 3) {
            //es marzo (3)?
            if (dateEvent.month === 3) {
              //antes del 13?
              if (dateEvent.day < 13) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
          //semestre 2
          // entre julio (7) y septiembre (9)
          if (7 <= dateEvent.month && dateEvent.month <= 9) {
            //es julio (7)?
            if (dateEvent.month === 7) {
              //despues del 21?
              if (dateEvent.day >= 21) {
                return true;
              } else {
                return false;
              }
            }
            // es septiembre
            if (dateEvent.month === 9) {
              //antes del 30?
              if (dateEvent.day <= 30) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
          return false;
        }),
      ];
    //filtro para el corte 2
    if (checkedBoxes.corte2)
      dataReturn = [
        ...dataReturn,
        ...prop.filter((d) => {
          const dateEvent = {
            month: new Date(d.partials.event_date).getMonth() + 1,
            day: new Date(d.partials.event_date).getDate(),
          };
          // entre marzo (3) y abril (4)
          if (3 <= dateEvent.month && dateEvent.month <= 4) {
            //es marzo (3)?
            if (dateEvent.month === 3) {
              //despues del 21?
              if (dateEvent.day >= 13) {
                return true;
              } else {
                return false;
              }
            }
            // es abril (4)?
            if (dateEvent.month === 4) {
              //antes del 27?
              if (dateEvent.day <= 27) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
          // entre octubre (10) y noviembre (11)
          if (10 <= dateEvent.month && dateEvent.month <= 11) {
            //es octubre (10)?
            if (dateEvent.month === 10) {
              //despues del 1?
              if (dateEvent.day >= 1) {
                return true;
              } else {
                return false;
              }
            }
            // es noviembre (11)
            if (dateEvent.month === 11) {
              //antes del 30?
              if (dateEvent.day <= 10) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
          return false;
        }),
      ];
    //filtro para el corte 3
    if (checkedBoxes.corte3)
      dataReturn = [
        ...dataReturn,
        ...prop.filter((d) => {
          const dateEvent = {
            month: new Date(d.partials.event_date).getMonth() + 1,
            day: new Date(d.partials.event_date).getDate(),
          };
          // entre abril (4) y julio (7)
          if (4 <= dateEvent.month && dateEvent.month <= 7) {
            //es abril (4)?
            if (dateEvent.month === 4) {
              //despues del 28?
              if (dateEvent.day >= 28) {
                return true;
              } else {
                return false;
              }
            }
            // es julio
            if (dateEvent.month === 7) {
              //antes del 30?
              if (dateEvent.day <= 20) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
          // entre noviembre (11) y diciembre (12)
          if (11 <= dateEvent.month) {
            //es noviembre (11)?
            if (dateEvent.month === 11) {
              //despues del 11?
              if (dateEvent.day >= 11) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
          return false;
        }),
      ];
    console.log(prop, dataReturn);
    if (dataReturn.length === 0) return prop;
    return dataReturn;
  };

  useEffect(() => {
    getSubjects();
    getMaterials();
  }, []); // eslint-disable-line

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} md={6} sm={6} lg={6}>
          {/* <CustomReactSelect
            className={classes.root}
            required
            options={subjects.map((i) => ({ value: i._id, label: i.label }))}
            onChange={(value) => setDataState({ ...dataState, subject: value })}
            value={dataState.subject}
          /> */}
          <Select
            value={dataState}
            onChange={(value) => setDataState(value.target.value)}
            style={{ width: "100%" }}
          >
            <MenuItem value="">No seleccionado</MenuItem>
            {subjects.map((i) => {
              return (
                <MenuItem key={i._id} value={i._id}>
                  <div style={{ width: "100%" }}>{i.label}</div>
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem xs={6} md={6} sm={6} lg={6}>
          <Button color="success" onClick={filterMaterial}>
            Filtrar
          </Button>
        </GridItem>
        <GridItem>
          <Checkbox
            value="corte1"
            checked={checkedBoxes.corte1}
            onChange={handleChange}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          <InputLabel>Corte 1</InputLabel>
        </GridItem>
        <GridItem>
          <Checkbox
            value="corte2"
            checked={checkedBoxes.corte2}
            onChange={handleChange}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          <InputLabel>Corte 2</InputLabel>
        </GridItem>
        <GridItem>
          <Checkbox
            value="corte3"
            checked={checkedBoxes.corte3}
            onChange={handleChange}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          <InputLabel>Corte 3</InputLabel>
        </GridItem>
      </GridContainer>
      <br />
      <MaterialTable
        isLoading={loading}
        title={`Materiales`}
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Peso", field: "size" },
          { title: "Fecha", field: "date" },
        ]}
        data={data
          ?.map((d) => {
            return {
              ...d.partial_materials,
              date: mapDateFormat(
                d.partials.event_date,
                "DD MMM YYYY, hh:mm A"
              ),
            };
          })
          ?.map((i) => {
            return { ...i, size: `${i.size} MB`, name: i.url.slice(-9) };
          })}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: getMaterials,
          },
          (rowData) => ({
            icon: "download",
            tooltip: "Descargar",
            onClick: async () => {
              setLoading(true);
              await downloadURI(rowData?.url, rowData?.name, files.current);
              setLoading(false);
            },
          }),
        ]}
        onRowClick={onRowClick}
        onSelectionChange={(rows) => (files.current = rows)}
        options={{
          actionsColumnIndex: -1,
          selection: true,
        }}
      ></MaterialTable>
    </div>
  );
};

export default Files;
