import { css } from "@emotion/css";
import {
  Divider,
  Grid,
  IconButton,
  Input,
  FormControlLabel,
  Checkbox,
  InputLabel,
  TextField,
  withStyles,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Select from "react-select";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import SaveIcon from "@material-ui/icons/Save";
// import GridContainer from "components/Grid/GridContainer";
import React, { useEffect, useState } from "react";
// import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import TeacherService from "services/TeacherService";
import TallerService from "services/TallerService";
import { toast } from "react-toastify";
import ColorModalService from "services/ColorModalService";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SimpleModal from "components/Modal/SimpleModal";
import swal from "sweetalert";
import { partialStateTranslate } from "functions/comun";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import SMSService from "services/SMSService";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { HomeWork, Visibility, WhatsApp } from "@material-ui/icons";
import ShareIcon from "@material-ui/icons/Share";
import EventIcon from "@material-ui/icons/Event";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import WhatsappIconYellow from "assets/customIcons/WhatsappIconYellow";
import { mapDate } from "functions/comun";
import PaymentAccepted from "./partials/PaymentAccepted";
import { EditInput } from "components/CustomInput/EditInput";
import PaymentService from "services/PaymentService";
import SubjectService from "services/SubjectService";
import CustomReactSelect from "components/Select/CustomReactSelect";
import ReactDOM from "react-dom";
import { adminPhone1 } from "variables/superAdminEmails";

const StyledCheckBox = withStyles({
  root: {
    color: "black",
  },
})(FormControlLabel);

const NewAlertUText = ({ uSelected, setUSelected }) => {
  const [value, setValue] = useState(uSelected.value);

  const onChangeF = (e) => {
    swal.setActionValue(e.target.value);
    setValue(e.target?.value);
    setUSelected({ ...uSelected, value: e.target.value });
  };

  return (
    <Input
      type="text"
      placeholder="Nombre..."
      onChange={onChangeF}
      value={value}
    ></Input>
  );
};

const NewAleertUSelect = ({ uSelected, setUSelected }) => {
  const uList = [
    { label: "Andes", value: "andes" },
    { label: "Antonio Nariño", value: "antonio nariño" },
    { label: "Católica", value: "catolica" },
    { label: "Sergio Arboleda", value: "sergio arboleda" },
    { label: "Javeriana", value: "javeriana" },
    { label: "Sabana", value: "sabana" },
    { label: "Salle", value: "salle" },
    { label: "Rosario", value: "rosario" },
    { label: "Uniminito", value: "uniminuto" },
    { label: "El Bosque", value: "el bosque" },
    { label: "Distrital", value: "distrita" },
    { label: "ECCI", value: "ecci" },
    { label: "ECI", value: "eci" },
    { label: "EAFIT", value: "eafit" },
    { label: "EIA", value: "eia" },
    { label: "Militar", value: "militar" },
    { label: "Soy_ingeniero", value: "soy_ingeniero" },
    { label: "Piloto", value: "piloto" },
    { label: "UNAL", value: "unal" },
    { label: "UNAD", value: "unad" },
    { label: "Otra", value: "otro" },
  ];

  const onChangeF = (e) => {
    swal.setActionValue(e.value);
    setUSelected(e);
  };

  return (
    <CustomReactSelect
      onChange={(value) => onChangeF(value)}
      placeholder="Universidad..."
      options={uList}
    />
  );
};

function Taller({
  homework,
  setHomework,
  getData,
  setService,
  payment,
  setPayment,
}) {
  const styles = css`
    .main-div-container {
      box-shadow: 0px 0px 11px 0px #000000;
      height: 60%;
      width: 80%;
      background-color: white;
      border-radius: 5px;
      display: flex;
      font-size: 1.1rem;
      justify-content: space-around;
      padding: 2%;
    }
    .column-item {
      justify-content: space-around;
    }

    .titles {
      font-weight: bold;
    }

    .back-button {
      position: relative;
      left: 0;
      top: 0;
      font-size: 2rem;
      border-style: none;
      background: transparent;
      color: gray;
    }

    .back-button:hover {
      cursor: pointer;
      background-color: lightgray;
      border-radius: 50%;
      font-size: 2rem;
    }

    @media (max-width: 1030px) {
      .main-div-container {
        height: 70%;
        width: 80%;
        padding: 3%;
      }
    }

    @media (max-width: 1030px) {
      .main-div-container {
        height: 80%;
        width: 80%;
        padding: 3%;
      }
    }

    @media (max-width: 768px) {
      .main-div-container {
        width: 95%;
        padding: 3%;
      }
    }

    @media (max-width: 452px) {
      .main-div-container {
        width: 95%;
        padding: 2%;
      }
    }
  `;
  const user = useSelector((state) => state.user.user);

  const [options, setOptions] = useState(null);
  const [assignedValues, setAssignedValues] = useState({
    tutor: { value: "", label: "" },
    cost: 0,
    debt_to_teacher: 0,
  });
  const [modal, setModal] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [alert, setAlert] = useState(false);
  const [img, setImg] = useState({ url: "", file: "" });
  const [haveScreenshot, setHaveScreenshot] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [textReturn, setTextAddReturn] = useState([]);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [dataToReturn, setDataToReturn] = useState({
    note: "",
    details: "",
    value: "",
    bank: "",
    acc: "",
  });
  const [name, setName] = useState(homework?.client);
  const [debDemo, setDebDemo] = useState();
  const [costDemo, setCostDemo] = useState(0);
  const [uSelected, setUSelected] = useState("");
  const [university, setUni] = useState();

  useEffect(() => {
    getTeachers();
  }, []); //eslint-disable-line

  const getTeachers = async () => {
    const res = await TeacherService.getAll({ user });
    if (res.message !== "OK") {
      //console.log(res.message);
    }
    setTeachers(res?.data);

    //Obtener con id de materia la lista de tutores
    const subjects = await SubjectService.findAll({ user });
    const teachers_list = subjects?.data.find((element) => {
      return element.label === homework.subject;
    })?.teachers_list;

    const opt = res?.data.filter((el) => {
      if (teachers_list?.find((element) => element === el._id)) {
        return el;
      }
      return false;
    });

    setOptions(opt?.map((i) => ({ value: i._id, label: i.names })));

    if (homework.teacher) {
      setAssignedValues({
        ...assignedValues,
        tutor: { label: homework.teacher.label, value: homework.teacher.value },
        cost: homework.cost,
      });
    }
  };

  const addToSalesExcel = async () => {
    // setLoading({ uno: true });
    const res = await TallerService.addToSalesExcel({
      user,
      idHomework: homework._id,
      debtToTeacher: assignedValues.debt_to_teacher,
      uSelected,
    });
    if (res?.data?.data) {
      setHomework(res?.data?.data);
      toast.success("Registro agregado correctamente!");
      /************* */
      const teacher = teachers.filter(
        (t) => t._id === homework.teacher.value
      )[0];

      const messageTeacher = `Hola ${teacher.names}, te hemos agendado un nuevo taller de ${homework.subject} en Copywrite®! `;
      //await SMSService.sendSMS({
      //user,
      //message: messageTeacher,
      //phoneNumber: teacher.phone,
      //}); DESACTIVADO
      /************* */
      //---
      setModal(false);
    } else {
      if (res?.data?.message) toast.error(res?.data?.message);
      else toast.error("Ha ocurrido un error!");
    }
    // setLoading({ uno: false });
  };

  const styleButton = {
    margin: "5px",
  };

  const submitChanges = async () => {
    // //console.log(assignedValues);
    if (assignedValues.tutor.value !== "") {
      // //console.log(assignedValues.tutor.value);
      console.log(assignedValues);
      const res = await TallerService.teacherAssign(
        assignedValues,
        homework._id,
        user
      );
      if (res?.data?.message === "UPDATED") {
        setHomework(res.data.data);
        return toast.success("Datos actualizados");
      }
    }
    toast.error("No se guardo ningun cambio");
  };

  const onChange = async (note = 0) => {
    const rescolor = await ColorModalService.updateColorModal({
      token: user.token,
      idClient: homework?._id,
      type: "homework",
      color: "#93e3fd",
    });
    if (rescolor?.message === "OK") {
      const reshomework = await TallerService.addReturn({
        user,
        textReturn,
        idHomework: homework?._id,
        date: new Date(),
      });
      if (reshomework?.data?.message === "OK") {
        const teacher = teachers.filter((i) => i._id === homework.assigned_to);
        const res = await TallerService.teacherAssign(
          {
            debt_to_teacher: (note / 4) * homework.debt_to_teacher,
            cost: (note / 4) * homework.cost,
          },
          homework._id,
          user
        );
        setHomework(res?.data?.data);
        const resReturn = await PaymentService.updateAmountByReturn({
          token: user.token,
          objectID: homework._id,
          typeObject: "HOMEWORK",
          newValue: (note / 4) * homework.debt_to_teacher,
        });
        const phone = adminPhone1;
        const text = `🚨 *NUEVA DEVOLUCIÓN POR $${
          dataToReturn.value
        } COP POR NOTA DE ${
          dataToReturn.note
        }*\n\n*Tipo:* Taller.\n*Asignatura:* ${
          homework.subject
        }\n*Nombre Estudiante:* ${homework.client}.\n*Banco:* ${
          dataToReturn.bank
        }\n*# Cuenta:* ${dataToReturn.acc}\n*Motivo:* ${
          textReturn[0]
        } \n*Tutor asignado:* ${teacher[0].names}\n*Id del servicio:* ${
          homework._id
        }\n*Valor a pagar a tutor:* ${
          (note / 4) * homework.debt_to_teacher
        } COP\n*Valor actual de la venta:* ${
          (note / 4) * homework.cost
        } COP\n*Notas:* ${dataToReturn.details}`;
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
          text
        )}`;
        window.open(url, `return_${homework._id}`);
        toast.success("Devolución creada con exito");
      }
    }
    setOpenModal3(false);
  };

  const onChangeReasson = (reasson) => {
    let arr = textReturn;
    if (arr.indexOf(reasson) === -1) {
      arr.push(reasson);
    } else {
      arr = arr.filter((item) => reasson !== item);
    }
    setTextAddReturn(arr);
  };

  const addReturn = () => {
    setOpenModal(true);
  };

  const addToSalesExcelAction = async () => {
    if (!homework.teacher) {
      return swal("No hay tutor asignado");
    }
    //seleccion de universidad
    let wrapper2 = document.createElement("div");
    ReactDOM.render(
      <NewAleertUSelect uSelected={uSelected} setUSelected={setUSelected} />,
      wrapper2
    );
    let sele = wrapper2.firstChild;
    //otra universidad
    let wrapper3 = document.createElement("div");
    ReactDOM.render(
      <NewAlertUText uSelected={uSelected} setUSelected={setUSelected} />,
      wrapper3
    );
    let el3 = wrapper3.firstChild;
    if (homework.is_paid || haveScreenshot || homework.paymentBill) {
      const value = await swal(
        `Ingresa la cantidad de dinero a pagar a: ${homework.teacher?.label}`,
        {
          content: "input",
        }
      );
      if (value) {
        swal({
          text: "¿De que Universidad es este Parcial?",
          content: sele,
        }).then((value) => {
          if (value === "otro") {
            swal({
              text: "¿Cuál es el nombre de la Universidad?",
              content: el3,
            }).then((value) => {
              setModal(true);
            });
          } else {
            setModal(true);
          }
        });
        setAssignedValues({ ...assignedValues, debt_to_teacher: value });
        setModal(true);
      }
    } else {
      setAlert(true);
    }
  };

  const onChangeFiles = (e) => {
    setImg({
      file: e.target.files,
      url: URL.createObjectURL(e.target.files.item(0)),
    });
  };

  const uploadPaymentBillAdditional = async (e) => {
    // const images = [];
    let isOk = true;
    let res;
    for (const i of e.target.files) {
      let j = 0;
      res = await TallerService.savePayment2({
        file: i,
        idHomework: homework._id,
        user,
      });
      if (res.data?.message !== "OK") {
        isOk = false;
        toast.success("Algo salió mal!");
      }
      j++;
    }
    if (isOk) {
      toast.success("Captura guardada correctamente");
      setHomework(res.data?.data);
    }
  };

  const onSendFiles = async (e) => {
    e.preventDefault();
    const res = await TallerService.savePayment({
      file: img.file,
      idHomework: homework._id,
      user,
    });
    if (res?.data?.message === "OK") {
      setHaveScreenshot(true);
      setAlert(false);
      toast.success("Captura guardada correctamente");
      const value = await swal(
        `Ingresa la cantidad de dinero a pagar a: ${homework.teacher?.label}`,
        {
          content: "input",
        }
      );
      if (value) {
        //seleccion de universidad
        let wrapper2 = document.createElement("div");
        ReactDOM.render(
          <NewAleertUSelect
            uSelected={uSelected}
            setUSelected={setUSelected}
          />,
          wrapper2
        );
        let sele = wrapper2.firstChild;
        //otra universidad
        let wrapper3 = document.createElement("div");
        ReactDOM.render(
          <NewAlertUText uSelected={uSelected} setUSelected={setUSelected} />,
          wrapper3
        );
        let el3 = wrapper3.firstChild;
        setAssignedValues({ ...assignedValues, debt_to_teacher: value });
        setModal(true);
      }
    } else {
      toast.error("Algo no salió bien");
    }
  };

  const today = moment();
  const week = today.week();
  const month = today.month() + 1;
  const year = today.year();

  const getSemester = (month) => {
    if (month >= 0 && month <= 5) return "A";
    return "B";
  };

  const parseMoneyToExcel = (cost) => {
    try {
      return Math.round(cost / 1000);
    } catch (e) {}
    return cost;
  };

  const updateName = async () => {
    const res = await TallerService.updateName({
      user,
      idHomework: homework?._id,
      name,
    });
    if (res?.data?.data) {
      toast.success("Nombre actualizado correctamente");
      setHomework(res?.data?.data);
    } else {
      toast.error("Algo salió mal");
    }
  };

  const updateUniversity = async () => {
    // console.log("save event", dateEvent);
    const res = await TallerService.updateUniversity({
      token: user.token,
      university,
      idHomework: homework?._id,
    });
    if (res) {
      toast.success("Universidad actualizado correctamente!");
      setHomework(res?.data?.data);
    }
  };

  const editValue = async () => {
    // //console.log(teacher);
    const value = await swal(
      `Ingresa la cantidad de dinero a modificar a: ${homework.teacher?.label}, el aterior valor es: ${homework.debt_to_teacher}`,
      {
        content: "input",
      }
    );
    if (value) {
      const res = await TallerService.editValue({
        idHomework: homework._id,
        newValue: value,
        user,
      });
      toast.success("Valor a pagar actualizado correctamente!");
      // //console.log(res?.data?.data);
      setHomework(res?.data?.data);
    }
  };

  const back = () => {
    setHomework(null);
    if (setService) setService(null);
    if (setPayment) setPayment(payment);
    if (getData) getData();
  };

  const myParseDate = (date) =>
    moment(date).format("ddd DD MMMM YYYY, hh:mm A");

  const copyToClipboard = (text, successText) => {
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value = text;

    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);

    toast.success(successText);
  };

  const addToGoogleCalendar = () => {
    const mainEmails = `copywrite.col@gmail.com,maicol.copywrite@gmail.com,stefanny.copywrite@gmail.com`;
    let emails = "";
    const teacherData = teachers.find(
      (item) => item._id === homework.assigned_to
    );
    if (teacherData?.email) {
      emails = `${mainEmails},${teacherData?.email}`;
    } else {
      emails = mainEmails;
    }

    copyToClipboard(emails, "Participantes copiado al portapapeles");

    const text = `Taller - ${homework?.client} - ${homework?.subject} - ${teacherData?.names}`;
    const details = `ID: ${homework._id} \nFecha del Taller: ${myParseDate(
      homework.event_date
    )}\nCliente: ${homework?.client} \nMateria: ${
      homework?.subject
    } \nEstado: ${partialStateTranslate(
      homework?.state
    )}  \nRecibido el: ${myParseDate(
      homework?.created_at
    )}\nAgregado a GC: ${myParseDate(moment())}\nAgregado por Admin: ${
      user.names
    }`;

    const startHomeworkAt = moment(homework?.event_date).local();
    const endHomeworkAt = moment(homework?.event_date)
      .local()
      .add(15, "minutes");

    // // 20200316T010000Z
    const f = "YYYYMMDDTHHmmss";

    const url = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${startHomeworkAt.format(
      f
    )}/${endHomeworkAt.format(f)}&text=${encodeURIComponent(
      text
    )}&details=${encodeURIComponent(details)}`;

    window.open(url, homework._id);
  };

  const notififyPay = async () => {
    const teacherData = teachers.find(
      (item) => item._id === homework.assigned_to
    );

    const bank = await swal(`¿A que banco se realizó el pago?`, {
      content: "input",
    });
    if (bank) {
      let notes = await swal(`Desea agregar notas/detalles`, {
        content: "input",
      });
      if (!notes) {
        notes = "";
      }
      const phone = adminPhone1;
      const confirmLink = `${window.location.origin}/admin/confirm/homework/${homework?._id}?payMetod=${bank}`;
      const text = `🧢 *NUEVO PAGO POR $${
        homework?.cost
      } COP*\n\n*Tipo*: Taller.\n*Asignatura:* ${
        homework?.subject
      }.\n*Tutor asignado:* ${teacherData?.names}.\n*Utilidad:* ${(
        ((homework.cost - homework.debt_to_teacher) / homework.cost) *
        100
      ).toFixed(2)}%\n*#Banco:* ${bank}.\n*Fecha de evento:* ${myParseDate(
        homework?.event_date
      )}.\n*ID:* ${homework?._id}\n*Estudiante:* ${
        homework?.client
      }.\n*#Whatsapp:* ${
        homework?.phone
      }.\n*Notas:* ${notes}\n*Link de confirmación:* ${confirmLink}`;
      const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
        text
      )}`;
      window.open(url, `material_link_${homework._id}`);
    }
  };

  const openWappMaterialLink = () => {
    const publicMaterialLink = `${window.location.origin}/public/homework-stepper/${homework._id}`;
    const phone = homework.phone;
    const text = `👋 *Hola ${homework.client}, ¡Transacción confirmada!* ✅\n\n🚀 Haz sido agendado en *Talleres Copywrite.* Ingresa aquí:\n\n🔗 ${publicMaterialLink}\n\n¡Si no subes material de preparación no hay garantía!📍
    `;
    // const text = `🚀 Hola ${partial.client}, has sido agendad@ en PARCIALES Copywrite!.\n\nLee toda la info, sigue los pasos y ¡listo!👇\n\n📍Recuerda que si no subes el material de preparación NO HAY GARANTIA\n\n🔗 ${publicMaterialLink}`;
    // const text = `🚀 Hola ${partial.client}. *¡Sigue los pasos y sube tu material de Preparación!* 👇\n\n${publicMaterialLink}\n\nSi tienes problemas para subirlo dinoslo y lo haremos MANUAL.\n\n#MejorConCopywrite`;
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, `material_link_${homework._id}`);
  };

  const openWhatsAppOff = () => {
    const teacherData = teachers.find(
      (item) => item._id === homework.assigned_to
    );
    if (teacherData) {
      const link = `${window.location.origin}/public/homework/${homework._id}`;
      const text = `👋 ¡Hola ${
        teacherData.names
      }!, tenemos un *Taller* en *Copywrite App*\n\n*Materia:* ${
        homework.subject
      }.\n*Fecha de entrega:* ${mapDate(
        homework.event_date
      )}.\n*Taller a solucionar:* ${link}\n\n🎈 ¿Puedes tomarlo y cuanto cobrarías?`;

      const url = `https://api.whatsapp.com/send?phone=${
        teacherData.phone
      }&text=${encodeURIComponent(text)}`;
      window.open(url, `material_link_${homework._id}`);
    } else {
      toast.warning("No hay tutor asignado");
    }
  };

  return (
    <div className={styles}>
      <ArrowBackIcon className="back-button" onClick={back} />
      <div
        style={{
          height: "75vh",
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: "1",
          paddingLeft: "3%",
        }}
      >
        <h4>Asignacion</h4>
        <Grid container spacing={3}>
          <Grid item xs={11} md={11} sm={4} lg={4}>
            <InputLabel>Tutor</InputLabel>
            {options && (
              <Select
                options={options}
                defaultValue={assignedValues.tutor.value}
                placeholder={
                  assignedValues.tutor.label
                    ? assignedValues.tutor.label
                    : "Tutor a asignar..."
                }
                onChange={(value) => {
                  setAssignedValues({ ...assignedValues, tutor: value });
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            sm={1}
            lg={1}
            style={{ display: "grid", placeContent: "center" }}
          >
            <IconButton onClick={() => openWhatsAppOff()}>
              <WhatsappIconYellow width={"30"} />
            </IconButton>
          </Grid>
          <Grid item xs={6} md={6} sm={7} lg={7}>
            <div style={{ display: "grid" }}>
              <InputLabel>Costo base del taller</InputLabel>
              <Input
                type="number"
                fullWidth
                value={assignedValues.cost}
                onChange={(value) => {
                  setAssignedValues({
                    ...assignedValues,
                    cost: value.target.value,
                  });
                }}
              />
              <Button
                style={{ justifySelf: "end" }}
                onClick={submitChanges}
                type="button"
                color={homework.assigned_to ? "warning" : "success"}
              >
                {homework.assigned_to ? "Reasignar" : "Asignar"}
              </Button>
            </div>
          </Grid>
          <Grid item xs={1} md={1} sm={1} lg={1}></Grid>
          <Grid
            item
            xs={6}
            md={6}
            sm={2}
            lg={2}
            style={{ display: "grid", justifyItems: "center" }}
          >
            <div style={{ textAlign: "center" }}>
              &nbsp;
              <b>Agendar en Calendar</b>
            </div>
            <IconButton onClick={addToGoogleCalendar}>
              <EventIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sm={2}
            lg={2}
            style={{ display: "grid", justifyItems: "center" }}
          >
            <div style={{ textAlign: "center" }}>
              &nbsp;
              <b>Link de mat. preparación</b>
            </div>
            <IconButton onClick={openWappMaterialLink}>
              <ShareIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sm={2}
            lg={2}
            style={{
              display: "grid",
              justifyItems: "center",
            }}
          >
            {homework?.is_inserted_at_sales_excel ? (
              <>
                <div style={{ textAlign: "center" }}>
                  &nbsp;
                  <b>Editar valor</b>
                </div>
                {
                  <IconButton onClick={editValue}>
                    <EditIcon />
                  </IconButton>
                }
              </>
            ) : (
              <>
                <div>
                  &nbsp;
                  <b>Agregar a ventas</b>
                </div>
                {
                  <IconButton
                    style={{ display: "grid", justifyItems: "center" }}
                    onClick={addToSalesExcelAction}
                    disabled={homework?.is_inserted_at_sales_excel}
                  >
                    <AttachMoneyIcon />
                  </IconButton>
                }
              </>
            )}
          </Grid>
          {homework?.is_inserted_at_sales_excel && (
            <>
              <Grid
                item
                xs={6}
                md={6}
                sm={2}
                lg={2}
                style={{
                  display: "grid",
                  justifyItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  &nbsp;
                  <b>Notificar pago</b>
                </div>
                <IconButton onClick={notififyPay}>
                  <WhatsApp />
                </IconButton>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={6}
            md={6}
            sm={2}
            lg={2}
            style={{
              display: "grid",
              justifyItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              &nbsp;
              <b>Crear devolución</b>
            </div>
            <IconButton onClick={addReturn}>
              <MoneyOffIcon />
            </IconButton>
            <SimpleModal
              open={openModal}
              setOpen={setOpenModal}
              widthModal={"45%"}
              heightModal={"75%"}
            >
              <div
                style={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  display: "grid",
                }}
              >
                <div
                  style={{
                    fontSize: "1.5rem",
                    color: "black",
                    fontWeight: "bolder",
                    paddingBottom: "1rem",
                  }}
                >
                  ¿Cual es el motivo de la devolución?
                </div>
                <StyledCheckBox
                  labelPlacement="end"
                  label={"Bajo desempeño del tutor"}
                  value={"Bajo desempeño del tutor"}
                  color="primary"
                  control={
                    <Checkbox
                      onChange={(e) => onChangeReasson(e.target.value)}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                />
                <StyledCheckBox
                  labelPlacement="end"
                  label={"Estudiante agendó materia ó temas equivocados"}
                  value={"Estudiante agendó materia ó temas equivocados"}
                  color="secondary"
                  control={
                    <Checkbox
                      onChange={(e) => onChangeReasson(e.target.value)}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                />
                <StyledCheckBox
                  labelPlacement="end"
                  label={
                    "Cancelación del requerimiento por parte del estudiante"
                  }
                  value={
                    "Cancelación del requerimiento por parte del estudiante"
                  }
                  color="black"
                  control={
                    <Checkbox
                      onChange={(e) => onChangeReasson(e.target.value)}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                />
                <StyledCheckBox
                  labelPlacement="end"
                  label={"No hay tutor disponible"}
                  value={"No hay tutor disponible"}
                  color="black"
                  control={
                    <Checkbox
                      onChange={(e) => onChangeReasson(e.target.value)}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                />
                <StyledCheckBox
                  labelPlacement="end"
                  label={"Descuido de Customer Service de Copywrite"}
                  value={"Descuido de Customer Service de Copywrite"}
                  color="black"
                  control={
                    <Checkbox
                      onChange={(e) => onChangeReasson(e.target.value)}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                />
                <StyledCheckBox
                  labelPlacement="end"
                  label={"Otro"}
                  color="black"
                  value={"Otro"}
                  control={
                    <Checkbox
                      onChange={(e) => onChangeReasson(e.target.value)}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                />
                <Button
                  // onClick={onChange}
                  onClick={() => {
                    setOpenModal(false);
                    setOpenModal2(true);
                  }}
                  style={{
                    placeSelf: "end",
                    backgroundColor: "green",
                    color: "white",
                  }}
                >
                  Siguiente
                </Button>
              </div>
            </SimpleModal>
            <SimpleModal
              open={openModal2}
              setOpen={setOpenModal2}
              widthModal={"45%"}
              heightModal={"75%"}
            >
              <form noValidate autoComplete="off">
                <div
                  style={{
                    paddingLeft: "2rem",
                    gap: "2rem",
                    paddingRight: "2rem",
                    display: "grid",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.5rem",
                      color: "black",
                      fontWeight: "bolder",
                      paddingBottom: "1rem",
                    }}
                  >
                    Detalles de la devolución
                  </div>
                  <TextField
                    type="number"
                    id="standard-basic"
                    label="Nota que obtuvo el estudiante"
                    onChange={(e) => {
                      setDataToReturn({
                        ...dataToReturn,
                        note: e.target.value,
                        value:
                          homework.cost - (e.target.value / 4) * homework.cost,
                      });
                    }}
                    value={dataToReturn.note}
                  />
                  <TextField
                    type="number"
                    id="standard-basic"
                    label="Valor de la devolución"
                    disabled={true}
                    // onChange={(e) => {
                    //   setDataToReturn({
                    //     ...dataToReturn,
                    //     value: e.target.value,
                    //   });
                    // }}
                    value={dataToReturn.value}
                  />

                  <TextField
                    id="standard-basic"
                    label="detalles sobre la devolución"
                    placeholder="detalles ..."
                    onChange={(e) => {
                      setDataToReturn({
                        ...dataToReturn,
                        details: e.target.value,
                      });
                    }}
                    value={dataToReturn.details}
                  />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <Button
                      // onClick={onChange}
                      onClick={() => {
                        setOpenModal(true);
                        setOpenModal2(false);
                      }}
                      style={{
                        placeSelf: "end",
                        backgroundColor: "grey",
                        color: "white",
                      }}
                    >
                      Atrás
                    </Button>
                    <Button
                      // onClick={onChange}
                      onClick={() => {
                        if (!dataToReturn.value) {
                          toast.error("El valor no puede estar vacío");
                        }
                        if (!dataToReturn.note) {
                          toast.error("La nota no puede estar vacía");
                        }
                        if (dataToReturn.value && dataToReturn.note) {
                          setOpenModal2(false);
                          setOpenModal3(true);
                        }
                      }}
                      style={{
                        placeSelf: "end",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      Siguiente
                    </Button>
                  </div>
                </div>
              </form>
            </SimpleModal>
            <SimpleModal
              open={openModal3}
              setOpen={setOpenModal3}
              widthModal={"45%"}
              heightModal={"75%"}
            >
              <form noValidate autoComplete="off">
                <div
                  style={{
                    paddingLeft: "2rem",
                    gap: "2rem",
                    paddingRight: "2rem",
                    display: "grid",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.5rem",
                      color: "black",
                      fontWeight: "bolder",
                      paddingBottom: "1rem",
                    }}
                  >
                    Datos del medio de pago
                  </div>
                  <TextField
                    id="standard-basic"
                    label="Banco"
                    onChange={(e) => {
                      setDataToReturn({
                        ...dataToReturn,
                        bank: e.target.value,
                      });
                    }}
                    value={dataToReturn.bank}
                  />
                  <TextField
                    id="standard-basic"
                    label="Número de cuenta"
                    onChange={(e) => {
                      setDataToReturn({
                        ...dataToReturn,
                        acc: e.target.value,
                      });
                    }}
                    value={dataToReturn.acc}
                  />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <Button
                      // onClick={onChange}
                      onClick={() => {
                        setOpenModal2(true);
                        setOpenModal3(false);
                      }}
                      style={{
                        placeSelf: "end",
                        backgroundColor: "grey",
                        color: "white",
                      }}
                    >
                      Atrás
                    </Button>
                    <Button
                      onClick={() => {
                        onChange(dataToReturn.note);
                      }}
                      style={{
                        placeSelf: "end",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </form>
            </SimpleModal>
          </Grid>
          {homework?.paymentBill && (
            <GridItem xs={12} md={12} sm={6} lg={6}>
              <div className="steptwo--btn">
                <div>
                  <h4 style={{ backgroundColor: "green", fontSize: "14px" }}>
                    Subir comprobante adicional
                  </h4>
                  <input
                    multiple
                    type="file"
                    className="custom-file-input"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={uploadPaymentBillAdditional}
                  />
                </div>
              </div>
            </GridItem>
          )}
          <GridItem xs={12} md={12} sm={6} lg={6}>
            <table>
              <th>
                <td>
                  {homework?.paymentBill && (
                    <>
                      <a
                        href={homework.paymentBill}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Ver comprobante principal{" -"}
                      </a>
                    </>
                  )}
                </td>
              </th>
              {homework?.paymentBill2?.map((item, index, arr) => {
                return (
                  <th>
                    <td>
                      <a
                        href={item}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Comprobate adicional {index + 1}
                        {arr.length > index + 1 && " -"}
                      </a>
                    </td>
                  </th>
                );
              })}
            </table>
          </GridItem>
        </Grid>
        <br />
        <Divider></Divider>
        <br />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <span
            style={{
              color: "red",
              fontSize: 12,
              width: "100%",
              textAlign: "center",
            }}
          >
            Esta sección es únicamente para cotizar valores, NO afecta al costo
            real del taller ni el valor a pagar al tutor
          </span>
          <div style={{ width: "100%", textAlign: "center", fontSize: 16 }}>
            Valor a pagar al tutor:{" "}
            <TextField
              id="standard-basic"
              label=""
              value={debDemo}
              onChange={(x) => {
                setDebDemo(x.target.value);
                setCostDemo((x.target.value / 0.55).toFixed(2));
              }}
            />{" "}
            , el valor a cobrar al estudiante seria{" "}
            <TextField id="standard-basic" label="" value={costDemo} disabled />
          </div>
        </div>
        <br />
        <Divider></Divider>
        <br />
        <div className="main-div-container">
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span className="titles">Nombre del cliente</span>
                </Grid>
                <Grid item xs={6}>
                  {/* <span>{homework.client}</span> */}
                  <EditInput
                    justifyContent="start"
                    onSave={updateName}
                    showComponent={<>{homework?.client}</>}
                    editComponent={
                      <div>
                        <Input
                          value={name}
                          onChange={(value) => setName(value.target.value)}
                          placeholder="Nombre del cliente..."
                        ></Input>
                      </div>
                    }
                  />
                </Grid>
              </React.Fragment>
            </Grid>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <React.Fragment>
                  <Grid item xs={6}>
                    <span className="titles">Universidad</span>
                  </Grid>
                  <Grid item xs={6}>
                    <EditInput
                      justifyContent="start"
                      onSave={updateUniversity}
                      showComponent={<>{homework?.university}</>}
                      editComponent={
                        <div>
                          <Input
                            value={university}
                            onChange={(value) => setUni(value.target.value)}
                            placeholder="Nombre de la universidad"
                          ></Input>
                        </div>
                      }
                    />
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span className="titles">Número de contacto</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{homework.phone.slice(3)}</span>
                </Grid>
              </React.Fragment>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span className="titles">Materia</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{homework.subject}</span>
                </Grid>
              </React.Fragment>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span className="titles">Fecha de entrega</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{homework.event_date}</span>
                </Grid>
              </React.Fragment>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span className="titles">Archivos del taller</span>
                </Grid>
                <Grid item xs={6}>
                  <span>
                    {homework.files.map((f, index) => {
                      return (
                        <Button
                          style={styleButton}
                          variant="contained"
                          color="primary"
                          onClick={() => window.open(f, homework.client)}
                        >
                          Ver archivo {index + 1}
                        </Button>
                      );
                    })}
                  </span>
                </Grid>
              </React.Fragment>
            </Grid>
            {/* <Grid container item xs={12} spacing={3}>
              <React.Fragment>
                <Grid item xs={12}>
                  <Button
                    startIcon={<SaveIcon />}
                    style={styleSendBtn}
                    variant="contained"
                    color="primary"
                  >
                    Enviar
                  </Button>
                </Grid>
              </React.Fragment>
            </Grid> */}
          </Grid>
        </div>
        <div>
          <PaymentAccepted partial={homework} user={user} isHomework={true} />
        </div>
      </div>
      <SimpleModal open={modal} setOpen={setModal} widthModal={"80%"}>
        <h4>Datos que se agregarán a excel</h4>
        <ul>
          <li>
            <b>(A) MATERIA:</b> {homework?.subject}
          </li>
          <li>
            <b>(B) TIPO SERVICIO:</b> Taller
          </li>
          <li>
            <b>(C) TUTOR ASIGNADO:</b> {homework?.teacher?.label}
          </li>
          <li>
            <b>(D) SEMANA:</b> {`Semana ${week}`}
          </li>
          <li>
            <b>(E) # MES:</b> {month}
          </li>
          <li>
            <b>(F) INGRESO NETO:</b> {parseMoneyToExcel(homework?.cost)}
          </li>
          <li>
            <b>(G) DEUDA A TUTOR:</b>{" "}
            {parseMoneyToExcel(assignedValues.debt_to_teacher)}
          </li>
          <li>
            <b>(H) UTILIDAD:</b>{" "}
            {parseMoneyToExcel(homework?.cost - assignedValues.debt_to_teacher)}
          </li>
          <li>
            <b>(I) ASESOR:</b> {user?.names}
          </li>
          <li>
            <b>(J) PERIODO:</b> {`${year}-${getSemester(month)}`}
          </li>
          <li>
            <b>(K) Agendado por:</b> Manual
          </li>
          <li>
            <b>(L) ID:</b> {homework?._id}
          </li>
        </ul>
        <Button
          type="button"
          color="success"
          // loading={loading.uno}
          onClick={addToSalesExcel}
        >
          Agregar a excel
        </Button>
      </SimpleModal>
      <SimpleModal open={alert} setOpen={setAlert} widthModal={"80%"}>
        <div style={{ marginLeft: "30px" }}>
          <label>
            Por favor suba la captura de pantalla del comprobante de pago
          </label>
        </div>
        <br />
        <GridContainer>
          <GridItem>
            <div className="final_step1" style={{ padding: "0px 60px" }}>
              <div>
                <h5 style={{ padding: "10px 0px", backgroundColor: "#fbbf13" }}>
                  Seleccionar Archivo
                </h5>
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  className="custom-file-input"
                  onChange={onChangeFiles}
                />
              </div>
            </div>
          </GridItem>
          <Button
            onClick={onSendFiles}
            disabled={img.url ? false : true}
            type="button"
            color={"success"}
          >
            {"Guardar"}
          </Button>
          <GridItem></GridItem>
        </GridContainer>
        <br />
        {img.url && (
          <img
            style={{ height: "667px", width: "375px" }}
            src={img.url}
            alt=""
          />
        )}
      </SimpleModal>
    </div>
  );
}

export default Taller;
