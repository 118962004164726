import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import TeacherRequest from "./TeacherRequest";
import TeacherManual from "./TeacherManual";

export default function PaginationTeacherAssign(props) {
  const [activePage, setActivePage] = useState(0);

  const handleClick = (e, index) => {
    e.preventDefault();
    setActivePage(index);
  };

  const renderItem = (activePageParam, text, index) => {
    return activePageParam === index ? (
      <Typography color="textPrimary">{text}</Typography>
    ) : (
      <Link color="inherit" href="/" onClick={(e) => handleClick(e, index)}>
        {text}
      </Link>
    );
  };

  const getComponent = () => {
    switch (activePage) {
      case 0:
        return <TeacherRequest {...props} />;
      case 1:
        return <TeacherManual {...props} />;
      default:
        return "NO PAGE";
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Breadcrumbs aria-label="breadcrumb">
          {renderItem(activePage, "Solicitudes", 0)}
          {renderItem(activePage, "Asignar manualmente", 1)}
        </Breadcrumbs>
      </div>
      <div>{getComponent()}</div>
    </>
  );
}
