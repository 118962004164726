import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FeedbackService from "services/FeedbackService";

const Feedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [averageResults, setAverageResults] = useState({
    q0: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
  });

  const getFeedback = async () => {
    const res = await FeedbackService.getAllFeedback();
    if (res.data?.message === "OK") {
      setFeedback(res.data.data);
      getAverageResults(res.data.data);
    } else {
      toast.error("Algo salió mal");
    }
  };

  const getAverageResults = (res) => {
    let averages = {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
    };
    let count = 1;
    res.forEach((item) => {
      averages = {
        q0: (item.q0 + averages.q0) / count,
        q1: (item.q1 + averages.q1) / count,
        q2: (item.q2 + averages.q2) / count,
        q3: (item.q3 + averages.q3) / count,
        q4: (item.q4 + averages.q4) / count,
      };
      count++;
    });
    setAverageResults(averages);
  };

  useEffect(() => {
    getFeedback();
  }, []); // eslint-disable-line

  return (
    <div className="feedback_container">
      <div className="nps good_font">
        <b className="good_font">{averageResults.q4.toFixed(1)}</b>{" "}
        <h3 className="good_font">NPS</h3>
      </div>
      <Divider style={{ width: "100%" }} />
      <div className="question1">
        <p className="good_font">¿Obtuviste una buena nota?</p>{" "}
        <b className="good_font">{averageResults.q0.toFixed(1)}</b>
      </div>
      <div className="question2">
        <p className="good_font">
          ¿La línea de atención al cliente te respondió de manera amable y
          eficiente?
        </p>{" "}
        <b className="good_font">{averageResults.q1.toFixed(1)}</b>
      </div>
      <Divider style={{ width: "100%" }} />
      <div className="question3">
        <p className="good_font">
          ¿Sientes confianza y respaldo al contratar con la marca Copywrite?
        </p>
        <b className="good_font">{averageResults.q2.toFixed(1)}</b>
      </div>
      <div className="question4">
        <p className="good_font">¿Nuestros precios son accesibles?</p>{" "}
        <b className="good_font">{averageResults.q3.toFixed(1)}</b>
      </div>
      <Divider style={{ width: "100%" }} />
      <br />
      <div className="comments_container">
        <div className="comments_text good_font">
          ¿Cuáles son las razones de que elijas comprar en Copywrite en lugar de
          la competencia?
        </div>
        <Divider style={{ width: "100%" }} />
        <div
          className="comments"
          style={{ maxHeight: 200, overflowY: "scroll" }}
        >
          {feedback
            .filter((item) => item.q5 !== "")
            .map((item, index) => {
              return (
                <>
                  <div className="comments_item good_font" key={index}>
                    {item.q5}
                  </div>
                  <Divider style={{ width: "100%" }} />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
