import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import { VERSION } from "service/deployVersion";

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    const { fluid, white } = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white,
    });
    return (
        <footer className={classes.footer}>
            <div className={container}>
                <div className={classes.left}>
                    <small>{VERSION}</small>
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool,
};
