/* eslint-disable */
import { Container, Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SingleLoading from "components/Loading/SingleLoading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { mapDate } from "functions/comun";
import swal from "sweetalert";
import { css } from "@emotion/css";
import GoBack from "components/Navigation/GoBack";
import { useHistory } from "react-router-dom";
import PartialService from "services/PartialService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { mapDateFormat } from "functions/comun";
import { BsBoxArrowInDown } from "react-icons/bs";
import InfoIcon from "@material-ui/icons/Info";
import "../../assets/css/styles.css";
import GetAppIcon from "@material-ui/icons/GetApp";

const pageCss = css`
  div {
    padding: 5px;
  }
  .tr-title {
    color: #ffbc00;
  }
  .tr-title-success {
    color: green;
  }
`;

const isPendingTeacher = (partial) => {
  //console.log("isPendingTeacher:", partial.assigned_to);
  //console.log("isPendingTeacher:", partial.assigned_to === null);
  return (
    (partial?.state === "PENDING" ||
      partial?.state === "PAYMENT_ACCEPTED_NO_TEACHER") &&
    (partial.assigned_to === undefined || partial.assigned_to === null)
  );
};

/*
const isAssignedToTeacher = (partial) => {
  return partial?.state === "ASSIGNED_TEACHER" || partial?.state === "READY";
};
*/

const notErrorMapping = (text) => {
  if (text === "ERROR_MAPPING") return "";
  return text;
};

const DetailNextPartial = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [material, setMaterial] = useState([]);

  const getPartialData = async () => {
    setLoading(true);
    const res = await PartialService.findByIdTeacherDetail({
      idPartial: id,
      user,
    });
    if (res?.data?.data) {
      setData(res?.data?.data);
      setLoading(false);
    }
  };

  const getMaterial = async () => {
    const res = await PartialService.getMaterials({ id });
    setMaterial(res.data.data);
  };

  useEffect(() => {
    getPartialData();
    getMaterial();
  }, []); // eslint-disable-line

  const { partial, teacher, teacherRequest } = data;
  const isRenderLoading = loading || partial === null || teacher === null;

  const rejectPartial = async () => {
    if (teacherRequest?._id) {
      swal({
        title: "¿Seguro?",
        text: "Una vez rechaces el parcial ya no podrás aceptarlo",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willReject) => {
        if (willReject) {
          setLoading(true);
          const res = await PartialService.rejectPartialTeacher({
            user,
            data: {
              idTeacherRequest: teacherRequest?._id,
            },
          });
          if (res?.data?.message) {
            swal("OK", "Parcial rechazado", "info").then(() => {
              setLoading(false);
              history.push("/teacher/parciales");
            });
          } else {
            setLoading(false);
            toast.error("Ha ocurrio un error");
          }
        }
      });
    } else {
      toast.error(
        "Ha ocurrido un error con el parcial, comunicate con soporte"
      );
    }
  };
  const acceptPartial = () => {
    if (teacherRequest?._id) {
      swal({
        title: "¿Seguro?",
        text: "Se te asignará este parcial",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (ok) => {
        if (ok) {
          setLoading(true);
          const res = await PartialService.acceptPartialTeacher({
            user,
            data: {
              idTeacherRequest: teacherRequest?._id,
            },
          });
          if (res?.data?.message) {
            setLoading(false);
            getPartialData();
          } else {
            setLoading(false);
            toast.error("Ha ocurrio un error");
          }
        }
      });
    } else {
      toast.error(
        "Ha ocurrido un error con el parcial, comunicate con soporte"
      );
    }
  };

  return (
    <>
      <GoBack
        back={() => history.push("/teacher/parciales")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      {isRenderLoading ? (
        <SingleLoading size={50} />
      ) : (
        <Container className={pageCss}>
          <GridContainer style={{ textAlign: "center", fontSize: 20 }}>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <img src={"/icon.png"} style={{ width: "70px" }} alt="" />
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <>
                <h3 className="detailpartail__title">
                  Información del Parcial
                </h3>
              </>

              {partial?.state === "ASSIGNED_TEACHER" && (
                <h5 style={{ lineHeight: "20px" }}>
                  <b
                    style={{ fontWeight: "500", fontSize: "initial" }}
                    className="tr-title-success good_font"
                  >
                    {/* ¡Listo {teacher.names}!, te hemos asignado el siguiente parcial{" "} */}
                    ¡Listo! has aceptado disponibilidad para el siguiente
                    requerimiento.{" "}
                    <b className="good_font">Todavia no se ha agendado</b>
                  </b>
                </h5>
              )}

              <div className="informacion__item">
                <b>Materia: </b>
                {partial.subject}
              </div>
              <div className="informacion__item">
                <b>Fecha: </b>
                {mapDate(partial.event_date)}
              </div>
              <div className="informacion__item">
                <b>Duración: </b>
                {partial.event_time} Minutos
              </div>
              {partial?.state === "PAYMENT_ACCEPTED_NO_TEACHER" && (
                <div
                  style={{
                    color: "#ffbd00",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b style={{ alignSelf: "center" }}>
                    Este parcial ya ha sido pagado
                  </b>
                  <InfoIcon style={{ alignSelf: "center" }} fontSize="medium" />
                </div>
              )}
              {partial.topics && (
                <div>
                  <b>Temas: </b>
                  {partial.topics?.toUpperCase()}
                </div>
              )}
              {partial.image_uploaded && (
                <div>
                  <b>Archivo: </b>
                  {partial.image_uploaded?.endsWith(".png") ? (
                    <a href={partial.image_uploaded}>
                      Descargar archivo adjuntado por el estudiante
                    </a>
                  ) : (
                    <>
                      <a href={partial.image_uploaded}>
                        Archivo adjuntado por el estudiante
                      </a>
                      <br />
                      <img
                        alt=""
                        src={partial.image_uploaded}
                        height="200"
                        width="200"
                      />
                    </>
                  )}
                </div>
              )}
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <br />
              <Divider></Divider>
              <br />
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              {/* <h4 className="nextpartials--material">
                Materiales de preparación
              </h4> */}
              {material.length > 0 ? (
                <>
                  <h3 className="detailpartail__title2">
                    Materiales de preparación
                  </h3>
                  {partial?.textMaterial?.split("\n").map((txm, i, data) => {
                    const view = txm.split(":");
                    if (i >= 2) {
                      if (i === data.length - 1) {
                        return (
                          <div>
                            <div className="informacion__item">
                              <b>{`Información adicional: `}</b>
                              <div>{view[1] ? view[1] : "-"}</div>
                            </div>
                            <br />
                          </div>
                        );
                      }
                      return (
                        <>
                          <div className="informacion__item">
                            <b>
                              {view[0]}
                              {": \n"}
                            </b>
                            <div>{view[1] ? view[1] : "-"}</div>
                          </div>
                          <br />
                        </>
                      );
                    } else {
                      return (
                        <div className="informacion__item">
                          <b>{`${view[0]}: `}</b>
                          {view[1] ? view[1] : "-"}
                        </div>
                      );
                    }
                  })}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                  >
                    <div
                      className="good_font"
                      style={{
                        color: "#f9004d",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      Nombre archivo
                    </div>
                    <div
                      className="good_font"
                      style={{
                        color: "#f9004d",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      Peso
                    </div>
                    <div
                      className="good_font"
                      style={{
                        color: "#f9004d",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      Descargar
                    </div>
                    {material?.map((item) => {
                      return (
                        <>
                          <div
                            style={{ fontSize: "1.1rem" }}
                            className="good_font"
                            key={item.name}
                          >
                            {item.name}
                          </div>
                          <div
                            style={{ fontSize: "1.1rem" }}
                            className="good_font"
                          >
                            {item.size}MB
                          </div>
                          <a href={item.url} target="_blank">
                            <GetAppIcon />
                          </a>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  {data?.partial?.tf_textMaterial ? (
                    <div className="informacion__item">
                      <b>{`Temas: `}</b>
                      <div>{data?.partial?.tf_textMaterial}</div>
                    </div>
                  ) : (
                    <h4
                      styles={{ fontSize: "2rem" }}
                      className="detailpartail__title2"
                    >
                      Aún no hay material
                    </h4>
                  )}
                </>
              )}
              <Divider />

              {/* <table
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <tr className="partialaccepted--tr">
                  <th>Nombre</th>
                  <th>Creado</th>
                  <th>Visualizar</th>
                </tr>
                {material.map((item) => (
                  <tr key={item.name} className="partialaccepted--td">
                    <td>{item.name}</td>
                    <td>
                      {mapDateFormat(item.created_at, "ddd DD MMM, hh:mm A")}
                    </td>
                    <td>
                      <a href={item.url} target="_blank">
                        <BsBoxArrowInDown
                          style={{
                            fontSize: "1.5rem",
                            color: "black",
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                ))}
                {material.length === 0 && !partial?.tf_file_url && (
                  <tr>
                    <td colSpan={3}>
                      <span style={{ fontSize: 12 }}>Aún sin archivos</span>
                    </td>
                  </tr>
                )}
                {partial?.tf_file_url && (
                  <tr className="partialaccepted--td">
                    <td>Primer archivo</td>
                    <td>
                      {mapDateFormat(
                        partial?.created_at,
                        "ddd DD MMM, hh:mm A"
                      )}
                    </td>
                    <td>
                      <a
                        href={partial?.tf_file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BsBoxArrowInDown
                          style={{
                            fontSize: "1.5rem",
                            color: "black",
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                )}
              </table> */}
              <Divider />
              {/* <span
                style={{
                  fontSize: "1.3rem",
                }}
              >
                <strong style={{ fontWeight: "500" }}>Descripción: </strong>{" "}
              </span>
              <span style={{ fontSize: 15 }}>
                {notErrorMapping(partial?.textMaterial)}
                <br />
                {notErrorMapping(partial?.tf_textMaterial)}
              </span> */}
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <br></br>
              <br></br>
            </GridItem>
            {isPendingTeacher(partial) && (
              <div className="detailpartial__button--container">
                <div>
                  <button
                    style={{
                      color: "black",
                      borderRadius: "10px",
                      backgroundColor: "#ffbc01",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={acceptPartial}
                  >
                    Aceptar
                  </button>
                </div>
                <div>
                  <button
                    style={{
                      backgroundColor: "#f9004d",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                    onClick={rejectPartial}
                  >
                    Rechazar
                  </button>
                </div>
              </div>
            )}
            {isPendingTeacher(partial) && (
              <GridItem
                style={{ textAlign: "start" }} /* revisar */
                xs={12}
                md={12}
                sm={12}
                lg={12}
              >
                <span className="aviso__footer">
                  <br></br>
                  <b>
                    En caso de no aceptar este parcial otro tutor puede tomarlo
                    en cualquier momento
                  </b>
                </span>
              </GridItem>
            )}
            {partial?.state === "ASSIGNED_TEACHER" && (
              <GridItem xs={12} md={12} sm={12} lg={12}>
                <span className="aviso__footer">
                  <b>
                    En un plazo de 6 horas hábiles te notificaremos si el
                    estudiante confirmó el pago y tomó el lugar
                  </b>
                </span>
              </GridItem>
            )}
          </GridContainer>
        </Container>
      )}
    </>
  );
};

export default DetailNextPartial;
