import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Graphs1 from "./graphs/Graphs1";
import Graphs2 from "./graphs/Graphs2";
import Graphs3 from "./graphs/Graphs3";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import StatisticsService from "services/StatisticsService";
import { useSelector } from "react-redux";
import { EditInput } from "components/CustomInput/EditInput";
import { mapDateFormat } from "functions/comun";
import ConfigVarService from "services/ConfigVarService";
import FormatMoney from "components/Money/FormatMoney";
import SingleLoading from "components/Loading/SingleLoading";
import CurrencyFormat from "react-currency-format";
import PaymentService from "services/PaymentService";
import AdminSells from "./graphs/AdminSells";
import FeedbackService from "services/FeedbackService";
import { toast } from "react-toastify";
import GoBack from "components/Navigation/GoBack";
import MaterialTable from "material-table";
import Parcial from "./Pages/Parcial";
import Tutoria from "./Pages/Tutoria";
import Taller from "./Pages/Taller";
import Graphs4 from "./graphs/Graphs4";

const actualWeek = moment().week();
const actualYear = moment().year();
// const actualMonth = moment().month();

export default function SimpleBreadcrumbs() {
  const { user } = useSelector((state) => state.user);
  const [activePage, setActivePage] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);
  const [students, setStudents] = useState(0);
  const [teachers, setTeachers] = useState(0);
  const [countries, setCountries] = useState([]);
  const [topSubjects, setTopSubjects] = useState([]);
  const [mainStats, setMainStats] = useState({
    sells: 0,
    sellsRequest: 0,
    cost: 0,
    util: 0,
    returns: [],
  });
  const [objetive, setObjetive] = useState({ month: 0, week: 0 });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    week: actualWeek,
    year: actualYear,
    request: "todo",
    month: "todo",
  });
  const [debtsTutor, setDebtsTutor] = useState(0);
  const [sells, setSells] = useState([]);
  const [month, setMonth] = useState(moment().month());
  const [averageResults, setAverageResults] = useState({
    q0: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
  });
  const [request, setRequest] = useState(null);

  const handleClick = (e, index) => {
    e.preventDefault();
    setActivePage(index);
  };

  const handleClickFilter = (e, index) => {
    e.preventDefault();
    setActiveFilter(index);
  };

  const renderItem = (activePageParam, text, index) => {
    return activePageParam === index ? (
      <Typography color="textPrimary">{text}</Typography>
    ) : (
      <Link color="inherit" href="/" onClick={(e) => handleClick(e, index)}>
        {text}
      </Link>
    );
  };

  const renderFilter = (activePageParam, text, index) => {
    return activePageParam === index ? (
      <Typography color="textPrimary">{text}</Typography>
    ) : (
      <Link
        color="inherit"
        href="/"
        onClick={(e) => handleClickFilter(e, index)}
      >
        {text}
      </Link>
    );
  };

  const getData = async () => {
    setLoading(true);
    const res = await StatisticsService.countByWeek({ token: user.token });
    if (res?.data?.data) {
      // setData(res?.data?.data.data1);
      // setTotalTeacher(res.data.data.totalTeachers);

      const { allSubjects } = res.data.data;
      setTopSubjects(allSubjects);
    }
    setLoading(false);
  };

  const getStudentCount = async () => {
    setLoading(true);
    const studentsCount = await StatisticsService.getStudents({ user });
    setStudents(studentsCount?.data?.data);
    setLoading(false);
  };

  const getTeacherCount = async () => {
    setLoading(true);
    const teacherCount = await StatisticsService.getTeachers({ user });
    setTeachers(teacherCount?.data?.data);
    setLoading(false);
  };

  const getCountryCount = async () => {
    setLoading(true);
    const teacherCount = await StatisticsService.countriesCount({ user });
    setCountries(teacherCount?.data?.data);
    setLoading(false);
  };

  const detailStats = async () => {
    setLoading(true);
    const res = await StatisticsService.detailStats({ user, filters });
    // console.log(res?.data?.data);
    setMainStats(res?.data?.data);
    setLoading(false);
  };

  const getObjetive = async () => {
    setLoading(true);
    const res = await ConfigVarService.getObjetive({ user });
    // console.log(res?.data?.number_value);
    // console.log(res.data);
    setObjetive({ month: res?.data?.month_value, week: res?.data?.week_value });
    setLoading(false);
  };

  const onSaveObjetive = async () => {
    setLoading(true);
    const res = await ConfigVarService.setObjetive({
      week_value: objetive.week,
      month_value: objetive.month,
    });
    // console.log(res);
    setObjetive({ month: res?.data?.month_value, week: res?.data?.week_value });
    setLoading(false);
    // console.log(res?.data);
  };

  const getSellsByAdmin = async () => {
    setLoading(true);
    const res = await StatisticsService.adminStatsByMonth({
      token: user.token,
      month,
    });
    setSells(res.data);
    setLoading(false);
  };

  const getAverageResults = (res) => {
    let averages = {
      q0: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
    };
    let count = 1;
    res.forEach((item) => {
      averages = {
        q0: (item.q0 + averages.q0) / count,
        q1: (item.q1 + averages.q1) / count,
        q2: (item.q2 + averages.q2) / count,
        q3: (item.q3 + averages.q3) / count,
        q4: (item.q4 + averages.q4) / count,
      };
      count++;
    });
    setAverageResults(averages);
  };

  const getFeedback = async () => {
    const res = await FeedbackService.getAllFeedback();
    if (res.data?.message === "OK") {
      // setFeedback(res.data.data);
      getAverageResults(res.data.data);
    } else {
      toast.error("Algo salió mal");
    }
  };

  useEffect(() => {
    getSellsByAdmin();
  }, [month]); //eslint-disable-line

  useEffect(() => {
    getStudentCount();
    getTeacherCount();
    getCountryCount();
    getData();
    detailStats();
    getObjetive();
    getDebtsTutor();
    getFeedback();
  }, []); //eslint-disable-line

  const getDebtsTutor = async () => {
    const resAsked = await PaymentService.findAll({
      token: user.token,
      state: "ASKED",
    });
    const resApproved = await PaymentService.findAll({
      token: user.token,
      state: "APPROVED",
    });

    let totalAmount = 0;
    const payments = [...resAsked?.data?.data, ...resApproved?.data?.data];
    for (let i = 0; i < payments.length; i++) {
      totalAmount += payments[i]?.amount;
    }

    // console.log(totalAmount);
    setDebtsTutor(totalAmount);
  };

  const handleChangeMonth = async (event) => {
    setLoading(true);
    setFilters({ ...filters, month: event.target.value, week: "todo" });
    const res = await StatisticsService.detailStats({
      user,
      filters: { ...filters, month: event.target.value, week: "todo" },
    });
    setMainStats(res?.data?.data);
    setLoading(false);
  };

  const handleChangeWeek = async (event) => {
    setLoading(true);
    setFilters({ ...filters, week: event.target.value, month: "todo" });
    const res = await StatisticsService.detailStats({
      user,
      filters: { ...filters, week: event.target.value, month: "todo" },
    });
    setMainStats(res?.data?.data);
    setLoading(false);
  };

  const handleChangeYear = async (event) => {
    setLoading(true);
    setFilters({ ...filters, year: event.target.value });
    const res = await StatisticsService.detailStats({
      user,
      filters: { ...filters, year: event.target.value },
    });
    setMainStats(res?.data?.data);
    setLoading(false);
  };

  const handleChangeRequest = async (event) => {
    setLoading(true);
    setFilters({ ...filters, request: event.target.value });
    const res = await StatisticsService.detailStats({
      user,
      filters: { ...filters, request: event.target.value },
    });
    console.log(res?.data?.data);
    setMainStats(res?.data?.data);
    setLoading(false);
  };

  const getComponent = () => {
    switch (activePage) {
      case 0:
        return <Graphs2 filters={filters} />;
      case 1:
        return <Graphs1 />;
      case 2:
        return <Graphs3 returns={mainStats.returns} />;
      case 3:
        return <Graphs4 filters={filters} />;
      default:
        return "NO PAGE";
    }
  };

  const getFilterComponent = (filter, handleChange, data) => {
    switch (activeFilter) {
      case 0:
        return (
          <Select value={filter} onChange={handleChange}>
            <MenuItem value="todo">Todas</MenuItem>
            {data.map((i) => (
              <MenuItem value={i}>Semana {i}</MenuItem>
            ))}
          </Select>
        );
      case 1:
        return (
          <Select value={filter} onChange={handleChange}>
            <MenuItem value="todo">Todos</MenuItem>
            {data.map((i, index) => (
              <MenuItem value={index}>{i}</MenuItem>
            ))}
          </Select>
        );
      default:
        return "NO PAGE";
    }
  };

  if (request) {
    return <RequestView request={request} setRequest={setRequest} />;
  }

  return (
    <>
      <div className="dashboard-graphs">
        <div className="filters">
          <Filters
            loading={loading}
            handleChangeWeek={handleChangeWeek}
            filters={filters}
            handleChangeYear={handleChangeYear}
            handleChangeRequest={handleChangeRequest}
            activeFilter={activeFilter}
            getFilterComponent={getFilterComponent}
            renderFilter={renderFilter}
            handleChangeMonth={handleChangeMonth}
          />
        </div>
        <div className="sells">
          <AdminSells
            month={month}
            setMonth={setMonth}
            sells={sells}
            setSells={setSells}
          />
        </div>
        <div className="data-sells">
          <DataSells
            mainStats={mainStats}
            objetive={objetive}
            setObjetive={setObjetive}
            onSaveObjetive={onSaveObjetive}
            loading={loading}
            debtsTutor={debtsTutor}
            averageResults={averageResults}
            setRequest={setRequest}
            activeFilter={activeFilter}
          />
        </div>
        <div className="graphs">
          <Graphs
            activePage={activePage}
            renderItem={renderItem}
            getComponent={getComponent}
            loading={loading}
          />
        </div>
      </div>
      <div className="dashboard-graphs2">
        <div className="top-ten">
          <TopTen topSubjects={topSubjects} loading={loading} />
        </div>
        <div className="top-ten-tutors">
          <TopTutor loading={loading} user={user} />
        </div>
        <div className="top-five">
          <TopFive countries={countries} loading={loading} />
        </div>
        <div className="country-count">
          <CountryCount countries={countries} loading={loading} />
        </div>
        <div className="tutor-count">
          <TutorCount teachers={teachers} loading={loading} />
        </div>
        <div className="students-count">
          <StudentCount students={students} loading={loading} />
        </div>
      </div>

      {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Breadcrumbs aria-label="breadcrumb">
          {renderItem(activePage, "Graficas 1", 0)}
          {renderItem(activePage, "Graficas 2", 1)}
        </Breadcrumbs>
      </div>
      <div>{getComponent()}</div> */}
    </>
  );
}

const Filters = ({
  filters,
  handleChangeWeek,
  handleChangeMonth,
  handleChangeYear,
  handleChangeRequest,
  activeFilter,
  getFilterComponent,
  renderFilter,
}) => {
  // const options = Array.from(
  //   { length: moment().week() },
  //   (_, i) => i + 1
  // ).reverse();

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const weeks = [];

  for (let i = 1; i <= 53; i++) {
    weeks.push(i);
  }

  const years = [];

  for (let i = 2021; i <= moment().year(); i++) {
    years.push(i);
  }

  return (
    <>
      <div className="filter-date">
        <FormControl fullWidth>
          <InputLabel>
            <Breadcrumbs>
              {renderFilter(activeFilter, "Semana", 0)}
              {renderFilter(activeFilter, "Mes", 1)}
            </Breadcrumbs>
          </InputLabel>
          {activeFilter === 0
            ? getFilterComponent(filters.week, handleChangeWeek, weeks)
            : getFilterComponent(filters.month, handleChangeMonth, months)}
          {/* <Select value={filters.week} onChange={handleChangeWeek}>
            <MenuItem value="todo">Todas</MenuItem>
            {weeks.map((i) => (
              <MenuItem value={i}>Semana {i}</MenuItem>
            ))}
          </Select> */}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Año</InputLabel>
          <Select value={filters.year} onChange={handleChangeYear}>
            <MenuItem value="todo">Todos</MenuItem>
            {years.map((i) => <MenuItem value={i}>{i}</MenuItem>).reverse()}
          </Select>
        </FormControl>
      </div>
      <br />
      <div className="filter-date">
        <FormControl fullWidth>
          <InputLabel>Tipo de Servicio</InputLabel>
          <Select value={filters.request} onChange={handleChangeRequest}>
            <MenuItem value="todo">Todos</MenuItem>
            <MenuItem value="partial">Parcial</MenuItem>
            <MenuItem value="homework">Taller</MenuItem>
            <MenuItem value="tutorship">Tutoría</MenuItem>
          </Select>
        </FormControl>
        <div
          className="good_font"
          style={{ width: "100%", placeSelf: "center", fontSize: "17px" }}
        >
          Semana actual: {actualWeek}
        </div>
      </div>
    </>
  );
};

const Graphs = ({ renderItem, activePage, getComponent, loading }) => {
  if (loading) return <SingleLoading />;

  return (
    <div>
      <Breadcrumbs className="breadcrumb" aria-label="breadcrumb">
        {renderItem(activePage, "Timeline", 0)}
        {renderItem(activePage, "Distribución", 1)}
        {renderItem(activePage, "Calidad", 2)}
        {renderItem(activePage, "Universidades", 3)}
      </Breadcrumbs>
      <div>{getComponent()}</div>
    </div>
  );
};

const DataSells = ({
  mainStats,
  objetive,
  setObjetive,
  onSaveObjetive,
  loading,
  debtsTutor,
  averageResults,
  activeFilter,
  setRequest,
}) => {
  const requestsClic = (list) => {
    if (list.length > 0) {
      setRequest(list);
    } else {
      toast.warning("No hay solicitudes para visualizar");
    }
  };
  if (loading) return <SingleLoading />;
  return (
    <>
      <div className="stats1">
        <h4 style={{ fontWeight: "bold" }} className="good_font">
          Solicitudes
        </h4>
        <div
          onClick={() => requestsClic(mainStats?.sells)}
          className="good_font"
          style={{ cursor: "pointer", color: "#ff0000" }}
        >
          <b># Solicitudes Vendidas: </b>
          <strong style={{ fontWeight: "bold" }}>
            {mainStats?.sells.length}
          </strong>
        </div>
        <div className="good_font">
          #Solicitudes Solicitadas:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {mainStats?.sellsRequest}
          </strong>
        </div>
        <div className="good_font">
          % Tasa de Conversion:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {(
              (100 * mainStats?.sells.length) /
              mainStats?.sellsRequest
            )?.toFixed("2")}
            %
          </strong>
        </div>
        <div className="good_font">
          #Devoluciones:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {mainStats?.returns?.length}
          </strong>
        </div>
        <div className="good_font">
          %Devoluciones:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {mainStats?.returns?.length === 0
              ? 0
              : (
                  (mainStats?.returns?.length / mainStats?.sells.length) *
                  100
                )?.toFixed("2")}
            %
          </strong>
        </div>
        <div className="good_font">
          NPS:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {averageResults?.q4?.toFixed(1)}
          </strong>
        </div>
      </div>
      <div className="stats2">
        <h4 style={{ fontWeight: "bold" }} className="good_font">
          Economics
        </h4>
        <div
          className="good_font"
          style={{
            display: "grid",
            gridAutoFlow: "row",
            justifyItems: "center",
            marginTop: "10px",
          }}
        >
          {activeFilter === 0 ? (
            <>
              <div style={{ alignSelf: "end" }}>Meta semanal de Ventas</div>
              <EditInput
                onSave={onSaveObjetive}
                showComponent={
                  <FormatMoney
                    style={{ fontWeight: "bold" }}
                    value={objetive.week}
                    country={{ label: "Colombia" }}
                  />
                }
                editComponent={
                  <FormatMoney
                    country={{ label: "Colombia" }}
                    onValueChange={(e) =>
                      setObjetive({ ...objetive, week: e.value })
                    }
                    value={objetive.week}
                    displayType="input"
                  />
                }
              />
            </>
          ) : (
            <>
              <div style={{ alignSelf: "end" }}>Meta mensual de Ventas</div>
              <EditInput
                onSave={onSaveObjetive}
                showComponent={
                  <FormatMoney
                    style={{ fontWeight: "bold" }}
                    value={objetive.month}
                    country={{ label: "Colombia" }}
                  />
                }
                editComponent={
                  <FormatMoney
                    country={{ label: "Colombia" }}
                    onValueChange={(e) =>
                      setObjetive({ ...objetive, month: e.value })
                    }
                    value={objetive.month}
                    displayType="input"
                  />
                }
              />
            </>
          )}
        </div>
        <div style={{ color: "#ff0000" }} className="good_font">
          <b>% Meta : </b>
          <strong style={{ fontWeight: "bold" }}>
            {activeFilter === 0 ? (
              <>{((100 * mainStats?.cost) / objetive.week)?.toFixed("2")}%</>
            ) : (
              <>{((100 * mainStats?.cost) / objetive.month)?.toFixed("2")}%</>
            )}
          </strong>
        </div>
        <div className="good_font">
          Dinero Vendido :{" "}
          <CurrencyFormat
            value={mainStats?.cost}
            suffix={" COP"}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontWeight: "bold" }}
          ></CurrencyFormat>
        </div>
        <div className="good_font">
          Ticket Promedio :{" "}
          <CurrencyFormat
            value={Math.trunc(mainStats?.cost / mainStats?.sells.length)}
            suffix={" COP"}
            displayType={"text"}
            thousandSeparator={true}
            style={{ fontWeight: "bold" }}
          ></CurrencyFormat>
        </div>
        <div className="good_font">
          % Utilidad:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {((mainStats?.util * 100) / mainStats?.cost)?.toFixed("2")}%
          </strong>
        </div>
        <div className="good_font">
          Utilidad bruta:{" "}
          <strong style={{ fontWeight: "bold" }}>
            <CurrencyFormat
              value={mainStats?.util}
              suffix={" COP"}
              displayType={"text"}
              thousandSeparator={true}
              style={{ fontWeight: "bold" }}
            ></CurrencyFormat>
          </strong>
        </div>
        <div className="good_font">
          Deuda total con tutores:{" "}
          <strong style={{ fontWeight: "bold" }}>
            <CurrencyFormat
              value={debtsTutor}
              suffix={" COP"}
              displayType={"text"}
              thousandSeparator={true}
              style={{ fontWeight: "bold" }}
            ></CurrencyFormat>
          </strong>
        </div>
      </div>
    </>
  );
};

const TopTen = ({ topSubjects, loading }) => {
  if (loading) return <SingleLoading />;

  const topTen = topSubjects
    ?.sort((a, b) => {
      return b.count - a.count;
    })
    .slice(0, 10);
  return (
    <>
      <h4 style={{ fontWeight: "bold" }} className="good_font">
        Top Materias
      </h4>
      <GridContainer>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <ul style={{ listStyle: "none" }}>
            {topTen.map((i, index) => (
              <li key={index} className="good_font">
                <b>{index + 1}.</b> {i.name} {`->`}{" "}
                <b style={{ fontWeight: "bold" }}>{i.count}</b>
              </li>
            ))}
          </ul>
        </GridItem>
      </GridContainer>
    </>
  );
};

const TopFive = ({ countries, loading }) => {
  const top = countries?.countries?.sort((a, b) => {
    return b.count - a.count;
  });
  // const top = [];

  // for (let i = 0; i < 5; i++) {
  //   if (data) {
  //     top.push(data[i]);
  //   }
  // }

  if (loading) return <SingleLoading />;
  return (
    <>
      <h4 style={{ fontWeight: "bold" }} className="good_font">
        Top Paises
      </h4>
      <GridContainer>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <ul style={{ listStyle: "none" }}>
            {top?.slice(0, 5)?.map((i, index) => (
              <li key={index} className="good_font">
                <b>{index + 1}.</b> {i?.name} {`->`}{" "}
                <b style={{ fontWeight: "bold" }}>{i?.count}</b>
              </li>
            ))}
          </ul>
        </GridItem>
      </GridContainer>
    </>
  );
};

const CountryCount = ({ countries, loading }) => {
  if (loading) return <SingleLoading />;
  return (
    <>
      <h4 className="good_font">
        Total paises: <b style={{ fontWeight: "bold" }}>{countries?.length}</b>
      </h4>
    </>
  );
};

const StudentCount = ({ students, loading }) => {
  if (loading) return <SingleLoading />;
  return (
    <>
      <h4 className="good_font">
        Total de estudiantes con que hemos trabajado:{" "}
        <b style={{ fontWeight: "bold" }}>{students}</b>
      </h4>
    </>
  );
};

const TutorCount = ({ teachers, loading }) => {
  if (loading) return <SingleLoading />;
  return (
    <>
      <h4 className="good_font">
        Total tutores: <b style={{ fontWeight: "bold" }}>{teachers}</b>
      </h4>
    </>
  );
};

const TopTutor = ({ loading, user }) => {
  const [value, setValue] = useState([]);

  const getValue = async () => {
    const res = await StatisticsService.getTopTutors({ user });
    if (res?.data?.message === "OK") setValue(res.data.data);
  };

  useEffect(() => {
    getValue();
  }, []);

  if (loading) return <SingleLoading />;
  return (
    <>
      <h4 style={{ fontWeight: "bold" }} className="good_font">
        Top Tutores
      </h4>
      <GridContainer>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <ul style={{ listStyle: "none" }}>
            {value.map((i, index) => (
              <li key={index} className="good_font">
                <b>{index + 1}.</b> {i.tutor.names} {`->`}{" "}
                <b style={{ fontWeight: "bold" }}>{i.count}</b>
              </li>
            ))}
          </ul>
        </GridItem>
      </GridContainer>
    </>
  );
};

const RequestView = ({ request, setRequest }) => {
  const [service, setService] = useState();
  const onRowClick = (e, rowData) => {
    setService(rowData);
  };

  if (service) {
    if (service.typeObject === "parcial")
      return <Parcial partial={service} setPartial={setService} />;
    if (service.typeObject === "tutoría")
      return <Tutoria tutorship={service} setTutorship={setService} />;
    if (service.typeObject === "taller")
      return <Taller homework={service} setHomework={setService} />;
  }

  return (
    <>
      <GoBack
        back={() => {
          setRequest(null);
        }}
      />
      {console.log(request)}
      <MaterialTable
        title={"Solicitudes vendidas"}
        onRowClick={onRowClick}
        columns={[
          {
            title: "Tipo de servicio",
            field: "typeObject",
            lookup: {
              parcial: "parcial",
              tutoría: "tutoría",
              taller: "taller",
            },
          },
          { title: "Cliente", field: "client" },
          { title: "Celular", field: "phone" },
          { title: "Materia", field: "subject", filtering: false },
          { title: "Universidad", field: "university" },
          {
            title: "Fecha p.",
            field: "event_date_formatted",
            filtering: false,
          },
          // { title: "Tiempo p.", field: "event_time", filtering: false },
          {
            title: "Solicitado",
            field: "inserted_at",
            filtering: false,
          },
          // {
          //   title: "Estado",
          //   field: "state",
          //   lookup: partialState(),
          // },
          // {
          //   title: "Color",
          //   field: "color",
          //   // lookup: colorsArray,
          // },
          { title: "Precio", field: "cost", filtering: false },
        ]}
        data={request.map((i) => {
          return {
            ...i,
            event_date_formatted: mapDateFormat(
              i.event_date,
              "DD MMM YYYY, hh:mm A"
            ),
            inserted_at: mapDateFormat(i.inserted_at, "DD MMM YYYY, hh:mm A"),
          };
        })}
        localization={{
          toolbar: {
            searchPlaceholder: "Buscar",
          },
        }}
        options={{
          actionsColumnIndex: -1,
          // pageSize: getRowsCount(data),
          pageSizeOptions: [10, 50, 100, 500, 1000],
          grouping: false,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.color,
          }),
          search: false,
          sorting: false,
          filtering: true,
          // exportAllData,
          exportButton: {
            csv: true,
            pdf: true,
          },
        }}
      ></MaterialTable>
    </>
  );
};
