import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  TextField,
  withStyles,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import GoBack from "components/Navigation/GoBack";
import { mapDate } from "functions/comun";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pending from "./partials/Pending";

import DeleteIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import CheckIcon from "@material-ui/icons/Check";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import Snackbar from "components/Snackbar/Snackbar";
import PartialService from "services/PartialService";
import PaymentAccepted from "./partials/PaymentAccepted";
import FormatMoney from "components/Money/FormatMoney";
import { partialStateTranslate } from "functions/comun";
import { partialTypeTranslate } from "functions/comun";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import PaginationTeacherAssign from "./partials/PaginationTeacherAssign";
import ShareIcon from "@material-ui/icons/Share";
import { toast } from "react-toastify";
import EventIcon from "@material-ui/icons/Event";

import moment from "moment";
import { EditInput } from "components/CustomInput/EditInput";
import Select from "react-select";
import { MiniComponentTeacherAssigned } from "./partials/MiniComponentTeacherAssigned";
import { MiniComponentPartialActions } from "./partials/MiniComponentPartialActions";
import { scrollToTop } from "functions/scrollToTop";
import CustomDateTimePicker from "components/DatePicker/CustomDateTimePicker";
import SingleLoading from "components/Loading/SingleLoading";
import SimpleModal from "components/Modal/SimpleModal";
import ColorModalService from "services/ColorModalService";
import PaymentService from "services/PaymentService";
import { adminPhone1 } from "variables/superAdminEmails";

const myParseDate = (date) => moment(date).format("ddd DD MMMM YYYY, hh:mm A");

const MAX_FILES_PER_PARTIAL = 5;
const MB = 40;
const MAX_FILE_SIZE_PARTIAL_MATERIAL = 1048576 * MB; // 40MB

const StyledCheckBox = withStyles({
  root: {
    color: "black",
  },
})(FormControlLabel);

const Parcial = ({
  partial,
  setPartial,
  getData,
  subjectOptions,
  setService,
  payment,
  setPayment,
}) => {
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [editSubject, setEditSubject] = useState(partial?.subject);
  const [teacherData, setTeacherData] = useState(null);
  const [editCost, setEditCost] = useState(partial?.cost);
  const [triggerReload, setTriggerReload] = useState(false);
  const [dateEvent, setDateEvent] = useState({
    date: partial?.event_date,
    time: partial?.event_time,
  });
  const [materials, setMaterials] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [dataToReturn, setDataToReturn] = useState({
    note: "",
    details: "",
    value: "",
    bank: "",
    acc: "",
  });
  const [textReturn, setTextAddReturn] = useState([]);
  const [name, setName] = useState(partial?.client);
  const [university, setUni] = useState();

  const onTriggerReload = () => {
    setTriggerReload(!triggerReload);
  };

  const getComponent = () => {
    switch (partial.state) {
      case "PENDING_PAY":
        return (
          <>
            <Pending
              partial={partial}
              loading={loading}
              setLoading={setLoading}
              user={user}
              setPartial={setPartial}
              getData={getData}
              onTriggerReload={onTriggerReload}
            />
            <PaymentAccepted partial={partial} user={user} />
          </>
        );
      case "PAYMENT_ACCEPTED_NO_TEACHER":
        return (
          <>
            <Pending
              partial={partial}
              loading={loading}
              setLoading={setLoading}
              user={user}
              setPartial={setPartial}
              getData={getData}
            />
            <PaymentAccepted partial={partial} user={user} />
          </>
        );
      case "READY":
        return <PaymentAccepted partial={partial} user={user} />;
      case "PAYMENT_DECLINED":
      case "PAYMENT_ERROR":
        return <PaymentAccepted partial={partial} user={user} />;
      default:
        return <PaymentAccepted partial={partial} user={user} />;
    }
  };

  const deletePartial = () => {
    swal({
      title: "¿Seguro?",
      text: "Se borrará completamente el parcial",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        const token = user.token;
        const id = partial._id;
        setLoading(true);
        const res = await PartialService.deletePartial({ token, id });
        if (res.data.message !== "OK") {
          console.error("Hubo un error al eliminar el parcial");
        } else {
          getData();
          setPartial(null);
          swal("Correcto", "Parcial eliminado", "success");
        }
        setLoading(false);
      }
    });
  };

  const copyToClipboard = (text, successText) => {
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value = text;

    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);

    toast.success(successText);
  };

  const addToGoogleCalendar = async () => {
    //console.log(partial);
    const mainEmails = `copywrite.col@gmail.com,maicol.copywrite@gmail.com,stefanny.copywrite@gmail.com`;
    let emails = "";
    if (teacherData?.email) {
      emails = `${mainEmails},${teacherData?.email}`;
    } else {
      emails = mainEmails;
    }

    copyToClipboard(emails, "Participantes copiado al portapapeles");

    const text = `Parcial - ${partial?.client} - ${partial?.subject} - ${teacherData?.names}`;
    const details = `ID: ${partial._id} \nFecha del parcial: ${myParseDate(
      partial.event_date
    )}\nCliente: ${partial?.client} \nMateria: ${
      partial?.subject
    } \nEstado: ${partialStateTranslate(partial?.state)} \nTipo: ${
      partial?.type
    } \nRecibido el: ${myParseDate(
      partial?.created_at
    )}\nAgregado a GC: ${myParseDate(moment())}\nAgregado por Admin: ${
      user.names
    }`;

    const startPartialAt = moment(partial?.event_date).local();
    const endPartialAt = moment(partial?.event_date)
      .local()
      .add(partial?.event_time, "minutes");

    // //console.log("startPartialAt:", startPartialAt.format());
    // //console.log("endPartialAt:", endPartialAt.format());

    // 20200316T010000Z
    const f = "YYYYMMDDTHHmmss";

    const url = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${startPartialAt.format(
      f
    )}/${endPartialAt.format(f)}&text=${encodeURIComponent(
      text
    )}&details=${encodeURIComponent(details)}`;

    window.open(url, partial._id);

    const calendar = await PartialService.addCalendar({
      user,
      idPartial: partial._id,
    });
    // console.log(calendar?.data?.data)
    setPartial(calendar?.data?.data);
  };

  const getTeacherData = async (assigned_to) => {
    //console.log("assigned_to:", assigned_to);
    if (assigned_to) {
      setLoading(true);
      const res = await PartialService.findTeacherById({
        idTeacher: assigned_to,
        token: user.token,
      });
      if (res?.data) {
        setTeacherData(res.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (partial?.assigned_to) {
      getTeacherData(partial?.assigned_to);
    }
  }, [partial?.assigned_to]); // eslint-disable-line

  const publicPaymentLink = `${window.location.origin}/public/payment-stepper/${partial._id}`;
  const publicMaterialLink = `${window.location.origin}/public/material-stepper/${partial._id}`; //mensaje para enviar por sms toca acortarlo

  const openWappPaymentLink = () => {
    const phone = partial.phone;
    const text = `*¡Hola ${partial.client}!*\n🚀 Ingresa al link, haz el pago y listo ¡Quedarás agendado!\n\n🔗 ${publicPaymentLink}\n\n*¡Si no subes material de preparación no hay garantía!📍*`;
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, `payment_link_${partial._id}`);
  };

  const openWappMaterialLink = () => {
    const phone = partial.phone;
    const text = `👋 *Hola ${partial.client}, ¡Transacción confirmada!* ✅\n\n🚀 Haz sido agendado en *Parciales Copywrite.* Ingresa aquí:\n\n🔗 ${publicMaterialLink}\n\n¡Si no subes material de preparación no hay garantía!📍`;
    // const text = `🚀 Hola ${partial.client}, has sido agendad@ en PARCIALES Copywrite!.\n\nLee toda la info, sigue los pasos y ¡listo!👇\n\n📍Recuerda que si no subes el material de preparación NO HAY GARANTIA\n\n🔗 ${publicMaterialLink}`;
    // const text = `🚀 Hola ${partial.client}. *¡Sigue los pasos y sube tu material de Preparación!* 👇\n\n${publicMaterialLink}\n\nSi tienes problemas para subirlo dinoslo y lo haremos MANUAL.\n\n#MejorConCopywrite`;
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, `material_link_${partial._id}`);
  };

  const onSaveCost = async () => {
    const res = await PartialService.updateCost({
      token: user.token,
      newCost: editCost,
      idPartial: partial._id,
    });
    if (res) {
      toast.success("Costo actualizado correctamente!");
      setPartial(res?.data?.data);
    }
  };

  const onSaveSubject = async () => {
    const res = await PartialService.updateSubject({
      token: user.token,
      idNewSubject: editSubject,
      idPartial: partial._id,
    });
    if (res) {
      toast.success("Materia actualizada correctamente!");
      setPartial(res?.data?.data);
    }
  };

  const updateName = async () => {
    const res = await PartialService.updateName({
      user,
      idPartial: partial?._id,
      name,
    });
    if (res?.data?.data) {
      toast.success("Nombre actualizado correctamente");
      setPartial(res?.data?.data);
    } else {
      toast.error("Algo salió mal");
    }
  };

  const saveDateEvent = async () => {
    // console.log("save event", dateEvent);
    const res = await PartialService.updateDate({
      token: user.token,
      event_date: dateEvent.date,
      event_time: dateEvent.time,
      _id: partial?._id,
    });
    if (res) {
      toast.success("Fecha y/o duracion actualizado correctamente!");
      setPartial(res?.data?.data);
    }
  };

  const updateUniversity = async () => {
    // console.log("save event", dateEvent);
    const res = await PartialService.updateUniversity({
      token: user.token,
      university,
      _id: partial?._id,
    });
    if (res) {
      toast.success("Universidad actualizado correctamente!");
      setPartial(res?.data?.data);
    }
  }

  const uploadMaterial = async (file) => {
    //console.log(file);
    if (file && partial?._id && file?.size > 0) {
      if (file.size <= MAX_FILE_SIZE_PARTIAL_MATERIAL) {
        setLoading(true);
        // return;
        const res = await PartialService.uploadMaterial({
          idPartial: partial?._id,
          file,
        });
        if (res?.data?.message) {
          toast.success("Archivo cargado correctamente");
          if (getData) {
            getMaterials();
            getData();
          }
        } else {
          toast.error("Ha ocurrido un error");
        }
        setLoading(false);
      } else {
        toast.error(`El tamaño maximo por archivo es de ${MB} MB`);
      }
    } else {
      toast.error(
        "Archivo no encontrado, intenta seleccionarlo desde tu galeria"
      );
    }
  };

  const getMaterials = async () => {
    if (partial?._id) {
      setLoading(true);
      const res = await PartialService.getMaterials({ id: partial?._id });
      if (res?.data?.data) setMaterials(res?.data?.data);
      setLoading(false);
    }
  };

  const deleteMaterial = async (id) => {
    if (id) {
      setLoading(true);
      const res = await PartialService.deleteMaterial({ id });
      if (res?.data?.message) {
        toast.success("Archivo borrado correctamente");
        if (getData) {
          getMaterials();
          getData();
        }
      } else {
        toast.error("Ha ocurrido un error");
      }
      setLoading(false);
    }
  };

  const uploadMultipleFiles = async (e) => {
    // onChange={(e) => uploadMaterial(e.target.files[0])}
    const files = e.target.files;
    setLoading(true);
    for (var file of files) {
      await uploadMaterial(file);
    }
    setLoading(false);
  };

  const addReturn = () => {
    setOpenModal(true);
  };

  const uploadPaymentBillAdditional = async (e) => {
    setLoading(true);
    // const images = [];
    let isOk = true;
    let res;
    for (const i of e.target.files) {
      let j = 0;
      res = await PartialService.savePayment2({
        file: i,
        idPartial: partial._id,
        user,
      });
      if (res.data?.message !== "OK") {
        isOk = false;
        toast.success("Algo salió mal!");
      }
      j++;
    }
    if (isOk) {
      toast.success("Captura guardada correctamente");
      setPartial(res.data?.data);
    }
    setLoading(false);
  };

  const onChange = async (note = 0) => {
    const rescolor = await ColorModalService.updateColorModal({
      token: user.token,
      idClient: partial?._id,
      type: "partial",
      color: "#93e3fd",
    });
    if (rescolor?.message === "OK") {
      const respartial = await PartialService.addReturn({
        user,
        textReturn,
        idPartial: partial?._id,
        date: new Date(),
      });
      if (respartial?.data?.message === "OK") {
        const res_cost = await PartialService.updateCost({
          token: user.token,
          newCost: (note / 4) * partial.cost,
          idPartial: partial._id,
        });
        const res_deb = await PartialService.editValue({
          idPartial: partial._id,
          newValue: (note / 4) * partial.debt_to_teacher,
          user,
        });
        if (res_cost && res_deb) {
          setPartial(res_deb?.data?.data);
          const res = await PaymentService.updateAmountByReturn({
            token: user.token,
            objectID: partial._id,
            typeObject: "PARTIAL",
            newValue: (note / 4) * partial.debt_to_teacher,
          });
        }
        const phone = adminPhone1;
        const text = `🚨 *NUEVA DEVOLUCIÓN POR $${
          dataToReturn.value
        } COP POR NOTA DE ${
          dataToReturn.note
        }*\n\n*Tipo:* Parcial.\n*Asignatura:* ${
          partial.subject
        }\n*Nombre Estudiante:* ${partial.client}.\n*Banco:* ${
          dataToReturn.bank
        }\n*# Cuenta:* ${dataToReturn.acc}\n*Motivo:* ${
          textReturn[0]
        } \n*Tutor asignado:* ${teacherData.names}\n*Id del servicio:* ${
          partial._id
        }\n*Valor a pagar a tutor:* ${
          (note / 4) * partial.debt_to_teacher
        } COP\n*Valor actual de la venta:* ${
          (note / 4) * partial.cost
        } COP\n*Notas:* ${dataToReturn.details}`;
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
          text
        )}`;
        window.open(url, `return_${partial._id}`);
        toast.success("Devolución creada con exito");
      }
    }
    setOpenModal3(false);
  };

  const onChangeReasson = (reasson) => {
    let arr = textReturn;
    if (arr.indexOf(reasson) === -1) {
      arr.push(reasson);
    } else {
      arr = arr.filter((item) => reasson !== item);
    }
    setTextAddReturn(arr);
  };

  useEffect(() => {
    scrollToTop();
  }, []); // eslint-disable-line

  return (
    <GridContainer style={{ textAlign: "center" }} spacing={10}>
      <GoBack
        back={() => {
          setPartial(null);
          if (setService) setService(null);
          if (setPayment) setPayment(payment);
        }}
      />
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <EditInput
          onSave={onSaveSubject}
          showComponent={<h1>{partial.subject}</h1>}
          editComponent={
            <div>
              <InputLabel>Materia:</InputLabel>
              {subjectOptions && (
                <Select
                  options={subjectOptions}
                  onChange={(e) => setEditSubject(e.value)}
                />
              )}
            </div>
          }
        />

        <div>
          ID: <b>{partial?._id}</b>
        </div>
        <br />
        <GridContainer>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            {/* <b>Cliente: </b>
            {partial?.client} */}
            <EditInput
              onSave={updateName}
              showComponent={
                <>
                  <b>Cliente:</b>
                  {partial?.client}
                </>
              }
              editComponent={
                <div>
                  <Input
                    value={name}
                    onChange={(value) => setName(value.target.value)}
                    placeholder="Nombre del cliente..."
                  ></Input>
                </div>
              }
            />
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Celular: </b>
            {partial?.phone}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Recibido: </b>
            {partial?.inserted_at}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Valor: </b>
            <EditInput
              onSave={onSaveCost}
              showComponent={
                <FormatMoney
                  value={partial?.cost}
                  country={{ label: "Colombia" }}
                />
              }
              editComponent={
                <FormatMoney
                  country={{ label: "Colombia" }}
                  onValueChange={(e) => setEditCost(e.value)}
                  value={editCost}
                  displayType="input"
                />
              }
            />
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <EditInput
              onSave={saveDateEvent}
              showComponent={
                <>
                  <b>Fecha parcial: </b>
                  {mapDate(partial?.event_date)}
                </>
              }
              editComponent={
                <CustomDateTimePicker
                  value={dateEvent.date}
                  setValue={(dateTime) =>
                    setDateEvent({ ...dateEvent, date: dateTime })
                  }
                  componentWidth="100%"
                />
              }
            ></EditInput>
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <EditInput
              onSave={saveDateEvent}
              showComponent={
                <>
                  <b>Tiempo parcial: </b>
                  {`${partial?.event_time} min`}
                </>
              }
              editComponent={
                <Input
                  value={dateEvent.time}
                  onChange={(dateTime) =>
                    setDateEvent({ ...dateEvent, time: dateTime.target.value })
                  }
                  componentWidth="100%"
                />
              }
            ></EditInput>
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Estado: </b>
            {partialStateTranslate(partial?.state)}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Tipo: </b>
            {partialTypeTranslate(partial?.type)}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <EditInput
              onSave={updateUniversity}
              showComponent={
                <>
                  <b>Universidad:</b>
                  {partial?.university}
                </>
              }
              editComponent={
                <div>
                  <Input
                    value={university}
                    onChange={(value) => setUni(value.target.value)}
                    placeholder="Nombre de la universidad..."
                  ></Input>
                </div>
              }
            />
          </GridItem>
        </GridContainer>
        <Divider />
        <br />
        <GridContainer>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {partial?.assigned_to ? (
              <>
                <b style={{ color: "green" }}>
                  Este parcial ya tiene un profesor asignado
                </b>
                <DoneIcon fontSize="large" style={{ color: "green" }} />
              </>
            ) : (
              <>
                <b style={{ color: "red" }}>
                  Este parcial aún no tiene un profesor asignado
                </b>
                <ErrorIcon fontSize="large" style={{ color: "red" }} />
              </>
            )}
          </GridItem>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {
              <>
                {partial?.is_paid || partial?.paymentBill ? (
                  <>
                    {partial.is_paid ? (
                      <>
                        <b style={{ color: "green" }}>
                          Este parcial ya ha sido pagado por pasarela virtual
                        </b>
                      </>
                    ) : (
                      <>Este parcial ya ha sido pagado</>
                    )}

                    <DoneIcon fontSize="large" style={{ color: "green" }} />
                  </>
                ) : (
                  <>
                    <b style={{ color: "red" }}>
                      Este parcial aún no ha sigo pagado
                    </b>
                    <ErrorIcon fontSize="large" style={{ color: "red" }} />
                  </>
                )}
              </>
            }
          </GridItem>
          {partial?.paymentBill && (
            <GridItem
              xs={12}
              md={12}
              sm={12}
              lg={12}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="steptwo--btn">
                <div>
                  <h4 style={{ backgroundColor: "green", fontSize: "14px" }}>
                    Subir comprobante adicional
                  </h4>
                  <input
                    multiple
                    type="file"
                    className="custom-file-input"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={uploadPaymentBillAdditional}
                  />
                </div>
              </div>
            </GridItem>
          )}
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <table>
              <th>
                <td>
                  {partial?.paymentBill && (
                    <>
                      <a
                        href={partial.paymentBill}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Ver comprobante principal{" -"}
                      </a>
                    </>
                  )}
                </td>
              </th>
              {partial?.paymentBill2?.map((item, index, arr) => {
                return (
                  <th>
                    <td>
                      <a
                        href={item}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Comprobate adicional {index + 1}
                        {arr.length > index + 1 && " -"}
                      </a>
                    </td>
                  </th>
                );
              })}
            </table>
          </GridItem>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <b>Acciones: </b>
            </div>
            <GridContainer>
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Link de pago</b>
                </div>
                <IconButton onClick={openWappPaymentLink}>
                  <ShareIcon />
                </IconButton>
              </GridItem>
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Link de mat. preparación</b>
                </div>
                <IconButton onClick={openWappMaterialLink}>
                  <ShareIcon />
                </IconButton>
              </GridItem>
              <MiniComponentPartialActions
                partial={partial}
                setPartial={setPartial}
                teacher={teacherData}
              />
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Crear calendar</b>
                </div>
                <IconButton onClick={addToGoogleCalendar}>
                  <EventIcon />
                </IconButton>
              </GridItem>
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Crear devolución</b>
                </div>
                <IconButton onClick={addReturn}>
                  <MoneyOffIcon />
                </IconButton>
                <SimpleModal
                  open={openModal}
                  setOpen={setOpenModal}
                  widthModal={"45%"}
                  heightModal={"75%"}
                >
                  <div
                    style={{
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      display: "grid",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                        fontWeight: "bolder",
                        paddingBottom: "1rem",
                      }}
                    >
                      ¿Cual es el motivo de la devolución?
                    </div>
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Bajo desempeño del tutor"}
                      value={"Bajo desempeño del tutor"}
                      color="primary"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Estudiante agendó materia ó temas equivocados"}
                      value={"Estudiante agendó materia ó temas equivocados"}
                      color="secondary"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={
                        "Cancelación del requerimiento por parte del estudiante"
                      }
                      value={
                        "Cancelación del requerimiento por parte del estudiante"
                      }
                      color="black"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"No hay tutor disponible"}
                      value={"No hay tutor disponible"}
                      color="black"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Descuido de Customer Service de Copywrite"}
                      value={"Descuido de Customer Service de Copywrite"}
                      color="black"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Otro"}
                      color="black"
                      value={"Otro"}
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <Button
                      // onClick={onChange}
                      onClick={() => {
                        setOpenModal(false);
                        setOpenModal2(true);
                      }}
                      style={{
                        placeSelf: "end",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      Siguiente
                    </Button>
                  </div>
                </SimpleModal>
                <SimpleModal
                  open={openModal2}
                  setOpen={setOpenModal2}
                  widthModal={"45%"}
                  heightModal={"75%"}
                >
                  <form noValidate autoComplete="off">
                    <div
                      style={{
                        paddingLeft: "2rem",
                        gap: "2rem",
                        paddingRight: "2rem",
                        display: "grid",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bolder",
                          paddingBottom: "1rem",
                        }}
                      >
                        Detalles de la devolución
                      </div>
                      <TextField
                        type="number"
                        id="standard-basic"
                        label="Nota que obtuvo el estudiante"
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            note: e.target.value,
                            value:
                              partial.cost -
                              (e.target.value / 4) * partial.cost,
                          });
                        }}
                        value={dataToReturn.note}
                      />
                      <TextField
                        type="number"
                        id="standard-basic"
                        disabled={true}
                        label="Valor de la devolución"
                        // onChange={(e) => {
                        //   setDataToReturn({
                        //     ...dataToReturn,
                        //     value: e.target.value,
                        //   });
                        // }}
                        value={dataToReturn.value}
                      />
                      <TextField
                        id="standard-basic"
                        label="Detalles sobre la devolución"
                        placeholder="detalles ..."
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            details: e.target.value,
                          });
                        }}
                        value={dataToReturn.details}
                      />

                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <Button
                          // onClick={onChange}
                          onClick={() => {
                            setOpenModal(true);
                            setOpenModal2(false);
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "grey",
                            color: "white",
                          }}
                        >
                          Atrás
                        </Button>
                        <Button
                          // onClick={onChange}
                          onClick={() => {
                            if (!dataToReturn.value) {
                              toast.error("El valor no puede estar vacío");
                            }
                            if (!dataToReturn.note) {
                              toast.error("La nota no puede estar vacía");
                            }
                            if (dataToReturn.value && dataToReturn.note) {
                              setOpenModal2(false);
                              setOpenModal3(true);
                            }
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "green",
                            color: "white",
                          }}
                        >
                          Siguiente
                        </Button>
                      </div>
                    </div>
                  </form>
                </SimpleModal>
                <SimpleModal
                  open={openModal3}
                  setOpen={setOpenModal3}
                  widthModal={"45%"}
                  heightModal={"75%"}
                >
                  <form noValidate autoComplete="off">
                    <div
                      style={{
                        paddingLeft: "2rem",
                        gap: "2rem",
                        paddingRight: "2rem",
                        display: "grid",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bolder",
                          paddingBottom: "1rem",
                        }}
                      >
                        Datos del medio de pago
                      </div>
                      <TextField
                        id="standard-basic"
                        label="Banco"
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            bank: e.target.value,
                          });
                        }}
                        value={dataToReturn.bank}
                      />
                      <TextField
                        id="standard-basic"
                        label="Número de cuenta"
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            acc: e.target.value,
                          });
                        }}
                        value={dataToReturn.acc}
                      />
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <Button
                          // onClick={onChange}
                          onClick={() => {
                            setOpenModal2(true);
                            setOpenModal3(false);
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "grey",
                            color: "white",
                          }}
                        >
                          Atrás
                        </Button>
                        <Button
                          onClick={() => {
                            onChange(dataToReturn.note);
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "green",
                            color: "white",
                          }}
                        >
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </form>
                </SimpleModal>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <br />
        <Divider />
        <br />
        {partial?.topics && (
          <div>
            <b>Temas: </b>
            {partial.topics?.toUpperCase()}
          </div>
        )}
        {partial?.image_uploaded && (
          <div>
            <b>Archivo: </b>
            {partial.image_uploaded?.endsWith(".png") ? (
              <a href={partial.image_uploaded}>
                Archivo adjuntado por el estudiante
              </a>
            ) : (
              <>
                <a href={partial.image_uploaded}>
                  Descargar archivo adjuntado por el estudiante
                </a>
                <br />
                <img
                  alt=""
                  src={partial.image_uploaded}
                  height="200"
                  width="200"
                />
              </>
            )}
          </div>
        )}
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h4>Asignación de profesor</h4>
        <MiniComponentTeacherAssigned
          teacherData={teacherData}
          partial={partial}
        />
        <PaginationTeacherAssign
          partial={partial}
          setPartial={setPartial}
          triggerReload={triggerReload}
        />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
        <br></br>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
        <br></br>
      </GridItem>
      {getComponent()}
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <div className="steptwo--btn" style={{ marginTop: "85px" }}>
          <div>
            <h4>Seleccionar Archivo</h4>
            <input
              multiple
              type="file"
              className="custom-file-input"
              onChange={uploadMultipleFiles}
              disabled={loading || materials.length >= MAX_FILES_PER_PARTIAL}
            />
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} className="cst2-grid">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Archivo</th>
              <th>Peso</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <SingleLoading size={20} />
                </td>
              </tr>
            ) : (
              materials.map((i) => (
                <tr key={i._id}>
                  <td>
                    <a href={i.url} target="_blank" rel="noopener noreferrer">
                      {i.name}
                    </a>
                  </td>
                  <td>{i.size} MB</td>
                  <td>
                    <IconButton
                      size="small"
                      onClick={() => deleteMaterial(i._id)}
                    >
                      <DeleteIcon style={{ color: "#dc3545" }} />
                    </IconButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
        <br></br>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Button
          style={{ backgroundColor: "rgb(220, 0, 78)", color: "white" }}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={deletePartial}
        >
          Eliminar Parcial
        </Button>
      </GridItem>
      <Snackbar
        place="tr"
        color="success"
        icon={CheckIcon}
        message="Copiado"
        open={copied}
        closeNotification={() => setCopied(false)}
        close
      />
    </GridContainer>
  );
};

export default Parcial;
