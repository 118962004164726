import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import swal from "sweetalert";
import { MenuItem, Select } from "@material-ui/core";
import { partialsState } from "functions/comun";
import { partialStateTranslate } from "functions/comun";
import StateModalService from "services/StateModalService";
import { toast } from "react-toastify";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function StateModal({
  getData,
  user,
  elementToState,
  open,
  setOpen,
  setElementToState,
  type,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(elementToState?.state);

  const handleClose = () => {
    setOpen(false);
    setElementToState(null);
  };

  const save = async () => {
    if (!selected) {
      swal("Error", "Seleccione un estado", "error");
      return;
    }

    setLoading(true);

    const token = user.token;
    const id = elementToState._id;
    const newState = selected;

    const res = await StateModalService.updateStateModal({
      token,
      id,
      type,
      newState,
    });
    //console.log(res);
    if (res.message !== "OK") {
      //console.log("Hubo un error en el color Modal");
    } else {
      setLoading(false);
      handleClose();
      if (getData) getData();
      toast.success("Estado actualizado");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h5 id="simple-modal-title" style={{ textAlign: "center" }}>
          Cambiar el estado del {type} {elementToState?.code}
        </h5>
        <GridContainer>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{ textAlign: "-webkit-center" }}
          >
            <Select
              fullWidth
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              {partialsState.map((i) => (
                <MenuItem value={i}>{partialStateTranslate(i)}</MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{ textAlign: "-webkit-center" }}
          >
            <Button
              type="button"
              color="success"
              size="sm"
              style={{ marginTop: 20 }}
              onClick={save}
              loading={loading}
            >
              {"Guardar"}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </Modal>
  );
}
