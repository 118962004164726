import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import WhatsappIconWhite from "assets/customIcons/WhatsappIconWhite";
// @material-ui/icons

const whatsAppButton = createTheme({
  palette: {
    primary: {
      main: "#1eb957",
    },
  },
});

export default function WhatsAppButton(props) {
  const { text, link } = props;
  return (
    <ThemeProvider theme={whatsAppButton}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        classes={{ label: "buttonLabel" }}
      >
        <a href={`${link}`}>
          <span role="img" aria-label="">
            <WhatsappIconWhite />{" "}
          </span>
          <span style={{ color: "white" }} className="good_font">
            {text}
          </span>
        </a>
      </Button>
    </ThemeProvider>
  );
}

WhatsAppButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};
