import axios from "axios";
const { URL_API } = require("service/config");

export default class PreRequestService {
  static async getAllRequest({ user }) {
    const config = {
      method: "get",
      url: URL_API + "pre-request",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async createPreRequest(data) {
    const {
      cost,
      dateTime,
      minutes,
      names,
      phone,
      subject,
      textMaterial,
      request_type,
      persons,
      files,
      country,
      dollarCost,
    } = data;
    // //console.log(data);
    const config = {
      method: "post",
      url: `${URL_API}pre-request`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        id_subject: subject.value,
        request_type,
        dateTime,
        minutes,
        names,
        phone,
        cost,
        country,
        dollarCost,
        textMaterial,
        persons: persons ? persons.value : "una",
        files: files ? files : 0,
        state: "Pendiente",
      }),
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async updatePreRequest(data) {
    const {
      cost,
      dateTime,
      minutes,
      names,
      phone,
      subject,
      textMaterial,
      // request_type,
      persons,
      country,
      dollarCost,
      files,
      state,
      idPreRequest,
      id_subject,
      _id,
    } = data;
    // //console.log(data);
    if (
      state === "Confirmado" ||
      state === "Pendiente" ||
      state === "Finalizado"
    ) {
      const config = {
        method: "put",
        url: `${URL_API}pre-request`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id_subject: subject ? subject.value : id_subject,
          minutes,
          dateTime,
          cost,
          phone,
          names,
          textMaterial,
          country,
          dollarCost,
          persons: persons ? persons.value : "una",
          state,
          files: files ? files.length : 0,
          _id: idPreRequest ? idPreRequest : _id,
        }),
      };
      try {
        const res = await axios(config);
        return res;
      } catch (error) {
        //console.log(error.message);
        return null;
      }
    }
  }

  static async updateAllData(data) {
    const {
      amount,
      event_date,
      event_time,
      hours,
      id_subject,
      nameStudent,
      // nameSubject,
      numberStudents,
      numberPhone,
      state,
      country,
      dollarCost,
      textMaterial,
      // type_request,
      _id,
    } = data;
    // //console.log(data);
    const config = {
      method: "put",
      url: `${URL_API}pre-request`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        subject: id_subject,
        event_time,
        event_date,
        amount,
        numberPhone,
        nameStudent,
        textMaterial: textMaterial,
        numberStudents,
        state,
        country,
        dollarCost,
        hours: hours,
        _id,
      }),
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }
}
