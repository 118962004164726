import React, { useEffect } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import { useSelector } from "react-redux";
import { useState } from "react";
import swal from "sweetalert";
import {
  isValidPassword,
  isValidPasswordDetail,
} from "utils/passwordValidator";
import AdminService from "services/AdminService";
import SingleLoading from "components/Loading/SingleLoading";

const PerfilAdmin = () => {
  const { token } = useSelector((state) => state.user.user);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [admin, setAdmin] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAdmin();
  }, []); // eslint-disable-line

  const isRenderLoading = loading || Object.entries(admin).length === 0;

  const getAdmin = async () => {
    setLoading(true);
    const res = (await AdminService.getAdmin({ token })).data;
    setAdmin(res);
    setLoading(false);
  };

  const changePassword = async (e) => {
    e.preventDefault();

    const errors = isValidPasswordDetail(password);
    if (errors.length > 0 && password !== repeatPassword) {
      const arrayErrors = [];
      errors.forEach((error) => {
        arrayErrors.push(error);
      });
      arrayErrors.push("Deben ser iguales las contraseñas");
      return swal(
        "Error",
        `${arrayErrors.map((error) => ` ${error}`)}`,
        "error"
      );
    }

    if (isValidPassword(password)) {
      const res = await AdminService.updatePasswordAdmin({
        token,
        password,
      });
      if (res) {
        swal("Genial", "Contraseña actualizada correctamente", "success");
        setPassword("");
        setRepeatPassword("");
      }
    }
  };

  return (
    <GridContainer>
      <form onSubmit={changePassword}>
        <h3>Cambiar contraseña</h3>
        {isRenderLoading ? (
          <SingleLoading size={50} />
        ) : (
          <>
            <h3>{admin.names} - Admin</h3>
            <h4>
              <strong>Celular: </strong>
              {admin.phone}
            </h4>
            <h4>
              <strong>Email: </strong>
              {admin.email}
            </h4>
          </>
        )}
        <CustomInput
          labelText="Password..."
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "password",
            autoComplete: "off",
            onChange: (e) => setPassword(e.target.value),
            value: password,
            required: true,
          }}
        />
        <CustomInput
          labelText="Repeat password..."
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "password",
            autoComplete: "off",
            onChange: (e) => setRepeatPassword(e.target.value),
            value: repeatPassword,
            required: true,
          }}
        />
        <Button type="submit" color="primary" size="lg" block>
          {"Cambiar contraseña"}
        </Button>
      </form>
    </GridContainer>
  );
};

export default PerfilAdmin;
