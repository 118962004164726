import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect } from "react";

export default function AdminSells({ month, setMonth, sells, setSells }) {
  const months = [
    { value: 0, name: "Enero" },
    { value: 1, name: "Febrero" },
    { value: 2, name: "Marzo" },
    { value: 3, name: "Abril" },
    { value: 4, name: "Mayo" },
    { value: 5, name: "Junio" },
    { value: 6, name: "Julio" },
    { value: 7, name: "Agosto" },
    { value: 8, name: "Septiembre" },
    { value: 9, name: "Octubre" },
    { value: 10, name: "Noviembre" },
    { value: 11, name: "Diciembre" },
  ];
  return (
    <div className="admin-sells-table good_font">
      <div>
        <b>Ventas por agente</b>
      </div>
      <Select value={month} onChange={(e) => setMonth(e.target.value)}>
        <MenuItem value="ALL">Todos</MenuItem>
        {months.map((i) => (
          <MenuItem value={i.value}>{i.name}</MenuItem>
        ))}
      </Select>
      {sells?.map((item) => {
        return (
          <>
            <div style={{ fontSize: 15 }}>{item.name}</div>
            <div style={{ textAlign: "end" }}>
              <b style={{ fontSize: 15 }}>{item.count}</b>
            </div>
          </>
        );
      })}
    </div>
  );
}
