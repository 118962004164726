import React, { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { Card, InputAdornment } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import CardFooter from 'components/Card/CardFooter';
import "../../assets/css/styles.css"
import LoginService from 'services/LoginService';
import swal from '@sweetalert/with-react';

const useStyles = makeStyles(styles);

const ForgotPassPage = () => {

  const classes = useStyles();
  const [email, setEmail] = useState("")

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleClick = async() => {
    const resp = await LoginService.forgotPass({email});
    if( resp.data.message !== "OK" ){
      swal("Error", "No se encontro el correo", "error")
      setEmail("")
    }else{
      swal("Success", "Se envio un mensaje a su correo", "success")
      setEmail("")
    }
    
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card className={classes[cardAnimaton]}>
            <CardHeader style={{ textAlign: "center" }}>
              <img src={"/icon.png"} alt="" style={{ width: 100 }} />
            </CardHeader>
            <CardBody>
              <h4 className='title-forgotpass'>Ingrese su correo: </h4>
              <CustomInput
                labelText="Email..."
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  onChange: (e) => setEmail(e.target.value),
                  value: email,
                  required: true,
                  type: "email",
                }}
              />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <button 
                className='btn-forgotpass'
                onClick={handleClick}
              >ENVIAR</button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default ForgotPassPage;
