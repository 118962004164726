import { Button, Container } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SingleLoading from "components/Loading/SingleLoading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { URL_API } from "service/config";
import { mapDate } from "functions/comun";
import swal from "sweetalert";
import { downloadBlob } from "functions/downloadBlob";
import { css } from "@emotion/css";

const pageCss = css`
  div {
    padding: 5px;
  }
  .tr-title {
    color: #ffbc00;
  }
  .tr-title-success {
    color: green;
  }
`;

const TeacherRequest = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reject, setReject] = useState(false);

  useEffect(() => {
    var config = {
      method: "get",
      url: URL_API + "public/teacher-request/" + id,
      headers: {},
    };
    axios(config)
      .then((response) => {
        if (
          response.data.status &&
          response.data.data &&
          response.data.data.partial._id &&
          response.data.data.teacherRequest._id &&
          response.data.data.teacher._id
        ) {
          setData(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); //eslint-disable-line

  const acceptPartial = () => {
    swal({
      title: "¿Seguro?",
      text: "Se te asignará este parcial",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        setLoading(true);
        const { partial, teacher, teacherRequest } = data;
        var dataPost = JSON.stringify({
          idPartial: partial._id,
          idTeacher: teacher._id,
          idTeacherRequest: teacherRequest._id,
        });

        var config = {
          method: "post",
          url: URL_API + "public/partial/accept",
          headers: {
            "Content-Type": "application/json",
          },
          data: dataPost,
        };

        axios(config)
          .then((response) => {
            setLoading(false);
            //console.log(JSON.stringify(response.data));
            if (response.data.status) {
              setSuccess(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const rejectPartial = () => {
    swal({
      title: "¿Seguro?",
      text:
        "El parcial se asignará a otro profesor una vez rechazes la solicitud",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReject) => {
      if (willReject) {
        axios
          .post(URL_API + "public/partial/rejected", {
            partialID: data.partial._id,
            teacherID: data.teacher._id,
          })
          .then(() => {
            setLoading(false);
            setSuccess(false);
            setReject(true);
            swal(
              "Has rechazado el parcial",
              "El parcial será asignado a otro profesor",
              "success"
            );
          })
          .catch(() => {
            swal(
              "Error",
              "No se pudo realizar la operación por un error en el servidor",
              "warning"
            );
          });
      }
    });
  };

  const handleClick = () => {
    if (success) downloadIcs();
    else acceptPartial();
  };

  const downloadIcs = () => {
    setLoading(true);
    var config = {
      method: "post",
      url: URL_API + "public/calendar/generate/" + partial._id,
      headers: {},
    };

    axios(config)
      .then((response) => {
        setLoading(false);
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        downloadBlob(file, "calendar_" + partial._id + ".ics");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (
    loading ||
    data.partial === null ||
    data.teacherRequest === null ||
    data.teacher === null
  )
    return <SingleLoading size={50} />;

  if (data && data.teacherRequest && data.teacherRequest.state) {
    if (
      data.teacherRequest.state !== "SENT" ||
      data.partial.state === "ASSIGNED"
    )
      return (
        <Container>
          <GridContainer style={{ textAlign: "center", fontSize: 20 }}>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <img
                src={"/icon.png"}
                style={{ width: "40%", padding: 10 }}
                alt=""
              />
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <div>Este parcial no existe o ya ha sido asignado</div>
            </GridItem>
          </GridContainer>
        </Container>
      );
  }

  const { partial, teacher } = data;

  return (
    <Container className={pageCss}>
      <GridContainer style={{ textAlign: "center", fontSize: 20 }}>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <img src={"/icon.png"} style={{ width: "40%", padding: 10 }} alt="" />
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          {!success && (
            <div>
              Hola Profesor <b>{teacher.names}</b> ¿Puedes hacer este parcial?
            </div>
          )}
          {success ? (
            <h5>
              <b className="tr-title-success">
                ¡Listo {teacher.names}!, te hemos asignado el siguiente parcial{" "}
              </b>
            </h5>
          ) : (
            <h5>
              <b>Parcial de: </b>
              <b>
                <span className="tr-title">{partial.subject}</span>
              </b>
            </h5>
          )}

          {reject ? (
            <h5>
              <b className="tr-title-success" style={{ color: "red" }}>
                ¡Parcial rechazado!
              </b>
            </h5>
          ) : null}
          <div>
            <b>Materia: </b>
            {partial.subject}
          </div>
          <div>
            <b>Fecha: </b>
            {mapDate(partial.event_date)}
          </div>
          <div>
            <b>Duración: </b>
            {partial.event_time} Minutos
          </div>
          {partial.topics && (
            <div>
              <b>Temas: </b>
              {partial.topics?.toUpperCase()}
            </div>
          )}
          {partial.image_uploaded && (
            <div>
              <b>Archivo: </b>
              {partial.image_uploaded?.endsWith(".png") ? (
                <a href={partial.image_uploaded}>
                  Descargar archivo adjuntado por el estudiante
                </a>
              ) : (
                <>
                  <a href={partial.image_uploaded}>
                    Archivo adjuntado por el estudiante
                  </a>
                  <br />
                  <img
                    alt=""
                    src={partial.image_uploaded}
                    height="200"
                    width="200"
                  />
                </>
              )}
            </div>
          )}
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <br></br>
          <br></br>
        </GridItem>
        {!reject && !success ? (
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <Button
              color="primary"
              variant={success || reject ? "outlined" : "contained"}
              size="large"
              onClick={handleClick}
            >
              {success ? "Descargar Calendar" : "Aceptar parcial"}
            </Button>
            <br />
            <br />
            <Button
              color="primary"
              variant={success || reject ? "outlined" : "contained"}
              size="large"
              onClick={rejectPartial}
            >
              Rechazar parcial
            </Button>
          </GridItem>
        ) : null}
        {!reject && !success ? (
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <span>
              <br></br>
              <b style={{ fontSize: 15 }}>
                Si no respondes esta solicitud en 24 horas se rechazará
                automaticamente
              </b>
            </span>
          </GridItem>
        ) : null}

        {success && (
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <span>
              <b>
                En un plazo de 6 horas hábiles te notificaremos si el estudiante
                confirmó el pago y tomó el lugar
              </b>
            </span>
          </GridItem>
        )}
      </GridContainer>
    </Container>
  );
};

export default TeacherRequest;
