import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Grid, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import PhoneInput from "react-phone-input-2";
import CustomDateTimePicker from "components/DatePicker/CustomDateTimePicker";
import SingleLoading from "components/Loading/SingleLoading";
import GoBack from "components/Navigation/GoBack";
import FormsService from "services/FormsService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import CustomReactSelect from "components/Select/CustomReactSelect";
import "./HomeworkForm_css.css";
import PreRequestService from "services/PreRequestService";
import WhatsAppButton from "components/Buttons/WhatsappButton";
import ScheduleButton from "components/Buttons/ScheduleButton";

const useStyles = makeStyles((theme) => ({
  title1: {
    fontSize: 24,
    color: "#ffbc00",
  },
  title2: {
    fontSize: 18,
    textAlign: "center",
  },
  title3: {
    fontSize: 18,
    textAlign: "center",
    color: "#ffbc00",
  },
  label: {
    padding: 10,
    fontWeight: "bold",
  },
}));

const isPhoneValid = (phoneForValidate) => {
  return phoneForValidate && phoneForValidate.length > 10;
};

const isDateTimeValid = (dateTimeForValidate) => {
  return dateTimeForValidate && dateTimeForValidate.isValid();
};

export default () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState();
  const [idPreRequest, setIdPreRequest] = useState(null);
  const [filesToShow, setFilesToShow] = useState([]);

  const [dataState, setDataState] = useState({
    subject: null,
    names: "",
    phone: "",
    dateTime: "",
    files: 0,
  });

  const getSubjects = async () => {
    const response = await FormsService.getSubjects({});
    if (response?.data?.data) setSubjects(response?.data?.data);
  };

  useEffect(() => {
    getSubjects();
  }, []);

  const saveData = async () => {
    setLoading(true);
    const dataForSave = {
      ...dataState,
      // dateTime: dataState.dateTime.toString(),
      subject: dataState.subject.label,
      phone: `+${dataState.phone}`,
    };
    const res = await FormsService.saveHomework(dataForSave);
    if (res?.data?.message === "OK") {
      toast.success("¡Datos guardados!");
      setLoading(false);
      setStep(3);
    } else {
      toast.error("Ha ocurrido un error");
    }
  };

  if (loading) return <SingleLoading />;

  return (
    <>
      <head>
        <meta
          name="title"
          content="Ayuda Trabajos - Parciales Universidad - colegios"
        />
        <meta
          name="description"
          content="Copywrite tu amigo de confianza te ayuda con los parciales y trabajos que necesites 
  cotiza y conoce todos los beneficios para ti"
        />
      </head>
      <div>
        {step === 0 && (
          <Step0 step={step} setStep={setStep} classes={classes} />
        )}
        {step === 1 && (
          <Step1
            step={step}
            setStep={setStep}
            classes={classes}
            dataState={dataState}
            setDataState={setDataState}
            subjects={subjects}
          />
        )}
        {step === 2 && (
          <Step2
            step={step}
            setStep={setStep}
            classes={classes}
            dataState={dataState}
            setDataState={setDataState}
            saveData={saveData}
            idPreRequest={idPreRequest}
            setIdPreRequest={setIdPreRequest}
            filesToShow={filesToShow}
            setFilesToShow={setFilesToShow}
          />
        )}
        {step === 3 && <Step3 classes={classes} />}
      </div>
    </>
  );
};

export const Step0 = ({ setStep, classes }) => {
  return (
    <>
      <div style={{ textAlign: "center", paddingTop: 80 }}>
        <a
          href="https://www.copywritecol.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={"/icon.png"}
            alt=""
            style={{ width: isMobile ? "50%" : 300 }}
          />
        </a>
      </div>
      <div style={{ textAlign: "center", padding: 40 }}>
        <h1 className={`good_font ${classes.title1}`}>
          <span className="black_font">Bienvenido a </span>
          <strong className="black_font">TALLERES</strong>
          <b>
            <a
              href="https://www.copywritecol.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "rgb(255, 188, 0)" }}
            >
              {" "}
              Copywrite®
            </a>
          </b>
        </h1>
      </div>
      <div>
        <p className={`good_font ${classes.title2}`}>
          <span>Sigue los pasos para solicitar ayuda </span>
          <br />
          <span>
            ¡En 5 minutos estarás agendad@!{" "}
            <span role="img" aria-label="">
              👇
            </span>
          </span>
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
          onClick={() => setStep(1)}
        >
          Comenzar
        </Button>
      </div>
    </>
  );
};

export const Step1 = ({
  step,
  setStep,
  classes,
  dataState,
  setDataState,
  subjects,
}) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPhoneValid(dataState.phone)) {
      if (dataState?.subject?.value) {
        if (isDateTimeValid(dataState.dateTime)) {
          setStep(2);
        } else {
          swal("Oops", "Selecciona una fecha valida", "warning");
        }
      } else {
        swal("Oops", "Selecciona una asignatura valida", "warning");
      }
    } else {
      swal("Oops", "Ingrese un número de celular valido", "warning");
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Cotiza con Copywrite
      </h3>
      <GoBack back={() => setStep(step - 1)} />

      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Como te llamas?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <TextField
            autoFocus
            required
            placeholder="Escribe tu nombre y apellido"
            fullWidth
            value={dataState.names}
            onChange={(e) =>
              setDataState({ ...dataState, names: e.target.value })
            }
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cúal es tu número de WhatsApp?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <PhoneInput
            required
            country={"co"}
            value={dataState.phone}
            onChange={(phone) => setDataState({ ...dataState, phone })}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          ¿En qué asignatura necesitas ayuda para el <strong>TALLER</strong>?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <CustomReactSelect
            required
            options={subjects.map((i) => ({ value: i._id, label: i.label }))}
            onChange={(value) => setDataState({ ...dataState, subject: value })}
            value={dataState.subject}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cúal es la fecha y hora de entrega del <b>TALLER</b>?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <CustomDateTimePicker
            setValue={(dateTime) => setDataState({ ...dataState, dateTime })}
            componentWidth="100%"
          />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
          type="submit"
        >
          Continuar
        </Button>
      </div>
    </form>
  );
};

export const Step2 = ({
  step,
  setStep,
  classes,
  dataState,
  setDataState,
  saveData,
  idPreRequest,
  setIdPreRequest,
  filesToShow,
  setFilesToShow,
}) => {
  const handleSubmit = () => {
    saveData();
    updatePreRequest("Finalizado");
  };

  const updatePreRequest = async (state) => {
    await PreRequestService.updatePreRequest({
      ...dataState,
      idPreRequest,
      state,
    });
    // //console.log(res.data);
  };

  const onChangeFiles = (e) => {
    if (e.target?.files?.length > 0) {
      setDataState({ ...dataState, files: e.target.files });
      filesInView(e.target?.files);
    }
  };

  useEffect(() => {
    if (!idPreRequest) createPreRequest();
  }, [idPreRequest]); // eslint-disable-line

  const createPreRequest = async () => {
    if (dataState?.subject?.value) {
      const res = await PreRequestService.createPreRequest({
        ...dataState,
        // cost,
        request_type: "Taller",
      });
      if (res?.data?.message === "OK" && res?.data?.data?._id) {
        setIdPreRequest(res?.data?.data?._id);
      }
    } else {
      swal("Oops", "Selecciona una asignatura valida", "warning");
    }
  };

  const filesInView = (files) => {
    let arrayFile = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        arrayFile.push(files.item(i));
      }
      setFilesToShow(arrayFile);
    }
  };

  const changeFiles = (name) => {
    let list = new DataTransfer();
    let arrayFile = filesToShow.filter((f) => {
      return f.name !== name;
    });
    for (let i = 0; i < arrayFile.length; i++) {
      list.items.add(arrayFile[i]);
    }
    setDataState({ ...dataState, files: list.files });
    setFilesToShow(arrayFile);
  };

  return (
    <>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Completa tu información
      </h3>
      <GoBack back={() => setStep(step - 1)} />
      <div style={{ textAlign: "center", padding: "5px 0px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          Porfa sube el documento o las fotos del{" "}
          <strong>TALLER (PDF ó IMAGEN)</strong>
        </InputLabel>
      </div>
      <div className="good_font" style={{ padding: 30 }}>
        {/* <span role="img" aria-label="">
          🔴
        </span> */}
        Importante: Para que el proceso sea muy rápido deben verse muy claros
        los puntos a realizar asi como gráficos, unidades y demas.
        {/* <span role="img" aria-label="">
          👇
        </span> */}
      </div>
      <div className="final_step1" style={{ padding: "0px 60px" }}>
        <div>
          <h5 style={{ padding: "10px 0px" }}>Seleccionar Archivo(s)</h5>
          <input
            multiple
            type="file"
            className="custom-file-input"
            onChange={onChangeFiles}
          />
        </div>
      </div>
      <br />
      {dataState?.files?.length > 0 && (
        <div style={{ textAlign: "center" }}>
          <small>
            <strong>{filesToShow?.length} archivos seleccionados</strong>
          </small>
        </div>
      )}
      <br />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="column"
      >
        {filesToShow?.map((f) => {
          return (
            <Grid
              container
              key={f.name}
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <InputLabel className={`${classes.label} good_font`}>
                  <strong>{f.name}</strong>
                </InputLabel>
                {/* <span className="good_font">{f.name}</span> */}
              </Grid>
              <Grid item>
                <div className="final_step1" style={{ padding: "0px 60px" }}>
                  <div>
                    <h5 style={{ padding: "5px" }}>X</h5>
                    <input
                      multiple
                      type="button"
                      className="custom-file-input"
                      onClick={() => {
                        changeFiles(f.name);
                      }}
                    />
                  </div>
                </div>
                {/* <Button
                  onClick={() => {
                    changeFiles(f.name);
                  }}
                >
                  X
                </Button> */}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
        >
          Guardar
        </Button>
      </div>
    </>
  );
};

export const Step3 = ({ classes }) => {
  return (
    <>
      <div style={{ textAlign: "center", paddingTop: 80 }}>
        <img
          src={"/icon.png"}
          alt=""
          style={{ width: isMobile ? "40%" : 300 }}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          padding: 40,
          color: "#ffbc00",
          fontWeight: "bold",
          fontSize: 18,
        }}
      >
        <div className="good_font red_font">
          ¡Genial!
          <span role="img" aria-label="">
            ⚡
          </span>{" "}
          Recibimos tu solicitud.
        </div>
        <br />

        <div className="good_font black_font">
          {/* <span role="img" aria-label="">
            🔴
          </span> */}
          Te contactaremos en 5 minutos via WhatsApp ó SMS.
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="good_font">
          <strong>¿Quieres volver a WhatsApp?</strong>
          {/* <span role="img" aria-label="">
            👇
          </span> */}
        </div>
        <br />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <WhatsAppButton
              text="Volver con Maicol"
              link="https://wa.link/tgp6yx"
            ></WhatsAppButton>
          </Grid>
        </Grid>
        {/* <div className="good_font">
          <a href="https://wa.link/tgp6yx">
            <span role="img" aria-label="">
              🔗
            </span>
            Volver con Maicol
          </a>
        </div>
        <div className="good_font">
          <a href="https://wa.link/caqhrq">
            <span role="img" aria-label="">
              🔗
            </span>
            Volver con Julian
          </a>
        </div> */}
        <br />
        <div className="good_font">
          ¿Quieres agendar algo más?
          {/* <span role="img" aria-label="">
            👇
          </span> */}
        </div>
        <br />
        <div className="good_font">
          <b>
            {/* <a href="https://www.copywritecol.com/">¡Agendate aquí!</a> */}
            <ScheduleButton
              text="¡Agendate aquí!"
              link="https://www.copywritecol.com/"
            ></ScheduleButton>
          </b>
        </div>
      </div>
    </>
  );
};
