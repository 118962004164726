import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import swal from "sweetalert";
import ColorModalService from "services/ColorModalService";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ColorModal({
  getData,
  user,
  elementToColor,
  colors,
  open,
  setOpen,
  setElementToColor,
  type,
  tableRefForGetData,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(elementToColor.color);

  const handleClose = () => {
    setOpen(false);
    setElementToColor(null);
  };

  const getOtherStyle = (color) => {
    if (color === selected) {
      return { borderWidth: 5, borderStyle: "solid", borderColor: "green" };
    }
    return {};
  };

  const save = async () => {
    if (!selected) {
      swal("Error", "Seleccione un color", "error");
      return;
    }

    setLoading(true);

    const token = user.token;
    const idClient = elementToColor._id;
    const color = selected;

    const res = await ColorModalService.updateColorModal({
      token,
      idClient,
      type,
      color,
    });
    if (res.message !== "OK") {
      //console.log("Hubo un error en el color Modal");
    } else {
      setLoading(false);
      handleClose();
      if (getData) {
        //console.log(tableRefForGetData);
        getData();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h5 id="simple-modal-title" style={{ textAlign: "center" }}>
          Marcar este parcial
        </h5>
        <GridContainer>
          {colors.map((item) => (
            <GridItem
              xs={4}
              md={4}
              sm={4}
              lg={4}
              key={item.color}
              style={{ textAlign: "-webkit-center" }}
            >
              <div
                style={{
                  cursor: "pointer",
                  backgroundColor: item.color,
                  height: 50,
                  width: 50,
                  borderRadius: 10,
                  ...getOtherStyle(item.color),
                }}
                onClick={() => setSelected(item.color)}
              />
              {selected === item.color && (
                <CheckCircleIcon style={{ color: "green" }} />
              )}
            </GridItem>
          ))}
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{ textAlign: "-webkit-center" }}
          >
            <Button
              type="button"
              color="success"
              size="sm"
              style={{ marginTop: 20 }}
              onClick={save}
              loading={loading}
            >
              {"Guardar"}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </Modal>
  );
}
