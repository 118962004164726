import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "redux/store";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import { VERSION, DATE_DEPLOY } from "service/deployVersion";
import "moment/locale/es";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import MainContainers from "MainContainers";

console.table({
  VERSION,
  DATE_DEPLOY,
});

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#FFBC00",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#FFBC00",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
      <MainContainers />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

/*
if (module.hot) {
    module.hot.accept();
}
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    registration.unregister().then(() => window.location.reload());
  },
  onSuccess: (registration) => {
    console.info("service worker on success state");
    //console.log(registration);
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
