import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class SmsTemplateService {
  static async findAll({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}sms-template`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async delete({ token, id }) {
    return GlobalService.sentRequest({
      method: "delete",
      url: `${URL_API}sms-template/${id}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async create({ token, data }) {
    return GlobalService.sentRequest({
      method: "post",
      url: `${URL_API}sms-template`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  }
}
