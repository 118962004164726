import { Button, IconButton } from "@material-ui/core";
import axios from "axios";
import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { URL_API } from "service/config";
import { css } from "@emotion/css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const PaymentUpload = ({ open, setOpen, setLoading, id, getPartial, paymentMethod }) => {
    const imgInput = useRef();
    const [imgLoaded, setImgLoaded] = useState(null);
    const [rawImg, setRawImg] = useState("");

    const { paymentInfo, logo } = paymentMethod;

    const cssCode = css`
        button:hover {
            cursor: pointer;
        }
        .clase {
            display: flex;
            background-color: lightgray;
            width: 80vw;
            height: 80vh;
            align-items: center;
            justify-content: center;
        }
        .divInfo {
            width: 50%;
        }
        .imgDiv {
            width: 30%;
        }
        @media (max-width: 768px) {
            .clase {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                height: auto;
                justify-content: flex-start;
                font-size: 90%;
            }
            .divInfo {
                width: 100%;
            }
            .imgDiv {
                width: 90%;
                height: auto;
                textalign: left;
            }
        }
    `;

    const styles = {
        mainContainer: {
            display: open ? "flex" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgb(0,0,0,0.4)",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        loadImgContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        },
    };

    const previewFile = (file) => {
        const reader = new FileReader();
        if (file.size) {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImgLoaded(reader.result);
            };
        }
    };

    const handleImgLoad = (e) => {
        if (e.target.files[0]) {
            previewFile(e.target.files[0]);
            setRawImg(e.target.files[0]);
        }
    };

    const handleSendFile = () => {
        if (rawImg) {
            var data = new FormData();
            data.append("idPartial", id);
            data.append("file", rawImg);

            var config = {
                method: "post",
                url: URL_API + "public/partial/upload-payment",
                headers: {},
                data,
            };

            swal({
                title: "¿Estás seguro que deseas enviar esta imagen?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willSend) => {
                if (willSend) {
                    setLoading(true);
                    axios(config)
                        .then(function (response) {
                            setLoading(false);
                            setOpen(false);
                            if (response.data.status) {
                                getPartial();
                                swal("Genial", "Tu comprobante de pago se cargó correctamente", "success");
                            }
                        })
                        .catch(function (error) {
                            swal("Ocurrió algo", "No se pudo cargar el archivo", "warning");
                            setLoading(false);
                        });
                }
            });
        }
    };

    const handleClose = () => {
        setImgLoaded(null);
        setOpen(false);
    };

    return (
        <div style={styles.mainContainer} className={cssCode}>
            <div className="clase">
                <div style={{ textAlign: "right", width: "100%", padding: 0 }}>
                    <IconButton component="span" style={{ float: "right" }} onClick={handleClose}>
                        <HighlightOffIcon />
                    </IconButton>
                </div>
                <div className="divInfo">
                    <h4>
                        <b>Información de pago</b>
                    </h4>
                    <p>
                        <img src={logo} alt="Logo del método de pago" width="20%" />
                    </p>
                    <br />
                    <p>
                        <b>Titular:</b> {paymentInfo?.owner}
                    </p>

                    {paymentInfo?.type === 1 && (
                        <p>
                            <b>Cédula:</b> {paymentInfo?.ownerDNI}
                        </p>
                    )}
                    {paymentInfo?.type === 2 && (
                        <p>
                            <b>Tipo de cuenta:</b> Ahorros
                        </p>
                    )}
                    {paymentInfo?.type === 2 && (
                        <p>
                            <b>Número de cuenta:</b> {paymentInfo?.phone}
                        </p>
                    )}
                    {paymentInfo?.type === 3 && (
                        // eslint-disable-next-line
                        <a href={paymentInfo?.link} target="_blank" rel="noopener noreferrer">
                            <small>{paymentInfo?.link}</small>
                        </a>
                    )}
                    {/*  */}
                </div>
                <div style={styles.loadImgContainer}>
                    {!imgLoaded && (
                        <>
                            <input
                                type="file"
                                name="image"
                                onChange={handleImgLoad}
                                accept="image/*"
                                style={{ display: "none" }}
                                ref={imgInput}
                            />
                            <span style={{ fontSize: 14 }}>Cargar comprobante de pago</span>
                            <IconButton color="secondary" onClick={() => imgInput.current.click()}>
                                <CloudUploadIcon style={{ fontSize: 50 }} />
                            </IconButton>
                        </>
                    )}
                    {imgLoaded && (
                        <div className="imgDiv">
                            <IconButton
                                component="span"
                                style={{ float: "right", marginRight: -20, marginBottom: -10 }}
                                onClick={() => setImgLoaded(null)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                            <img src={imgLoaded} alt="Comprobante de pago" width="100%" />
                        </div>
                    )}
                    {imgLoaded ? (
                        <Button color="primary" variant="contained" onClick={handleSendFile}>
                            Enviar
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default PaymentUpload;
