import { css } from "@emotion/css";
import { Container, Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SingleLoading from "components/Loading/SingleLoading";
import GoBack from "components/Navigation/GoBack";
import { mapDate } from "functions/comun";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TallerService from "services/TallerService";
import InfoIcon from "@material-ui/icons/Info";
import GetAppIcon from "@material-ui/icons/GetApp";

const pageCss = css`
  div {
    padding: 5px;
  }
  .tr-title {
    color: #ffbc00;
  }
  .tr-title-success {
    color: green;
  }
`;

const DetailNextHomework = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [homework, setHomework] = useState({});
  const [material, setMaterial] = useState([]);

  const getHomeworkData = async () => {
    setLoading(true);
    const res = await TallerService.findByIdAdmin({
      id,
    });
    if (res?.data?.data) {
      setHomework(res?.data?.data);
      setLoading(false);
    }
  };

  const getMaterial = async () => {
    const res = await TallerService.getMaterials({ id });
    // console.log(res?.data);
    setMaterial(res?.data?.data);
  };

  useEffect(() => {
    getHomeworkData();
    getMaterial();
  }, []);

  //   const { homework, teacher, teacherRequest } = data;
  //   const isRenderLoading = loading || homework === null || teacher === null;

  return (
    <>
      <GoBack
        back={() => history.push("/teacher/talleres")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      {loading ? (
        <SingleLoading size={50} />
      ) : (
        <Container className={pageCss}>
          <GridContainer style={{ textAlign: "center", fontSize: 20 }}>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <img src={"/icon.png"} style={{ width: "70px" }} alt="" />
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <>
                <h3 className="detailpartail__title">Información del Taller</h3>
              </>

              {console.log(homework)}
              {homework?.state === "ASSIGNED_TEACHER" && (
                <h5 style={{ lineHeight: "20px" }}>
                  <b
                    style={{ fontWeight: "500", fontSize: "initial" }}
                    className="tr-title-success good_font"
                  >
                    {/* ¡Listo {teacher.names}!, te hemos asignado el siguiente Tutoría{" "} */}
                    ¡Listo! has aceptado disponibilidad para el siguiente
                    requerimiento.{" "}
                    <b className="good_font">Todavia no se ha agendado</b>
                  </b>
                </h5>
              )}

              <div className="informacion__item">
                <b>Materia: </b>
                {homework.subject}
              </div>
              <div className="informacion__item">
                <b>Fecha: </b>
                {mapDate(homework.event_date)}
              </div>
              {homework?.state === "PAYMENT_ACCEPTED_NO_TEACHER" && (
                <div
                  style={{
                    color: "#ffbd00",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b style={{ alignSelf: "center" }}>
                    Este Taller ya ha sido pagado
                  </b>
                  <InfoIcon style={{ alignSelf: "center" }} fontSize="medium" />
                </div>
              )}
              {homework.topics && (
                <div>
                  <b>Temas: </b>
                  {homework.topics?.toUpperCase()}
                </div>
              )}
              {homework.image_uploaded && (
                <div>
                  <b>Archivo: </b>
                  {homework.image_uploaded?.endsWith(".png") ? (
                    <a href={homework.image_uploaded}>
                      Descargar archivo adjuntado por el estudiante
                    </a>
                  ) : (
                    <>
                      <a href={homework.image_uploaded}>
                        Archivo adjuntado por el estudiante
                      </a>
                      <br />
                      <img
                        alt=""
                        src={homework.image_uploaded}
                        height="200"
                        width="200"
                      />
                    </>
                  )}
                </div>
              )}
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <br />
              <Divider></Divider>
              <br />
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              {/* <h4 className="nextpartials--material">
            Materiales de preparación
          </h4> */}
              {material.length > 0 ? (
                <>
                  <h3 className="detailpartail__title2">
                    Materiales de preparación
                  </h3>
                  {homework?.textMaterial?.split("\n").map((txm, i, data) => {
                    const view = txm.split(":");
                    if (i >= 2) {
                      if (i === data.length - 1) {
                        return (
                          <div>
                            <div className="informacion__item">
                              <b>{`Información adicional: `}</b>
                              <div>{view[1] ? view[1] : "-"}</div>
                            </div>
                            <br />
                          </div>
                        );
                      }
                      return (
                        <>
                          <div className="informacion__item">
                            <b>
                              {view[0]}
                              {": \n"}
                            </b>
                            <div>{view[1] ? view[1] : "-"}</div>
                          </div>
                          <br />
                        </>
                      );
                    } else {
                      return (
                        <div className="informacion__item">
                          <b>{`${view[0]}: `}</b>
                          {view[1] ? view[1] : "-"}
                        </div>
                      );
                    }
                  })}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                  >
                    <div
                      className="good_font"
                      style={{
                        color: "#f9004d",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      Nombre archivo
                    </div>
                    <div
                      className="good_font"
                      style={{
                        color: "#f9004d",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      Peso
                    </div>
                    <div
                      className="good_font"
                      style={{
                        color: "#f9004d",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                    >
                      Descargar
                    </div>
                    {material?.map((item) => {
                      return (
                        <>
                          <div
                            style={{ fontSize: "1.1rem" }}
                            className="good_font"
                            key={item.name}
                          >
                            {item.name}
                          </div>
                          <div
                            style={{ fontSize: "1.1rem" }}
                            className="good_font"
                          >
                            {item.size}MB
                          </div>
                          <a href={item.url} target="_blank">
                            <GetAppIcon />
                          </a>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  {homework?.tf_textMaterial ? (
                    <div className="informacion__item">
                      <b>{`Temas: `}</b>
                      <div>{homework?.tf_textMaterial}</div>
                    </div>
                  ) : (
                    <h4
                      styles={{ fontSize: "2rem" }}
                      className="detailpartail__title2"
                    >
                      Aún no hay material
                    </h4>
                  )}
                </>
              )}
              <Divider />

              {/* <table
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <tr className="partialaccepted--tr">
              <th>Nombre</th>
              <th>Creado</th>
              <th>Visualizar</th>
            </tr>
            {material.map((item) => (
              <tr key={item.name} className="partialaccepted--td">
                <td>{item.name}</td>
                <td>
                  {mapDateFormat(item.created_at, "ddd DD MMM, hh:mm A")}
                </td>
                <td>
                  <a href={item.url} target="_blank">
                    <BsBoxArrowInDown
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                      }}
                    />
                  </a>
                </td>
              </tr>
            ))}
            {material.length === 0 && !partial?.tf_file_url && (
              <tr>
                <td colSpan={3}>
                  <span style={{ fontSize: 12 }}>Aún sin archivos</span>
                </td>
              </tr>
            )}
            {partial?.tf_file_url && (
              <tr className="partialaccepted--td">
                <td>Primer archivo</td>
                <td>
                  {mapDateFormat(
                    partial?.created_at,
                    "ddd DD MMM, hh:mm A"
                  )}
                </td>
                <td>
                  <a
                    href={partial?.tf_file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsBoxArrowInDown
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                      }}
                    />
                  </a>
                </td>
              </tr>
            )}
          </table> */}
              <Divider />
              {/* <span
            style={{
              fontSize: "1.3rem",
            }}
          >
            <strong style={{ fontWeight: "500" }}>Descripción: </strong>{" "}
          </span>
          <span style={{ fontSize: 15 }}>
            {notErrorMapping(partial?.textMaterial)}
            <br />
            {notErrorMapping(partial?.tf_textMaterial)}
          </span> */}
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <br></br>
              <br></br>
            </GridItem>
            {/*{isPendingTeacher(homework) && (
              <div className="detailpartial__button--container">
                <div>
                  <button
                    style={{
                      color: "black",
                      borderRadius: "10px",
                      backgroundColor: "#ffbc01",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={accepthomework}
                  >
                    Aceptar
                  </button>
                </div>
                <div>
                  <button
                    style={{
                      backgroundColor: "#f9004d",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    variant="contained"
                    onClick={rejecthomework}
                  >
                    Rechazar
                  </button>
                </div>
              </div>
            )}
            {isPendingTeacher(homework) && (
              <GridItem
                style={{ textAlign: "start" }} // revisar 
                xs={12}
                md={12}
                sm={12}
                lg={12}
              >
                <span className="aviso__footer">
                  <br></br>
                  <b>
                    En caso de no aceptar esta Tutoría otro tutor puede tomarla
                    en cualquier momento
                  </b>
                </span>
              </GridItem>
            )}*/}
            {homework?.state === "ASSIGNED_TEACHER" && (
              <GridItem xs={12} md={12} sm={12} lg={12}>
                <span className="aviso__footer">
                  <b>
                    En un plazo de 6 horas hábiles te notificaremos si el
                    estudiante confirmó el pago y tomó el lugar
                  </b>
                </span>
              </GridItem>
            )}
          </GridContainer>
        </Container>
        // <Container className={pageCss}>
        //   <GridContainer style={{ textAlign: "center", fontSize: 20 }}>
        //     <GridItem xs={12} md={12} sm={12} lg={12}>
        //       <img
        //         src={"/icon.png"}
        //         style={{ width: "40%", padding: 10 }}
        //         alt=""
        //       />
        //     </GridItem>
        //     <GridItem xs={12} md={12} sm={12} lg={12}>
        //       {homework?.state === "PENDING" && (
        //         <>
        //           <h3 className="detailpartail__title">
        //             Información de la Tutoria
        //           </h3>
        //         </>
        //       )}
        //       {homework?.state === "ASSIGNED_TEACHER" && (
        //         <h5>
        //           <b className="tr-title-success">
        //             ¡Listo {teacher?.names}!, te hemos asignado la siguiente
        //             tutoria{" "}
        //           </b>
        //         </h5>
        //       )}

        //       <div className="informacion__item">
        //         <b>Materia: </b>
        //         {homework.subject}
        //       </div>
        //       <div className="informacion__item">
        //         <b>Fecha: </b>
        //         {mapDate(homework.event_date)}
        //       </div>
        //       <div className="informacion__item">
        //         <b>Duración: </b>
        //         {homework.event_time} Horas
        //       </div>
        //       {homework?.state === "PAYMENT_ACCEPTED_NO_TEACHER" && (
        //         <div
        //           style={{
        //             color: "#ffbd00",
        //             display: "flex",
        //             flexDirection: "column",
        //           }}
        //         >
        //           <b style={{ alignSelf: "center", color: "green" }}>
        //             Esta tutoria ya ha sido pagada
        //           </b>
        //           <InfoIcon
        //             style={{ alignSelf: "center", color: "green" }}
        //             fontSize="medium"
        //           />
        //         </div>
        //       )}
        //       {homework.topics && (
        //         <div>
        //           <b>Temas: </b>
        //           {homework.topics?.toUpperCase()}
        //         </div>
        //       )}
        //     </GridItem>
        //     {isPendingTeacher(homework) && (
        //       <div className="detailpartial__button--container">
        //         <div>
        //           <button
        //             style={{
        //               color: "black",
        //               borderRadius: "10px",
        //               backgroundColor: "#ffbc01",
        //             }}
        //             color="primary"
        //             variant="contained"
        //             onClick={accepthomework}
        //           >
        //             Aceptar
        //           </button>
        //         </div>
        //         <div>
        //           <button
        //             style={{
        //               backgroundColor: "#f9004d",
        //               color: "white",
        //               borderRadius: "10px",
        //             }}
        //             variant="contained"
        //             onClick={rejecthomework}
        //           >
        //             Rechazar
        //           </button>
        //         </div>
        //       </div>
        //     )}
        //     {isPendingTeacher(homework) && (
        //       <GridItem xs={12} md={12} sm={12} lg={12}>
        //         <span className="aviso__footer">
        //           <br></br>
        //           <b>
        //             En caso de no aceptar esta tutoria otro tutor puede tomarlo
        //             en cualquier momento
        //           </b>
        //         </span>
        //       </GridItem>
        //     )}
        //     {homework?.state === "ASSIGNED_TEACHER" && (
        //       <GridItem xs={12} md={12} sm={12} lg={12}>
        //         <span className="aviso__footer">
        //           <b>
        //             En un plazo de 6 horas hábiles te notificaremos si el
        //             estudiante confirmó el pago y tomó el lugar
        //           </b>
        //         </span>
        //       </GridItem>
        //     )}
        //   </GridContainer>
        // </Container>
      )}
    </>
  );
};

export default DetailNextHomework;
