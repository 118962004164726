import React from "react";
import PartialInfo from "./partial";
import TutorshipInfo from "./tutorship";
import HomeworkInfo from "./homework";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const InfoPage = () => {
  const { type } = useParams();

  if (type === "partial") return <PartialInfo />;
  if (type === "tutorship") return <TutorshipInfo />;
  if (type === "homework") return <HomeworkInfo />;
};

export default InfoPage;
