import SingleLoading from "components/Loading/SingleLoading";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PUBLIC_KEY_WOMPI } from "service/config";
import WompiService from "services/WompiService";

const Wompi = ({ idObjectCW, typeObject }) => {
  const [state, setState] = useState({
    signature: null,
    amount: 0,
    money: "",
  });

  useEffect(() => {
    getSignature();
  }, []); // eslint-disable-line

  const getSignature = async () => {
    const res = await WompiService.getSignature({ idObjectCW, typeObject });
    //console.log(res?.data?.data);
    if (res?.data?.data?.signature) {
      setState({
        signature: res?.data?.data?.signature,
        amount: res?.data?.data?.amount,
        money: res?.data?.data?.money,
      });
    } else {
      toast.error("Ocurrio un error");
    }
  };

  useEffect(() => {
    if (state.signature) {
      const script = document.createElement("script");
      script.setAttribute("src", "https://checkout.wompi.co/widget.js");
      script.setAttribute("data-render", "button");
      script.setAttribute("data-public-key", PUBLIC_KEY_WOMPI);
      script.setAttribute("data-currency", state.money);
      script.setAttribute("data-amount-in-cents", state.amount);
      script.setAttribute("data-reference", `${typeObject}-${idObjectCW}`);
      script.setAttribute("data-signature:integrity", state.signature);
      script.setAttribute(
        "data-redirect-url",
        process.env.REACT_APP_REDIRECT_URL_WOMPI
      );

      document.getElementById("divPayer").appendChild(script);
      return () => {
        document.getElementById("divPayer").removeChild(script);
      };
    }
  }, [state.signature]); // eslint-disable-line

  return (
    <>
      {!state.signature && <SingleLoading size={20} justIcon />}
      <div id="divPayer" />
    </>
  );
};

export default Wompi;
