import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useDispatch } from "react-redux";
import { setUserAction } from "redux/user/userActions";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import swal from "sweetalert";
import CardHeader from "components/Card/CardHeader";
import { MenuItem, Select } from "@material-ui/core";

import LoginService from "services/LoginService";
import { Link, useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";
import "../../assets/css/styles.css";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const setUser = (user) => dispatch(setUserAction(user));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rol, setRol] = useState("TEACHER");
  const [pathname, setPathName] = useState(null);

  const onLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await LoginService.login({
      username: `${rol}:${email}`,
      password,
    });
    if (res?.data?.access_token) {
      const user = {
        ...res.data.user,
        token: `Bearer ${res.data.access_token}`,
      };
      // //console.log(JSON.stringify(user))
      setUser(user);

      try {
        if (pathname) {
          history.push(pathname);
        }
      } catch (error) {}
    } else {
      swal("Error", "Credenciales incorrectas", "error");
      setPassword("");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query.get("pathname")) setPathName(query.get("pathname"));
    if (query.get("email")) setEmail(query.get("email"));
  }, []); // eslint-disable-line

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form method="post" onSubmit={onLogin}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader style={{ textAlign: "center" }}>
                <a
                  href="https://www.copywritecol.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={"/icon.png"} alt="" style={{ width: 100 }} />
                </a>
              </CardHeader>
              <CardBody>
                <Select
                  value={rol}
                  onChange={(e) => setRol(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <MenuItem value={"TEACHER"}>Profesor</MenuItem>
                  <MenuItem value={"ADMIN"}>Administrador</MenuItem>
                </Select>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: (e) => setEmail(e.target.value),
                    value: email,
                    required: true,
                    type: "email",
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          {"lock_outline"}
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: (e) => setPassword(e.target.value),
                    value: password,
                    required: true,
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  block
                  loading={loading}
                >
                  {"Ingresar"}
                </Button>
              </CardFooter>
              {rol === "TEACHER" && (
                <Link to="forgot-password" className="link-forgotpass">
                  Recuperar contraseña
                </Link>
              )}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
