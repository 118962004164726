/* eslint-disable */
import {
  Button,
  Divider,
  Checkbox,
  IconButton,
  Input,
  FormControlLabel,
  TextField,
  withStyles,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import GoBack from "components/Navigation/GoBack";
import { mapDate, mapStateTeacherRequest } from "functions/comun";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import CheckIcon from "@material-ui/icons/Check";
import Snackbar from "components/Snackbar/Snackbar";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import PaymentAccepted from "./partials/PaymentAccepted";
import FormatMoney from "components/Money/FormatMoney";
import { partialStateTranslate } from "functions/comun";
import { partialTypeTranslate } from "functions/comun";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import { tutorshipStateTranslate } from "functions/comun";
import SimpleModal from "components/Modal/SimpleModal";
import TutorService from "services/TutorService";
import { toast } from "react-toastify";
import ShareIcon from "@material-ui/icons/Share";
import moment from "moment";
import EventIcon from "@material-ui/icons/Event";
import { MiniComponentTutorshipActions } from "./tutorships/MiniComponentTutorshipActions";
import { MiniComponentTeacherAssigned } from "./tutorships/MiniComponentTeacherAssigned";
import PaginationTeacherAssign from "./tutorships/PaginatonTeacherAssign";
import { EditInput } from "components/CustomInput/EditInput";
import CustomDateTimePicker from "components/DatePicker/CustomDateTimePicker";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ColorModalService from "services/ColorModalService";
import Pending from "./partials/Pending";
import PaymentService from "services/PaymentService";
import { adminPhone1 } from "variables/superAdminEmails";

const StyledCheckBox = withStyles({
  root: {
    color: "black",
  },
})(FormControlLabel);

const Tutoria = ({
  tutorship,
  setTutorship,
  getData,
  setService,
  payment,
  setPayment,
}) => {
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [teacherRequests, setTeacherRequests] = useState([]);
  const [copied, setCopied] = useState(false);

  const [teacherData, setTeacherData] = useState(null);
  const [editCost, setEditCost] = useState(tutorship?.cost);
  const [dateEvent, setDateEvent] = useState({
    date: tutorship?.event_date,
    time: tutorship?.event_time,
  });
  const [textReturn, setTextAddReturn] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [dataToReturn, setDataToReturn] = useState({
    note: "",
    details: "",
    value: "",
    bank: "",
    acc: "",
  });
  const [name, setName] = useState(tutorship?.client);
  const [university, setUni] = useState();

  const onTriggerReload = () => {
    setTriggerReload(!triggerReload);
  };

  const getComponent = () => {
    switch (tutorship.state) {
      case "PENDING_PAY":
        return (
          <>
            <Pending
              partial={tutorship}
              loading={loading}
              setLoading={setLoading}
              user={user}
              setPartial={setTutorship}
              getData={getData}
              onTriggerReload={onTriggerReload}
            />
            <PaymentAccepted partial={tutorship} user={user} />
          </>
        );
      case "PAYMENT_ACCEPTED_NO_TEACHER":
        return (
          <>
            <Pending
              partial={tutorship}
              loading={loading}
              setLoading={setLoading}
              user={user}
              setPartial={setTutorship}
              getData={getData}
            />
            <PaymentAccepted partial={tutorship} user={user} />
          </>
        );
      case "READY":
        return <PaymentAccepted partial={tutorship} user={user} />;
      case "PAYMENT_DECLINED":
      case "PAYMENT_ERROR":
        return <PaymentAccepted partial={tutorship} user={user} />;
      default:
        return <PaymentAccepted partial={tutorship} user={user} />;
    }
  };

  const getTeacherRequests = async () => {
    const idTutorship = tutorship._id;
    const token = user.token;

    setLoading(true);

    const res = await TutorService.getTeacherRequestByTutorship({
      token,
      idTutorship,
    });
    if (res?.data?.data) {
      setTeacherRequests(res.data.data);
      setLoading(false);
    }
  };

  const deleteTutorship = () => {
    swal({
      title: "¿Seguro?",
      text: "Se borrará completamente la tutoria",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        const token = user.token;
        const id = tutorship._id;
        setLoading(true);
        const res = await TutorService.delete({ token, id });
        if (res.data.message !== "OK") {
          console.error("Hubo un error al eliminar el parcial");
        } else {
          if (getData) {
            getData();
            setTutorship(null);
          }
          toast.success("Tutoria eliminada");
        }
        setLoading(false);
      }
    });
  };

  const uploadPaymentBillAdditional = async (e) => {
    setLoading(true);
    // const images = [];
    let isOk = true;
    let res;
    for (const i of e.target.files) {
      let j = 0;
      res = await TutorService.savePayment2({
        file: i,
        idTutorship: tutorship._id,
        user,
      });
      if (res.data?.message !== "OK") {
        isOk = false;
        toast.success("Algo salió mal!");
      }
      j++;
    }
    if (isOk) {
      toast.success("Captura guardada correctamente");
      setTutorship(res.data?.data);
    }
    setLoading(false);
  };

  const onChange = async (note = 0) => {
    const rescolor = await ColorModalService.updateColorModal({
      token: user.token,
      idClient: tutorship?._id,
      type: "tutorship",
      color: "#93e3fd",
    });
    if (rescolor?.message === "OK") {
      const restutorship = await TutorService.addReturn({
        user,
        textReturn,
        idTutorship: tutorship?._id,
        date: new Date(),
      });
      if (restutorship?.data?.message === "OK") {
        const res_cost = await TutorService.updateCost({
          token: user.token,
          newCost: (note / 4) * tutorship.cost,
          idTutorship: tutorship._id,
        });
        const res_deb = await TutorService.editValue({
          idTutorship: tutorship._id,
          newValue: (note / 4) * tutorship.debt_to_teacher,
          user,
        });
        if (res_deb && res_cost) {
          setTutorship(res_deb?.data?.data);
          const res = await PaymentService.updateAmountByReturn({
            token: user.token,
            objectID: tutorship._id,
            typeObject: "TUTORSHIP",
            newValue: (note / 4) * tutorship.debt_to_teacher,
          });
        }
        const phone = adminPhone1;
        const text = `🚨 *NUEVA DEVOLUCIÓN POR $${
          dataToReturn.value
        } COP POR NOTA DE ${
          dataToReturn.note
        }*\n\n*Tipo:* Tutoría.\n*Asignatura:* ${
          tutorship.subject
        }\n*Nombre Estudiante:* ${tutorship.client}.\n*Banco:* ${
          dataToReturn.bank
        }\n*# Cuenta:* ${dataToReturn.acc}\n*Motivo:* ${
          textReturn[0]
        } \n*Tutor asignado:* ${teacherData.names}\n*Id del servicio:* ${
          tutorship._id
        }\n*Valor a pagar a tutor:* ${
          (note / 4) * tutorship.debt_to_teacher
        } COP\n*Valor actual de la venta:* ${
          (note / 4) * tutorship.cost
        } COP\n*Notas:* ${dataToReturn.details}`;
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
          text
        )}`;
        window.open(url, `return_${tutorship._id}`);
        toast.success("Devolución creada con exito");
      }
    }
    setOpenModal3(false);
  };

  const onChangeReasson = (reasson) => {
    let arr = textReturn;
    if (arr.indexOf(reasson) === -1) {
      arr.push(reasson);
    } else {
      arr = arr.filter((item) => reasson !== item);
    }
    setTextAddReturn(arr);
  };

  const addReturn = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    setEditCost(tutorship?.cost);
  }, [tutorship]);

  useEffect(() => {
    getTeacherRequests();
    if (tutorship?.assigned_to) {
      getTeacherData(tutorship?.assigned_to);
    }
  }, [tutorship?.assigned_to]);

  const publicPaymentLink = `${window.location.origin}/public/payment-stepper-tutorship/${tutorship._id}`;
  const publicMaterialLink = `${window.location.origin}/public/material-tutorship-stepper/${tutorship._id}`;

  const openWappPaymentLink = () => {
    const phone = tutorship.phone;
    const text = `*¡Hola ${tutorship.client}!*\n🚀 Ingresa al link, haz el pago y listo ¡Quedarás agendado!\n\n🔗 ${publicPaymentLink}\n\n*¡Si no subes material de preparación no hay garantía!📍*`;
    // const text = `🚀 Hola ${tutorship.client}. ¡Sigue los pasos y paga tu Tutoria! 👇\n\n${publicPaymentLink}\n\nSi tienes problemas con el pago online lo haremos MANUAL.\n\n#PagoSeguroConCopywrite`;
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, `payment_link_${tutorship._id}`);
  };

  const openWappMaterialLink = () => {
    const phone = tutorship.phone;
    const text = `👋 *Hola ${tutorship.client}, ¡Transacción confirmada!* ✅\n\n🚀 Haz sido agendado en *Tutorías Copywrite.* Ingresa aquí:\n\n🔗 ${publicMaterialLink}\n\n¡Sube todo el material de preparación que quieres estudiar!📍`;
    // const text = `*¡Hola ${tutorship.client}!*\n🚀 Haz sido agendado en Copywrite. Sigue los pasos y ¡listo!\n\n🔗 ${publicMaterialLink}\n\n*¡Si no subes material de preparación no hay garantía!📍*`;
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, `payment_link_${tutorship._id}`);
  };

  const copyToClipboard = (text, successText) => {
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value = text;

    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);

    toast.success(successText);
  };

  const updateName = async () => {
    const res = await TutorService.updateName({
      user,
      idTutorship: tutorship?._id,
      name,
    });
    if (res?.data?.data) {
      toast.success("Nombre actualizado correctamente");
      setTutorship(res?.data?.data);
    } else {
      toast.error("Algo salió mal");
    }
  };

  const myParseDate = (date) =>
    moment(date).format("ddd DD MMMM YYYY, hh:mm A");

  const addToGoogleCalendar = () => {
    // //console.log(tutorship);
    const mainEmails = `copywrite.col@gmail.com,maicol.copywrite@gmail.com,stefanny.copywrite@gmail.com,hr.copywritecol@gmail.com`;
    let emails = "";
    if (teacherData?.email) {
      emails = `${mainEmails},${teacherData?.email}`;
    } else {
      emails = mainEmails;
    }

    copyToClipboard(emails, "Participantes copiado al portapapeles");

    const text = `Tutoria - ${tutorship?.client} - ${tutorship?.subject} - ${teacherData?.names}`;
    const details = `ID: ${tutorship._id} \nFecha de la tutoria: ${myParseDate(
      tutorship.event_date
    )}\nCliente: ${tutorship?.client} \nMateria: ${
      tutorship?.subject
    } \nEstado: ${partialStateTranslate(tutorship?.state)} \nTipo: ${
      tutorship?.type
    } \nRecibido el: ${myParseDate(
      tutorship?.created_at
    )}\nAgregado a GC: ${myParseDate(moment())}\nAgregado por Admin: ${
      user.names
    }`;

    const startTutorshipAt = moment(tutorship?.event_date).local();
    const endTutorshipAt = moment(tutorship?.event_date)
      .local()
      .add(tutorship?.event_time, "hours");

    // //console.log("startPartialAt:", startPartialAt.format());
    // //console.log("endPartialAt:", endPartialAt.format());

    // 20200316T010000Z
    const f = "YYYYMMDDTHHmmss";

    const url = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${startTutorshipAt.format(
      f
    )}/${endTutorshipAt.format(f)}&text=${encodeURIComponent(
      text
    )}&details=${encodeURIComponent(details)}`;

    window.open(url, tutorship._id);
  };

  const getTeacherData = async (assigned_to) => {
    // //console.log("assigned_to:", assigned_to);
    if (assigned_to) {
      setLoading(true);
      const res = await TutorService.findTeacherById({
        idTeacher: assigned_to,
        token: user.token,
      });
      if (res?.data) {
        setTeacherData(res.data);
      }
      setLoading(false);
    }
  };

  const onSaveCost = async () => {
    const res = await TutorService.updateCost({
      token: user.token,
      newCost: editCost,
      idTutorship: tutorship._id,
    });
    if (res) {
      toast.success("Costo actualizado correctamente!");
      setTutorship(res?.data?.data);
    }
  };

  const updateUniversity = async () => {
    // console.log("save event", dateEvent);
    const res = await TutorService.updateUniversity({
      token: user.token,
      university,
      idTutorship: tutorship._id,
    });
    if (res) {
      toast.success("Universidad actualizado correctamente!");
      setTutorship(res?.data?.data);
    }
  }

  const saveDateEvent = async () => {
    // console.log("save event", dateEvent);
    const res = await TutorService.updateDate({
      token: user.token,
      event_date: dateEvent.date,
      event_time: dateEvent.time,
      _id: tutorship?._id,
    });
    if (res) {
      toast.success("Fecha y/o duracion actualizado correctamente!");
      setTutorship(res?.data?.data);
    }
  };

  return (
    <GridContainer style={{ textAlign: "center" }} spacing={10}>
      <GoBack
        back={() => {
          setTutorship(null);
          if (setService) setService(null);
          if (setPayment) setPayment(payment);
        }}
      />
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h1>{tutorship?.subject}</h1>
        <div>
          ID: <b>{tutorship?._id}</b>
        </div>
        <br />
        <GridContainer>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            {/* <b>Cliente: </b>
            {tutorship?.client} */}
            <EditInput
              onSave={updateName}
              showComponent={
                <>
                  <b>Cliente:</b>
                  {tutorship?.client}
                </>
              }
              editComponent={
                <div>
                  <Input
                    value={name}
                    onChange={(value) => setName(value.target.value)}
                    placeholder="Nombre del cliente..."
                  ></Input>
                </div>
              }
            />
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            <b>Celular: </b>
            {tutorship?.phone}
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            <b>Recibido: </b>
            {tutorship?.inserted_at}
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            <b>Valor: </b>
            <EditInput
              onSave={onSaveCost}
              showComponent={
                <FormatMoney
                  country={{ label: "Colombia" }}
                  value={tutorship?.cost}
                />
              }
              editComponent={
                <FormatMoney
                  onValueChange={(e) => setEditCost(e.value)}
                  value={editCost}
                  country={{ label: "Colombia" }}
                  displayType="input"
                />
              }
            />
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            {/* <b>Fecha tutoria: </b>
            {mapDate(tutorship?.event_date)}
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            <b>Tiempo tutoria: </b>
            {`${tutorship?.event_time} hora/s`}
          </GridItem> */}
            <EditInput
              onSave={saveDateEvent}
              showComponent={
                <>
                  <b>Fecha tutoria: </b>
                  {mapDate(tutorship?.event_date)}
                </>
              }
              editComponent={
                <CustomDateTimePicker
                  value={dateEvent.date}
                  setValue={(dateTime) =>
                    setDateEvent({ ...dateEvent, date: dateTime })
                  }
                  componentWidth="100%"
                />
              }
            ></EditInput>
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <EditInput
              onSave={saveDateEvent}
              showComponent={
                <>
                  <b>Tiempo tutoria: </b>
                  {`${tutorship?.event_time} hora/s`}
                </>
              }
              editComponent={
                <Input
                  value={dateEvent.time}
                  onChange={(dateTime) =>
                    setDateEvent({ ...dateEvent, time: dateTime.target.value })
                  }
                  componentWidth="100%"
                />
              }
            ></EditInput>
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            <b>Estado: </b>
            {tutorshipStateTranslate(tutorship?.state)}
          </GridItem>
          <GridItem xs={12} md={3} sm={3} lg={3}>
            <b>Tipo: </b>
            {partialTypeTranslate(tutorship?.type)}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <EditInput
              onSave={updateUniversity}
              showComponent={
                <>
                  <b>Universidad:</b>
                  {tutorship?.university}
                </>
              }
              editComponent={
                <div>
                  <Input
                    value={university}
                    onChange={(value) => setUni(value.target.value)}
                    placeholder="Nombre de la universidad..."
                  ></Input>
                </div>
              }
            />
          </GridItem>
        </GridContainer>
        <Divider />
        <br />
        <GridContainer>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {tutorship?.assigned_to ? (
              <>
                <b style={{ color: "green" }}>
                  Esta tutoria ya tiene un profesor asignado
                </b>
                <DoneIcon fontSize="large" style={{ color: "green" }} />
              </>
            ) : (
              <>
                <b style={{ color: "red" }}>
                  Esta tutoria aún no tiene un profesor asignado
                </b>
                <ErrorIcon fontSize="large" style={{ color: "red" }} />
              </>
            )}
          </GridItem>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {
              <>
                {tutorship?.is_paid || tutorship?.paymentBill ? (
                  <>
                    {tutorship.is_paid ? (
                      <b style={{ color: "green" }}>
                        Esta tutoría ya ha sido pagada por pasarela virtual
                      </b>
                    ) : (
                      <b style={{ color: "green" }}>
                        Esta tutoría ya ha sido pagada
                      </b>
                    )}
                    <DoneIcon fontSize="large" style={{ color: "green" }} />
                  </>
                ) : (
                  <>
                    <b style={{ color: "red" }}>
                      Esta tutoría aún no ha sigo pagada
                    </b>
                    <ErrorIcon fontSize="large" style={{ color: "red" }} />
                  </>
                )}
              </>
            }
          </GridItem>
          {tutorship.paymentBill && (
            <GridItem
              xs={12}
              md={12}
              sm={12}
              lg={12}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="steptwo--btn">
                <div>
                  <h4 style={{ backgroundColor: "green", fontSize: "14px" }}>
                    Subir comprobante adicional
                  </h4>
                  <input
                    multiple
                    type="file"
                    className="custom-file-input"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={uploadPaymentBillAdditional}
                  />
                </div>
              </div>
            </GridItem>
          )}
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <table>
              <th>
                <td>
                  {tutorship?.paymentBill && (
                    <>
                      <a
                        href={tutorship.paymentBill}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Ver comprobante principal{" -"}
                      </a>
                    </>
                  )}
                </td>
              </th>
              {tutorship?.paymentBill2?.map((item, index, arr) => {
                return (
                  <th>
                    <td>
                      <a
                        href={item}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Comprobate adicional {index + 1}
                        {arr.length > index + 1 && " -"}
                      </a>
                    </td>
                  </th>
                );
              })}
            </table>
          </GridItem>
          <GridItem
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <b>Acciones: </b>
            </div>
            <GridContainer>
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Link de pago</b>
                </div>
                {/* <IconButton> */}
                <IconButton onClick={openWappPaymentLink}>
                  <ShareIcon />
                </IconButton>
              </GridItem>
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Link de mat. preparación</b>
                </div>
                {/* <IconButton disabled> */}
                <IconButton onClick={openWappMaterialLink}>
                  <ShareIcon />
                </IconButton>
              </GridItem>
              <MiniComponentTutorshipActions
                tutorship={tutorship}
                setTutorship={setTutorship}
                teacher={teacherData}
              />
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Crear calendar</b>
                </div>
                {/* <IconButton> */}
                <IconButton onClick={addToGoogleCalendar}>
                  <EventIcon />
                </IconButton>
              </GridItem>
              <GridItem xs={6} md={2} sm={2} lg={2}>
                <div>
                  &nbsp;
                  <b>Crear devolución</b>
                </div>
                <IconButton onClick={addReturn}>
                  <MoneyOffIcon />
                </IconButton>
                <SimpleModal
                  open={openModal}
                  setOpen={setOpenModal}
                  widthModal={"45%"}
                  heightModal={"75%"}
                >
                  <div
                    style={{
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      display: "grid",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                        fontWeight: "bolder",
                        paddingBottom: "1rem",
                      }}
                    >
                      ¿Cual es el motivo de la devolución?
                    </div>
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Bajo desempeño del tutor"}
                      value={"Bajo desempeño del tutor"}
                      color="primary"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Estudiante agendó materia ó temas equivocados"}
                      value={"Estudiante agendó materia ó temas equivocados"}
                      color="secondary"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={
                        "Cancelación del requerimiento por parte del estudiante"
                      }
                      value={
                        "Cancelación del requerimiento por parte del estudiante"
                      }
                      color="black"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"No hay tutor disponible"}
                      value={"No hay tutor disponible"}
                      color="black"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Descuido de Customer Service de Copywrite"}
                      value={"Descuido de Customer Service de Copywrite"}
                      color="black"
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <StyledCheckBox
                      labelPlacement="end"
                      label={"Otro"}
                      color="black"
                      value={"Otro"}
                      control={
                        <Checkbox
                          onChange={(e) => onChangeReasson(e.target.value)}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                    />
                    <Button
                      // onClick={onChange}
                      onClick={() => {
                        setOpenModal(false);
                        setOpenModal2(true);
                      }}
                      style={{
                        placeSelf: "end",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      Siguiente
                    </Button>
                  </div>
                </SimpleModal>
                <SimpleModal
                  open={openModal2}
                  setOpen={setOpenModal2}
                  widthModal={"45%"}
                  heightModal={"75%"}
                >
                  <form noValidate autoComplete="off">
                    <div
                      style={{
                        paddingLeft: "2rem",
                        gap: "2rem",
                        paddingRight: "2rem",
                        display: "grid",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bolder",
                          paddingBottom: "1rem",
                        }}
                      >
                        Detalles de la devolución
                      </div>
                      <TextField
                        type="number"
                        id="standard-basic"
                        label="Nota que obtuvo el estudiante"
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            note: e.target.value,
                            value:
                              tutorship.cost -
                              (e.target.value / 4) * tutorship.cost,
                          });
                        }}
                        value={dataToReturn.note}
                      />
                      <TextField
                        type="number"
                        id="standard-basic"
                        label="Valor de la devolución"
                        disabled={true}
                        // onChange={(e) => {
                        //   setDataToReturn({
                        //     ...dataToReturn,
                        //     value: e.target.value,
                        //   });
                        // }}
                        value={dataToReturn.value}
                      />

                      <TextField
                        id="standard-basic"
                        label="Detalles sobre la devolución"
                        placeholder="detalles ..."
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            details: e.target.value,
                          });
                        }}
                        value={dataToReturn.details}
                      />
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <Button
                          // onClick={onChange}
                          onClick={() => {
                            setOpenModal(true);
                            setOpenModal2(false);
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "grey",
                            color: "white",
                          }}
                        >
                          Atrás
                        </Button>
                        <Button
                          // onClick={onChange}
                          onClick={() => {
                            if (!dataToReturn.value) {
                              toast.error("El valor no puede estar vacío");
                            }
                            if (!dataToReturn.note) {
                              toast.error("La nota no puede estar vacía");
                            }
                            if (dataToReturn.value && dataToReturn.note) {
                              setOpenModal2(false);
                              setOpenModal3(true);
                            }
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "green",
                            color: "white",
                          }}
                        >
                          Siguiente
                        </Button>
                      </div>
                    </div>
                  </form>
                </SimpleModal>
                <SimpleModal
                  open={openModal3}
                  setOpen={setOpenModal3}
                  widthModal={"45%"}
                  heightModal={"75%"}
                >
                  <form noValidate autoComplete="off">
                    <div
                      style={{
                        paddingLeft: "2rem",
                        gap: "2rem",
                        paddingRight: "2rem",
                        display: "grid",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bolder",
                          paddingBottom: "1rem",
                        }}
                      >
                        Datos del medio de pago
                      </div>
                      <TextField
                        id="standard-basic"
                        label="Banco"
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            bank: e.target.value,
                          });
                        }}
                        value={dataToReturn.bank}
                      />
                      <TextField
                        id="standard-basic"
                        label="Número de cuenta"
                        onChange={(e) => {
                          setDataToReturn({
                            ...dataToReturn,
                            acc: e.target.value,
                          });
                        }}
                        value={dataToReturn.acc}
                      />
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <Button
                          // onClick={onChange}
                          onClick={() => {
                            setOpenModal2(true);
                            setOpenModal3(false);
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "grey",
                            color: "white",
                          }}
                        >
                          Atrás
                        </Button>
                        <Button
                          onClick={() => {
                            onChange(dataToReturn.note);
                          }}
                          style={{
                            placeSelf: "end",
                            backgroundColor: "green",
                            color: "white",
                          }}
                        >
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </form>
                </SimpleModal>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <br />
        <Divider />
        <br />
        {tutorship?.topics && (
          <div>
            <b>Temas: </b>
            {tutorship?.topics?.toUpperCase()}
          </div>
        )}
        {tutorship?.image_uploaded && (
          <div>
            <b>Archivo: </b>
            {tutorship?.image_uploaded?.endsWith(".png") ? (
              <a href={tutorship?.image_uploaded}>
                Archivo adjuntado por el estudiante
              </a>
            ) : (
              <>
                <a href={tutorship?.image_uploaded}>
                  Descargar archivo adjuntado por el estudiante
                </a>
                <br />
                <img src={tutorship?.image_uploaded} height="200" width="200" />
              </>
            )}
          </div>
        )}
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h4>Asignación de profesor</h4>
        <MiniComponentTeacherAssigned
          teacherData={teacherData}
          tutorship={tutorship}
        />
        <PaginationTeacherAssign
          tutorship={tutorship}
          setTutorship={setTutorship}
        />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
        <br></br>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
        <br></br>
      </GridItem>
      {getComponent()}
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Divider />
        <br></br>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <Button
          style={{ backgroundColor: "rgb(220, 0, 78)", color: "white" }}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={deleteTutorship}
        >
          Eliminar Tutoria
        </Button>
      </GridItem>
      <Snackbar
        place="tr"
        color="success"
        icon={CheckIcon}
        message="Copiado"
        open={copied}
        closeNotification={() => setCopied(false)}
        close
      />
    </GridContainer>
  );
};

export default Tutoria;
