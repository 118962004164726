import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PreRequestService from "services/PreRequestService";
import SimpleModal from "components/Modal/SimpleModal";
import {
  Button,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormsService from "services/FormsService";
import { toast } from "react-toastify";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import PhoneInput from "react-phone-input-2";

const PreRequest = () => {
  const user = useSelector((state) => state.user.user);
  const [preRequests, setPreRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [preRequest, setPreRequest] = useState({});

  useEffect(() => {
    getRequests();
  }, []); // eslint-disable-line

  const getRequests = async () => {
    const res = await PreRequestService.getAllRequest({ user });
    if (res?.message) setPreRequests(res.data);
  };

  const openModel = (rowData) => {
    setOpen(true);
    setPreRequest(rowData);
    //console.log(rowData, preRequest);
  };

  const sendData = async () => {
    if (preRequest.state === "Finalizado") {
      await PreRequestService.updatePreRequest(preRequest);
      getRequests();
      setOpen(false);

      if (preRequest.request_type === "Parcial") partialSaveData();
      if (preRequest.request_type === "Tutoria") tutoriaSaveData();
      if (preRequest.request_type === "Taller") homeworkSaveData();

      // e.preventDefault();
      // if (isPhoneValid(dataState.phone)) {
      // if (isDateTimeValid(dataState.dateTime)) {

      // } else {
      //   swal("Oops", "Selecciona una fecha valida", "warning");
      // }
      // } else {
      //   swal("Oops", "Ingrese un número de celular valido", "warning");
      // }
      //console.log(preRequest);
    } else {
      await PreRequestService.updatePreRequest(preRequest);
      getRequests();
      setOpen(false);
    }
    // //console.log(preRequest);
    // //console.log(res);
  };

  const partialSaveData = async () => {
    // setLoading(true);
    const preRequestForSave = {
      ...preRequest,
      // dateTime: dataState.dateTime.toString(),
      subject: preRequest.nameSubject,
      phone: `+${preRequest.phone}`,
    };
    const res = await FormsService.savePartial(preRequestForSave);
    // //console.log(res);
    if (res?.data?.message === "OK") {
      toast.success("¡Datos guardados!");
      // setLoading(false);
      // setStep(4);
    } else {
      toast.error("Ha ocurrido un error");
    }
  };

  const tutoriaSaveData = async () => {
    // setLoading(true);
    const preRequestForSave = {
      ...preRequest,
      // dateTime: dataState.dateTime.toString(),
      subject: preRequest.nameSubject,
      phone: `+${preRequest.phone}`,
    };
    // //console.log(JSON.stringify(dataForSave));
    // return;
    const res = await FormsService.saveTutorship(preRequestForSave);
    if (res?.data?.message === "OK") {
      toast.success("¡Datos guardados!");
      // setLoading(false);
      // setStep(4);
    } else {
      toast.error("Ha ocurrido un error");
    }
  };

  const homeworkSaveData = async () => {
    // setLoading(true);
    const preRequestForSave = {
      ...preRequest,
      // dateTime: dataState.dateTime.toString(),
      subject: preRequest.nameSubject,
      phone: `+${preRequest.phone}`,
    };
    const res = await FormsService.saveHomeworkPR(preRequestForSave);
    //console.log(res);
    if (res?.data?.message === "OK") {
      toast.success("¡Datos guardados!");
      // setLoading(false);
      // setStep(3);
    } else {
      toast.error("Ha ocurrido un error");
    }
  };

  return (
    <>
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={[
            // { title: "id", field: "_id" },
            { title: "Materia", field: "nameSubject" },
            { title: "Nombre del estudiante", field: "names" },
            { title: "Tipo de solicitud", field: "request_type" },
            { title: "Estado", field: "state" },
          ]}
          data={preRequests}
          title="Pre Solicitudes"
          actions={[
            {
              icon: "refresh",
              tooltip: "Recargar",
              isFreeAction: true,
              onClick: getRequests,
            },
            (rowData) => ({
              icon: "edit",
              tooltip: "Editar",
              isFreeAction: false,
              onClick: () => openModel(rowData),
              disabled: rowData.state === "Finalizado",
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </div>
      <SimpleModal open={open} setOpen={setOpen}>
        {/* {//console.log(preRequest)} */}
        <GridContainer
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <h3
            className="good_font"
            style={{
              textAlign: "center",
              color: "#ffbc00",
              fontWeight: "bold",
            }}
          >
            Datos del estudiante
          </h3>
          {/* <Divider variant="middle" /> */}
          {/* <FormModule label={"ID"} value={preRequest._id} disabled></FormModule> */}
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Estudiante" />
            <InputForm
              value={preRequest.names}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              name="names"
            />
          </GridContainer>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Número de celular" />
            <GridItem xs={8} sm={8} md={8} lg={8} xl={8}>
              <div
                style={{
                  padding: "5px",
                  marginRight: "30px",
                  textAlign: "left",
                }}
              >
                <PhoneInput
                  style={{ width: "100%" }}
                  // required
                  country={"co"}
                  value={preRequest.phone}
                  onChange={(phone) => setPreRequest({ ...preRequest, phone })}
                />
              </div>
            </GridItem>
            {/* <InputForm
              value={preRequest.phone}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              name="phone"
            /> */}
          </GridContainer>
          <h3
            className="good_font"
            style={{
              textAlign: "center",
              color: "#ffbc00",
              fontWeight: "bold",
            }}
          >
            Datos de la materia
          </h3>
          {/* <Divider variant="middle" /> */}
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Materia" />
            <InputForm
              value={preRequest.nameSubject}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              name="nameSubject"
            />
          </GridContainer>
          {/* <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Duracion" />
            <InputForm
              value={preRequest.dateTime}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              type="number"
              name="dateTime"
            />
          </GridContainer> */}
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Minutos" />
            <InputForm
              value={preRequest.minutes}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              type="number"
              name="minutes"
            />
          </GridContainer>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Número de estudiantes" />
            <InputForm
              value={preRequest.persons}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              dataSelect={options}
              select
              defaultValue={preRequest.persons}
              name="persons"
            />
          </GridContainer>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Fecha del evento" />
            <GridItem xs={8} sm={8} md={8} lg={8} xl={8}>
              <div
                style={{
                  padding: "5px",
                  marginRight: "30px",
                  textAlign: "left",
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    style={{ width: "100%" }}
                    minutesStep={5}
                    disablePast
                    value={preRequest.dateTime}
                    onChange={(dateTime) =>
                      setPreRequest({ ...preRequest, dateTime })
                    }
                    // allowKeyboardControl
                    clearable
                  />
                </MuiPickersUtilsProvider>
              </div>
            </GridItem>
          </GridContainer>

          <h3
            className="good_font"
            style={{
              textAlign: "center",
              color: "#ffbc00",
              fontWeight: "bold",
            }}
          >
            Datos de solicitud
          </h3>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Estado" />
            <InputForm
              value={preRequest.state}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              dataSelect={requestState}
              select
              defaultValue={preRequest.state}
              name="state"
            />
          </GridContainer>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Tipo" />
            <InputForm
              value={preRequest.request_type}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              dataSelect={requestType}
              select
              defaultValue={preRequest.request_type}
              name="request_type"
            />
          </GridContainer>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LabelForm label="Costo" />
            <InputForm
              value={preRequest.cost}
              preRequest={preRequest}
              setPreRequest={setPreRequest}
              type="number"
              name="cost"
            />
          </GridContainer>
        </GridContainer>
        <Button onClick={sendData}>Guardar</Button>
      </SimpleModal>
    </>
  );
};

const requestState = [
  {
    value: "Confirmado",
    label: "Confirmado",
  },
  {
    value: "Pendiente",
    label: "Pendiente",
  },
  {
    value: "Finalizado",
    label: "Finalizado",
  },
];

const requestType = [
  {
    value: "Parcial",
    label: "Parcial",
  },
  {
    value: "Taller",
    label: "Taller",
  },
  {
    value: "Tutoria",
    label: "Tutoria",
  },
];

const options = [
  { value: "una", label: "Una" },
  { value: "dos", label: "Dos" },
  { value: "tres", label: "Tres" },
  { value: "cuatro", label: "Cuatro" },
  { value: "cinco", label: "Cinco" },
  { value: "seis", label: "Seis" },
  { value: "+seis", label: "Mas de seis" },
];

const LabelForm = ({ label }) => {
  const useStyles = makeStyles((theme) => ({
    title1: {
      fontSize: 24,
      color: "#ffbc00",
    },
    title2: {
      fontSize: 18,
      textAlign: "center",
    },
    title3: {
      fontSize: 18,
      textAlign: "center",
      color: "#ffbc00",
    },
    label: {
      padding: 10,
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();

  return (
    <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
      <div
        style={{
          padding: "5px",
          marginLeft: "20px",
          textAlign: "right",
        }}
      >
        <InputLabel className={`${classes.label} good_font`}>
          {`${label}: `}
        </InputLabel>
      </div>
    </GridItem>
  );
};

const InputForm = ({
  value,
  dataSelect,
  preRequest,
  setPreRequest,
  nameProp,
  ...rest
}) => {
  const onChangeInputs = (e) => {
    e.preventDefault();
    setPreRequest({ ...preRequest, [e.target.name]: e.target.value });
  };

  if ({ ...rest }?.select) {
    return (
      <GridItem xs={8} sm={8} md={8} lg={8} xl={8}>
        <div
          style={{
            padding: "5px",
            marginRight: "30px",
            textAlign: "left",
          }}
        >
          <TextField
            // required
            {...rest}
            select
            fullWidth
            value={value ? value : ""}
            onChange={(e) => onChangeInputs(e)}
          >
            {dataSelect.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </GridItem>
    );
  } else {
    return (
      <GridItem xs={8} sm={8} md={8} lg={8} xl={8}>
        <div
          style={{
            padding: "5px",
            marginRight: "30px",
            textAlign: "left",
          }}
        >
          <TextField
            // required
            {...rest}
            fullWidth
            value={value ? value : ""}
            onChange={(e) => onChangeInputs(e)}
          ></TextField>
        </div>
      </GridItem>
    );
  }
};

export default PreRequest;
