import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { getRowsCount } from "functions/comun";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import GoBack from "components/Navigation/GoBack";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Input,
  Divider,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { isMobile } from "react-device-detect";
import swal from "sweetalert";
import SubjectService from "services/SubjectService";
import TeacherService from "services/TeacherService";
import Select from "react-select";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { toast } from "react-toastify";
import StarIcon from "@material-ui/icons/Star";
import { useForm, Controller } from "react-hook-form";
import ConfigVarService from "services/ConfigVarService";
import WhatshotIcon from "@material-ui/icons/Whatshot";

const isImage = (string) => {
  switch (string) {
    case "img":
      return true;
    case "jpeg":
      return true;
    case "jpg":
      return true;
    case "png":
      return true;
    default:
      return false;
  }
};

const isStared = (id, startTeacherSubjectParam) => {
  // //console.log("id:", id);
  for (const i of startTeacherSubjectParam) {
    if (i.id_teacher === id && i.star) return true;
  }
  return false;
};

const isFired = (id, startTeacherSubjectParam) => {
  // //console.log("id:", id);
  for (const i of startTeacherSubjectParam) {
    if (i.id_teacher === id && i.fire) return true;
  }
  return false;
};

const Subject = () => {
  const user = useSelector((state) => state.user.user);

  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [newTeacher, setNewTeacher] = useState("");
  const [options, setOptions] = useState(null);
  const [startTeacherSubject, setStartTeacherSubject] = useState([]);
  const { control, handleSubmit, setValue } = useForm();
  const [currency, setCurrency] = useState(0);
  const [TRM, setTMR] = useState();
  const [img, setImg] = useState("");
  const [solution, setSolution] = useState("");

  useEffect(() => {
    getData();
    getTeachers();
    getTRM();
  }, []); //eslint-disable-line

  const getTRM = async () => {
    const res = await ConfigVarService.getTRM();
    // //console.log(res);
    setTMR(res?.data?.number_value);
  };

  const setCurrencyMethod = (cost) => {
    // const totalNumber = (cost * 1.35) / 4637.87; //Valor variable
    const totalNumber = (cost * 1.35) / TRM; //Valor variable
    const integerPart = Math.trunc(totalNumber);
    if (totalNumber - integerPart > 0.9) {
      setCurrency(integerPart + 1.9);
    } else if (totalNumber - integerPart < 0.9) {
      setCurrency(integerPart + 0.9);
    } else {
      setCurrency(totalNumber);
    }
  };
  const getData = async () => {
    setLoading(true);
    const res = await SubjectService.findAll({ user });
    setLoading(false);
    setSubjects(res.data);
  };

  const getTeachers = async () => {
    setLoading(true);
    const res = await TeacherService.getAll({ user });
    if (res.message !== "OK") {
      //console.log(res.message);
    }
    setLoading(false);
    setTeachers(res.data);
    setOptions(res.data.map((i) => ({ value: i._id, label: i.names })));
  };

  const onRowClick = (e, rowData) => {
    setCurrencyMethod(rowData?.partial_base_cost);
    const anotherTeachers = teachers.filter(
      (item) => !isInTeachersList(rowData, item._id)
    );

    // //console.log(JSON.stringify(res.data));
    setOptions(anotherTeachers.map((i) => ({ value: i._id, label: i.names })));
    setImg(rowData.test);
    setSolution(rowData.solution);
    // setSubject(rowData);
    getStarTeacherSubject(rowData);
    if (
      rowData.teachers_list_homework.length !== rowData.teachers_list.length &&
      rowData.teachers_list.length !== rowData.teachers_list_tutorship.length
    ) {
      setSubject({
        ...rowData,
        teachers_list_homework: rowData.teachers_list,
        teachers_list_tutorship: rowData.teachers_list,
      });
    } else {
      setSubject(rowData);
    }
  };

  const getNameTeacher = (id) => {
    for (const item of teachers) {
      if (item._id === id) return item.names;
    }
  };

  const getTeacherById = (id) => {
    for (const item of teachers) {
      if (item._id === id) return item;
    }
  };

  const isInTeachersList = (rowData, id) => {
    ////console.log(JSON.stringify(rowData.teachers_list));
    const teachers_list = rowData.teachers_list;
    if (teachers_list) return teachers_list.includes(id);
    return false;
  };

  const save = async () => {
    if (newTeacher) {
      var data = {
        idSubject: subject._id,
        idTeacher: newTeacher,
      };
      setLoading(true);
      const res = await SubjectService.updateSubject({
        user,
        data,
        isAddTeacher: true,
      });
      if (res.data.message !== "OK") {
        //console.log("Hubo un error al actualizar subject");
      }
      swal("Genial", "Profesor agregado!", "success");
      setLoading(false);
      getData();
      setSubject(null);
    }
  };

  const deleteTeacher = (idTeacher) => {
    swal({
      title: "¿Estas seguro?",
      text: "Se eliminará este tutor de la materia",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        var data = {
          idSubject: subject._id,
          idTeacher,
        };
        setLoading(true);
        const res = await SubjectService.deleteTeacherInSubject({ user, data });
        if (res.data.message !== "OK") {
          //console.log("Hubo un problema al eliminar subject");
        }
        swal("Genial", "Tutor eliminado!", "success");
        getData();
        setSubject(null);
      }
    });
  };

  const openWapp = (id) => {
    const teacher = getTeacherById(id);
    if (teacher && teacher?.phone) {
      const url = `https://api.whatsapp.com/send?phone=${teacher?.phone}&text=Hola ${teacher?.names}, te escribimos de CopyWrite.`;
      window.open(url, teacher._id);
    }
  };

  const upTeacher = async (id, list) => {
    setLoading(true);
    const res = await SubjectService.changeTeacherPosition(
      subject._id,
      id,
      "up",
      list,
      user
    );
    if (res?.data?.message === "OK") {
      toast.success("OK");
      setSubject(res?.data?.data);
    }
    setLoading(false);
  };

  const downTeacher = async (id, list) => {
    setLoading(true);
    const res = await SubjectService.changeTeacherPosition(
      subject._id,
      id,
      "down",
      list,
      user
    );
    if (res?.data?.message === "OK") {
      toast.success("OK");
      setSubject(res?.data?.data);
    }
    setLoading(false);
  };

  const startTeacher = async (id, type) => {
    setLoading(true);
    const res = await SubjectService.startTeacher(subject._id, id, type, user);
    if (res?.data?.message === "OK") {
      toast.success("OK");
      getStarTeacherSubject(subject);
    }
    setLoading(false);
  };

  const getStarTeacherSubject = async (rowData) => {
    if (rowData) {
      setLoading(true);
      const res = await SubjectService.getStarTeacherSubject(rowData._id);
      if (res?.data?.message === "OK") {
        setStartTeacherSubject(res.data.data);
      }
      setLoading(false);
    }
  };

  const onSubmitSubject = async (data) => {
    setLoading(true);

    const dataForSave = {
      ...data,
      partial_base_cost: parseInt(data.partial_base_cost),
    };

    const res = await SubjectService.create({ data: dataForSave, user });
    if (res?.data?.data) {
      getData();
      toast.success("¡Materia creada!");
      setValue("label", "");
      setValue("partial_base_cost", "");
    }

    //console.log(data);
    setLoading(false);
  };

  const deleteSubject = (oldData) => {
    setLoading(true);
    if (oldData?.number_teachers === 0) {
      swal("¿Seguro deseas borrar esta materia?", {
        icon: "warning",
        buttons: {
          cancel: "Cancelar",
          done: {
            text: "Si, borrala",
            value: "delete",
          },
        },
      }).then(async (value) => {
        switch (value) {
          case "delete":
            const res = await SubjectService.deleteSubject({
              data: oldData,
              user,
            });
            if (res?.data?.data) {
              getData();
              toast.success("¡Materia Eliminada!");
            }
            break;

          default:
        }
      });
    } else {
      swal({
        title: "Esta materia no se puede eliminar",
        text: "Aún hay tutores asignados",
        icon: "info",
        button: "OK",
      });
    }
    setLoading(false);
  };

  const editSubject = async (newData, oldData, resolve) => {
    const dataUpdate = [...subjects];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData;
    setSubjects([...dataUpdate]);

    const res = await SubjectService.updateSubject({
      user,
      data: dataUpdate[index],
      isAddTeacher: false,
    });

    if (res?.data?.data) {
      getData();
      toast.info("Materia modificada");
    }

    resolve();
  };

  const priceCalculate = (value) => {
    const integerPart = Math.trunc(value / 1000) * 1000;
    if (value - integerPart > 990) {
      return integerPart + 1990;
    }
    if (value - integerPart < 990) {
      return integerPart + 990;
    }
    return value;
  };

  const priceCalculateTutor = (value) => {
    const integerPart = (Math.trunc(value / 1000) + 1) * 1000;
    return integerPart;
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const res = await SubjectService.saveTest({
        file: e.target.files,
        idSubject: subject._id,
        user,
      });
      if (res?.data?.message === "OK") {
        setImg(res?.data?.data?.test);
      }
    }
  };

  const uploadFileSolution = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const res = await SubjectService.saveTestSolution({
        file: e.target.files,
        idSubject: subject._id,
        user,
      });
      if (res?.data?.message === "OK") {
        setSolution(res?.data?.data?.solution);
      }
    }
  };

  const deleteFile = async (e) => {
    e.preventDefault();
    swal({
      title: "¿Seguro quieres elimar esta prueba?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Prueba eliminada correctamente", {
          icon: "success",
        });
        const res = await SubjectService.deleteTest({
          idSubject: subject._id,
          user,
        });
        if (res?.data?.message === "OK") {
          setImg(null);
        }
      }
    });
  };

  const deleteFileSolution = (e) => {
    e.preventDefault();
    swal({
      title: "¿Seguro quieres elimar esta solucion?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Solucion eliminada correctamente", {
          icon: "success",
        });
        const res = await SubjectService.deleteTestSolution({
          idSubject: subject._id,
          user,
        });
        if (res?.data?.message === "OK") {
          setSolution(null);
        }
      }
    });
  };

  if (subject !== null) {
    return (
      <GridContainer>
        <GoBack back={() => setSubject(null)} />
        <GridItem xs={12} md={12} sm={4} lg={4}>
          <h2>{subject.label}</h2>
          <div>Id: {subject?._id}</div>
          <div>
            Costo base estudiante:{" "}
            {`${priceCalculate(
              subject.partial_base_cost
            )} COP / ${currency.toFixed(2)} USD`}
          </div>
          <div>
            Costo tutoria estudiante:{" "}
            {priceCalculate(subject.partial_base_cost * 0.43)}
          </div>
          <div>
            Costo base tutor parcial:{" "}
            {priceCalculateTutor(subject.partial_base_cost * 0.62)}
          </div>
          <div>
            Costo base tutor tutoria:{" "}
            {priceCalculateTutor(subject.partial_base_cost * 0.43 * 0.63)}
          </div>
          <br />
          <br />
        </GridItem>
        <GridItem xs={12} md={12} sm={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              {img ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Button
                      style={{ backgroundColor: "#ff044c" }}
                      variant="contained"
                      onClick={deleteFile}
                    >
                      Eliminar prueba
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className="final_step1 btn1-modalFile"
                  style={{ width: "100%", cursor: img ? "none" : "pointer" }}
                >
                  <div>
                    <h5
                      style={{
                        padding: "10px 0px",
                        backgroundColor: "#fbbf13",
                      }}
                    >
                      Seleccionar prueba
                    </h5>
                    <input
                      style={{ cursor: img ? "default" : "pointer" }}
                      type="file"
                      // accept="image/png, image/gif, image/jpeg"
                      className="custom-file-input"
                      onChange={uploadFile}
                    />
                  </div>
                </div>
              )}
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <div
                style={{
                  width: !isImage(img?.split(".")[img.split(".").length - 1])
                    ? "40%"
                    : "100%",
                  margin: !isImage(img?.split(".")[img.split(".").length - 1])
                    ? "3rem 30%"
                    : "0",
                }}
                className="imgModalFile"
              >
                {img &&
                  (!isImage(img?.split(".")[img?.split(".").length - 1]) ? (
                    <a href={img} target="_blank">
                      <div
                        style={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          backgroundColor: "#ff044c",
                          padding: "0.5rem",
                          width: "100%",
                          color: "white",
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                        }}
                      >
                        <PictureAsPdfIcon />
                        <b>Ver archivo</b>
                      </div>
                    </a>
                  ) : (
                    <a href={img} target="_blank">
                      <img
                        style={{ width: "100%", objectFit: "contain" }}
                        src={img}
                        alt=""
                      />
                    </a>
                  ))}
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={12} sm={4} lg={4}>
          <GridContainer>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              {solution ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Button
                      style={{ backgroundColor: "#ff044c" }}
                      variant="contained"
                      onClick={deleteFileSolution}
                    >
                      Eliminar solución
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className="final_step1 btn1-modalFile"
                  style={{ width: "100%" }}
                >
                  <div>
                    <h5
                      style={{
                        padding: "10px 0px",
                        backgroundColor: "#fbbf13",
                      }}
                    >
                      Seleccionar solución
                    </h5>
                    <input
                      style={{ cursor: solution ? "default" : "pointer" }}
                      type="file"
                      // accept="image/png, image/jpg, image/jpeg"
                      className="custom-file-input"
                      onChange={uploadFileSolution}
                    />
                  </div>
                </div>
              )}
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <div
                style={{
                  width: !isImage(
                    solution?.split(".")[solution?.split(".").length - 1]
                  )
                    ? "40%"
                    : "100%",
                  margin: !isImage(
                    solution?.split(".")[solution?.split(".").length - 1]
                  )
                    ? "3rem 30%"
                    : "0",
                }}
                className="imgModalFile"
              >
                {solution &&
                  (solution?.split(".")[solution?.split(".").length - 1] ? (
                    <a href={solution} target="_blank">
                      <div
                        style={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          backgroundColor: "#ff044c",
                          padding: "0.5rem",
                          width: "100%",
                          color: "white",
                          display: "grid",
                          gridTemplateColumns: "1fr 3fr",
                        }}
                      >
                        <PictureAsPdfIcon />
                        <b>Ver archivo</b>
                      </div>
                    </a>
                  ) : (
                    <a href={solution} target="_blank">
                      <img
                        style={{ width: "100%", objectFit: "contain" }}
                        src={solution}
                        alt=""
                      />
                    </a>
                  ))}
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} md={6} sm={6} lg={6}>
          <InputLabel>Agregar</InputLabel>
          {options && (
            <Select
              options={options}
              onChange={(e) => setNewTeacher(e.value)}
            />
          )}
        </GridItem>
        <GridItem xs={6} md={6} sm={6} lg={6}>
          <Button
            type="button"
            color="success"
            loading={loading}
            onClick={save}
          >
            {"Guardar"}
          </Button>
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <h4>Lista de Tutores: </h4>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: isMobile ? "100%" : "50%" }}>
              <h4 className="good_font" style={{ color: "#FFBC00" }}>
                PARCIALES
              </h4>
              <List>
                {subject?.teachers_list?.map((item, key) => (
                  <ListItem key={key} style={{ padding: 0 }}>
                    <ListItemAvatar>
                      <h1>{key + 1}</h1>
                    </ListItemAvatar>
                    <ListItemText primary={getNameTeacher(item)} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="start"
                        aria-label="Start"
                        onClick={() => startTeacher(item, "star")}
                        disabled={loading}
                      >
                        <StarIcon
                          style={{
                            color: isStared(item, startTeacherSubject)
                              ? "green"
                              : "",
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                      <IconButton
                        edge="start"
                        aria-label="Start"
                        onClick={() => startTeacher(item, "fire")}
                        disabled={loading}
                      >
                        <WhatshotIcon
                          style={{
                            color: isFired(item, startTeacherSubject)
                              ? "red"
                              : "",
                          }}
                          fontSize="large"
                        />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => upTeacher(item, "partial")}
                        disabled={loading}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => downTeacher(item, "partial")}
                        disabled={loading}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => openWapp(item)}
                      >
                        <WhatsappIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteTeacher(item)}
                        disabled={loading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
            <div style={{ width: isMobile ? "100%" : "50%" }}>
              <h4 className="good_font" style={{ color: "#FFBC00" }}>
                TALLERES
              </h4>
              <List>
                {subject?.teachers_list_homework?.map((item, key, arr) => (
                  <ListItem key={key}>
                    <ListItemAvatar>
                      <h1>{key + 1}</h1>
                    </ListItemAvatar>
                    <ListItemText primary={getNameTeacher(item)} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => upTeacher(item, "homework")}
                        disabled={loading}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => downTeacher(item, "homework")}
                        disabled={loading}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
            <div style={{ width: isMobile ? "100%" : "50%" }}>
              <h4 className="good_font" style={{ color: "#FFBC00" }}>
                TUTORÍAS
              </h4>
              <List>
                {subject?.teachers_list_tutorship?.map((item, key, arr) => (
                  <ListItem key={key}>
                    <ListItemAvatar>
                      <h1>{key + 1}</h1>
                    </ListItemAvatar>
                    <ListItemText primary={getNameTeacher(item)} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => upTeacher(item, "tutorship")}
                        disabled={loading}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => downTeacher(item, "tutorship")}
                        disabled={loading}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmitSubject)}>
      <GridContainer>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <InputLabel>Nombre</InputLabel>
          <Controller
            name="label"
            id="label"
            control={control}
            render={({ field }) => (
              <Input {...field} type="text" required fullWidth />
            )}
          />
        </GridItem>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <InputLabel>Costo base del parcial</InputLabel>
          <Controller
            name="partial_base_cost"
            id="partial_base_cost"
            control={control}
            render={({ field }) => (
              <Input {...field} type="number" required fullWidth />
            )}
          />
        </GridItem>
        <GridItem xs={3} md={3} sm={3} lg={3}>
          <Button
            style={{ color: "white", backgroundColor: "#4caf50" }}
            type="submit"
            color="success"
            loading={loading}
          >
            {"Crear"}
          </Button>
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <br />
          <Divider />
          <br />
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <MaterialTable
            onRowClick={onRowClick}
            title={"Materias"}
            columns={[
              { title: "id", field: "_id", editable: "never" },
              // { title: "typeFormId", field: "id", editable: "never" },
              { title: "Nombre", field: "label", editable: "onUpdate" },
              {
                title: "Profesores",
                field: "number_teachers",
                editable: "never",
              },
              {
                title: "Costo base del parcial",
                field: "new_partial_base_cost",
                editable: "onUpdate",
                type: "numeric",
              },
              {
                title: "Costo tutor",
                field: "tutor_cost_base",
                editable: "onUpdate",
                type: "numeric",
              },
              {
                title: "Utilidad",
                field: "dif",
                editable: "never",
              },
            ]}
            key={subjects.length}
            data={subjects
              ?.sort((a, b) => {
                if (b.label < a.label) return 1;
                if (b.label > a.label) return -1;
                return 0;
              })
              .map((item) => ({
                ...item,
                number_teachers: item.teachers_list
                  ? item.teachers_list.length
                  : 0,
                new_partial_base_cost: item.partial_base_cost,
                dif: `${(
                  ((item.partial_base_cost - item.tutor_cost_base) /
                    item.partial_base_cost) *
                  100
                ).toFixed(1)}%`,
              }))}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Recargar",
                isFreeAction: true,
                onClick: getData,
              },
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar",
                isFreeAction: false,
                onClick: () => deleteSubject(rowData),
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: getRowsCount(subjects),
              //filtering: true,
              //grouping: true,
              rowStyle: (rowData) => ({
                backgroundColor:
                  (rowData.partial_base_cost - rowData.tutor_cost_base) /
                    rowData.partial_base_cost >=
                  0.5
                    ? "#66ff00"
                    : (rowData.partial_base_cost - rowData.tutor_cost_base) /
                        rowData.partial_base_cost >=
                      0.4
                    ? "#f3ed39"
                    : "#ff0000",
              }),
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  editSubject(newData, oldData, resolve);
                }),
              editTooltip: () => {
                return "Modificar";
              },
            }}
            isLoading={loading}
          />
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default Subject;
