import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import { IconButton } from "@material-ui/core";
import SingleLoading from "components/Loading/SingleLoading";

export const EditInput = ({
  showComponent,
  editComponent,
  onSave,
  justifyContent = "center",
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInternalSave = async () => {
    setLoading(true);
    setIsEdit(false);
    await onSave();
    setLoading(false);
  };

  if (loading) return <SingleLoading justIcon size={14} />;

  return (
    <div
      style={{
        // backgroundColor: "red",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContent,
      }}
    >
      {isEdit ? (
        <>
          <div style={{ width: "100%" }}>{editComponent}</div>
          <div>
            <IconButton onClick={onInternalSave}>
              <DoneIcon fontSize="small" />
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <div>{showComponent}</div>
          <div>
            <IconButton onClick={() => setIsEdit(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};
