import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class FormsService {
  static async getSubjects({ token, password }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}subject`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async savePartial(dataForSave) {
    const config = {
      method: "post",
      url: URL_API + "partial/forms/receive-partial",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(dataForSave),
    };
    return GlobalService.sentRequest(config);
  }

  static async saveTutorship(dataForSave) {
    const config = {
      method: "post",
      url: URL_API + "tutorship/forms/receive-tutorship",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(dataForSave),
    };
    return GlobalService.sentRequest(config);
  }

  static async saveHomeworkPR(dataForSave) {
    const config = {
      method: "post",
      url: URL_API + "homework/forms/receive-homework/preRequest",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(dataForSave),
    };
    return GlobalService.sentRequest(config);
  }

  static async saveHomework(dataForSave) {
    const FormData = require("form-data");
    const data = new FormData();
    for (const property in dataForSave) {
      if (property === "files") {
        if (dataForSave[property]) {
          for (const file of dataForSave[property]) {
            data.append("files", file);
          }
        } else {
          data.append("files", []);
        }
      } else data.append(property, dataForSave[property]);
    }

    const config = {
      method: "post",
      url: URL_API + "homework/forms/receive-homework",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    };
    return GlobalService.sentRequest(config);
  }
}
