import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// @material-ui/icons

const scheduleButton = createTheme({
  palette: {
    primary: {
      main: "#f9004d",
    },
  },
});

export default function ScheduleButton(props) {
  const { text, link } = props;
  return (
    <ThemeProvider theme={scheduleButton}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        classes={{ label: "buttonLabel" }}
      >
        <a href={`${link}`}>
          <span style={{ color: "white" }} className="good_font">
            {text}
          </span>
        </a>
      </Button>
    </ThemeProvider>
  );
}

ScheduleButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};
