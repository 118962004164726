import FormatMoney from "components/Money/FormatMoney";
import { splitText } from "functions/comun";
import { mapDateFormat } from "functions/comun";
import React from "react";

const Item = ({
  title,
  eventDate,
  paymentDate,
  amount,
  backgroundColor = "#aec4c1",
  handleOnClick,
}) => {
  return (
    <button
      style={{ cursor: "pointer" }}
      className="next-partial-btn"
      onClick={handleOnClick}
    >
      <div className="container__payment-btn" style={{ backgroundColor }}>
        <div style={{ display: "grid", width: "100%" }}>
          <h5
            style={{ fontWeight: "bold", color: "black", fontSize: 14 }}
            className="good_font"
          >
            {splitText(title, 30)}
          </h5>
          <div className="payment_sub-item2">
            <div style={{ fontSize: 12 }}>
              <span className="good_font black_font">
                <div>Fecha cobro:</div>
                <div style={{ fontSize: 10 }}>
                  {mapDateFormat(paymentDate, "ddd DD MMM YYYY, hh:mm A")}
                </div>
              </span>
            </div>
            <div style={{ fontSize: 11 }}>
              <span className="good_font black_font">
                <div>Fecha evento:</div>
                {mapDateFormat(eventDate, "ddd DD MMM YYYY, hh:mm A")}
              </span>
            </div>
            <div>
              <FormatMoney
                value={amount}
                fontSize={18}
                country={{ label: "Colombia" }}
                fontColor="black"
              />
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default Item;
