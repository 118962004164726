import axios from "axios";
import { URL_API } from "service/config";
import GlobalService from "./GlobalService";

export default class TeacherService {
  static async getOne({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}teacher/getone-id`,
      headers: { Authorization: token },
    });
  }

  static async getAll({ user }) {
    var config = {
      method: "get",
      url: URL_API + "teacher",
      headers: { Authorization: user.token },
    };
    const resp = await axios(config);
    if (resp.data.message !== "OK") {
      //console.log(resp.data.message);
    }
    return resp.data;
  }

  static async createTeacher({ user, data }) {
    var config = {
      method: "post",
      url: `${URL_API}teacher`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    return GlobalService.sentRequest(config);
  }

  static async updateTeacher({ token, data, idTeacher }) {
    const dataUpdate = { ...data };
    console.log(dataUpdate);
    const resp = await axios.put(
      URL_API + "teacher/update/" + idTeacher,
      dataUpdate,
      {
        headers: { Authorization: token },
      }
    );
    return resp;
  }

  static async deleteTeacher({ user, oldData }) {
    const resp = await axios.delete(URL_API + "teacher/" + oldData._id, {
      headers: { Authorization: user.token },
    });
    return resp;
  }

  static async updatePasswordTeacher({ token, password }) {
    var config = {
      method: "put",
      url: URL_API + "teacher/update-password/",
      data: JSON.stringify({
        password,
      }),
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    try {
      const resp = await axios(config);
      return resp;
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
}
