import {
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function FinanceForm({ control }) {
  const classes = useStyles();
  const [validate, useValidate] = useState(null);

  return (
    <div>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <h5
        className="good_font"
        style={{
          textAlign: "center",
          color: "#ff044c",
          fontWeight: "bold",
        }}
      >
        ¿Quieres ser parte del equipo de <b>Tutores Copywrite®</b>?
      </h5>
      <Grid Container>
        <Grid Item>
          <Controller
            name="bank"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                style={{ width: "80%" }}
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    Banco
                  </InputLabel>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
        <Grid Item>
          <br />
          <Controller
            name="account_type"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                style={{ width: "80%" }}
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    Tipo de cuenta
                  </InputLabel>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
        <Grid Item>
          <br />
          <Controller
            name="account_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                style={{ width: "80%" }}
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    Número de cuenta
                  </InputLabel>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
      </Grid>
    </div>
  );
}

export default FinanceForm;
