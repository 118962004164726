import React, { useEffect, useState } from "react";
import WompiService from "services/WompiService";
import ReactJson from "react-json-view";
import PartialService from "services/PartialService";
// import { mapDateFormat } from "functions/comun";
// import { BsBoxArrowInDown } from "react-icons/bs";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";

const myParseDate = (date) => moment(date).format("ddd DD MMMM YYYY, hh:mm A");

const PaymentAccepted = ({ partial, user, isHomework = false }) => {
  const idPartial = partial._id;

  const [data, setData] = useState([]);
  const [material, setMaterial] = useState([]);

  useEffect(() => {
    // getPayments();
    getMaterials();
  }, []); // eslint-disable-line

  const getPayments = async () => {
    const res = await WompiService.getById({ idPartial, user });
    setData(res?.data?.data);
  };

  const getMaterials = async () => {
    const res = await PartialService.getAdminMaterials({ idPartial, user });
    setMaterial(res?.data?.data);
  };

  const stateTranslate = (state) => {
    switch (state) {
      case "APPROVED":
        return "aprovado";
      default:
        return state;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "0 10rem",
        textAlign: "left",
        marginLeft: "2rem",
      }}
    >
      {!isHomework && (
        <>
          <h3>Descripción Pago por Pasarela</h3>
          {data?.map((item, index) => {
            return (
              <>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div>
                    <div className="informacion__itemParcial">
                      <b>{`Correo`}</b>
                      <div>
                        {item.external_data.data.transaction.customer_email}
                      </div>
                    </div>
                    <br />
                  </div>
                  <div>
                    <div className="informacion__itemParcial">
                      <b>{`Valor`}</b>
                      <div>
                        $
                        {item.external_data.data.transaction.amount_in_cents /
                          100}
                      </div>
                    </div>
                    <br />
                  </div>
                  <div>
                    <div className="informacion__itemParcial">
                      <b>{`Fecha de aprobación`}</b>
                      <div>
                        {myParseDate(
                          item.external_data.data.transaction.created_at
                        )}
                      </div>
                    </div>
                    <br />
                  </div>
                  <div>
                    <div className="informacion__itemParcial">
                      <b>{`Estado de la transacción`}</b>
                      <div>
                        {stateTranslate(
                          item.external_data.data.transaction.status
                        )}
                      </div>
                    </div>
                    <br />
                  </div>
                  <div>
                    <div className="informacion__itemParcial">
                      <b>{`Medio de pago`}</b>
                      <div>
                        {
                          item.external_data.data.transaction
                            .payment_method_type
                        }
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                {/* <ReactJson
              key={index}
              src={item}
              theme="harmonic"
              iconStyle="square"
              displayDataTypes={false}
              displayObjectSize={false}
              indentWidth={1}
              collapsed={2}
              enableAdd={false}
            /> */}

                <hr />
              </>
            );
          })}
        </>
      )}
      {material?.length > 0 ? (
        <>
          {isHomework ? (
            <h3>Informacíon y material extra</h3>
          ) : (
            <h3>Materiales de preparación</h3>
          )}
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {partial?.textMaterial?.split("\n").map((txm, i, data) => {
              const view = txm.split(":");
              if (i === data.length - 1) {
                return (
                  <div>
                    <div className="informacion__itemParcial">
                      {view.length > 1 ? (
                        <div>{view[1] ? view[1] : "-"}</div>
                      ) : (
                        <div>{view[0] ? view[0] : "-"}</div>
                      )}
                    </div>
                    <br />
                  </div>
                );
              }
              if (i >= 2) {
                return (
                  <div>
                    <div className="informacion__itemParcial">
                      <b>
                        {view[0]}
                        {": \n"}
                      </b>
                      <div>{view[1] ? view[1] : "-"}</div>
                    </div>
                    <br />
                  </div>
                );
              } else {
                return (
                  <div className="informacion__itemParcial">
                    <b>{`${view[0]}: `}</b>
                    {view[1] ? view[1] : "-"}
                    <br />
                  </div>
                );
              }
            })}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <div style={{ color: "#f9004d", fontWeight: "bold" }}>
              Nombre archivo
            </div>
            <div style={{ color: "#f9004d", fontWeight: "bold" }}>Peso</div>
            <div style={{ color: "#f9004d", fontWeight: "bold" }}>
              Descargar
            </div>
            {material?.map((item) => {
              return (
                <>
                  <div key={item.name}>{item.name}</div>
                  <div>{item.size}MB</div>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <GetAppIcon />
                  </a>
                </>
              );
            })}
          </div>
        </>
      ) : (
        <>
          {partial?.tf_textMaterial ? (
            <div className="informacion__item">
              <b>{`Temas: `}</b>
              <div>{partial?.tf_textMaterial}</div>
            </div>
          ) : (
            <h4 styles={{ fontSize: "2rem" }} className="detailpartail__title2">
              Aún no hay material
            </h4>
          )}
        </>
      )}

      {/* <table
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <tr>
          <th>Nombre</th>
          <th>Creado</th>
          <th>Visualizar</th>
        </tr>
        {material.map((item) => (
          <tr key={item.name}>
            <td>{item.name}</td>
            <td>{mapDateFormat(item.created_at, "ddd DD MMM, hh:mm A")}</td>
            <td>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <BsBoxArrowInDown
                  style={{
                    fontSize: "1.5rem",
                    color: "black",
                  }}
                />
              </a>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={3}>{partial?.textMaterial}</td>
        </tr>
      </table> */}
    </div>
  );
};

export default PaymentAccepted;
