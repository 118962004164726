import axios from "axios";
import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class SubjectService {
  static async findAll({ user }) {
    var config = {
      method: "get",
      url: URL_API + "subject",
      headers: {
        Authorization: user.token,
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async updateSubject({ user, data, isAddTeacher }) {
    /* const url = `${URL_API}subject/update/subject-id/${idSubject}/teacher-id/${idTeacher}`;
    const resp = axios.put(url, {
      headers: { Authorization: user.token },
    });
    return resp;
*/
    //console.log(user, data, isAddTeacher);
    if (isAddTeacher) {
      const config = {
        method: "put",
        url: `${URL_API}subject/update/subject-id/${data.idSubject}/teacher-id/${data.idTeacher}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
      };
      return GlobalService.sentRequest(config);
    } else {
      const config = {
        method: "put",
        url: `${URL_API}subject`,
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
        data: JSON.stringify(data),
      };
      try {
        const res = await axios(config);
        return res;
      } catch (error) {
        //console.log(error.message);
        return null;
      }
    }
  }

  static async deleteTeacherInSubject({
    user,
    data: { idSubject, idTeacher },
  }) {
    // const url = `${URL_API}subject/delete/subject-id/${idSubject}/teacher-id/${idTeacher}`;
    // const resp = axios.put(url, {
    //   headers: { Authorization: user.token },
    // });
    // return resp;
    const config = {
      method: "put",
      url: `${URL_API}subject/delete/subject-id/${idSubject}/teacher-id/${idTeacher}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    };
    const res = await axios(config);
    return res;
  }

  static async changeTeacherPosition(
    idSubject,
    idTeacher,
    direction,
    list,
    user
  ) {
    const data = JSON.stringify({
      idTeacher,
      idSubject,
      direction,
      list,
    });

    const config = {
      method: "put",
      url: `${URL_API}subject/change-teacher-position`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data,
    };
    return GlobalService.sentRequest(config);
  }

  static async startTeacher(idSubject, idTeacher, type, user) {
    const data = JSON.stringify({
      idTeacher,
      idSubject,
      type,
    });

    const config = {
      method: "put",
      url: `${URL_API}subject/start-teacher-in-subject`,
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
      data,
    };
    return GlobalService.sentRequest(config);
  }

  static async getStarTeacherSubject(idSubject) {
    const config = {
      method: "get",
      url: `${URL_API}subject/teacher-subject/find-by-subject/${idSubject}`,
      headers: {},
    };
    return GlobalService.sentRequest(config);
  }

  static async create({ data, user }) {
    const config = {
      method: "post",
      url: `${URL_API}subject`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async deleteSubject({ data, user }) {
    const config = {
      method: "delete",
      url: `${URL_API}subject`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async getByTutor({ user, idTutor }) {
    const config = {
      method: "get",
      url: `${URL_API}subject/by-tutor/${idTutor}`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async saveTest({ idSubject, file, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file.item(0));
    data.append("idSubject", idSubject);
    var config = {
      method: "post",
      url: `${URL_API}subject/save-test`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async saveTestSolution({ idSubject, file, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file.item(0));
    data.append("idSubject", idSubject);
    var config = {
      method: "post",
      url: `${URL_API}subject/save-test-solution`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async deleteTest({ idSubject, user }) {
    var config = {
      method: "put",
      url: `${URL_API}subject/delete-test`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: { idSubject },
    };

    return GlobalService.sentRequest(config);
  }

  static async deleteTestSolution({ idSubject, user }) {
    var config = {
      method: "put",
      url: `${URL_API}subject/delete-test-solution`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: { idSubject },
    };

    return GlobalService.sentRequest(config);
  }
}
