import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import SchoolIcon from "@material-ui/icons/School";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SubjectService from "services/SubjectService";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { SportsMmaSharp } from "@material-ui/icons";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AcademicForm({ control, register, setDataState, dataState }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const [asignatures, setAsignatures] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [types, setTypes] = useState([]);

  const getAsignatures = async () => {
    const res = await SubjectService.findAll({ user });
    setAsignatures(
      res.data
        ?.sort((da, db) => {
          if (da.label > db.label) {
            return 1;
          }
          if (da.label < db.label) {
            return -1;
          }
          return 0;
        })
        .map((i) => {
          return <MenuItem value={i._id}>{i.label}</MenuItem>;
        })
    );
  };

  const handleChange = (event) => {
    if (event.target.value.length <= 4) {
      setSubjects(event.target.value);
    } else {
      toast.warning("Solamente puedes seleccionar 4 asignaturas");
    }
  };

  const handleChangeTypes = (event) => {
    setTypes(event.target.value);
  };

  const onChangeFiles = (e) => {
    console.log(e.target.files[0]);
    if (e.target?.files?.length > 0) {
      setDataState({ ...dataState, files: e.target.files });
    }
  };

  useEffect(() => {
    getAsignatures();
  }, []);

  return (
    <div>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <h5
        className="good_font"
        style={{ textAlign: "center", color: "#ff044c", fontWeight: "bold" }}
      >
        ¿Quieres ser parte del equipo de <b>Tutores Copywrite®</b>?{" "}
      </h5>
      <Grid Container>
        <Grid Item>
          <Controller
            name="levelStudy"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                className={classes.margin}
                id="input-with-icon-textfield"
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    ¿Cuál es tu nivel de estudio?
                  </InputLabel>
                }
                style={{ width: "80%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SchoolIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
        <Grid Item>
          <Controller
            name="preGrad"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                className={classes.margin}
                id="input-with-icon-textfield"
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    ¿Qué Pre-grado estudias?
                  </InputLabel>
                }
                style={{ width: "80%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SchoolIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
        <Grid Item>
          <Controller
            name="studies"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                className={classes.margin}
                id="input-with-icon-textfield"
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    ¿En qué Universidad estudiaste?
                  </InputLabel>
                }
                style={{ width: "80%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SchoolIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
        <Grid Item>
          <div
            style={{ textAlign: "left", padding: "5px 10%", marginTop: "5px" }}
          >
            <InputLabel
              style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
              className="good_font"
            >
              ¿Qué experiencia tienes realizando Parciales, Tutorías o Talleres?
            </InputLabel>
          </div>
          <Controller
            name="exp"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                required
                style={{ width: "80%" }}
                maxRows={3}
                minRows={3}
              />
            )}
          ></Controller>
        </Grid>
        <Grid Item>
          <div
            style={{ textAlign: "left", padding: "0 10%", marginTop: "5px" }}
          >
            <InputLabel
              style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
              className="good_font"
            >
              ¿En que asignaturas puedes recibir solicitudes?{" "}
            </InputLabel>
          </div>
          <FormControl
            style={{ width: "80%", margin: "0", paddingBottom: "10px" }}
            className={classes.formControl}
          >
            <InputLabel
              style={{ fontSize: "0.9rem" }}
              id="demo-mutiple-name-label"
            >
              Asignaturas (Máximo 4)
            </InputLabel>
            <Select
              required
              {...register("subjects")}
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={subjects}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {asignatures}
            </Select>
          </FormControl>
          <br />
        </Grid>
        <Grid Item>
          <div
            style={{ textAlign: "left", padding: "0 10%", marginTop: "5px" }}
          >
            <InputLabel
              style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
              className="good_font"
            >
              Adjunta tu certificado de promedio de notas de las asignaturas
              seleccionadas. (Adjuntar en pdf o imagen)
            </InputLabel>
          </div>
          <input required type="file" onChange={onChangeFiles} />
        </Grid>
        <Grid Item>
          <div
            style={{ textAlign: "left", padding: "0 10%", marginTop: "5px" }}
          >
            <InputLabel
              style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
              className="good_font"
            >
              ¿Qué tipo de solicitudes puedes atender?{" "}
            </InputLabel>
          </div>
          <FormControl
            style={{ width: "80%", margin: "0", paddingBottom: "10px" }}
            className={classes.formControl}
          >
            <InputLabel
              style={{ fontSize: "0.9rem" }}
              id="demo-mutiple-name-label"
            >
              Solicitudes (Puedes seleccionar varias)
            </InputLabel>
            <Select
              required
              {...register("types")}
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={types}
              onChange={handleChangeTypes}
              input={<Input />}
              MenuProps={MenuProps}
            >
              <MenuItem value="partial">Parciales</MenuItem>
              <MenuItem value="tutorship">Tutorias</MenuItem>
              <MenuItem value="homework">Talleres</MenuItem>
            </Select>
          </FormControl>
          <br />
        </Grid>
        <Grid Item>
          <div
            style={{ textAlign: "left", padding: "0 10%", marginTop: "5px" }}
          >
            <InputLabel style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
              ¿A través de que medio te enteraste de la oferta?
            </InputLabel>
          </div>
          <FormControl
            style={{ width: "80%", margin: "0", paddingBottom: "10px" }}
            className={classes.formControl}
          >
            <InputLabel
              style={{ fontSize: "0.9rem" }}
              id="demo-mutiple-name-label"
            >
              Opciones
            </InputLabel>
            <Select
              required
              {...register("options")}
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
            >
              <MenuItem value="1">
                En una publicación de Instagram (Feed)
              </MenuItem>
              <MenuItem value="2">En una Pauta de Instagram (Post)</MenuItem>
              <MenuItem value="3">Un amigo me recomendó</MenuItem>
              <MenuItem value="4">En @Soy_Ingeniero</MenuItem>
              <MenuItem value="5">Otra pagina de la universidad</MenuItem>
              <MenuItem value="6">Otro</MenuItem>
            </Select>
          </FormControl>
          <br />
        </Grid>
      </Grid>
    </div>
  );
}

export default AcademicForm;
