import React, { useState } from "react";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import WhatsappIconYellow from "assets/customIcons/WhatsappIconYellow";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { mapDate } from "functions/comun";
import swal from "sweetalert";
import PartialService from "services/PartialService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const getTeacherAssigned = (data) => {
  if (data?.names) return data?.names;
  return "SIN TUTOR";
};

const openWhatsApp = (teacher, partial) => {
  const text = `🚀 ¡Hola ${
    teacher.names
  }!, tenemos este parcial en *Copywrite App*\n\n*Materia:* ${
    partial.subject
  }.\n*Fecha:* ${mapDate(partial.event_date)}.\n*Duración del parcial:* ${
    partial.event_time
  } minutos.\n*Material de Preparación:* ${
    window.location.origin
  }/teacher/parciales/${partial._id}.\n\n🌀¿Puedes tomarlo?`;

  const url = `https://api.whatsapp.com/send?phone=${
    teacher.phone
  }&text=${encodeURIComponent(text)}`;
  window.open(url, teacher._id);
};

const openWhatsAppOff = (teacher, partial) => {
  // const text = `🚀 ¡Hola ${
  //   teacher.names
  // }!, tenemos este parcial en *Copywrite App*\n\n*Materia:* ${
  //   partial.subject
  // }.\n*Fecha:* ${mapDate(partial.event_date)}.\n*Duración del parcial:* ${
  //   partial.event_time
  // } minutos.\n*Material de Preparación:* ${
  //   window.location.origin
  // }/teacher/parciales/${partial._id}.\n\n🌀¿Puedes tomarlo?`;

  const text = `👋 ¡Hola ${
    teacher.names
  }!, tenemos un *Parcial de Oferta* en *Copywrite App*\n\n*Materia:* Pruebas.\n*Fecha:* ${mapDate(
    partial.event_date
  )}\n*Duración del parcial:* ${
    partial.event_time
  } minutos.\n*Material de Preparación:* ${
    window.location.origin
  }/teacher/parciales/${
    partial._id
  }.\n\n✅ Por ser *Parcial de Oferta se pagan 10.000 COP adicionales*\n\n🎈 ¿Puedes tomarlo?`;

  const url = `https://api.whatsapp.com/send?phone=${
    teacher.phone
  }&text=${encodeURIComponent(text)}`;
  window.open(url, teacher._id);
};

export const MiniComponentTeacherAssigned = ({ teacherData, partial }) => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const sendTutorRequests = () => {
    swal({
      title: "¿Seguro?",
      text: `Se enviarán solicitudes a todos los tutores asignados en ${partial.subject}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        setLoading(true);
        const res = await PartialService.sendTutorRequests({
          token: user.token,
          idPartial: partial._id,
        });
        if (res?.data) {
          toast.success("Solicitudes enviadas!");
          const reloadBtn = document.getElementById(
            `handle_reload_teacher_requests_partial_${partial._id}`
          );
          if (reloadBtn && reloadBtn.click) reloadBtn.click();
        }
        setLoading(false);
      }
    });
  };

  return (
    <div>
      Profesor asignado: {getTeacherAssigned(teacherData)}
      {teacherData?._id ? (
        <>
          <IconButton onClick={() => openWhatsApp(teacherData, partial)}>
            <WhatsappIcon />
          </IconButton>
          <IconButton onClick={() => openWhatsAppOff(teacherData, partial)}>
            <WhatsappIconYellow />
          </IconButton>
        </>
      ) : (
        <div>
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <Button
              size="small"
              style={{ backgroundColor: "rgb(220, 0, 78)", color: "white" }}
              variant="contained"
              color="secondary"
              onClick={sendTutorRequests}
            >
              <small>Enviar solicitudes</small>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
