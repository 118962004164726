import { getRowsCount } from "functions/comun";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { mapDate } from "functions/comun";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import Taller from "./Pages/Taller";
import ColorModal from "./Pages/ColorModal";
import TallerService from "services/TallerService";
import ColorService from "services/ColorService";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import SingleLoading from "components/Loading/SingleLoading";
import { SearchById } from "./Pages/partials/SearchById";

const tableRef = React.createRef();

const Talleres = () => {
  const user = useSelector((state) => state.user.user);

  // const [homeworks, setHomeworks] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState("ALL");
  const [homework, setHomework] = React.useState(null);
  const [colors, setColors] = useState([]);
  const [openColorModal, setOpenColorModal] = React.useState(false);
  const [homeworkToColor, setHomeworkToColor] = useState(null);
  const [data, setData] = useState([]);
  const [colorsArray, setColorsArray] = useState({});
  const [colorSelected, setColorSelected] = useState("ALL");

  const getColorsArray = async () => {
    const obj = {};
    const res = await ColorService.findAll({ user });
    const colors = res.data.data;
    setLoading(true);
    colors.forEach((item) => {
      obj[item.color] = item.name;
    });
    setColorsArray(obj);
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
    getColors();
    getColorsArray();
  }, [state]); //eslint-disable-line

  const isRenderLoading = loading || colors !== [] || data !== [];

  const getData = async () => {
    setLoading(true);
    if (state === "ALL") {
      const res = await TallerService.getAll({ user });
      setLoading(false);
      setData(res.data);
    } else {
      const res = await TallerService.getAll({ user, state });
      setLoading(false);
      setData(res.data);
    }
  };

  const getColors = async () => {
    setLoading(true);
    const res = await ColorService.findAll({ user });
    if (res.data.message !== "OK") {
      //console.log("Hubo un error");
    }
    setLoading(false);
    setColors(res.data.data);
    // var config = {
    //   method: "get",
    //   url: URL_API + "color",
    //   headers: { Authorization: user.token },
    // };
    // axios(config)
    //   .then((res) => {
    //     //console.log(res)
    //     if (res.data.status) {
    //       setColors(res.data.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const onRowClick = (e, rowData) => setHomework(rowData);

  const stateTranslate = (state) => {
    switch (state) {
      case "PENDING":
        return "Pendiente";

      case "WAITING_TEACHER":
        return "Esperando profesor";

      case "ASSIGNED":
        return "Asignado";

      case "PAYMENT_SENT":
        return "Pago enviado";

      case "PAYMENT_OK":
        return "Pago revisado OK";

      case "NOT_PAYMENT":
        return "No pagado";

      default:
        return "OTRO";
    }
  };

  const copyToClipboard = (text) => {
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value =
      `Materia: ${text.subject}` +
      `\nFecha: ${mapDate(text.event_date)}` +
      `\nDuración: ${text.event_time} minutos`;

    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
  };

  const brushHomework = (homework) => {
    //console.log(homework);
    setHomeworkToColor(homework);
    //console.log(homeworkToColor);
    setOpenColorModal(true);
  };

  const openWhatsApp = (homework) => {
    const url = `https://api.whatsapp.com/send?phone=${
      homework.phone
    }&text=Hola ${homework.client}, te escribimos de CopyWrite.%0D%0DMateria: ${
      homework.subject
    }%0DFecha y hora de entrega: ${mapDate(homework.event_date)}%0DArchivo: ${
      homework.image_assigment
    }%0D%0DEl taller tendría un costo entre 50k y 80k, confírmanos si te interesa el rango de precios para hablar con el profesor y darte un valor exacto.`;
    window.open(url, homework._id);
  };

  const onChangeColorSelected = async (e) => {
    setLoading(true);
    setColorSelected(e.target.value);
    const color = e.target.value;
    if (e.target.value !== "ALL") {
      const res = await TallerService.getFilterColor({ user, state, color });
      setData(res.data);
    } else {
      getData();
    }
    setLoading(false);
  };

  if (homework)
    return (
      <Taller homework={homework} setHomework={setHomework} getData={getData} />
    );

  return (
    <>
      {isRenderLoading ? (
        <GridContainer>
          <GridItem xs={6} md={6} sm={6} lg={6}>
            <InputLabel>Estado</InputLabel>
            <Select
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={{ width: "50%" }}
            >
              <MenuItem value={"ALL"}>Todos</MenuItem>
              <MenuItem value={"PENDING"}>Pendientes</MenuItem>
              <MenuItem value={"ASSIGNED"}>Asignado</MenuItem>
              <MenuItem value={"WAITING_TEACHER"}>Esperando profesor</MenuItem>
              <MenuItem value={"PAYMENT_SENT"}>Pago enviado</MenuItem>
              <MenuItem value={"PAYMENT_OK"}>Pago revisado OK</MenuItem>
              <MenuItem value={"NOT_PAYMENT"}>No pagado</MenuItem>
            </Select>
          </GridItem>
          <GridItem xs={6} md={6} sm={6} lg={6}>
            <InputLabel>Colores</InputLabel>
            <Select
              value={colorSelected}
              onChange={onChangeColorSelected}
              style={{ width: "50%" }}
            >
              <MenuItem value={"ALL"}>Todos</MenuItem>
              <MenuItem value={"#fff"}>Sin color</MenuItem>
              {colors.map((color) => (
                <MenuItem value={color.color}>
                  <div style={{ backgroundColor: color.color, width: "100%" }}>
                    {color.name}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <hr></hr>
          </GridItem>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <MaterialTable
              onRowClick={onRowClick}
              title={"Talleres"}
              columns={[
                { title: "Cliente", field: "client" },
                { title: "Celular", field: "phone" },
                { title: "Materia", field: "subject", filtering: false },
                {
                  title: "Fecha de entrega",
                  field: "event_date",
                  filtering: false,
                  customSort: (a, b) =>
                    new Date(a.event_date).valueOf() -
                    new Date(b.event_date).valueOf(),
                },
                { title: "Estado", field: "state", filtering: false },
                {
                  title: "Color",
                  field: "color",
                  lookup: colorsArray,
                },
                { title: "Solicitado", field: "inserted_at", filtering: false },
              ]}
              key={data.length}
              data={data.map((item) => ({
                ...item,
                state: stateTranslate(item.state),
                inserted_at: mapDate(item.inserted_at),
              }))}
              localization={{
                toolbar: {
                  searchPlaceholder: "Buscar",
                },
              }}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Recargar",
                  isFreeAction: true,
                  onClick: getData,
                },
                (rowData) => ({
                  icon: "content_copy",
                  tooltip: "Copiar",
                  isFreeAction: false,
                  onClick: () => copyToClipboard(rowData),
                }),
                (rowData) => ({
                  icon: "brush",
                  tooltip: "Colorear",
                  isFreeAction: false,
                  onClick: () => brushHomework(rowData),
                }),
                (rowData) => ({
                  icon: () => <WhatsappIcon />,
                  tooltip: "WhatsApp",
                  isFreeAction: false,
                  onClick: () => openWhatsApp(rowData),
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100, 1000],
                // pageSize: getRowsCount(data),
                //filtering: true,
                grouping: true,
                search: false,
                sorting: false,
                filtering: true,
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.color,
                }),
              }}
              isLoading={loading}
            />
          </GridItem>
          {openColorModal && (
            <ColorModal
              colors={colors}
              open={openColorModal}
              setOpen={setOpenColorModal}
              setElementToColor={setHomeworkToColor}
              user={user}
              getData={getData}
              elementToColor={homeworkToColor}
              type="homework"
            />
          )}
          <SearchById setService={setHomework} context="homework" />
        </GridContainer>
      ) : (
        <SingleLoading size={50} />
      )}
    </>
  );
};

export default Talleres;
