import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Modal, IconButton } from "@material-ui/core";
import { isMobile } from "react-device-detect";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "scroll",
  };
}

export default function SimpleModal({
  open,
  setOpen,
  children,
  widthModal,
  heightModal,
}) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: isMobile ? "100%" : widthModal || "90%",
      height: isMobile ? "100%" : heightModal || "90%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
    },
  }));
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div style={{ textAlign: "right" }}>
          <IconButton
            component="span"
            color="primary"
            title="Cerrar"
            onClick={() => setOpen(false)}
          >
            <CloseIcon style={{ fontSize: 40 }} />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
}
