import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import useCountry from "hooks/useCountry";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function BasicForm({ register, control }) {
  const classes = useStyles();
  const [validate, useValidate] = useState(null);
  const { paises, states, cities, onClickCountry, onClickState } = useCountry();

  const onclickOnCountry = (e, a) => {
    console.log(a.key.split("$")[1]);
    onClickCountry({ target: { value: a.key.split("$")[1] } });
  };

  return (
    <div>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <h5
        className="good_font"
        style={{
          textAlign: "center",
          color: "#ffbc00",
          color: "#ff044c",
          fontWeight: "bold",
        }}
      >
        ¿Quieres ser parte del equipo de <b>Tutores Copywrite®</b>?
      </h5>
      <Grid Contianer>
        <Grid Item>
          <Controller
            name="names"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                style={{ width: "80%" }}
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    Nombres
                  </InputLabel>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
          <br />
        </Grid>
        <Grid>
          <br />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                style={{ width: "80%" }}
                label={
                  <InputLabel
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      width: "30rem",
                      textAlign: "start",
                    }}
                  >
                    Correo
                  </InputLabel>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          ></Controller>
        </Grid>
        <Grid>
          <div style={{ textAlign: "left", padding: "5px 10%" }}>
            <br />
            <InputLabel
              style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
              className={`${classes.label} good_font`}
            >
              ¿Cúal es tu número de WhatsApp? *
            </InputLabel>
            <div style={{ textAlign: "center", marginTop: "5px" }}>
              <Controller
                name="whatsapp"
                control={control}
                style={{ width: "100%" }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    style={{ width: "100%" }}
                    country={"co"}
                  />
                )}
              ></Controller>
            </div>
          </div>
        </Grid>
        <Grid Item>
          <FormControl
            style={{ width: "80%", marginTop: "0.5rem" }}
            className={classes.formControl}
          >
            <InputLabel
              style={{ fontWeight: "bolder" }}
              id="demo-simple-select-label"
            >
              País de residencia
            </InputLabel>
            <Select
              required
              {...register("country")}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="country"
              onChange={onclickOnCountry}
            >
              {paises.map((data) => (
                <MenuItem key={data.label} value={data.value}>
                  {data.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid Item>
          <FormControl
            style={{ width: "80%", marginTop: "0.5rem" }}
            className={classes.formControl}
          >
            <InputLabel
              style={{ fontWeight: "bolder" }}
              id="demo-simple-select-label"
            >
              Estado
            </InputLabel>
            <Select
              required
              {...register}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="state"
              onChange={onClickState}
            >
              {states.map((data) => (
                <MenuItem key={data.value} value={data.label}>
                  {data.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid Item>
          <FormControl
            style={{ width: "80%", marginTop: "0.5rem" }}
            className={classes.formControl}
          >
            <InputLabel
              style={{ fontWeight: "bolder" }}
              id="demo-simple-select-label"
            >
              Ciudad
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="city"
              {...register("idLocation")}
            >
              {cities.map((data) => (
                <MenuItem key={data.value} value={data.label}>
                  {data.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default BasicForm;
