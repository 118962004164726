import { InputLabel, makeStyles } from "@material-ui/core";
import SingleLoading from "components/Loading/SingleLoading";
import { mapDate } from "functions/comun";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TallerService from "services/TallerService";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  title1: {
    fontSize: 24,
    color: "#ffbc00",
  },
  title2: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold",
  },
  title3: {
    fontSize: 18,
    textAlign: "center",
    color: "#ffbc00",
  },
  label: {
    padding: 10,
  },
}));

function NextHomework() {
  const { id } = useParams("id");
  const classes = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const res = await TallerService.findByIdAdmin({ id });
    if (res?.data?.message === "OK") setData(res?.data?.data);
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-next-line

  return (
    <div>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Talleres <b>Copywrite</b>
      </h3>
      <div style={{ textAlign: "center", padding: "5px 20px" }}>
        <InputLabel className={`${classes.title2} good_font`}>
          Materia:
        </InputLabel>
        <InputLabel className={`${classes.label} good_font`}>
          {data?.subject}
        </InputLabel>
        <InputLabel className={`${classes.title2} good_font`}>
          Fecha de entrega:
        </InputLabel>
        <InputLabel className={`${classes.label} good_font`}>
          {mapDate(data?.event_date)}
        </InputLabel>
        {data?.files?.length > 0 ? (
          <>
            <InputLabel className={`${classes.title2} good_font`}>
              Material del Taller
            </InputLabel>
            {console.log(data?.files)}
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Archivo</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={3}>
                      <SingleLoading size={20} />
                    </td>
                  </tr>
                ) : (
                  data?.files.map((i, j) => (
                    <tr key={j}>
                      <td>
                        <Button
                          //   style={styleButton}
                          variant="contained"
                          color="primary"
                          onClick={() => window.open(i)}
                        >
                          Ver archivo {j + 1}
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        ) : (
          <InputLabel
            style={{ color: "red" }}
            className={`${classes.label} good_font`}
          >
            No hay archivos cargados por parte del estudiante
          </InputLabel>
        )}
      </div>
    </div>
  );
}

export default NextHomework;
