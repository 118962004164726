import {
  Breadcrumbs,
  Divider,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import InReview from "./payments/InReview";
import moment from "moment";
import { Pay_date } from "functions/Pay_date";

const getMonth = (month) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return months[month];
};

const useStyles = makeStyles((theme) => ({
  ol: {
    justifyContent: "space-around",
  },
}));

const RequestPayments = (props) => {
  const classes = useStyles();
  const [activePage, setActivePage] = useState(0);

  const handleClick = (e, index) => {
    e.preventDefault();
    setActivePage(index);
  };

  const renderItem = (activePageParam, text, index) => {
    return activePageParam === index ? (
      <Typography color="#d5004d">
        <b style={{ color: "#d5004d", textDecoration: "overline" }}>{text}</b>
      </Typography>
    ) : (
      <Link color="inherit" href="/" onClick={(e) => handleClick(e, index)}>
        {text}
      </Link>
    );
  };

  const getComponent = () => {
    switch (activePage) {
      case 0:
        return <InReview state="ASKED" />;
      case 1:
        return <InReview state="APPROVED" />;
      case 2:
        return <InReview state="DENIED" />;
      case 3:
        return <InReview state="PAID" />;
      default:
        return "NO PAGE";
    }
  };
  return (
    <>
      <div
        className="good_font"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          fontSize: 12,
          marginTop: 15,
        }}
      >
        <p>
          <b>Próxima fecha de pago:</b> {Pay_date(new Date().getMonth())}
          <br />
          <b>Periodo a facturar:</b> 16 de {getMonth(moment().month() - 1)} a 15
          de {getMonth(moment().month())}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "30px",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          maxItems={4}
          separator=""
          classes={classes}
        >
          {renderItem(activePage, "En revisión", 0)}
          {renderItem(activePage, "Aceptadas", 1)}
          {renderItem(activePage, "Rechazadas", 2)}
          {renderItem(activePage, "Historial", 3)}
        </Breadcrumbs>
      </div>
      <div>
        <Divider />{" "}
      </div>
      <div>{getComponent()}</div>
    </>
  );
};
export default RequestPayments;
