import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import BasicForm from "./BasicForm";
import AcademicForm from "./AcademicForm";
import FinanceForm from "./FinanceForm";
import { useForm } from "react-hook-form";
import HiringService from "services/HiringService";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: "bolder",
  },
  title1: {
    fontSize: 24,
    color: "#ffbc00",
  },
  title2: {
    fontSize: 18,
    textAlign: "center",
  },
  title3: {
    fontSize: 18,
    textAlign: "center",
    color: "#ffbc00",
  },
  label: {
    padding: 10,
    fontWeight: "bold",
  },
}));

const useStylesButton = makeStyles((theme) => ({
  label: {
    fontWeight: "bolder",
  },
}));

function getSteps() {
  return ["Datos basicos", "Informacíon academica", "Informacíon financiera"];
}

function getStepContent(stepIndex, register, control, setDataState, dataState) {
  switch (stepIndex) {
    case 0:
      return <BasicForm register={register} control={control} />;
    case 1:
      return (
        <AcademicForm
          control={control}
          register={register}
          dataState={dataState}
          setDataState={setDataState}
        />
      );
    case 2:
      return <FinanceForm control={control} />;
    default:
      return "Unknown stepIndex";
  }
}

const isPhoneValid = (phoneForValidate) => {
  return phoneForValidate && phoneForValidate.length > 10;
};

function Form() {
  const classes = useStyles();
  const button = useStylesButton();
  const [activeStep, setActiveStep] = React.useState(0);
  const { register, control, handleSubmit } = useForm();
  const [firstTime, setFirstTime] = useState(true);
  const steps = getSteps();
  const [dataState, setDataState] = useState({
    files: 0,
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uploadFile = () => {};

  const sendData = async (info) => {
    //Guardamos en la base de datos
    uploadFile();
    const data = { ...info, status: true, files: dataState };
    const res = await HiringService.sendData(data);
  };

  const onSubmit = (data) => {
    switch (activeStep) {
      case 0:
        console.log(data);
        // if (true) {
        if (isPhoneValid(data.whatsapp)) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          swal("Oops", "Ingrese un número de celular valido", "warning");
        }
        break;
      case 1:
        console.log(data);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        console.log(data);
        sendData(data);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      default:
        break;
    }
  };

  if (firstTime) {
    return (
      <>
        <div style={{ textAlign: "center", paddingTop: 80 }}>
          <a
            href="https://www.copywritecol.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={"/icon.png"}
              alt=""
              style={{ width: isMobile ? "50%" : 150 }}
            />
          </a>
        </div>
        <div style={{ textAlign: "center", padding: 40 }}>
          <h1 className={`good_font ${classes.title1}`}>
            <p
              style={{
                textDecoration: "none",
                color: "rgb(255, 188, 0)",
                fontSize: "30px",
              }}
            >
              {" "}
              Bienvenido
            </p>
          </h1>
        </div>
        <div>
          <p
            style={{
              paddingLeft: "25%",
              paddingRight: "25%",
              textAlign: "center",
            }}
            className={`good_font ${classes.title2}`}
          >
            <span>
              Buscamos los mejores tutores para generar ingresos <b>($)</b> con
              su conocimiento. Mira el video instructivo y sigue los pasos
              indicados.
            </span>
          </p>
        </div>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/2Oco28oS6uw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div style={{ textAlign: "center" }}>
          <br />
          <Button
            variant="contained"
            color="primary"
            classes={{ label: "buttonLabel" }}
            onClick={() => setFirstTime(false)}
          >
            Comenzar
          </Button>
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className="good_font">
              <small className="justText">
                <b>{label}</b>
              </small>
            </StepLabel>{" "}
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div style={{ width: "100%", textAlign: "justify", padding: "5rem" }}>
            <Typography className={classes.instructions}>
              <div
                style={{
                  width: "100%",
                  fontWeight: "bolder",
                  color: "#ff044c",
                  textAlign: "center",
                  fontSize: "1.5rem",
                }}
              >
                ¡Genial!
              </div>
              <br />
              <br />
              <b>
                Hemos enviado la prueba de conocimiento a tu correo electrónico.
              </b>
              <br />
              Debes aprobar esta prueba para formar parte de nuestro equipo de
              tutores. Por favor, revisa tu carpeta de Spam o busca en la barra
              de búsqueda el correo electrónico con el remitente
              <b>"no-reply@copywritecol.com"</b> para encontrarlo.
            </Typography>
            <br />
            <div
              style={{ margin: "15px 0", width: "100%", textAlign: "center" }}
            >
              <Button
                className={classes.backButton}
                classes={{ label: "buttonLabel" }}
                onClick={handleBack}
              >
                Atrás
              </Button>
            </div>
          </div>
        ) : (
          <form
            style={{ width: "100%", textAlign: "center" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography className={classes.instructions}>
              {getStepContent(
                activeStep,
                register,
                control,
                setDataState,
                dataState
              )}
            </Typography>
            <div
              style={{ margin: "15px 0", width: "100%", textAlign: "center" }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                classes={{ label: "buttonLabel" }}
              >
                Atrás
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                classes={{ label: "buttonLabel" }}
                className="good_font"
                // onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Terminar" : "Siguiente"}{" "}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Form;
