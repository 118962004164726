import React, { useEffect } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useState } from "react";
import GoBack from "components/Navigation/GoBack";
import { InputLabel, Slider, makeStyles } from "@material-ui/core";
import CustomReactSelect from "components/Select/CustomReactSelect";
import Button from "@material-ui/core/Button";
import SubjectService from "services/SubjectService";
import { useSelector } from "react-redux";
import FormatMoney from "components/Money/FormatMoney";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";

const useStyles = makeStyles((theme) => ({
  title1: {
    fontSize: 24,
    color: "#ffbc00",
  },
  title2: {
    fontSize: 18,
    textAlign: "center",
  },
  title3: {
    fontSize: 18,
    textAlign: "center",
    color: "#ffbc00",
  },
  label: {
    padding: 10,
    fontWeight: "bold",
  },
}));

function Cotizar() {
  const user = useSelector((state) => state.user.user);
  const [view, setView] = useState(null);
  const classes = useStyles();

  if (view) {
    switch (view) {
      case "parcial":
        return <ViewParcial setBack={setView} classes={classes} user={user} />;
      case "tutoria":
        return <ViewTutoria setBack={setView} classes={classes} user={user} />;
      default:
        break;
    }
  }

  return (
    <div className="container--dashboard">
      <div className="dashboard--items">
        <div
          className="item1"
          style={{ cursor: "pointer" }}
          onClick={() => setView("parcial")}
        >
          <div className="mobile-item">
            <h3>Parcial</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item2"
          style={{ cursor: "pointer" }}
          onClick={() => setView("tutoria")}
        >
          <div className="mobile-item">
            <h3>Tutoría</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item3"
          style={{ cursor: "pointer" }}
          onClick={() => toast.info("Este servicio aún esta en construccion")}
        >
          <div className="mobile-item">
            <h3>Taller</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}
const ViewParcial = ({ setBack, classes, user }) => {
  const [subject, setSubject] = useState({});
  const [minutes, setMinutes] = useState(20);
  const [subjects, setSubjects] = useState([]);
  const [cost, setCost] = useState(0);

  const getSubjects = async () => {
    const res = await SubjectService.getByTutor({ user, idTutor: user.id });
    setSubjects(res?.data);
  };

  const tryToParseInt = (stringNumber) => {
    try {
      return parseInt(stringNumber, 10);
    } catch (error) {}
    return 0;
  };

  const costTutor = () => {
    if (minutes >= 0 && minutes <= 29) return 0.52;
    if (minutes === 30) return 0.47;
    if (minutes >= 31 && minutes <= 59) return 0.47;
    if (minutes >= 60 && minutes <= 120) return 0.55;
    if (minutes >= 121 && minutes <= 150) return 0.54;
    if (minutes >= 151 && minutes <= 180) return 0.53;
  };

  const factorTutor = (initialCost) => {
    setCost(initialCost * costTutor());
  };

  const factorMinutes = () => {
    // //console.log("subjectObject:", subjectObject);
    const saldo = tryToParseInt(subject.partial_base_cost);
    let initialCost = 0;
    if (minutes > 0 && minutes <= 180) {
      if (minutes >= 0 && minutes <= 29) initialCost = saldo * 0.3;
      if (minutes === 30) initialCost = saldo * 0.4;
      if (minutes >= 31 && minutes <= 59) initialCost = saldo * 0.7;
      if (minutes >= 60 && minutes <= 120) initialCost = saldo * 1;
      if (minutes >= 121 && minutes <= 150) initialCost = saldo * 1.3;
      if (minutes >= 151 && minutes <= 180) initialCost = saldo * 1.45;
    } else {
      initialCost = saldo;
    }
    factorTutor(initialCost);
  };

  const priceCalculateTutor = (value) => {
    const integerPart = (Math.trunc(value / 1000) + 1) * 1000;
    return integerPart;
  };

  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    factorMinutes();
  }, [minutes, subject]);

  return (
    <div>
      {" "}
      <GoBack
        back={() => setBack(null)}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div style={{ textAlign: "left", padding: 10 }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cuál es la asignatura del <strong>PARCIAL</strong>?
        </InputLabel>
        <br />
        <div style={{ padding: "0px 40px", textAlign: "center" }}>
          <CustomReactSelect
            required
            options={subjects.map((i) => ({ value: i, label: i.label }))}
            onChange={(value) => setSubject(value.value)}
            value={subject}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: 10 }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cuántos MINUTOS dura el <strong>PARCIAL</strong>?
        </InputLabel>
        <br />
        <div style={{ padding: "0px 40px", textAlign: "center" }}>
          <Slider
            // onChangeCommitted={(event, value) => setMinutes(value)}
            onChange={(event, value) => {
              setMinutes(value);
            }}
            defaultValue={30}
            getAriaValueText={(value) => `${value} NOSE`}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            //valueLabelFormat={valueLabelFormat}
            step={10}
            marks
            min={20}
            max={180}
            // scale={(x) => x * 20}
          />
          <span>
            <b>{minutes} minutos</b>
          </span>
        </div>
        <br />
      </div>
      <div style={{ textAlign: "center" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          Por un servicio de este tipo obtendrias:
        </InputLabel>
        <CurrencyFormat
          value={priceCalculateTutor(cost)}
          displayType={"text"}
          thousandSeparator={true}
          suffix={" COP"}
          className="good_font"
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#fc044c",
          }}
        />
      </div>
      <div className="good_font" style={{ textAlign: "center" }}>
        <br />* En algunas ocasiones el pago neto del servicio podria variar
        hasta <b>3000 COP</b> positiva o negativamente
      </div>
    </div>
  );
};

const ViewTutoria = ({ setBack, classes, user }) => {
  const [subjects, setSubjects] = useState([]);
  const [persons, setPersons] = useState({ value: "una", label: "Uno" });
  const [hours, setHours] = useState(1);
  const [subject, setSubject] = useState({});
  const [cost, setCost] = useState(0);

  const getSubjects = async () => {
    const res = await SubjectService.getByTutor({ user, idTutor: user.id });
    setSubjects(res?.data);
  };

  const tryToParseInt = (stringNumber) => {
    try {
      return parseInt(stringNumber, 10);
    } catch (error) {}
    return 0;
  };

  const factorMinutes = () => {
    // //console.log("subjectObject:", subjectObject);
    const saldo = tryToParseInt(subject.partial_base_cost);

    // //console.log("persons:", persons);
    // //console.log("saldo:", saldo);
    switch (persons.value) {
      case "una":
        getCostTutor(saldo * 0.43);
        break;
      case "dos":
        getCostTutor(saldo * 0.53);
        break;
      case "tres":
        getCostTutor(saldo * 0.63);
        break;
      case "cuatro":
        getCostTutor(saldo * 0.73);
        break;
      case "cinco":
        getCostTutor(saldo * 0.83);
        break;
      default:
        getCostTutor(saldo * 1);
        break;
    }
  };

  const getCostTutor = (value) => {
    const costByTutor = 0.62;
    switch (hours) {
      case 1:
        setCost(value * 1 * costByTutor);
        break;
      case 2:
        setCost(value * 0.9 * costByTutor);
        break;
      case 3:
        setCost(value * 0.82 * costByTutor);
        break;
      case 4:
        setCost(value * 0.77 * costByTutor);
        break;
      case 5:
        setCost(value * 0.76 * costByTutor);
        break;
      default:
        setCost(value * 0.75 * costByTutor);
        break;
    }
  };

  const priceCalculateTutor = (value) => {
    const integerPart = (Math.trunc(value / 1000) + 1) * 1000;
    return integerPart;
  };

  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    factorMinutes();
  }, [subject, persons, hours]);

  return (
    <div>
      <GoBack
        back={() => setBack(null)}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Para cuál asignatura será la <strong>TUTORÍA</strong>?
        </InputLabel>
        <br />
        <div style={{ padding: "0px 40px", textAlign: "center" }}>
          <CustomReactSelect
            required
            options={subjects.map((i) => ({ value: i, label: i.label }))}
            onChange={(value) => setSubject(value.value)}
            value={subject}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cuántas <strong>PERSONAS</strong> asistirán a la{" "}
          <strong>TUTORÍA</strong>?
        </InputLabel>
        <div style={{ padding: "0px 40px", textAlign: "center" }}>
          <br />
          <Slider
            // onChangeCommitted={(event, value) => setMinutes(value)}
            onChange={(event, value) => {
              //setDataState({ ...dataState, minutes: value })
              switch (value) {
                case 1:
                  setPersons({ value: "una" });
                  break;
                case 2:
                  setPersons({ value: "dos" });
                  break;
                case 3:
                  setPersons({ value: "tres" });
                  break;
                case 4:
                  setPersons({ value: "cuatro" });
                  break;
                case 5:
                  setPersons({ value: "cinco" });
                  break;
                case 6:
                  setPersons({ value: "seis" });
                  break;
                case 7:
                  setPersons({ value: "+seis" });
                  break;
                default:
                  break;
              }
            }}
            defaultValue={1}
            getAriaValueText={(value) => `${value} NOSE`}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            //valueLabelFormat={valueLabelFormat}
            step={1}
            marks
            min={1}
            max={7}
            // scale={(x) => x * 20}
          />
          <span style={{ fontSize: "1.25rem" }}>
            <b>
              {persons.value === "+seis" ? (
                <>Más de seis personas</>
              ) : (
                <>
                  {" "}
                  {persons.value === "una" ? (
                    <>Una persona</>
                  ) : (
                    <>
                      {" "}
                      {persons.value
                        .split("")
                        .map((x, i) => {
                          if (i === 0) return x.toUpperCase();
                          return x;
                        })
                        .join("")}{" "}
                      personas
                    </>
                  )}
                </>
              )}{" "}
            </b>
          </span>
          {/* <CustomReactSelect
            required
            options={[
              { value: "uno", label: "Una" },
              { value: "dos", label: "Dos" },
              { value: "tres", label: "Tres" },
              { value: "cuatro", label: "Cuatro" },
              { value: "cinco", label: "Cinco" },
              { value: "seis", label: "Seis" },
              { value: "+seis", label: "Mas de seis" },
            ]}
            onChange={(value) => setPersons(value)}
            value={persons}
          />*/}
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cuántas <strong>HORAS</strong> serán de <strong>TUTORÍA</strong>?
        </InputLabel>
        <div style={{ padding: "0px 40px", textAlign: "center" }}>
          <br />
          <Slider
            // onChangeCommitted={(event, value) => setMinutes(value)}
            onChange={(event, value) => {
              //setDataState({ ...dataState, minutes: value })
              setHours(value);
            }}
            defaultValue={1}
            getAriaValueText={(value) => `${value} NOSE`}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            //valueLabelFormat={valueLabelFormat}
            step={1}
            marks
            min={1}
            max={9}
            // scale={(x) => x * 20}
          />
          <span style={{ fontSize: "1.25rem" }}>
            <b>
              {hours} {hours > 1 ? <> horas.</> : <>hora.</>}
            </b>
          </span>
          {/* <CustomReactSelect
            required
            options={[
              { value: "uno", label: "Una" },
              { value: "dos", label: "Dos" },
              { value: "tres", label: "Tres" },
              { value: "cuatro", label: "Cuatro" },
              { value: "cinco", label: "Cinco" },
              { value: "seis", label: "Seis" },
              { value: "+seis", label: "Mas de seis" },
            ]}
            onChange={(value) => setPersons(value)}
            value={persons}
          />*/}
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          El pago por hora de la Tutoría será:
        </InputLabel>
        <CurrencyFormat
          value={priceCalculateTutor(cost)}
          displayType={"text"}
          thousandSeparator={true}
          suffix={" COP"}
          className="good_font"
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#fc044c",
          }}
        />
      </div>
      <div className="good_font" style={{ textAlign: "center" }}>
        <br />* En algunas ocasiones el pago neto del servicio podria variar
        hasta <b>3000 COP</b> positiva o negativamente
      </div>
    </div>
  );
};

export default Cotizar;
