import { Button } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import DetailPayment from "views/Pages/public/DetailPayment";
import TeacherRequest from "views/Pages/public/TeacherRequest";
import PaymentStepper from "views/Pages/public/PaymentStepper";
import MaterialStepper from "views/Pages/public/MaterialStepper";
import PaymentFinished from "views/Pages/public/PaymentFinished";
import PaymentStepperTutor from "views/Pages/public/PaymentStepperTutor";
import PartialForm from "views/Pages/public/forms/PartialForm";
import HomeworkForm from "views/Pages/public/forms/HomeworkForm";
import TutorshipForm from "views/Pages/public/forms/TutorshipForm";
import HomeworkStepper from "views/Pages/public/HomeworkStepper";
import MaterialTutorshipStepper from "views/Pages/public/MaterialTutorshipStepper";
import Feedback from "views/Pages/public/Feedback/FeedBack";
import InfoPage from "views/Pages/public/info/InfoPage";
import NextHomework from "views/teacher/NextHomework";
import Form from "views/Pages/public/hiring/Form";
import Test from "views/Pages/public/hiring/Test";

const PublicLayout = () => {
  return (
    <Switch>
      <Route path="/public/payment-finished" component={PaymentFinished} />
      <Route path="/public/payment/:id" component={DetailPayment} />
      <Route path="/public/payment-stepper/:id" component={PaymentStepper} />
      <Route
        path="/public/material-tutorship-stepper/:id"
        component={MaterialTutorshipStepper}
      />
      <Route path="/public/homework-stepper/:id" component={HomeworkStepper} />
      <Route
        path="/public/payment-stepper-tutorship/:id"
        component={PaymentStepperTutor}
      />
      <Route path="/public/homework/:id" component={NextHomework} />
      <Route path="/public/t-r/:id" component={TeacherRequest} />
      <Route path="/public/material-stepper/:id" component={MaterialStepper} />
      <Route path="/public/forms/partial" component={PartialForm} />
      <Route path="/public/forms/homework" component={HomeworkForm} />
      <Route path="/public/forms/tutorship" component={TutorshipForm} />
      <Route path="/public/feedback" component={Feedback} />
      <Route path="/public/Q&A/:type" component={InfoPage} />
      <Route path="/public/hiring/form" component={Form} />
      <Route path="/public/hiring/test/:id" component={Test} />
      <Route path="/public/" component={NotFound} />
    </Switch>
  );
};

const NotFound = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Pagina no encontrada</h1>
      <Button color="primary" variant="contained">
        Ir al inicio
      </Button>
    </div>
  );
};

export default PublicLayout;
