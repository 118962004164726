import { css } from "@emotion/css";
import {
  Button,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FakeLoading } from "./paymentStepper/FakeLoading";
import SingleLoading from "components/Loading/SingleLoading";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import StepMaterial from "./StepMaterial";
import StepRules from "./StepRules";
import TallerService from "services/TallerService";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import StepOne from "./paymentStepperHomework/StepOne";
import StepTwo from "./paymentStepperHomework/StepTwo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 0px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Check", "Guía", "Material"];
}

export default function MaterialTutorshipStepper() {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [stateStepTwo, setStateStepTwoPure] = useState({
    radio1: "",
    radio2: "",
    radio3: "",
    radio2Text: "",
    listTopis: "",
    nameBook: "",
    file1: null,
    file2: null,
  });
  const [textMaterial, setTextMaterial] = useState("");
  const [textMaterial2, setTextMaterial2] = useState("");

  const clearFilesState = () => {
    setStateStepTwoPure({ ...stateStepTwo, file1: null, file2: null });
  };

  const setStateStepTwo = (name, value) => {
    const obj = {};
    obj[name] = value;
    setStateStepTwoPure({ ...stateStepTwo, ...obj });
  };

  //   const handleReset = () => {
  //     setActiveStep(0);
  //   };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTextMaterial = (text) => {
    try {
      const textArray = text.split("\n\n");
      setTextMaterial(textArray[1]);
      setTextMaterial2(textArray[0]);
    } catch (error) {
      console.error(error);
    }
    // if (tutorship.text_material1 || tutorship.text_material2) {
    //   try {
    //     // const textArray = text.split("HTML-SEPARATOR");
    //     setTextMaterial(tutorship.text_material1);
    //     setTextMaterial2(tutorship.text_material2);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
  };

  const getHomework = async () => {
    // setLoading(true);

    //console.log(id);
    const res = await TallerService.findByIdAdmin({ id });
    console.log(res.data);
    if (res?.data?.data) {
      setData(res.data.data);
      //   setLoading(false);
      setActiveStep(0);

      handleTextMaterial(res?.data?.data?.textMaterial);
    } else {
      console.error(res);
    }
  };

  function getStepContent() {
    if (data) {
      switch (activeStep) {
        case 0:
          return <StepOne data={data} notLastMessage={true} />;
        //   return <StepMaterial origen="Tutorship" />;
        // case 1:
        //   return <StepMaterial origen="Homework" />;
        case 1:
          return <StepRules origen="Homework" />;
        case 2:
          return (
            <StepTwo
              data={data}
              textMaterial={textMaterial}
              textMaterial2={textMaterial2}
              setTextMaterial={setTextMaterial}
              setTextMaterial2={setTextMaterial2}
              setState={setStateStepTwo}
              state={stateStepTwo}
              clearFilesState={clearFilesState}
            />
          );
        default:
          return "NO STEP DEFINIED";
      }
    }
  }
  //   if (data && data.url_payment) return <PaymentDone data={data} />;

  useEffect(() => {
    getHomework();
  }, []); //eslint-disable-line

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={css`
          padding: 10px !important;
        `}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <small className="justText">
                <b>{label}</b>
              </small>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <div>
        {activeStep === steps.length ? (
          <div style={{ textAlign: "center", padding: 20 }}>
            <FakeLoading
              ms={1000}
              onLoading={<SingleLoading />}
              onLoad={
                <div>
                  <br />
                  <br />
                  <Typography
                    style={{ color: "#333", fontSize: 20, fontWeight: "bold" }}
                    className="good_font"
                  >
                    <p style={{ color: "#f9004d" }}>¡Ya todo quedó listo!</p>
                    <p style={{ color: "rgb(255, 188, 0" }}>
                      Has sido agendado
                    </p>
                    <br />
                    <div>
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: "green" }}
                      />
                    </div>
                    <br />
                    <small>
                      ¡Te deseamos un excelente día!{" "}
                      <span role="img" aria-label="corazon">
                        ❤️
                      </span>
                      <br />
                      Equipo de Copywrite
                    </small>
                  </Typography>
                  <br /> <br />
                  <br />
                </div>
              }
            />

            <Button
              variant="contained"
              color="primary"
              classes={{ label: "buttonLabel" }}
              onClick={handleBack}
            >
              Regresar
            </Button>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              classes={{ label: "buttonLabel" }}
              onClick={() =>
                window.open(
                  "https://www.copywritecol.com/",
                  "https://www.copywritecol.com/"
                )
              }
              endIcon={<OpenInNewIcon />}
            >
              Agendar algo mas
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent()}
            <div
              style={{
                textAlign: "center",
                marginTop: "50px",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 20,
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                classes={{ label: "buttonLabel" }}
              >
                Atrás
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ label: "buttonLabel" }}
                  onClick={handleNext}
                >
                  Terminar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ label: "buttonLabel" }}
                  onClick={handleNext}
                >
                  Siguiente
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
