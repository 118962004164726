import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Select from "react-select";
import TeacherService from "services/TeacherService";
import Button from "components/CustomButtons/Button.js";
import { InputLabel } from "@material-ui/core";
import { toast } from "react-toastify";
import TutorService from "services/TutorService";

const isAssigned = (tutorship) => {
  //   //console.log(tutorship?.assigned_to);
  return tutorship?.assigned_to;
};

export default function TeacherManual({ tutorship, setTutorship }) {
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [newTeacher, setNewTeacher] = React.useState("");

  useEffect(() => {
    getTeachers();
  }, []); //eslint-disable-line

  const getTeachers = async () => {
    setLoading(true);
    const res = await TeacherService.getAll({ user });
    if (res.message !== "OK") {
      //console.log(res.message);
    }
    setLoading(false);
    setOptions(res.data.map((i) => ({ value: i._id, label: i.names })));
  };

  const save = async () => {
    if (newTeacher) {
      setLoading(true);
      const res = await TutorService.assignTeacherManual({
        token: user.token,
        idTutorship: tutorship._id,
        idTeacher: newTeacher,
      });
      if (res?.data?.message === "OK") {
        toast.success("¡Tutor asignado correctamente!");
        // const link = `${window.location.origin}/teacher/tutorias/${tutorship._id}`;

        // const message = `Hola ${t.names}, te hemos agendado una nueva tutoria en Copywrite®!  INFO: `;
        // await SMSService.sendSMSWhitShort({
        //   user,
        //   message,
        //   phoneNumber: t.phone,
        //   link,
        // });
        setTutorship(res?.data?.data);
      } else {
        toast.error("Ocurrio un error");
      }
      setLoading(false);
    }
  };

  const is = isAssigned(tutorship);

  return (
    <GridContainer>
      <GridItem xs={6} md={6} sm={6} lg={6}>
        <InputLabel>Tutor</InputLabel>
        {options && (
          <Select options={options} onChange={(e) => setNewTeacher(e.value)} />
        )}
      </GridItem>
      <GridItem xs={6} md={6} sm={6} lg={6}>
        <Button
          type="button"
          color={is ? "warning" : "success"}
          loading={loading}
          onClick={save}
        >
          {is ? "Reasignar" : "Asignar"}
        </Button>
        {is && (
          <>
            <br />
            <span style={{ color: "#eed202", fontWeight: "bold" }}>
              ¡Este parcial ya tiene tutor asignado!
            </span>
          </>
        )}
      </GridItem>
    </GridContainer>
  );
}
