import React from "react";
import "../../../assets/css/styles.css";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: "100%",
//         padding: "20px 20px",
//     },
//     backButton: {
//         marginRight: theme.spacing(1),
//     },
//     instructions: {
//         marginTop: theme.spacing(1),
//         marginBottom: theme.spacing(1),
//     },
// }));

const PaymentFinished = () => {
  // const classes = useStyles();

  // const history = useHistory();

  return (
    <div className="payfinished">
      <h1>Pago Recibido</h1>
      <div className="payfinished--img">
        <img src={"/icon.png"} alt="logo-cw" />
      </div>
      <h4 style={{ textAlign: "center" }}>
        Estamos validando tu pago, nos comunicaremos contigo tan pronto sea
        confirmado.
      </h4>
      <div className="payfinished--back">
        <a href="https://copywritecol.com">Regresar</a>
      </div>
    </div>
    // <div className={classes.root} style={{ textAlign: "center" }}>
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <h1>Pago recibido</h1>
    //     <div style={{ textAlign: "center", paddingTop: 10 }}>
    //         <img src={"/icon.png"} style={{ width: 70 }} alt="" />
    //     </div>
    //     <br />
    //     <br />
    //     <div style={{ padding: 20, fontSize: 18, color: "green", fontWeight: "bold" }}>
    //         <span>Estamos validando tu pago, nos comunicaremos contigo tan pronto sea confirmado.</span>
    //     </div>
    // </div>
  );
};

export default PaymentFinished;
