import SingleLoading from "components/Loading/SingleLoading";
import useDashboard from "hooks/useDashboard";
import React from "react";
import { useHistory } from "react-router-dom";
import "../../assets/css/styles.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Pay_date } from "functions/Pay_date";
import moment from "moment";
import LaunchIcon from "@material-ui/icons/Launch";

const getMonth = (month) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return months[month];
};

const DashboardTeacher = () => {
  const { user, loading } = useDashboard();

  const history = useHistory();

  if (loading) return <SingleLoading />;

  const month = new Date().getMonth();

  return (
    <div className="container--dashboard">
      <h3 style={{ lineHeight: "40px", color: "#ffbc01" }} className="title">
        <span style={{ color: "#ffbc01" }}>Bienvenido</span>
        <br /> {user.names}
      </h3>

      <h3 className="sub-tittle">Solicitudes</h3>

      <div className="dashboard--items">
        <div
          className="item1"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/parciales")}
        >
          <div className="mobile-item">
            <h3>Parciales</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item2"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/talleres")}
        >
          <div className="mobile-item">
            <h3>Talleres</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item3"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/tutorias")}
        >
          <div className="mobile-item">
            <h3>Tutorias</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
      </div>
      <h3 className="sub-tittle">Cobros</h3>
      <div className="dashboard--items">
        <div
          className="item1"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/create-payments")}
        >
          <div className="mobile-item">
            <h3>Cobrar</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item2"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/request-payments")}
        >
          <div className="mobile-item">
            <h3>Estado de Solicitudes</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item3"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/payments")}
        >
          <div className="mobile-item">
            <h3>Pagos</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
      </div>
      <h3 className="sub-tittle">Tools</h3>
      <div className="dashboard--items">
        <div
          className="item1"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/cotizador")}
        >
          <div className="mobile-item">
            <h3>Cotizador</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item2"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/teacher/q&a")}
        >
          <div className="mobile-item">
            <h3>Q&A</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
      </div>
      <div
        className="good_font"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          fontSize: 12,
          marginTop: 15,
        }}
      >
        <p>
          <b>Próxima fecha de pago:</b> {Pay_date(month)}
          <br />
          <b>Periodo a facturar:</b> 16 de {getMonth(moment().month() - 1)} a 15
          de {getMonth(moment().month())}
          <br />
          <a
            style={{
              color: "#ff044c",
            }}
            href="https://chat.whatsapp.com/BZLsjuzNBflLS276dwkMSN"
          >
            <LaunchIcon fontSize="small" /> Ingresar a la comunidad de Tutores
          </a>
        </p>
      </div>
    </div>
  );
};

export default DashboardTeacher;
