import React, { useState } from "react";
import swal from "@sweetalert/with-react";
import { useSelector } from "react-redux";
import { IconButton, InputLabel } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import WompiService from "services/WompiService";
import { toast } from "react-toastify";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CardFooter from "components/Card/CardFooter";
import moment from "moment";

const PaymentLinks = () => {
  const user = useSelector((state) => state.user.user);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [singleUse, setSingleUse] = useState(true);
  const [amount, setAmount] = useState();
  const [expiresAt, setExpiresAt] = useState();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState();

  const handleCreateLink = async (e) => {
    e.preventDefault();

    var amount_in_cents = null;
    try {
      if (isNaN(amount) && amount.length > 0) {
        toast.error("Amount invalido");
        return;
      } else {
        amount_in_cents = parseInt(amount, 10);
      }
    } catch (error) {}

    if (amount_in_cents) {
      amount_in_cents = amount_in_cents * 100;
      if (amount_in_cents < 150000) {
        toast.error("El valor minimo es 1500");
      }
    }

    setLoading(true);
    const data = {
      name,
      description,
      single_use: singleUse,
      amount_in_cents,
      expires_at:
        expiresAt === undefined ? null : moment(expiresAt).toISOString(),
    };

    // //console.log(data);
    const res = await WompiService.createPaymentLink({ user, data });
    if (res?.data?.url) {
      const linkToSet = res?.data?.url;
      setLink(linkToSet);
      swal(
        <div>
          <h3 style={{ color: "green" }}>Link creado correctamente!</h3>
          <p>{linkToSet}</p>
          <IconButton onClick={() => copyToClipboard(linkToSet)}>
            <FileCopyIcon fontSize="large" />
          </IconButton>
        </div>
      );
      setName("");
      setDescription("");
      setAmount("");
      setExpiresAt("");
    }
    setLoading(false);
  };

  const copyToClipboard = (text) => {
    swal.close();
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value = text;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
    toast.success("Url copiada");
  };

  return (
    <Card>
      <CardHeader>
        <h3>Wompi</h3>
      </CardHeader>
      <CardBody>
        <form method="post" onSubmit={handleCreateLink}>
          <GridContainer>
            <GridItem>
              <CustomInput
                labelText="Nombre del link de pago..."
                id="name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => setName(e.target.value),
                  value: name,
                  required: true,
                  type: "text",
                }}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                labelText="Descripción..."
                id="description"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => setDescription(e.target.value),
                  value: description,
                  required: true,
                  type: "text",
                }}
              />
            </GridItem>
            <GridItem>
              <InputLabel>¿Unico uso?</InputLabel>
              <input
                type="checkbox"
                checked={singleUse}
                onChange={(e) => setSingleUse(e.target.checked)}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                labelText="Valor en COP..."
                id="amount"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => setAmount(e.target.value),
                  value: amount,
                  type: "number",
                }}
              />
            </GridItem>
            <GridItem>
              <InputLabel>Fecha de expiración</InputLabel>
              <input
                type="datetime-local"
                value={expiresAt}
                onChange={(e) => setExpiresAt(e.target.value)}
              />
            </GridItem>
            <GridItem>
              <Button loading={loading} type="submit" color="primary">
                Crear link
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
      {link && (
        <CardFooter>
          <div>
            Link: <b>{link}</b>
          </div>
        </CardFooter>
      )}
    </Card>
  );
};

export default PaymentLinks;
