import moment from "moment";
import "moment/locale/es";

export function isObject(object) {
  if (
    object === null ||
    object === undefined ||
    Object.keys(object).length === 0
  ) {
    return false;
  }
  return true;
}

export function mapDate(date) {
  if (date === null || date === undefined) {
    return `BAD_DATE(${date})`;
  }
  const dateMoment = moment(date);
  if (dateMoment.isValid())
    return dateMoment.format("dddd DD MMMM YYYY, hh:mm A");
  return date;
}
export function mapDateFormat(date, format) {
  if (date === null || date === undefined) {
    return "BAD_DATE";
  }
  return moment(date).format(format);
}

export function myParseInt(number, defaultReturn) {
  try {
    const n = parseInt(number);
    if (Number.isNaN(n)) return defaultReturn;
    return n;
  } catch (e) {
    console.error("Error (myParseInt): " + e.message);
  }
  return defaultReturn;
}

export function getRowsCount(data) {
  try {
    const rows = data.length;
    if (rows <= 5) {
      return 5;
    } else if (rows <= 10) {
      return 10;
    } else if (rows <= 25) {
      return 25;
    } else if (rows <= 50) {
      return 50;
    }
    return 100;
  } catch (e) {
    console.error("Error (getRowsCount): " + e.message);
  }
  return 5;
}

export function mapStateTeacherRequest(state) {
  switch (state) {
    case "CLOSE":
      return "Rechazado";
    case "REJECTED":
      return "Rechazado";
    case "ACCEPTED":
      return "Asignado";
    case "SENT":
      return "Enviado";
    default:
      return state;
  }
}

export function mapState(state) {
  switch (state) {
    case "CREATED":
      return "Creado";
    case "SYSTEM_OK":
      return "Sistema OK";
    case "ACTIVE":
      return "Activo";
    case "INACTIVE":
      return "Inactivo";
    case "END":
      return "Termino";
    default:
      return "Sin estado";
  }
}

export function partialStateTranslate(state) {
  switch (state) {
    case "PENDING":
      return "Pendiente";
    case "PENDING_PAY":
      return "Pendiente de pago";
    case "ASSIGNED_TEACHER":
      return "Asignado a profesor";
    case "PAYMENT_ACCEPTED":
      return "Pago verificado";
    case "NOT_PAYMENT":
      return "No pagado";
    case "CLIENT_NOT_ACCEPT":
      return "No acepto dinero";
    case "PAYMENT_ACCEPTED_NO_TEACHER":
      return "Pago verificado sin profesor";
    case "PAYMENT_ERROR":
      return "Error en pago";
    case "PAYMENT_DECLINED":
      return "Pago rechazado";
    case "FINISHED":
      return "Finalizado";
    case "READY":
      return "Listo";
    default:
      return state;
  }
}

export function tutorshipStateTranslate(state) {
  switch (state) {
    case "PENDING":
      return "Pendiente";
    case "PENDING_PAY":
      return "Pendiente de pago";
    case "ASSIGNED_TEACHER":
      return "Asignado a profesor";
    case "PAYMENT_ACCEPTED":
      return "Pago verificado";
    case "NOT_PAYMENT":
      return "No pagado";
    case "CLIENT_NOT_ACCEPT":
      return "No acepto dinero";
    case "PAYMENT_ACCEPTED_NO_TEACHER":
      return "Pago verificado sin profesor";
    case "PAYMENT_ERROR":
      return "Error en pago";
    case "PAYMENT_DECLINED":
      return "Pago rechazado";
    default:
      return state;
  }
}

export function partialTypeTranslate(type) {
  switch (type) {
    case "FIRST_ASSIGN":
      return "Normal";
    case "FIRST_PAY":
      return "1ero pago";
    default:
      return type;
  }
}

export const partialsState = [
  "PENDING",
  "CLIENT_NOT_ACCEPT",
  "ASSIGNED_TEACHER",
  "READY",
  "PAYMENT_DECLINED",
  "PAYMENT_ERROR",
  "FINISHED",
  "PENDING_PAY",
  "PAYMENT_ACCEPTED_NO_TEACHER",
];

export function partialState() {
  const obj = {};
  partialsState.forEach((item) => {
    obj[item] = partialStateTranslate(item);
  });
  return obj;
}

export function splitText(text, max) {
  try {
    if (text.length >= max) return `${text.substring(0, max)}...`;
    return text;
  } catch (error) {
    // console.error(error);
  }
  return text;
}

export const paymentsState = ["ASKED", "APPROVED", "DENIED", "PAID"];

export function paymentsStateTranslate(state) {
  switch (state) {
    case "ASKED":
      return "Solicitado";
    case "APPROVED":
      return "Aprobado";
    case "DENIED":
      return "Rechazado";
    case "PAID":
      return "Pagado";

    default:
      return state;
  }
}
