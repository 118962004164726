import axios from "axios";
const { URL_API } = require("service/config");

export default class FeedbackService {
  static async createFeedback({ id, object, results }) {
    const config = {
      method: "post",
      url: URL_API + "feedback",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ results }),
      // data: JSON.stringify({ id, object, results }),
    };
    return await axios(config);
  }

  static async getAllFeedback() {
    const config = {
      method: "get",
      url: URL_API + "feedback",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(config);
  }
}
