import { CircularProgress } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";

const SingleLoading = ({ size, justIcon }) => {
    if (justIcon) {
        return (
            <GridContainer style={{ textAlign: "center" }}>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                    <br></br>
                </GridItem>
                <GridItem xs={12} md={12} sm={12} lg={12}>
                    <CircularProgress size={size} />
                </GridItem>
            </GridContainer>
        );
    }

    return (
        <GridContainer style={{ textAlign: "center" }}>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <br></br>
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <h5>
                    <b>Cargando...</b>
                </h5>
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <br></br>
            </GridItem>
            <GridItem xs={12} md={12} sm={12} lg={12}>
                <CircularProgress size={size} />
            </GridItem>
        </GridContainer>
    );
};

export default SingleLoading;
