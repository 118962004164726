import React, { useEffect, useState } from "react";
import "chart.js/auto";
import SingleLoading from "components/Loading/SingleLoading";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import StatisticsService from "services/StatisticsService";

export default function Graphs3() {
  const [data, setData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [reassons, setReasson] = useState([]);

  const user = useSelector((state) => state.user.user);

  const sortData = () => {
    const s = [];
    const countS = [];
    const r = [];
    const countR = [];
    for (let element of data) {
      if (s.indexOf(element.subject) === -1) {
        s.push(element.subject);
        countS.push({ name: element.subject, count: 1 });
      } else {
        for (const j of countS) {
          if (j.name === element.subject) {
            j.count = ++j.count;
          }
        }
      }
      element.reassonReturn.forEach((element) => {
        if (r.indexOf(element) === -1) {
          r.push(element);
          countR.push({ reasson: element, count: 1 });
        } else {
          for (const j of countR) {
            if (j.reasson === element) {
              j.count = ++j.count;
            }
          }
        }
      });
    }
    setSubjects(
      countS
        ?.sort((a, b) => {
          return b.count - a.count;
        })
        .slice(0, 8)
    );
    setReasson(
      countR
        ?.sort((a, b) => {
          return b.count - a.count;
        })
        .slice(0, 5)
    );
  };

  const getData = async () => {
    const res = await StatisticsService.getReturns({ user });
    console.log(res);
    if (res?.data?.message === "OK") {
      setData(res?.data?.data);
    }
  };

  useEffect(() => {
    sortData();
  }, [data]);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  if (data === null) return <SingleLoading />;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(192 240 255)",
          padding: "1rem",
          borderRadius: "10px",
          margin: "1rem",
        }}
      >
        <h4 style={{ fontWeight: "bold" }} className="good_font">
          Top causas de devolución
        </h4>
        <GridContainer>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
              {reassons.map((i, index) => (
                <li
                  key={index}
                  className="good_font"
                  style={{ fontSize: "15px" }}
                >
                  <b>{index + 1}.</b> {i.reasson} {`->`}{" "}
                  <b style={{ fontWeight: "bold" }}>{i.count}</b>
                </li>
              ))}
            </ul>
          </GridItem>
        </GridContainer>
      </div>
      <div
        style={{
          backgroundColor: "rgb(192 240 255)",
          padding: "1rem",
          borderRadius: "10px",
          margin: "1rem",
        }}
      >
        <h4 style={{ fontWeight: "bold" }} className="good_font">
          Top materias devueltas
        </h4>
        <GridContainer>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <ul
              style={{
                listStyle: "none",
                paddingLeft: "0px",
                fontSize: "15px",
              }}
            >
              {subjects.map((i, index) => (
                <li
                  style={{ fontSize: "15px" }}
                  key={index}
                  className="good_font"
                >
                  <b>{index + 1}.</b> {i.name} {`->`}{" "}
                  <b style={{ fontWeight: "bold" }}>{i.count}</b>
                </li>
              ))}
            </ul>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
