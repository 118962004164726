// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import FunctionsIcon from "@material-ui/icons/Functions";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonIcon from "@material-ui/icons/Person";
import NextPartials from "views/teacher/NextPartials";
import DashboardTeacher from "views/teacher/DashboardTeacher";
import DetailNextPartial from "views/teacher/DetailNextPartial";
import PerfilTeacher from "views/teacher/PerfilTeacher";
import TalleresPage from "views/teacher/TalleresPage";
import NextTutorships from "views/teacher/NextTutorships";
import DetailNextTutorship from "views/teacher/DetailNextTutorship";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BuildIcon from "@material-ui/icons/Build";
import TeacherPayments from "views/teacher/TeacherPayments";
import Payments from "views/teacher/Payments";
import RequestPayments from "views/teacher/RequestPayments";
import Cotizar from "views/teacher/Cotizar";
import QYA from "views/teacher/q&a";
import DetailNextHomework from "views/teacher/DetailNextHomework";

const teacherRoutes = [
  {
    path: "/dashboard",
    name: "Inicio",
    icon: DashboardIcon,
    component: DashboardTeacher,
    layout: "/teacher",
  },
  {
    path: "/parciales/:id",
    component: DetailNextPartial,
    layout: "/teacher",
    internal: true,
  },
  {
    path: "/talleres/:id",
    component: DetailNextHomework,
    layout: "/teacher",
    internal: true,
  },
  {
    path: "/parciales",
    name: "Parciales",
    icon: FunctionsIcon,
    component: NextPartials,
    layout: "/teacher",
  },
  {
    path: "/talleres",
    name: "Talleres",
    icon: DashboardIcon,
    component: TalleresPage,
    layout: "/teacher",
  },
  {
    path: "/tutorias/:id",
    component: DetailNextTutorship,
    layout: "/teacher",
    internal: true,
  },
  {
    path: "/tutorias",
    name: "Tutorias",
    icon: AccessibilityNewIcon,
    component: NextTutorships,
    layout: "/teacher",
  },
  {
    collapse: true,
    name: "Cobros",
    icon: AttachMoneyIcon,
    state: "contabilidadCollapse",
    views: [
      {
        path: "/create-payments",
        name: "Cobrar",
        component: TeacherPayments,
        layout: "/teacher",
      },
      {
        path: "/request-payments",
        name: "Solicitudes",
        component: RequestPayments,
        layout: "/teacher",
      },
      {
        path: "/payments",
        name: "Pagos",
        component: Payments,
        layout: "/teacher",
      },
    ],
  },
  {
    collapse: true,
    name: "Tools",
    icon: BuildIcon,
    state: "toolsCollapse",
    views: [
      {
        path: "/cotizador",
        name: "Cotizador",
        component: Cotizar,
        layout: "/teacher",
      },
      {
        path: "/q&a",
        name: "Q&A",
        component: QYA,
        layout: "/teacher",
      },
    ],
  },
  {
    path: "/perfil",
    name: "Perfil",
    icon: PersonIcon,
    component: PerfilTeacher,
    layout: "/teacher",
  },
];
export default teacherRoutes;
