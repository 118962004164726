import SingleLoading from "components/Loading/SingleLoading";
import React from "react";
import { useParams } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import FeedbackService from "services/FeedbackService";
import { toast } from "react-toastify";

const Feedback = () => {
  const { id, object } = useParams();
  const [results, setResults] = useState({
    q0: 1,
    q1: 1,
    q2: 1,
    q3: 1,
    q4: 1,
    q5: "",
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const next = async () => {
    console.log(results);
    setLoading(true);
    if (step === 2) {
      // const res = await FeedbackService.createFeedback({ id, object, results });
      const res = await FeedbackService.createFeedback({ results });
      if (res.data?.message !== "OK")
        toast.error("Algo salio mal ocn tu solicitud");
    }
    if (step < 3) setStep(step + 1);
    setLoading(false);
  };

  if (loading) return <SingleLoading />;

  return (
    <>
      {step === 0 && (
        <div>
          <div style={{ textAlign: "center", paddingTop: 40 }}>
            <img
              src={"/icon.png"}
              alt=""
              style={{ width: isMobile ? "50%" : 300 }}
            />
          </div>
          <div style={{ textAlign: "center", padding: 40 }}>
            <h1 className={`good_font tittle`}>
              <span className={`good_font black_font`}>
                ¡Queremos ser mejores para ti cada día!
              </span>
            </h1>
          </div>
          <div>
            <p style={{ textAlign: "center" }} className={`good_font text`}>
              <span>
                🔴 Responde las siguientes preguntas y obtén 10% OFF en tu
                próxima compra.
              </span>
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              classes={{ label: "buttonLabel" }}
              onClick={() => setStep(1)}
            >
              Comenzar
            </Button>
          </div>
        </div>
      )}
      {step === 1 && (
        <Step1
          results={results}
          setResults={setResults}
          setStep={setStep}
          step={step}
          next={next}
        />
      )}
      {step === 2 && (
        <Step2
          results={results}
          setResults={setResults}
          next={next}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 3 && (
        <div>
          <div>
            <div style={{ textAlign: "center", paddingTop: 40 }}>
              <img
                src={"/icon.png"}
                alt=""
                style={{ width: isMobile ? "50%" : 300 }}
              />
            </div>
            <div style={{ textAlign: "center", padding: 40 }}>
              <h1 style={{ lineHeight: "35px" }} className={`good_font`}>
                <span className={`good_font black_font tittle`}>
                  🥦 Perfecto, eso es todo. ¡Gracias por tu opinión!
                </span>
                {/* <strong className="black_font">PARCIALES y QUICES</strong> */}
              </h1>
            </div>
            <div>
              <p style={{ textAlign: "center" }} className={`good_font text`}>
                <span>
                  Toma screenshot de esta pantalla para redimir tu descuento
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Feedback;
