import React from "react";
import CurrencyFormat from "react-currency-format";

const FormatMoney = ({
  value,
  fontSize,
  fontColor,
  displayType,
  dolarValue,
  country,
  marginLeft,
  ...otherProps
}) => {
  if (country?.label === "Colombia" || country?.label === "colombia") {
    return (
      <CurrencyFormat
        {...otherProps}
        value={value || 0}
        displayType={displayType || "text"}
        thousandSeparator={true}
        suffix={" COP"}
        className="good_font"
        style={{ width: "100%", fontSize, color: fontColor, marginLeft }}
      />
    );
  }

  return (
    <CurrencyFormat
      {...otherProps}
      value={dolarValue?.toFixed(2) || 0}
      displayType={displayType || "text"}
      thousandSeparator={true}
      suffix={" USD"}
      className="good_font"
      style={{ width: "100%", fontSize, color: fontColor }}
    />
  );
};

export default FormatMoney;
