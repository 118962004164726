import React from "react";

const WhatsappIconWhite = ({ width = 24 }) => (
  <img
    width={width}
    alt="Whatsapp"
    title="Whatsapp"
    src={require("../img/customIcons/whatsapp_icon_white.png")}
  />
);

export default WhatsappIconWhite;
