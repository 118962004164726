import { InputLabel, Slider, TextareaAutosize } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import GoBack from "components/Navigation/GoBack";

const Step2 = ({ results, setResults, next, step, setStep }) => {
  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  return (
    <div>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Feedback Copywrite
      </h3>
      <GoBack back={() => setStep(step - 1)} />
      <div style={{ textAlign: "left", padding: "10px 20px" }}>
        <InputLabel className={`good_font`}>
          <b>
            En una escala de 1 a 10 ¿Qué tan satisfecho quedaste con el servicio
            de Copywrite?
          </b>
        </InputLabel>
        <br />
        <div style={{ padding: "0px 30px", textAlign: "center" }}>
          <Slider
            // onChangeCommitted={(event, value) => setMinutes(value)}
            onChange={(event, value) => setResults({ ...results, q4: value })}
            defaultValue={1}
            getAriaValueText={(value) => `${value} NOSE`}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            //valueLabelFormat={valueLabelFormat}
            step={1}
            marks={marks}
            min={1}
            max={10}
            // scale={(x) => x * 20}
          />
        </div>
        <div>
          <InputLabel className={`good_font`}>
            <b>
              ¿Cuáles son las razones de que elijas comprar en Copywrite en
              lugar de la competencia?
            </b>
          </InputLabel>
          <br />
          <div
            className="text_area_input"
            style={{ padding: "0px 30px", textAlign: "center" }}
          >
            <TextareaAutosize
              onChange={(element) =>
                setResults({ ...results, q5: element.target.value })
              }
              minRows={10}
              style={{ width: "100%" }}
            ></TextareaAutosize>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <br />
          <Button variant="contained" color="primary" onClick={next}>
            <b>Terminar</b>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
