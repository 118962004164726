import {
  Button,
  Divider,
  Grid,
  Switch,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import SimpleModal from "components/Modal/SimpleModal";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import HiringService from "services/HiringService";
import SubjectService from "services/SubjectService";
import StarIcon from "@material-ui/icons/Star";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import TeacherService from "services/TeacherService";
import swal from "sweetalert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { toast } from "react-toastify";
import { WhatsApp } from "@material-ui/icons";
import WhatsappButton from "components/WhatsappButton/WhatsappButton";

const isImage = (string) => {
  switch (string) {
    case "img":
      return true;
    case "jpeg":
      return true;
    case "jpg":
      return true;
    case "png":
      return true;
    default:
      return false;
  }
};

const translateTypes = (type) => {
  return type?.map((t) => {
    switch (t) {
      case "partial":
        return "Parciales";
      case "tutorship":
        return "Tutorias";
      case "homework":
        return "Talleres";
      default:
        break;
    }
  });
};

function ApplicantDetail() {
  const id = useParams("id");
  const [answers, setAnswer] = useState([]);
  const [info, setInfo] = useState({});
  const [subjects, setSubjects] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [list, setList] = useState([]);
  const [tutor, setTutor] = useState({});

  const whatsappMessage = `🍀 Buen día ${info?.names}.\nQueremos saber si hay algún problema con la realización de tu prueba.🤓\n📍 *¡Recuerda que tienes 15 días para realizarla desde la aplicación!*`;

  const getSubjects = async (idSubjects) => {
    const res = await SubjectService.findAll({ user });
    // console.log(res?.data);
    if (res?.data) {
      setSubjects(
        idSubjects.map((e) => {
          return res?.data?.find((d) => d._id === e);
        })
      );
    }
  };

  const getInfo = async () => {
    const info = await HiringService.getTest(id.id);
    if (info?.data?.data) {
      setInfo(info.data.data);
      getSubjects(info.data.data.subjects);
      getAnswers(info.data.data._id);
    }
  };

  const getAnswers = async (id) => {
    const res = await HiringService.getAswers({ id });
    if (res?.data?.data) setAnswer(res.data.data);
  };

  const whatsappMessageHiring = () => {
    const phone = "+" + info.whatsapp;
    const text = `🧢 *¡Hola ${info.names}!*\nQueremos comunicarte que hemos revisado tu prueba de admisión en Copywrite y ha sido satisfactoria.\n*¡Felicitaciones!*\n\nA tu email enviamos la información que necesitas para comenzar a trabajar con nosotros. ✅\n\nEl remitente del correo es\nno-reply@copywritecol.com`;
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, `payment_link_${info._id}`);
  };

  const hire = async () => {
    const data = {
      names: info.names,
      phone: "+" + info.whatsapp,
      email: info.email,
      bankName: info.bank,
      accountType: info.account_type,
      accountNumber: info.account_number,
      idLocation: info.idLocation,
    };

    swal({
      title: "¿Seguro?",
      text: "Estas a punto de contratar a este aspirante como tutor",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const res = await TeacherService.createTeacher({ user, data });
        const colorStatus = await HiringService.updateColor(
          user,
          "#4d5ebd",
          info._id
        );
        if (res?.data?.message !== "OK") {
          swal(
            "Error",
            "Es posible que ya exista un tutor con este correo",
            "error"
          );
          setTutor(res?.data?.data);
          setOpenModal(true);
        } else {
          setTutor(res?.data?.data);
          swal("Ok", "Tutor creado", "success").then(setOpenModal(true));
        }
      } else {
      }
    });
  };

  const dontHire = () => {
    swal({
      title: "¿Seguro?",
      text: "Estas a punto de rechazar la solucitud de este aspirante",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const colorStatus = await HiringService.updateColor(
          user,
          "#e92d2d",
          info._id
        );
        const phone = "+" + info.whatsapp;
        const text = `🧢 *¡Hola ${info.names}!*\nLamentamos informarte que tu prueba de admisión en Copywrite no fue satisfactoria.\n\nTe animamos a mejorar tus habilidades y considerar volver a presentarte en el futuro. 🎈\n\n*Equipo de Copywrite*`;
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
          text
        )}`;
        window.open(url, `payment_link_${info._id}`);
      } else {
      }
    });
  };

  const startTeacher = async (s, value, index) => {
    let localSubjects = subjects;
    localSubjects[index] = { ...localSubjects[index], status: value };
    setSubjects(localSubjects);
  };

  const saveInfo = async () => {
    //asignar al tutor
    for (const subject of subjects) {
      if (subject.status !== "no" && subject.status) {
        const res = await SubjectService.updateSubject({
          user,
          data: { idSubject: subject._id, idTeacher: tutor._id },
          isAddTeacher: true,
        });
        if (res?.data?.message === "OK") {
          if (subject.status !== "si") {
            const res = await SubjectService.startTeacher(
              subject._id,
              tutor._id,
              subject.status,
              user
            );
            if (res?.data?.message === "OK") {
              toast.success("OK");
            }
          } else {
            //No se cambia el estado del tutor
          }
        }
      } else {
        //No se asigna a nada el tutor
      }
    }
    toast.success("Cambios guardados");
    setOpenModal(false);
  };
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ width: "100%" }}>Informacíon básica</h1>
      <Grid container>
        <Grid item xs={3}>
          Nombre: <b>{info.names}</b>
        </Grid>
        <Grid item xs={3}>
          Correo: <b>{info.email}</b>
        </Grid>
        <Grid item xs={3}>
          Telefono: <b>+{info.whatsapp}</b>
        </Grid>
        <Grid item xs={3}>
          Pais: <b>{info.country}</b>
        </Grid>
      </Grid>
      <br />
      <Divider style={{ width: "100%" }} />
      <br />
      <h1 style={{ width: "100%" }}>Informacíon academica</h1>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿Cuál es tu nivel de estudio?: <b>{info.levelStudy}</b>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿Qué Pre-grado estudias?: <b>{info.preGrad}</b>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿En qué Universidad estudiaste?: <b>{info.studies}</b>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿Qué experiencia tienes realizando Parciales, Tutorías o Talleres?:{" "}
          <b>{info.exp}</b>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿En que asignaturas puedes recibir solicitudes?:{" "}
          <b>{subjects.map((el) => el.label).join(", ")}</b>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          Certificado de notas:{" "}
          <a
            href={info.certificate}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "blue" }}
          >
            Ver Certificado
          </a>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿Qué tipo de solicitudes puedes atender?:{" "}
          <b>
            {translateTypes(info.types)?.map((t) => {
              return <>{t} </>;
            })}
          </b>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "start" }}>
          ¿A través de que medio te enteraste de la oferta?:{" "}
          <b>{info.options}</b>
        </Grid>
      </Grid>
      <br />
      <Divider style={{ widt: "100%" }} />
      <br />
      <h1 style={{ width: "100%" }}>Respuestas de la prueba admisión</h1>
      {answers.length > 0 ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {console.log(subjects, answers)}
            {subjects.map((e, i) => {
              const results = answers.find(
                (element) => element.id_subject === e._id
              );
              if (results) {
                return (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h4>
                        <b>Asignatura {subjects[i]?.label}</b>
                      </h4>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <div style={{ width: "100%", padding: "0 1rem" }}>
                        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Prueba
                        </h4>
                        {/* <a target="_blank" href={subjects[i]?.test}>
                        <img
                          style={{ width: "100%", objectFit: "contain" }}
                          src={subjects[i]?.test}
                          alt=""
                        />
                      </a> */}
                        <div
                          style={{
                            width: !isImage(
                              subjects[i]?.test?.split(".")[
                                subjects[i]?.test.split(".").length - 1
                              ]
                            )
                              ? "40%"
                              : "100%",
                            margin: !isImage(
                              subjects[i]?.test?.split(".")[
                                subjects[i]?.test.split(".").length - 1
                              ]
                            )
                              ? "3rem 30%"
                              : "0",
                          }}
                          className="imgModalFile"
                        >
                          {subjects[i]?.test &&
                            (!isImage(
                              subjects[i]?.test?.split(".")[
                                subjects[i]?.test.split(".").length - 1
                              ]
                            ) ? (
                              <a href={subjects[i]?.test} target="_blank">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    backgroundColor: "#ff044c",
                                    padding: "0.5rem",
                                    width: "100%",
                                    color: "white",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 3fr",
                                  }}
                                >
                                  <PictureAsPdfIcon />
                                  <b>Ver archivo</b>
                                </div>
                              </a>
                            ) : (
                              <a href={subjects[i]?.test} target="_blank">
                                <img
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={subjects[i]?.test}
                                  alt=""
                                />
                              </a>
                            ))}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                        Solución
                      </h4>
                      <div style={{ width: "100%", padding: "0 1rem" }}>
                        <div
                          style={{
                            width: !isImage(
                              subjects[i]?.solution?.split(".")[
                                subjects[i]?.solution.split(".").length - 1
                              ]
                            )
                              ? "40%"
                              : "100%",
                            margin: !isImage(
                              subjects[i]?.solution?.split(".")[
                                subjects[i]?.solution.split(".").length - 1
                              ]
                            )
                              ? "3rem 30%"
                              : "0",
                          }}
                          className="imgModalFile"
                        >
                          {subjects[i]?.solution &&
                            (!isImage(
                              subjects[i]?.solution?.split(".")[
                                subjects[i]?.solution?.split(".").length - 1
                              ]
                            ) ? (
                              <a href={subjects[i]?.solution} target="_blank">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    backgroundColor: "#ff044c",
                                    padding: "0.5rem",
                                    width: "100%",
                                    color: "white",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 3fr",
                                  }}
                                >
                                  <PictureAsPdfIcon />
                                  <b>Ver arvhivo</b>
                                </div>
                              </a>
                            ) : (
                              <a href={subjects[i]?.solution} target="_blank">
                                <img
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={subjects[i]?.solution}
                                  alt=""
                                />
                              </a>
                            ))}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <div style={{ width: "100%", padding: "0 1rem" }}>
                        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                          Respuesta del aspirante
                        </h4>
                        <div
                          style={{
                            width: !isImage(
                              results.answer?.split(".")[
                                results.answer.split(".").length - 1
                              ]
                            )
                              ? "40%"
                              : "100%",
                            margin: !isImage(
                              results.answer?.split(".")[
                                results.answer.split(".").length - 1
                              ]
                            )
                              ? "3rem 30%"
                              : "0",
                          }}
                          className="imgModalFile"
                        >
                          {results.answer &&
                            (!isImage(
                              results.answer?.split(".")[
                                results.answer?.split(".").length - 1
                              ]
                            ) ? (
                              <a href={results.answer} target="_blank">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    backgroundColor: "#ff044c",
                                    padding: "0.5rem",
                                    width: "100%",
                                    color: "white",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 3fr",
                                  }}
                                >
                                  <PictureAsPdfIcon />
                                  <b>Ver archivo</b>
                                </div>
                              </a>
                            ) : (
                              <a href={results.answer} target="_blank">
                                <img
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={results.answer}
                                  alt=""
                                />
                              </a>
                            ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                );
              } else {
                return <div></div>;
              }
            })}
          </Grid>
        </Grid>
      ) : (
        <h3>No hay respuestas por parte de este aspirante</h3>
      )}
      <Grid container>
        <Grid item xs={4}>
          <div
            style={{
              width: "100%",
              textAlign: "end",
            }}
          >
            <WhatsappButton
              number={"+" + info.whatsapp}
              text={whatsappMessage}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              width: "100%",
              textAlign: "start",
            }}
          >
            <Grid container>
              <Grid item xs={3}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "end",
                    paddingRight: "1rem",
                  }}
                >
                  <Button
                    classes={{ label: "buttonLabel" }}
                    color="primary"
                    variant="contained"
                    onClick={hire}
                  >
                    Contratar
                  </Button>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "start",
                    paddingLeft: "1rem",
                  }}
                >
                  <Button
                    classes={{ label: "buttonLabel" }}
                    color="primary"
                    variant="contained"
                    onClick={dontHire}
                  >
                    No Contratar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <SimpleModal
        open={openModal}
        setOpen={setOpenModal}
        widthModal={"45%"}
        heightModal={"75%"}
      >
        <div style={{ padding: "1rem" }}>
          <div>
            Por favor asigna al tutor a las materias que fue aprovado con su
            respectivo estado.
          </div>
          <br />
          {subjects.map((s, index) => {
            return (
              <Grid
                key={index}
                container
                style={{
                  width: "100%",
                  borderRadius: "5px solid black",
                  padding: "1rem",
                }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div style={{ fontWeight: "bold" }}>{s?.label}</div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label labelId="demo-simple-select-label">Estado</label>
                  <Select
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      startTeacher(s, e.target.value, index);
                    }}
                    defaultValue={"no"}
                  >
                    <MenuItem value={"star"}>Estrella</MenuItem>
                    <MenuItem value={"si"}>Respaldo</MenuItem>
                    <MenuItem value={"fire"}>Emergencia</MenuItem>
                    <MenuItem value={"no"}>No asignar</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <br />
                </Grid>
              </Grid>
            );
          })}
          <br />
          <br />
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                style={{ color: "white", backgroundColor: "#4caf50" }}
                onClick={saveInfo}
                classes={{ label: "buttonLabel" }}
              >
                Guardar cambios
              </Button>
            </Grid>
            <Grid
              style={{ textAling: "end" }}
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              <IconButton onClick={whatsappMessageHiring}>
                <WhatsApp />
                <div style={{ fontSize: "0.8rem", color: "black" }}>
                  {"  "}Notificar por Whatsapp
                </div>
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </SimpleModal>
    </div>
  );
}

export default ApplicantDetail;
