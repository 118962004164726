import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { mapDate } from "functions/comun";
import { mapStateTeacherRequest } from "functions/comun";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import StarIcon from "@material-ui/icons/Star";
import TutorService from "services/TutorService";
import WhatsappIconYellow from "assets/customIcons/WhatsappIconYellow";

export default function TeacherRequest({ tutorship }) {
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [teacherRequests, setTeacherRequests] = useState([]);

  const getTeacherRequests = async () => {
    const idTutorship = tutorship._id;
    const token = user.token;

    setLoading(true);

    const res = await TutorService.getTeacherRequestByTutorship({
      token,
      idTutorship,
    });
    if (res?.data?.data) {
      setTeacherRequests(res.data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeacherRequests();
  }, []); // eslint-disable-line

  const openWhatsApp = (rowData) => {
    //console.log(rowData?.db_state);
    const { teacher } = rowData;
    if (rowData?.db_state === "ACCEPTED") {
      const text = `🚀 ¡Hola ${
        teacher.names
      }!, aceptaste esta tutoría en *Copywrite App*, se confirmó y serás agendado\n\n*Materia:* ${
        tutorship.subject
      }.\n*Fecha:* ${mapDate(
        tutorship.event_date
      )}.\n*Duración de la tutoría:* ${
        tutorship.event_time
      } horas. 🚀\n\n🌀¿De acuerdo?`;

      const url = `https://api.whatsapp.com/send?phone=${
        teacher.phone
      }&text=${encodeURIComponent(text)}`;
      window.open(url, teacher._id);
    } else {
      const text = `🚀 ¡Hola ${
        teacher.names
      }!, tenemos esta *Tutoría* en *Copywrite App*.\n\n*Materia:* ${
        tutorship.subject
      }.\n*Fecha:* ${mapDate(
        tutorship.event_date
      )}.\n*Duración de la tutoría:* ${
        tutorship.event_time
      } horas.\n*Material de Preparación:* ${
        window.location.origin
      }/teacher/tutorias/${tutorship._id}\n\n🌀¿Puedes tomarlo?`;

      const url = `https://api.whatsapp.com/send?phone=${
        teacher.phone
      }&text=${encodeURIComponent(text)}`;
      window.open(url, teacher._id);
    }
  };

  const openWhatsAppOff = (rowData) => {
    //console.log(rowData?.db_state);
    const { teacher } = rowData;
    if (rowData?.db_state === "ACCEPTED") {
      const text = `🚀 ¡Hola ${
        teacher.names
      }!, aceptaste esta tutoría en *Copywrite App*, se confirmó y serás agendado\n\n*Materia:* ${
        tutorship.subject
      }.\n*Fecha:* ${mapDate(
        tutorship.event_date
      )}.\n*Duración de la tutoría:* ${
        tutorship.event_time
      } horas. 🚀\n\n🌀¿De acuerdo?`;

      const url = `https://api.whatsapp.com/send?phone=${
        teacher.phone
      }&text=${encodeURIComponent(text)}`;
      window.open(url, teacher._id);
    } else {
      const text = `👋 ¡Hola ${
        teacher.names
      }!, tenemos esta *Tutoría en Oferta* en *Copywrite App.*\n\n*Materia:* Probabilidad y Estadística 2 (Con R ó Excel).\n*Fecha:* ${mapDate(
        tutorship.event_date
      )} .\n*Duración de la tutoría:* ${
        tutorship.event_time
      } horas.\n*Material de Preparación:* ${
        window.location.origin
      }/teacher/tutorias/${
        tutorship._id
      }.\n\n✅ Por ser *Tutoría de Oferta se pagan 5000 COP/Hora adicionales*\n\n🎈 ¿Puedes tomarla?`;

      const url = `https://api.whatsapp.com/send?phone=${
        teacher.phone
      }&text=${encodeURIComponent(text)}`;
      window.open(url, teacher._id);
    }
  };

  // //console.log(JSON.stringify(teacherRequests));

  return (
    <>
      <MaterialTable
        title={"Solicitudes a tutores"}
        columns={[
          { title: "Tutor", field: "teacher.names" },
          { title: "Fecha", field: "date" },
          { title: "Fecha cerrado", field: "end_date" },

          { title: "Estado", field: "state" },
          { title: "Estrella", field: "star" },
        ]}
        key={teacherRequests.length}
        data={teacherRequests.map((item) => ({
          ...item,
          end_date:
            item.state === "REJECTED"
              ? mapDate(item.close_date)
              : mapDate(item.date_accepted),
          date: mapDate(item.created_at),
          state: mapStateTeacherRequest(item.state),
          db_state: item.state,
          star: item?.teacher?.isStarInThisSubject ? (
            <StarIcon
              style={{
                color: "green",
              }}
              fontSize="small"
            />
          ) : null,
        }))}
        localization={{
          toolbar: {
            searchPlaceholder: "Buscar",
          },
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: getTeacherRequests,
          },

          (rowData) => ({
            icon: () => <WhatsappIcon />,
            tooltip: "WhatsApp",
            isFreeAction: false,
            onClick: () => openWhatsApp(rowData),
          }),

          (rowData) => ({
            icon: () => <WhatsappIconYellow />,
            tooltip: "WhatsApp",
            isFreeAction: false,
            onClick: () => openWhatsAppOff(rowData),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          //filtering: true,
          grouping: true,
          sorting: true,
        }}
        isLoading={loading}
      />
      <button
        id={`handle_reload_teacher_requests_tutorship_${tutorship._id}`}
        style={{ display: "none" }}
        type="button"
        onClick={() => getTeacherRequests()}
      >
        BUTTON HIDE
      </button>
    </>
  );
}
