import { IconButton, TextareaAutosize } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import "./StepTwo.css";
import DeleteIcon from "@material-ui/icons/Delete";
import PartialService from "services/PartialService";
import SingleLoading from "components/Loading/SingleLoading";
import { toast } from "react-toastify";
import StepTwoModal from "./StepTwoModal";

const MAX_FILES_PER_PARTIAL = 5;
const MB = 40;
const MAX_FILE_SIZE_PARTIAL_MATERIAL = 1048576 * MB; // 40MB

const validateInitModal = (text) => {
  try {
    if (text.length > 0) return false;
  } catch (error) {}
  return true;
};

function StepTwo({
  data,
  setTextMaterial,
  textMaterial,
  setTextMaterial2,
  textMaterial2,
  state,
  setState,
  clearFilesState,
}) {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(validateInitModal(textMaterial2));

  const getMaterials = async () => {
    if (data?._id) {
      setLoading(true);
      const res = await PartialService.getMaterials({ id: data?._id });
      if (res?.data?.data) setMaterials(res?.data?.data);
      setLoading(false);
    }
  };

  const uploadMaterial = async (file) => {
    //console.log(file);
    if (file && data?._id && file?.size > 0) {
      if (file.size <= MAX_FILE_SIZE_PARTIAL_MATERIAL) {
        setLoading(true);
        // return;
        const res = await PartialService.uploadMaterial({
          idPartial: data?._id,
          file,
        });
        if (res?.data?.message) {
          toast.success("Archivo cargado correctamente");
          getMaterials();
        } else {
          toast.error("Ha ocurrido un error");
        }
        setLoading(false);
      } else {
        toast.error(`El tamaño maximo por archivo es de ${MB} MB`);
      }
    } else {
      toast.error(
        "Archivo no encontrado, intenta seleccionarlo desde tu galeria"
      );
    }
  };

  const deleteMaterial = async (id) => {
    if (id) {
      setLoading(true);
      const res = await PartialService.deleteMaterial({ id });
      if (res?.data?.message) {
        toast.success("Archivo borrado correctamente");
        getMaterials();
      } else {
        toast.error("Ha ocurrido un error");
      }
      setLoading(false);
    }
  };

  const updateTextMaterial = (e) => {
    updateTextMaterialInDB(textMaterial2, e.target.value);
  };

  const handleSetInternalTextMaterial = (txt) => {
    setTextMaterial2(txt);
    updateTextMaterialInDB(txt, textMaterial);
  };

  const updateTextMaterialInDB = (t1, t2) => {
    if (data?._id) {
      const text = `${t1}\n${t2}`;
      PartialService.updateTextMaterial({
        text,
        idPartial: data?._id,
      });
    }
  };

  const uploadMultipleFiles = async (e) => {
    // onChange={(e) => uploadMaterial(e.target.files[0])}
    const files = e.target.files;
    setLoading(true);
    for (var file of files) {
      await uploadMaterial(file);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data?._id) getMaterials();
  }, [data?._id]); // eslint-disable-line

  return (
    <GridContainer className="cst2-container">
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        className="cst2-grid"
        style={{ marginBottom: "20px" }}
      >
        <span
          style={{ display: "block", fontSize: 20, marginTop: 20 }}
          className="textBoldYellow"
        >
          Por favor carga el material de preparación
        </span>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <div className="steptwo--subtitle">
          <h4>Archivos importantes de tu parcial</h4>
          <span
            style={{
              fontSize: "1rem",
              color:
                materials.length >= MAX_FILES_PER_PARTIAL ? "red" : "black",
            }}
          >
            <br />
            <strong>
              1) Ejercicios similares a los que saldrán en tu examen
            </strong>{" "}
            (Talleres de clase o Parciales anteriores)
            <br />
            <strong>2) Apuntes de clase </strong>(Tuyos o Diapositivas del
            profesor)
          </span>
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <div className="steptwo--btn" style={{ marginTop: "85px" }}>
          <div>
            <h4>Seleccionar Archivo</h4>
            <input
              multiple
              type="file"
              className="custom-file-input"
              onChange={uploadMultipleFiles}
              disabled={loading || materials.length >= MAX_FILES_PER_PARTIAL}
            />
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} className="cst2-grid">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Archivo</th>
              <th>Peso</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <SingleLoading size={20} />
                </td>
              </tr>
            ) : (
              materials.map((i) => (
                <tr key={i._id}>
                  <td>
                    <a href={i.url} target="_blank" rel="noopener noreferrer">
                      {i.name}
                    </a>
                  </td>
                  <td>{i.size} MB</td>
                  <td>
                    <IconButton
                      size="small"
                      onClick={() => deleteMaterial(i._id)}
                    >
                      <DeleteIcon style={{ color: "#dc3545" }} />
                    </IconButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} className="cst2-grid">
        <br />
        <TextareaAutosize
          readOnly
          style={{
            width: "100%",
            borderRadius: 10,
            textAlign: "justify",
            border: ".5px solid #2b2527",
            padding: 13,
            backgroundColor: "lightgray",
          }}
          aria-label="minimum height"
          minRows={2}
          maxRows={8}
          value={textMaterial2}
          onClick={() => setOpenModal(true)}
        />
        <TextareaAutosize
          style={{
            width: "100%",
            borderRadius: 10,
            textAlign: "justify",
            border: ".5px solid #2b2527",
            padding: 13,
          }}
          aria-label="minimum height"
          minRows={4}
          maxRows={8}
          placeholder="Escribe alguna información adicional"
          value={textMaterial}
          onChange={(e) => setTextMaterial(e.target.value)}
          onBlur={updateTextMaterial}
        />
        <br />
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <br />
      </GridItem>
      <StepTwoModal
        open={openModal}
        setOpen={setOpenModal}
        state={state}
        setState={setState}
        setInternalTextMaterial={handleSetInternalTextMaterial}
        uploadMaterial={uploadMaterial}
        clearFilesState={clearFilesState}
      />
    </GridContainer>
  );
}

export default StepTwo;
