import GlobalService from "./GlobalService";
const { URL_API } = require("service/config");

export default class PartialService {
  static async findAll({ query, user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "partial" + query,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async findAllMarketplace({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}partial/find-marketplace`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async findPagination({ user, query }) {
    const { page, pageSize } = query;

    // //console.log(JSON.stringify(query));

    let url = `${URL_API}partial/pagination?pageNumber=${
      page + 1
    }&perPage=${pageSize}`;

    const colorfilter = PartialService.searchFilter("color", query);
    const statefilter = PartialService.searchFilter("state", query);
    const clientfilter = PartialService.searchFilter("client", query);
    const phoneFilter = PartialService.searchFilter("phone", query);
    /*
    //console.log("colorfilter:", colorfilter);
    //console.log("statefilter:", statefilter);
    //console.log("clientfilter:", clientfilter);
    */

    if (colorfilter) url = url + "&color=" + encodeURIComponent(colorfilter);
    if (statefilter) url = url + "&state=" + statefilter;
    if (clientfilter) url = url + "&client=" + clientfilter;
    if (phoneFilter) url = url + "&phone=" + phoneFilter;

    //console.log("url:", url);

    return GlobalService.sentRequest({
      method: "get",
      url,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async findByIdPublic({ idPartial }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "partial/" + idPartial,
      headers: {},
    });
  }

  static async findByIdAdmin({ idPartial }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "partial/" + idPartial,
      headers: {},
    });
  }

  static async findByIdTeacherDetail({ idPartial, user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "partial/teacher-detail/" + idPartial,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async findTeacherById({ idTeacher, token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: `${URL_API}teacher/find/idTeacher/${idTeacher}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async getTeacherRequestByPartial({ token, idPartial }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `partial/teacher-request/partial-id/${idPartial}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async sendTeacherRequest({ user, data }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + "partial/assign-teacher",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static async acceptPartialTeacher({ user, data }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "partial/accept-partial",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static async rejectPartialTeacher({ user, data }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "partial/reject-partial",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static async findByTeacher({ user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "partial/find-by-teacher",
      headers: {
        Authorization: user.token,
      },
    });
  }
  static async deletePartial({ token, id }) {
    return GlobalService.sentRequest({
      method: "delete",
      url: URL_API + `partial/delete-partial/${id}`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async getMaterials({ id }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `partial/client/find-material-by-partial/${id}`,
      headers: {},
    });
  }

  static async getAdminMaterials({ idPartial, user }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `partial/admin/find-material-by-partial/${idPartial}`,
      headers: {
        Authorization: user.token,
      },
    });
  }

  static async uploadMaterial({ file, idPartial }) {
    //console.log("uploadMaterial");
    var FormData = require("form-data");

    //console.log("FormData:", FormData);

    var data = new FormData();

    //console.log("data:", data);

    data.append("file", file);
    data.append("idPartial", idPartial);

    //console.log("data:", data);

    var config = {
      method: "post",
      url: `${URL_API}partial/client/upload-material`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async deleteMaterial({ id }) {
    return GlobalService.sentRequest({
      method: "delete",
      url: URL_API + `partial/client/delete-material/${id}`,
      headers: {},
    });
  }

  static async updateCost({ token, newCost, idPartial }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/update-cost`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        newCost,
        idPartial,
      }),
    });
  }

  static async updateSubject({ token, idNewSubject, idPartial }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/update-subject`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idNewSubject,
        idPartial,
      }),
    });
  }

  static async updateTextMaterial({ text, idPartial }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `partial/client/update-text-material-partial`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPartial,
        text,
      }),
    });
  }

  static searchFilter(nameParam, query) {
    try {
      if (query?.filters) {
        for (const filter of query?.filters) {
          if (filter?.column?.field === nameParam) {
            if (filter?.value) {
              if (Array.isArray(filter?.value)) {
                return filter?.value.join(",");
              }
              return filter?.value;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static assignTeacherManual({ token, idPartial, idTeacher }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/assign-teacher-manually`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPartial,
        idTeacher,
      }),
    });
  }

  static addToSalesExcel({ user, idPartial, debtToTeacher, uSelected }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/add-to-sales-excel`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPartial,
        debtToTeacher,
        uSelected: uSelected.label,
        adminUser: user.names,
      }),
    });
  }

  static sendTutorRequests({ token, idPartial }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/send-teacher-requests`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPartial,
      }),
    });
  }

  static findPartialsForPayment({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `partial/payment/find-partials-for-payment`,
      headers: {
        Authorization: token,
      },
    });
  }

  static sendPayment({ token, typeObject, idObject, amount, comment }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `payment`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        typeObject,
        idObject,
        amount,
        comment,
      }),
    });
  }

  static async editValue({ idPartial, newValue, user }) {
    return GlobalService.sentRequest({
      method: "put",
      url: `${URL_API}partial`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idPartial,
        newValue,
      }),
    });
  }

  static async savePayment({ idPartial, file, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file.item(0));
    data.append("idPartial", idPartial);
    var config = {
      method: "post",
      url: `${URL_API}partial/save-payment`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async savePayment2({ file, idPartial, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file);
    data.append("idPartial", idPartial);
    var config = {
      method: "post",
      url: `${URL_API}partial/save-payment2`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async updateDate({ token, event_date, event_time, _id }) {
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/update-date`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        event_date,
        _id,
        event_time,
      }),
    });
  }

  static async updateUniversity({ token, university, _id }){
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `partial/update-university`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        _id,
        university
      }),
    });
  }

  static async addReturn({ user, textReturn, idPartial, date }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `partial/add-return`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        textReturn,
        idPartial,
        date,
      }),
    });
  }

  static async addCalendar({ user, idPartial }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `partial/add-calendar`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: { idPartial },
    });
  }

  static async updateName({ user, idPartial, name }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `partial/update-name`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: { idPartial, name },
    });
  }
}
