import { IconButton } from "@material-ui/core";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import React from "react";

export default function ({ number, text = "" }) {
  const openWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(
      text
    )}`;
    window.open(url, text.substring(0, 10));
  };

  return (
    <>
      <IconButton onClick={() => openWhatsApp()}>
        <WhatsappIcon />
      </IconButton>
    </>
  );
}
