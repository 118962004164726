import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import swal from "sweetalert";
import Button from "@material-ui/core/Button";

import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    height: 550,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const fontFamily = "Poppins, sans-serif";

export default function StepTwoModal({
  open,
  setOpen,
  state,
  setState,
  setInternalTextMaterial,
  uploadMaterial,
  clearFilesState,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const handleClose = () => setOpen(false);

  console.log(state);

  const handleNext = async () => {
    if (step === 0) setStep(1);
    if (step === 1) {
      setLoading(true);
      const {
        radio1,
        radio2,
        radio2Text,
        radio3,
        listTopis,
        nameBook,
        file1,
        file2,
      } = state;
      // {"radio1":"Abierto","radio2":"No","radio3":"Teoria","radio2Text":"","listTopis":"","nameBook":"","file1":null,"file2":null}
      setInternalTextMaterial(
        `Requiere software: ${radio2} - ${radio2Text} \nTipo tutoría: ${radio3} \nLista temas: ${listTopis} \nlibro guia: ${nameBook}`
      );
      if (file1 && file2) {
        await Promise.all([uploadMaterial(file1), uploadMaterial(file2)]);
      } else {
        if (file1) await uploadMaterial(file1);
        if (file2) await uploadMaterial(file2);
      }
      clearFilesState();
      setStep(0);
      handleClose();
      setLoading(false);
    }
  };

  const btnProps = {
    variant: "contained",
    color: "primary",
    classes: "buttonLabel",
    onClick: handleNext,
    loading: loading,
  };

  return (
    <Modal
      disableEnforceFocus
      disableBackdropClick
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {step === 0 && (
          <InternalStep0
            state={state}
            setState={setState}
            btnProps={btnProps}
          />
        )}
        {step === 1 && (
          <InternalStep1
            state={state}
            setState={setState}
            btnProps={btnProps}
          />
        )}
        <div style={{ textAlign: "center", padding: 10 }}></div>
      </div>
    </Modal>
  );
}

const MyCustomBtn = (props) => {
  if (props?.loading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <div>
          <span style={{ color: "#FFA421", fontWeight: "bold" }}>
            Cargando archivos...
          </span>
        </div>
      </div>
    );
  return (
    <Button style={{ justifySelf: "center", width: "30%" }} {...props}>
      {props.children}
    </Button>
  );
};

const InternalStep0 = ({ state, setState, btnProps }) => {
  const [internalRadio2, setInternalRadio2] = useState("");

  const handleChangeRadio2 = async (e) => {
    // console.log("handleChangeRadio2:", e.target.value);
    setState("radio2", e.target.value);
    setInternalRadio2(e.target.value);
  };

  useEffect(() => {
    const doRadio2 = async () => {
      if (internalRadio2 === "Si") {
        const value = await swal("¿Cúal?", {
          content: "input",
        });
        setState("radio2Text", value);
      }
      if (internalRadio2 === "No") setState("radio2Text", "");
    };

    doRadio2();
  }, [internalRadio2]); // eslint-disable-line

  return (
    <div
      className="good_font"
      style={{
        backgroundColor: "rgba(255,26,59,1)",
        borderRadius: 5,
        fontFamily,
        color: "white",
        display: "grid",
      }}
    >
      <h4
        className="good_font"
        id="simple-modal-title"
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 20,
          paddingTop: 5,
          fontFamily,
        }}
      >
        ¿Cómo será tu Tutoría?
      </h4>
      <div
        className="good_font"
        style={{
          margin: 20,
          padding: 10,
          backgroundColor: "rgba(255,199,26,0.8)",
          color: "white",
          borderRadius: 10,
        }}
      >
        Llena esta info con un <b>X</b> para ayudarte de la mejor manera con tu
        Tutoría
      </div>
      <div
        style={{
          margin: "0px 20px",
          padding: "0px 20px",
          color: "white",
        }}
      >
        <b style={{ color: "#FFBC01", fontSize: 14 }}>
          ¿Necesitas estudiar con algún software?
        </b>
        <br />
        <RadioGroup
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          value={state?.radio2}
          onChange={handleChangeRadio2}
          // onChange={(e) => console.log(e.target)}
        >
          <FormControlLabel
            value="Si"
            control={<Radio color="primary" />}
            label={<span style={{ color: "white" }}>Si, ¿Cual?</span>}
          />
          <FormControlLabel
            value="No"
            control={<Radio color="primary" />}
            label={<span style={{ color: "white" }}>No</span>}
          />
        </RadioGroup>
      </div>
      <div
        style={{
          margin: "0px 20px",
          padding: "0px 20px",
          color: "white",
        }}
      >
        <b style={{ color: "#FFBC01", fontSize: 14 }}>
          ¿Cómo quieres que sea tu tutoría?
        </b>
        <br />
        <RadioGroup
          value={state?.radio3}
          onChange={(e) => setState("radio3", e.target.value)}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            value="Ejercicios"
            control={<Radio color="primary" />}
            label={<span style={{ color: "white" }}>Ejercicios</span>}
          />
          <FormControlLabel
            value="Teoria"
            control={<Radio color="primary" />}
            label={<span style={{ color: "white" }}>Teoría</span>}
          />
          <FormControlLabel
            value="Ejercicios y teoría"
            control={<Radio color="primary" />}
            label={<span style={{ color: "white" }}>Ejercicios y teoría</span>}
          />
        </RadioGroup>
      </div>
      <br />
      <MyCustomBtn
        variant={btnProps.variant}
        color={btnProps.color}
        classes={{ label: btnProps.classes }}
        onClick={btnProps.onClick}
        loading={btnProps.loading}
      >
        {"Siguiente"}
      </MyCustomBtn>
      <br />
    </div>
  );
};

const InternalStep1 = ({ state, setState, btnProps }) => {
  return (
    <div className="good_font" style={{ display: "grid" }}>
      <h4 className="good_font" style={{ color: "#f9004d" }}>
        {/* <span role="img" aria-label="">
          📍
        </span> */}
        <strong>MATERIAL DE PREPARACIÓN</strong>
      </h4>
      <div>
        Para que tu tutoría sea lo mejor posible es necesario que envíes{" "}
        <b>TODA</b> la información
      </div>
      <br />
      <div style={{ padding: 10, fontSize: 12 }}>
        {/* <span role="img" aria-label="">
          🌀
        </span> */}
        <strong>1. Listado de temas que quieres estudiar</strong>
        <TextareaAutosize
          style={{
            width: "100%",
            borderRadius: 10,
            textAlign: "justify",
            border: ".5px solid #2b2527",
            padding: 13,
            fontFamily,
          }}
          aria-label="minimum height"
          minRows={1}
          maxRows={3}
          value={state.listTopis}
          onChange={(e) => setState("listTopis", e.target.value)}
        />
      </div>
      <div style={{ padding: 10, fontSize: 12 }}>
        {/* <span role="img" aria-label="">
          🌀
        </span> */}
        <strong>2. Nombre del libro Guia</strong>
        <TextareaAutosize
          style={{
            width: "100%",
            borderRadius: 10,
            textAlign: "justify",
            border: ".5px solid #2b2527",
            padding: 13,
            fontFamily,
          }}
          aria-label="minimum height"
          minRows={1}
          maxRows={3}
          value={state.nameBook}
          onChange={(e) => setState("nameBook", e.target.value)}
        />
      </div>
      {/* <div style={{ padding: 10, fontSize: 12 }}>
        <span role="img" aria-label="">
          🌀
        </span>
        3. Ejercicios similares a como será el Parcial (Talleres ó Preparcial ó
        Parciales de semestres pasados) <b>con Solución es lo ideal (en PDF)</b>
        <input
          type="file"
          onChange={(e) => setState("file1", e.target.files[0])}
        />
      </div>
      <div style={{ padding: 10, fontSize: 12 }}>
        <span role="img" aria-label="">
          🌀
        </span>
        4. Diapostivas de clase ó Apuntes de clase <b>(en PDF)</b>
        <input
          type="file"
          onChange={(e) => setState("file2", e.target.files[0])}
        />
      </div> */}
      <br />
      <MyCustomBtn
        variant={btnProps.variant}
        color={btnProps.color}
        classes={{ label: btnProps.classes }}
        onClick={btnProps.onClick}
        loading={btnProps.loading}
      >
        {"Guardar"}
      </MyCustomBtn>
    </div>
  );
};
