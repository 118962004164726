import axios from "axios";
const { URL_API } = require("service/config");

export default class SMSService {
  static async sendSMS({ user, message, phoneNumber }) {
    var config = {
      method: "post",
      url: URL_API + "notification/send-sms",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        message,
        phoneNumber,
      }),
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async sendSMSWhitShort({
    user,
    message,
    phoneNumber,
    link,
    idTeacher,
  }) {
    var config = {
      method: "post",
      url: URL_API + "notification/send-sms-with-short",
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        message,
        phoneNumber,
        link,
        idTeacher,
      }),
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async findBySubject({ filter, user }) {
    var config = {
      method: "get",
      url: `${URL_API}sms-template/filter-by-date/${filter.label}`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async sendTimer({ user, message, phoneNumber, timer, inView }) {
    var config = {
      method: "post",
      url: `${URL_API}notification/save-sms-timer`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        message,
        phoneNumber,
        timer,
        inView,
      }),
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async getAllSMS({ user }) {
    var config = {
      method: "get",
      url: `${URL_API}notification`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }

  static async deleteTimer({ _id, user }) {
    var config = {
      method: "delete",
      url: `${URL_API}notification`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ _id }),
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }
}
