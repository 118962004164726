import React from "react";

export function Pay_date(month) {
  const pay_day = [31, [28, 29], 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const year = new Date().getFullYear();

  if (month === 1) {
    if (year % 4 === 0)
      return `${pay_day[month][1]} de ${months[month]} del ${year}`;
    return `${pay_day[month][0]} de ${months[month]} del ${year}`;
  }

  return `${pay_day[month]} de ${months[month]} del ${year}`;
}
