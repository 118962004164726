import React, { useEffect } from "react";

const TestWompi = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.setAttribute("src", "https://checkout.wompi.co/widget.js");

    script.setAttribute("data-render", "button");
    script.setAttribute(
      "data-public-key",
      "pub_test_qwlUHeB02Wk1ogh83JbyP82HuV3Vrvtj"
    );
    script.setAttribute("data-currency", "COP");
    script.setAttribute("data-amount-in-cents", "4950000");
    script.setAttribute("data-reference", "4XMPGKWWPKWQ");
    script.setAttribute(
      "data-signature:integrity",
      "test_integrity_VsSFqKKOzLTgGrIRC5FNoEyQZaf8WPir"
    );

    //console.log(script);
    document.getElementById("acosta").appendChild(script);
    return () => {
      document.getElementById("acosta").removeChild(script);
    };
  }, []);
  return (
    <div id="acosta">
      <h1>TestWompi</h1>
    </div>
  );
};

export default TestWompi;
