import { useEffect, useState } from "react";
import CountriesService from "services/CountryService";

const useCountry = () => {
  const [paises, setPaises] = useState([]);
  const [country, setCountry] = useState("");
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getCountries();
    if (country) {
      getStates();
    }
    if (state) {
      getCities();
    }
  }, [country, state]); // eslint-disable-line

  const getCountries = async () => {
    const res = await CountriesService.getCountries();
    setPaises(res);
  };

  const getStates = async () => {
    const res = await CountriesService.getStates({ country });
    setStates(res);
  };

  const getCities = async () => {
    const res = await CountriesService.getCities({ country, state });
    setCities(res);
  };

  const onClickCountry = (e) => {
    setCountry(e.target.value);
  };

  const onClickState = (e) => {
    setState(e.target.value);
  };

  return {
    paises,
    states,
    cities,
    onClickState,
    onClickCountry,
  };
};

export default useCountry;
