import { Container } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SingleLoading from "components/Loading/SingleLoading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { css } from "@emotion/css";
import { InputLabel } from "@material-ui/core";
import axios from "axios";
import { URL_API } from "service/config";
import PaymentUpload from "./PaymentUpload";
import FormatMoney from "components/Money/FormatMoney";
import { mapDate } from "functions/comun";

const logoNequi = require("../../../assets/img/logo-nequi.png");
const logoDaviplata = require("../../../assets/img/logo-daviplata.png");
const logoMovii = require("../../../assets/img/logo-movii.png");
const logoEfecty = require("../../../assets/img/logo-efecty.png");
const logoPagatodo = require("../../../assets/img/logo-pagatodo.jpg");
const logoBaloto = require("../../../assets/img/logo-baloto.png");
const logoPaypal = require("../../../assets/img/logo-paypal.png");

const paymentMethods = [
  {
    name: "nequi",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 2,
    },
    logo: logoNequi,
  },
  {
    name: "daviplata",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 2,
    },
    logo: logoDaviplata,
  },
  {
    name: "movii",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 2,
    },
    logo: logoMovii,
  },
  {
    name: "efecty",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 1,
    },
    logo: logoEfecty,
  },
  {
    name: "pagatodo",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 1,
    },
    logo: logoPagatodo,
  },
  {
    name: "baloto",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 1,
    },
    logo: logoBaloto,
  },
  {
    name: "paypal",
    paymentInfo: {
      owner: "Julián Orduz",
      ownerDNI: "1072708692",
      phone: "3123916788",
      email: "test@email.com",
      type: 3,
      link: "https://www.paypal.me/julianorduz",
    },
    logo: logoPaypal,
  },
];

const pageCss = css`
  div {
    padding: 5px;
  }
  .tr-title {
    color: #ffbc00;
  }
  .tr-title-success {
    color: green;
  }
  img:hover {
    cursor: pointer;
  }
`;

const DetailPayment = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  useEffect(() => {
    getPartial();
  }, []); //eslint-disable-line

  const getPartial = () => {
    setLoading(true);
    var config = {
      method: "get",
      url: URL_API + "public/partial/get/" + id,
      headers: {},
    };
    axios(config)
      .then((response) => {
        if (response.data.status && response.data.data) {
          setData(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const paymentMethodSelection = async (method) => {
    await setPaymentMethod(method);
    await setLogo(
      paymentMethods.filter((pm) => pm.name === method.name)[0].logo
    );
    setOpen(true);
  };

  if (loading || data === null) return <SingleLoading size={50} />;

  return (
    <Container className={pageCss}>
      <GridContainer style={{ textAlign: "center", fontSize: 20 }}>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <img src={"/icon.png"} style={{ width: "30%", padding: 10 }} alt="" />
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          {data.url_payment ? (
            <p>
              Hola <b>{data.client}</b>! ¡Has pagado exitosamente tu parcial!
            </p>
          ) : (
            <small>
              Hola <b>{data.client}</b>! Confirma la INFO de tu parcial y
              realiza el pago, tan pronto lo hagas, agendaremos al profesor
            </small>
          )}

          <div style={{ textAlign: "left" }}>
            <b>Materia:</b> {data.subject} <br />
            <b>Fecha:</b> {mapDate(data.event_date)}
            <br />
            <b>Valor a pagar:</b>{" "}
            <FormatMoney country={{ label: "Colombia" }} value={data.cost} />
          </div>
          <br />

          {data.url_payment ? (
            <small>
              Estamos verificando tu pago, pronto te contacaremos via SMS o
              WhatsApp
            </small>
          ) : (
            <small>
              Tienes 6 horas para subir el screenshot de la transferencia ó tu
              solicitud caducará.
            </small>
          )}
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          {!data.url_payment && (
            <>
              <h4>
                <b>Métodos de pago</b>
              </h4>
              <small>Haz click sobre alguno para ver la info</small>
              <br />
            </>
          )}
          <GridContainer>
            {!data.url_payment ? (
              paymentMethods.map((pm) => (
                <GridItem
                  key={pm.name}
                  xs={3}
                  md={3}
                  sm={3}
                  lg={3}
                  style={{ alignSelf: "center" }}
                >
                  <img
                    src={pm.logo}
                    alt={`Logo ${pm.name}`}
                    width="100%"
                    style={{ marginLeft: "5px", paddingBottom: 10 }}
                    onClick={() => paymentMethodSelection(pm)}
                  />
                </GridItem>
              ))
            ) : (
              <h4
                style={{
                  fontWeight: "bold",
                  color: "green",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                ¡Pagado!
              </h4>
            )}
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <PaymentUpload
            open={open}
            setOpen={setOpen}
            setLoading={setLoading}
            id={id}
            getPartial={getPartial}
            logo={logo}
            paymentMethod={paymentMethod}
          />
        </GridItem>
        {data.url_payment ? (
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <InputLabel>
              <b>Comprobante del pago</b>
            </InputLabel>
            <br />
            <img
              onClick={() => window.open(data.url_payment, data.url_payment)}
              src={data.url_payment}
              alt=""
              width="50%"
              style={{ borderRadius: 30, cursor: "pointer" }}
            />
          </GridItem>
        ) : null}
        <GridItem xs={12} md={12} sm={12} lg={12}>
          <br />
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default DetailPayment;
