import React, { useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default ({ setValue, componentWidth = "auto", value = null }) => {
  const [selectedDate, handleDateChange] = useState(value);

  useEffect(() => {
    setValue(selectedDate);
  }, [selectedDate]); // eslint-disable-line

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        style={{ width: componentWidth }}
        minutesStep={5}
        disablePast
        value={selectedDate}
        onChange={handleDateChange}
        // allowKeyboardControl
        clearable
      />
    </MuiPickersUtilsProvider>
  );
};
