import Cookies from "js-cookie";
const TOKEN_NAME = "CW_SESSION";

export const setTokenSession = (token, expiration) => {
  Cookies.set(TOKEN_NAME, token, {
    expires: expiration,
  });
};

export const getTokenSession = () => Cookies.get(TOKEN_NAME);

export const removeToken = () => Cookies.remove(TOKEN_NAME);
