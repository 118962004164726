import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import TeacherLayout from "layouts/Teacher";
import { useSelector } from "react-redux";
import PublicLayout from "layouts/PublicLayout";
import TestWompi from "TestWompi";
import TestPaypal from "TestPaypal";

const hist = createBrowserHistory();

const App = () => {
  // state

  // //console.log();

  return (
    <Router history={hist}>
      <InternalApp />
    </Router>
  );
};

const InternalApp = () => {
  const { pathname } = useLocation();

  const isSimpleRedirect = pathname?.includes("teacher/parciales/");

  const { user, isLogged } = useSelector((state) => state.user);
  return isLogged ? (
    <Switch>
      {user.role === "ADMIN" && <Route path="/admin" component={AdminLayout} />}
      {user.role === "TEACHER" && (
        <Route path="/teacher" component={TeacherLayout} />
      )}
      <Route path="/public" component={PublicLayout} />
      <Route path="/test-wompi" component={TestWompi} />
      <Route path="/test-paypal" component={TestPaypal} />
      <Redirect
        from="/"
        to={user.role === "ADMIN" ? "/admin/dashboard" : "/teacher/dashboard"}
      />
    </Switch>
  ) : (
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/public" component={PublicLayout} />
      {isSimpleRedirect ? (
        <Redirect from="/" to={`/auth/login-page?pathname=${pathname}`} />
      ) : (
        <Redirect from="/" to="/auth/login-page" />
      )}
    </Switch>
  );
};

export default App;
