import MaterialTable from "@material-table/core";
import { mapDateFormat } from "functions/comun";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HiringService from "services/HiringService";
import SubjectService from "services/SubjectService";

const myParseDate = (date) => moment(date).format("ddd DD MMMM YYYY, hh:mm A");

function Hiring() {
  const user = useSelector((state) => state.user.user);

  const history = useHistory();
  const [applicants, setApplicants] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const onRowClick = (e, rowData) => {
    history.push("/admin/hiring/" + rowData._id);
  };

  const getStatus = (a) => {
    if (a) return "Si";
    return "No";
  };

  const getSubjects = async () => {
    const data = await SubjectService.findAll({ user });
    if (data?.data) {
      setSubjects(data.data);
    }
  };

  const getInfo = async () => {
    const data = await HiringService.getAll();
    // if (data?.data?.data) setApplicants(data.data.data);
    if (data?.data?.data) {
      const pend = data.data.data.filter((x) => {
        if (!x.color) return x;
      });
      const hire = data.data.data.filter((x) => {
        if (x.color === "#4d5ebd") return x;
      });
      const noHire = data.data.data.filter((x) => {
        if (x.color === "#e92d2d") return x;
      });
      console.log({ pend, hire, noHire });
      setApplicants([
        ...pend.sort((a, b) => {
          const aTime = new Date(a.created_at).getTime();

          const bTime = new Date(b.created_at).getTime();

          if (aTime > bTime) return -1;
          if (aTime < bTime) return 1;
          return 0;
        }),
        ...hire.sort((a, b) => {
          const aTime = new Date(a.created_at).getTime();

          const bTime = new Date(b.created_at).getTime();

          if (aTime > bTime) return -1;
          if (aTime < bTime) return 1;
          return 0;
        }),
        ...noHire.sort((a, b) => {
          const aTime = new Date(a.created_at).getTime();

          const bTime = new Date(b.created_at).getTime();

          if (aTime > bTime) return -1;
          if (aTime < bTime) return 1;
          return 0;
        }),
      ]);
    }
  };

  const getSubjectsText = (array) => {
    const names = [];
    for (let a of array) {
      const s = subjects.find((x) => x._id === a);
      if (s) {
        names.push(s);
      }
    }
    return names
      .map((n) => {
        return n.label;
      })
      .join(", ");
  };

  useEffect(() => {
    getInfo();
    getSubjects();
  }, []);

  const statusTranslate = (status) => {
    if (status === "#4d5ebd") return "Contratado";
    if (status === "#e92d2d") return "No contratado";
    return "Pendiente";
  };

  return (
    <div>
      <MaterialTable
        title={"Aspirantes"}
        columns={[
          { title: "Nombre", field: "names" },
          { title: "Telefono", field: "whatsapp" },
          { title: "Carrera", field: "preGrad" },
          { title: "Asignaturas", field: "subjectText" },
          { title: "Pais", field: "country" },
          { title: "Prueba", field: "test" },
          { title: "Fecha de registro", field: "date" },
          {
            title: "Estado",
            field: "status",
          },
        ]}
        data={applicants.map((a) => {
          return {
            ...a,
            test: getStatus(a.test),
            status: statusTranslate(a.color),
            date: myParseDate(a.created_at),
            subjectText: getSubjectsText(a.subjects),
          };
        })}
        onRowClick={onRowClick}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.color,
          }),
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recargar",
            isFreeAction: true,
            onClick: getInfo,
          },
        ]}
      ></MaterialTable>
    </div>
  );
}

export default Hiring;
