export const SUPER_ADMIN_EMAILS = [
  "edwin@copywritecol.com",
  "julian.orlando.orduz@gmail.com",
  "maicol.copywrite@gmail.com"
];

export const isSuperAdmin = (email) => {
  return SUPER_ADMIN_EMAILS.includes(email);
};


export const adminPhone1 = "+573224120987";