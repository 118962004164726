import { Container } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import React from "react";

const StartStep = () => {
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "left", marginBottom: "20px" }}
      >
        <h3
          style={{ textAlign: "start", fontSize: "1.2rem" }}
          className="textBoldPurple"
        >
          ¡Hola!, queremos solucionar tus dudas respecto a:
        </h3>
      </GridItem>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "left" }}
        className="stepone--text"
      >
        <h5 className="noMargin">
          <b>
            * Garantía <br /> * Reglamento general <br />* Cancelaciones y
            Aplazamientos <br />* Instrucciones
          </b>
        </h5>
      </GridItem>
      <GridItem>
        <br />
        <span
          style={{ display: "block", fontSize: "1rem" }}
          className="textBoldYellow"
        >
          Hemos trabajado con más de 8000 estudiantes y nos gustan las cosas
          claras.
        </span>
      </GridItem>
      <GridItem style={{ textAlign: "center" }}>
        <img
          style={{ width: "80%", objectFit: "contain" }}
          className="img-pdf"
          alt=""
          src={require("../../../../assets/img/banderas-y-paises-bien.png")}
        />
      </GridItem>
    </Container>
  );
};

export default StartStep;
