import axios from "axios";
import { URL_API } from "service/config";
import GlobalService from "./GlobalService";

export default class TallerService {
  static async getAll({ user, state }) {
    let config = {
      method: "get",
      url: URL_API + "homework",
      headers: { Authorization: user.token },
    };
    if (state === undefined) {
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    } else {
      config.url = URL_API + `homework/filter?state=${state}`;
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    }
  }

  static async getFilterColor({ user, state, color }) {
    const colorH = color.slice(1);
    var config = {
      method: "get",
      url: URL_API + `homework/filter?color=${colorH}`,
      headers: { Authorization: user.token },
    };
    //console.log(state);
    if (state === "ALL") {
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    } else {
      const colorH = color.slice(1);
      config = {
        method: "get",
        url: URL_API + `homework/filter?state=${state}&color=${colorH}`,
        headers: { Authorization: user.token },
      };
      const resp = await axios(config);
      if (resp.data.message !== "OK") {
        //console.log(resp.data.message);
      }
      return resp.data;
    }
  }

  static async teacherAssign(newData, idHomework, user) {
    const config = {
      method: "put",
      url: URL_API + `homework/assign-tutor`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        newData,
        idHomework,
      }),
    };
    const res = await axios(config);
    return res;
  }

  static async addToSalesExcel({ debtToTeacher, idHomework, user, uSelected }) {
    const config = {
      method: "post",
      url: URL_API + `homework/add-to-sales-excel`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        adminUser: user.names,
        idHomework,
        debtToTeacher,
        uSelected: uSelected.value
      }),
    };

    const res = await axios(config);
    return res;
  }

  static async editValue({ idHomework, newValue, user }) {
    const config = {
      method: "put",
      url: `${URL_API}homework`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idHomework,
        newValue,
      }),
    };

    const res = await axios(config);
    return res;
  }

  static async findHomeworksForPayment({ token }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `homework/payment/find-homeworks-for-payment`,
      headers: {
        Authorization: token,
      },
    });
  }

  static async getByTeacherId({ user }) {
    const config = {
      method: "get",
      url: `${URL_API}homework/by-teacher`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
    };

    const res = await axios(config);
    return res;
  }

  static async savePayment({ idHomework, file, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file.item(0));
    data.append("idHomework", idHomework);
    var config = {
      method: "post",
      url: `${URL_API}homework/save-payment`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async savePayment2({ file, idHomework, user }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file);
    data.append("idHomework", idHomework);
    var config = {
      method: "post",
      url: `${URL_API}homework/save-payment2`,
      headers: {
        Authorization: user.token,
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async addReturn({ user, textReturn, idHomework, date }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `homework/add-return`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        textReturn,
        idHomework,
        date,
      }),
    });
  }

  static async findByIdAdmin({ id }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "homework/" + id,
      headers: {},
    });
  }

  static async getMaterials({ id }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + `homework/client/find-material-by-homework/${id}`,
      headers: {},
    });
  }

  static async uploadMaterial({ file, idHomework }) {
    //console.log("uploadMaterial");
    var FormData = require("form-data");

    //console.log("FormData:", FormData);

    var data = new FormData();

    //console.log("data:", data);

    data.append("file", file);
    data.append("idHomework", idHomework);

    //console.log("data:", data);

    var config = {
      method: "post",
      url: `${URL_API}homework/client/upload-material`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async deletePrevMaterial({ id, index }) {
    var config = {
      method: "put",
      url: `${URL_API}homework/delete-prev-material`,
      headers: {},
      data: { id, index },
    };

    return GlobalService.sentRequest(config);
  }

  static async updateTextMaterial({ idHomework, text }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `homework/client/update-text-material-homework`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idHomework,
        text,
      }),
    });
  }

  static async updateName({ user, idHomework, name }) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + `homework/update-name`,
      headers: {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      data: { idHomework, name },
    });
  }

  static async updateUniversity({ token, university, idHomework }){
    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + `homework/update-university`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        idHomework,
        university
      }),
    });
  }
}
