//import { URL_API } from 'service/config';
//import axios from "axios";
//import swal from 'sweetalert';

import { LOGIN, LOGOUT } from "./type";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { setTokenSession } from "utils/cookieUtil";
import { removeToken } from "utils/cookieUtil";

export const setUserAction = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  tryToSetCookie(user);
  return {
    type: LOGIN,
    payload: user,
  };
};

export const removeUserAction = () => {
  removeToken();
  if (localStorage.getItem("user")) localStorage.removeItem("user");
  return { type: LOGOUT };
};

const tryToSetCookie = (user) => {
  try {
    if (user?.token) {
      const decoded = jwt_decode(user?.token);
      const dateExpiration = moment.unix(decoded.exp);
      //console.log("dateExpiration: ", dateExpiration.toDate());
      if (dateExpiration.isValid())
        setTokenSession("NOT_NEEDED_HERE", dateExpiration.toDate());
    }
  } catch (error) {
    console.error(error);
  }
};
/*
export const updateUserAction = data => {
    return async (dispatch, getState) => {

        dispatch(handleDispatch(LOADING_UPDATE_USER, true));

        const { user } = getState().user;

        const setUser = user => dispatch(setUserAction(user));

        var url = "NONE";
        if (user.type === "O") {
            url = URL_API + 'organizer/update-user';
        } else if (user.type === "P") {
            url = URL_API + 'player/update-user';
        } else if (user.type === "R") {
            url = URL_API + 'referee/update-user';
        }

        var config = {
            method: 'put',
            url,
            headers: {
                'Authorization': user.token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        axios(config).then(res => {
            if (res.data.status) {
                const newUser = {
                    ...user,
                    ...res.data.data
                }
                setUser(newUser);
                dispatch(handleDispatch(SUCCESS_UPDATE_USER, null));
                swal("Bien", "Datos actualizados", "success");
            } else
                dispatch(handleDispatch(FAILURE_UPDATE_USER, res.data.message));
        }).catch(err => {
            dispatch(handleDispatch(FAILURE_UPDATE_USER, err.message));
        });
    }
}

export const updatePasswordAction = data => {
    return async (dispatch, getState) => {

        dispatch(handleDispatch(LOADING_UPDATE_PASSWORD, true));

        const { user } = getState().user;

        var url = "NONE";
        if (user.type === "O") {
            url = URL_API + 'organizer/update-password';
        } else if (user.type === "P") {
            url = URL_API + 'player/update-password';
        } else if (user.type === "R") {
            url = URL_API + 'referee/update-password';
        }

        var config = {
            method: 'put',
            url,
            headers: {
                'Authorization': user.token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        axios(config).then(res => {
            if (res.data.status) {
                dispatch(handleDispatch(SUCCESS_UPDATE_PASSWORD, null));
                swal("Bien!", "Contraseña actualizada!", "success");
            } else {
                swal("Error!", "Contraseña antigua no coincide", "error");
                dispatch(handleDispatch(FAILURE_UPDATE_PASSWORD, res.data.message));
            }
        }).catch(err => {
            dispatch(handleDispatch(FAILURE_UPDATE_PASSWORD, err.message));
        });
    }
}*/

//const handleDispatch = (type, payload) => ({ type, payload });
