import {
  CircularProgress,
  FormControl,
  IconButton,
  Input,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PartialService from "services/PartialService";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
import CustomReactSelect from "components/Select/CustomReactSelect";
import moment from "moment";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import SMSService from "services/SMSService";
import ReactDOM from "react-dom";
import SubjectService from "services/SubjectService";
import { WhatsApp } from "@material-ui/icons";
import { adminPhone1 } from "variables/superAdminEmails";
// import GridContainer from "components/Grid/GridContainer";

const myParseDate = (date) => moment(date).format("ddd DD MMMM YYYY, hh:mm A");

const parseMoneyToExcel = (cost) => {
  try {
    return Math.round(cost / 1000);
  } catch (e) {}
  return cost;
};

const getSemester = (month) => {
  if (month >= 0 && month <= 5) return "A";
  return "B";
};

const NewAlertUText = ({ uSelected, setUSelected }) => {
  const [value, setValue] = useState(uSelected.value);

  const onChangeF = (e) => {
    swal.setActionValue(e.target.value);
    setValue(e.target?.value);
    setUSelected({ ...uSelected, value: e.target.value });
  };

  return (
    <Input
      type="text"
      placeholder="Nombre..."
      onChange={onChangeF}
      value={value}
    ></Input>
  );
};

const NewAleertUSelect = ({ uSelected, setUSelected }) => {
  const uList = [
    { label: "Andes", value: "andes" },
    { label: "Antonio Nariño", value: "antonio nariño" },
    { label: "Católica", value: "catolica" },
    { label: "Sergio Arboleda", value: "sergio arboleda" },
    { label: "Javeriana", value: "javeriana" },
    { label: "Sabana", value: "sabana" },
    { label: "Salle", value: "salle" },
    { label: "Rosario", value: "rosario" },
    { label: "Uniminito", value: "uniminuto" },
    { label: "El Bosque", value: "el bosque" },
    { label: "Distrital", value: "distrita" },
    { label: "ECCI", value: "ecci" },
    { label: "ECI", value: "eci" },
    { label: "EAFIT", value: "eafit" },
    { label: "EIA", value: "eia" },
    { label: "Militar", value: "militar" },
    { label: "Soy_ingeniero", value: "soy_ingeniero" },
    { label: "Piloto", value: "piloto" },
    { label: "UNAL", value: "unal" },
    { label: "UNAD", value: "unad" },
    { label: "Otra", value: "otro" },
  ];

  const onChangeF = (e) => {
    swal.setActionValue(e.value);
    setUSelected(e);
  };

  return (
    <CustomReactSelect
      onChange={(value) => onChangeF(value)}
      placeholder="Universidad..."
      options={uList}
    />
  );
};

const NewAlertSells = ({ value, setValue }) => {
  const onChangeF = (e) => {
    swal.setActionValue(e.target.value);
    setValue(e.target.value);
  };

  return (
    <Input
      type={"number"}
      placeholder={"valor sugerido: " + value}
      onChange={(e) => onChangeF(e)}
    ></Input>
  );
};

const factorMinutes = (subjectCost, minutes) => {
  if (minutes >= 0 && minutes <= 29) return subjectCost * 0.3;
  if (minutes === 30) return subjectCost * 0.4;
  if (minutes >= 31 && minutes <= 59) return subjectCost * 0.7;
  if (minutes >= 60 && minutes <= 120) return subjectCost * 1;
  if (minutes >= 121 && minutes <= 150) return subjectCost * 1.3;
  if (minutes >= 151 && minutes <= 180) return subjectCost * 1.45;
  return subjectCost;
};

const costTutor = (minutes) => {
  if (minutes >= 0 && minutes <= 29) return 0.52;
  if (minutes === 30) return 0.47;
  if (minutes >= 31 && minutes <= 59) return 0.47;
  if (minutes >= 60 && minutes <= 120) return 0.55;
  if (minutes >= 121 && minutes <= 150) return 0.54;
  if (minutes >= 151 && minutes <= 180) return 0.53;
};

const getCostTutor = ({ subjects, partial }) => {
  const s = subjects?.find((sub) => sub.label === partial.subject);
  //const costEst = factorMinutes(s?.partial_base_cost, partial?.event_time);
  //return costEst * costTutor(partial?.event_time);
  if (s.tutor_cost_base) {
    return s.tutor_cost_base;
  } else {
    return 0;
  }
};

const round = (int) => {
  const result = (Math.trunc(int / 1000) + 1) * 1000;
  return result;
};

export const MiniComponentPartialActions = ({
  partial,
  setPartial,
  teacher,
}) => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState({ uno: false, dos: false });
  const [modal, setModal] = useState(false);
  const [debtToTeacher, setDebtToTeacher] = useState(0);
  const [alert, setAlert] = useState(false);
  const [img, setImg] = useState({ url: "", file: "" });
  const [haveScreenshot, setHaveScreenshot] = useState(false);
  const [value, setValue] = useState();
  const [uSelected, setUSelected] = useState("");

  useEffect(() => {
    getSubjects();
  }, [partial, value]); // eslint-disable-next-line

  const getSubjects = async () => {
    const res = await SubjectService.findAll({ user });
    if (res?.data) {
      setValue(getCostTutor({ subjects: res?.data, partial }));
    }
  };

  const addToSalesExcelAction = async () => {
    if (!teacher) {
      return swal("No hay tutor asignado");
    }
    //pago al tutor
    let wrapper = document.createElement("div");
    ReactDOM.render(
      <NewAlertSells
        value={value}
        setValue={setValue}
        uSelected={uSelected}
        setUSelected={setUSelected}
      />,
      wrapper
    );
    let el = wrapper.firstChild;
    //seleccion de universidad
    let wrapper2 = document.createElement("div");
    ReactDOM.render(
      <NewAleertUSelect uSelected={uSelected} setUSelected={setUSelected} />,
      wrapper2
    );
    let sele = wrapper2.firstChild;
    //otra universidad
    let wrapper3 = document.createElement("div");
    ReactDOM.render(
      <NewAlertUText uSelected={uSelected} setUSelected={setUSelected} />,
      wrapper3
    );
    let el3 = wrapper3.firstChild;
    if (partial.is_paid || haveScreenshot || partial.paymentBill) {
      swal({
        text: `Ingresa la cantidad de dinero a pagar a: ${teacher.names}`,
        content: el,
      }).then((b) => {
        if (parseInt(b) > 0) {
          setValue(b);
          setDebtToTeacher(b);
          swal({
            text: "¿De que Universidad es este Parcial?",
            content: sele,
          }).then((value) => {
            if (value === "otro") {
              swal({
                text: "¿Cuál es el nombre de la Universidad?",
                content: el3,
              }).then((value) => {
                setModal(true);
              });
            } else {
              setModal(true);
            }
          });
        } else {
          if (b) {
            setDebtToTeacher(round(value));
            setModal(true);
          }
        }
      });
    } else {
      setAlert(true);
    }
  };

  const onChangeFiles = (e) => {
    setImg({
      file: e.target.files,
      url: URL.createObjectURL(e.target.files.item(0)),
    });
  };

  const onSendFiles = async (e) => {
    e.preventDefault();
    const res = await PartialService.savePayment({
      file: img.file,
      idPartial: partial._id,
      user,
    });
    if (res?.data?.message === "OK") {
      setHaveScreenshot(true);
      setAlert(false);
      toast.success("Captura guardada correctamente");
      let wrapper = document.createElement("div");
      ReactDOM.render(
        <NewAlertSells
          value={value}
          setValue={setValue}
          uSelected={uSelected}
          setUSelected={setUSelected}
        />,
        wrapper
      );
      let el = wrapper.firstChild;
      //seleccion de universidad
      let wrapper2 = document.createElement("div");
      ReactDOM.render(
        <NewAleertUSelect uSelected={uSelected} setUSelected={setUSelected} />,
        wrapper2
      );
      let sele = wrapper2.firstChild;
      //otra universidad
      let wrapper3 = document.createElement("div");
      ReactDOM.render(
        <NewAlertUText uSelected={uSelected} setUSelected={setUSelected} />,
        wrapper3
      );
      let el3 = wrapper3.firstChild;

      swal({
        text: `Ingresa la cantidad de dinero a pagar a: ${teacher.names}`,
        content: el,
      }).then((b) => {
        if (parseInt(b) > 0) {
          setValue(b);
          setDebtToTeacher(b);
          swal({
            text: "¿De que Universidad es este Parcial?",
            content: sele,
          }).then((value) => {
            if (value === "otro") {
              swal({
                text: "¿Cuál es el nombre de la Universidad?",
                content: el3,
              }).then((value) => {
                setModal(true);
              });
            } else {
              setModal(true);
            }
          });
        } else {
          if (b) {
            setDebtToTeacher(round(value));
            setModal(true);
          }
        }
      });
    } else {
      toast.error("Algo no salió bien");
    }
  };

  const addToSalesExcel = async () => {
    setLoading({ uno: true });
    const res = await PartialService.addToSalesExcel({
      user,
      idPartial: partial._id,
      debtToTeacher,
      uSelected,
    });
    if (res?.data?.data) {
      setPartial(res?.data?.data);
      toast.success("Registro agregado correctamente!");

      //----
      const publicMaterialLink = `${window.location.origin}/public/material-stepper/${partial._id}`;

      const message = `Hola ${partial.client}! Tu pago fue verificado, ya estas agendado con Copywrite®!`;
      await SMSService.sendSMSWhitShort({
        user,
        message,
        phoneNumber: partial.phone,
        link: publicMaterialLink,
      });
      //--
      //mensaje de confirmacion para tutores//
      /************* */
      const shortLinkTeacher = `${window.location.origin}/teacher/parciales/${partial._id}`;

      const messageTeacher = `Hola ${teacher.names}, te hemos agendado un nuevo parcial en Copywrite®!  INFO: `;
      //await SMSService.sendSMSWhitShort({
      //user,
      //message: messageTeacher,
      //phoneNumber: teacher.phone,
      //link: shortLinkTeacher,
      //}); DESACTIVADO
      /************* */
      //--ENvio de 30 minutos antes y feedback
      //--estudiante
      const messageBeforeStudent = `¡Hola ${partial.client}! Empiezas tu Parcial con Copywrite en 30 minutos. Ingresa aquí: https://wa.link/hnua76`;
      const dateBeforeStudent = new Date(partial.event_date);

      const minutesBeforeStudent = dateBeforeStudent.getMinutes();
      dateBeforeStudent.setMinutes(minutesBeforeStudent - 30);

      //await SMSService.sendTimer({
      //user,
      //message: messageBeforeStudent,
      ///phoneNumber: partial.phone,
      //timer: dateBeforeStudent,
      //inView: false,
      //});
      //profesor
      const messageBeforeTeacher = `¡Hola ${teacher.names}! En 30 minutos empezamos el parcial que tienes agendado con Copywrite. Ingresa aquí: https://wa.link/vwlcfr y confirma que estas listo`;
      const dateBeforeTeacher = new Date(partial.event_date);

      const minutesBeforeTeacher = dateBeforeTeacher.getMinutes();
      dateBeforeTeacher.setMinutes(minutesBeforeTeacher - 30);

      await SMSService.sendTimer({
        user,
        message: messageBeforeTeacher,
        phoneNumber: teacher.phone,
        timer: dateBeforeTeacher,
        inView: false,
      });
      //--fb
      const messageFeedback = `¡Hola ${partial.client}!, te escribimos de Copywrite®. Empezamos tu Parcial en 30 minutos. ¡Vamos con toda!`;
      const dateFeedback = new Date(partial.event_date);

      const minutesFeedback = dateFeedback.getMinutes();
      dateFeedback.setMinutes(minutesFeedback + partial.event_time + 20);

      //await SMSService.sendTimer({
      //user,
      //message: messageFeedback,
      //phoneNumber: partial.phone,
      //timer: dateFeedback,
      //inView: false,
      //});
      //---

      setModal(false);
    } else {
      if (res?.data?.message) toast.error(res?.data?.message);
      else toast.error("Ha ocurrido un error!");
    }
    setLoading({ uno: false });
  };

  const editValue = async () => {
    // //console.log(teacher);
    setLoading({ uno: true });
    const value = await swal(
      `Ingresa la cantidad de dinero a modificar a: ${teacher.names}, el aterior valor es: ${partial.debt_to_teacher}`,
      {
        content: "input",
      }
    );
    if (value) {
      const res = await PartialService.editValue({
        idPartial: partial._id,
        newValue: value,
        user,
      });
      // //console.log(res?.data?.data);
      setPartial(res?.data?.data);
    }
    setLoading({ uno: false });
  };

  const notififyPay = async () => {
    const bank = await swal(`¿A que banco se realizó el pago?`, {
      content: "input",
    });
    if (bank) {
      let notes = await swal(`Desea agregar notas/detalles`, {
        content: "input",
      });
      if (!notes) {
        notes = "";
      }
      const phone = adminPhone1;
      const confirmLink = `${window.location.origin}/admin/confirm/partial/${partial?._id}?payMetod=${bank}`;
      const text = `🧢 *NUEVO PAGO POR $${
        partial?.cost
      } COP*\n\n*Tipo*: Parcial.\n*Asignatura:* ${
        partial?.subject
      }.\n*Tutor asignado:* ${teacher?.names}.\n*Utilidad:* ${(
        ((partial.cost - partial.debt_to_teacher) / partial.cost) *
        100
      ).toFixed(2)}%\n*#Banco:* ${bank}.\n*Fecha de evento:* ${myParseDate(
        partial?.event_date
      )}.\n*ID:* ${partial?._id}\n*Estudiante:* ${
        partial?.client
      }.\n*#Whatsapp:* ${
        partial?.phone
      }.\n*Notas:* ${notes}\n*Link de confirmación:* ${confirmLink}`;
      const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
        text
      )}`;
      window.open(url, `material_link_${partial._id}`);
    }
  };

  if (partial === null || partial === undefined) return null;

  const today = moment();
  const week = today.week();
  const month = today.month() + 1;
  const year = today.year();

  return (
    <>
      <GridItem xs={6} md={2} sm={2} lg={2}>
        {/* <div>
          &nbsp;
          <b>Agregar a ventas</b>
        </div>
        {loading.uno ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton
            onClick={addToSalesExcelAction}
            disabled={partial?.is_inserted_at_sales_excel}
          >
            <AttachMoneyIcon />
          </IconButton>
        )} */}
        {partial?.is_inserted_at_sales_excel ? (
          <>
            <div>
              &nbsp;
              <b>Editar valor</b>
            </div>
            {loading.uno ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={editValue}>
                <EditIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            <div>
              &nbsp;
              <b>Agregar a ventas</b>
            </div>
            {loading.uno ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                onClick={addToSalesExcelAction}
                disabled={
                  partial?.is_inserted_at_sales_excel ||
                  !partial?.isAddedInCalendar
                }
              >
                <AttachMoneyIcon />
              </IconButton>
            )}
          </>
        )}
      </GridItem>
      {partial?.is_inserted_at_sales_excel && (
        <GridItem xs={6} md={2} sm={2} lg={2}>
          <div>
            &nbsp;
            <b>Notificar pago</b>
          </div>
          <IconButton onClick={notififyPay}>
            <WhatsApp />
          </IconButton>
        </GridItem>
      )}
      <SimpleModal open={modal} setOpen={setModal} widthModal={"80%"}>
        <h4>Datos que se agregarán a excel</h4>
        <ul>
          <li>
            <b>(A) MATERIA:</b> {partial?.subject}
          </li>
          <li>
            <b>(B) TIPO SERVICIO:</b> Parcial
          </li>
          <li>
            <b>(C) TUTOR ASIGNADO:</b> {teacher?.names}
          </li>
          <li>
            <b>(D) SEMANA:</b> {`Semana ${week}`}
          </li>
          <li>
            <b>(E) # MES:</b> {month}
          </li>
          <li>
            <b>(F) INGRESO NETO:</b> {parseMoneyToExcel(partial?.cost)}
          </li>
          <li>
            <b>(G) DEUDA A TUTOR:</b> {parseMoneyToExcel(debtToTeacher)}
          </li>
          <li>
            <b>(H) UTILIDAD:</b>{" "}
            {parseMoneyToExcel(partial?.cost - debtToTeacher)}
          </li>
          <li>
            <b>(I) ASESOR:</b> {user?.names}
          </li>
          <li>
            <b>(J) PERIODO:</b> {`${year}-${getSemester(month)}`}
          </li>
          <li>
            <b>(K) Agendado por:</b> Manual
          </li>
          <li>
            <b>(L) ID:</b> {partial?._id}
          </li>
        </ul>
        <Button
          type="button"
          color="success"
          loading={loading.uno}
          onClick={addToSalesExcel}
        >
          Agregar a excel
        </Button>
      </SimpleModal>
      <SimpleModal open={alert} setOpen={setAlert} widthModal={"80%"}>
        <div className="modalFile">
          <div className="textModalFile" style={{ marginLeft: "30px" }}>
            <label style={{ color: "black" }}>
              Este parcial no ha sido pagado aún, si ya fue pagado por favor
              suba captura de pantalla del recibo de pago
            </label>
          </div>
          <br />
          <div
            className="final_step1 btn1-modalFile"
            style={{ padding: "0px 60px" }}
          >
            <div>
              <h5 style={{ padding: "10px 0px", backgroundColor: "#fbbf13" }}>
                Seleccionar Archivo
              </h5>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="custom-file-input"
                onChange={onChangeFiles}
              />
            </div>
          </div>
          <div className="btn2-modalFile">
            <Button
              onClick={onSendFiles}
              disabled={img.url ? false : true}
              type="button"
              color={"success"}
            >
              {"Guardar"}
            </Button>
          </div>
          <br />
          <div className="imgModalFile">
            {img.url && <img style={{}} src={img.url} alt="" />}
          </div>
        </div>
      </SimpleModal>
    </>
  );
};
