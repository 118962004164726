import axios from "axios";
const { URL_API } = require("service/config");

export default class ConfirmService {
  static async confirm({ user, typeObject, idObject }) {
    var config = {
      method: "put",
      url: URL_API + "confirm",
      headers: {
        Authorization: user.token,
      },
      data: {
        typeObject,
        idObject,
      },
    };
    try {
      const res = await axios(config);
      return res;
    } catch (error) {
      //console.log(error.message);
      return null;
    }
  }
}
