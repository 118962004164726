import { Divider, TextareaAutosize } from "@material-ui/core";
import { EditInput } from "components/CustomInput/EditInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SingleLoading from "components/Loading/SingleLoading";
import FormatMoney from "components/Money/FormatMoney";
import GoBack from "components/Navigation/GoBack";
import { partialTypeTranslate } from "functions/comun";
import { partialStateTranslate } from "functions/comun";
import { mapDate } from "functions/comun";
import { paymentsStateTranslate } from "functions/comun";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PaymentService from "services/PaymentService";
import Button from "components/CustomButtons/Button";

const DetailAdminPayment = ({ payment, setPayment, getData, setService }) => {
  const { object: partial, teacher } = payment;

  const [editAmount, setEditAmount] = useState(payment.amount);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [comment, setComment] = useState("");
  const [stateLogs, setStateLogs] = useState([]);

  const user = useSelector((state) => state.user.user);

  const onSaveAmount = async () => {
    setLoading(true);
    const res = await PaymentService.updateAmount({
      token: user.token,
      newAmount: editAmount,
      idPayment: payment._id,
    });
    if (res?.data?.data) {
      toast.success("Valor actualizado correctamente!");
      setPayment({
        ...payment,
        created_at: mapDate(res?.data?.data.created_at),
        event_date_f: mapDate(res?.data?.data?.object?.event_date),
        db_state: res?.data?.data.state,
        state: paymentsStateTranslate(res?.data?.data.state),
        ...res?.data?.data,
      });
      getData();
    }
    setLoading(false);
  };

  const changeState = async (newState) => {
    setLoading(true);
    const res = await PaymentService.chageState({
      token: user.token,
      idPayment: payment._id,
      newState,
    });
    if (res?.data?.data) {
      toast.success("Estado actualizado correctamente!");
      setPayment({
        ...payment,
        created_at: mapDate(res?.data?.data.created_at),
        event_date_f: mapDate(res?.data?.data?.object?.event_date),
        db_state: res?.data?.data.state,
        state: paymentsStateTranslate(res?.data?.data.state),
        ...res?.data?.data,
      });
      getSatateLogs();
      getData();
    }
    setLoading(false);
  };

  const getComments = async () => {
    setLoading(true);
    const res = await PaymentService.findComments({
      token: user.token,
      idPayment: payment._id,
    });
    if (res?.data?.data) setComments(res.data.data);
    setLoading(false);
  };

  const getSatateLogs = async () => {
    setLoading(true);
    const res = await PaymentService.findStateLogs({
      token: user.token,
      idPayment: payment._id,
    });
    if (res?.data?.data) setStateLogs(res.data.data);
    setLoading(false);
  };

  const handleSubmitAddComment = async (e) => {
    e.preventDefault();
    setLoading1(true);
    const res = await PaymentService.addComment({
      token: user.token,
      text: comment,
      idPayment: payment._id,
    });
    if (res.data.data) {
      toast.success("Comentario agregado correctamente!");
      setComment("");
      getComments();
    }
    setLoading1(false);
  };

  useEffect(() => {
    getComments();
    getSatateLogs();
  }, []); // eslint-disable-line

  const isPaid = payment?.db_state === "PAID";

  return (
    <GridContainer>
      <GoBack back={() => setPayment(null)} />
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
        {/* <h3>Cobro: {payment._id}</h3> */}
        <h3>
          {partial.reasson_date && <b>Devolución - </b>}
          {teacher.names} - {partial.subject}
        </h3>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
        {isPaid ? (
          <FormatMoney
            country={{ label: "Colombia" }}
            fontSize={30}
            value={editAmount}
          />
        ) : (
          <EditInput
            onSave={onSaveAmount}
            showComponent={
              <FormatMoney
                country={{ label: "Colombia" }}
                value={payment?.amount}
                fontSize={30}
              />
            }
            editComponent={
              <FormatMoney
                fontSize={30}
                country={{ label: "Colombia" }}
                onValueChange={(e) => setEditAmount(e.value)}
                value={editAmount}
                displayType="input"
              />
            }
          />
        )}
      </GridItem>
      {isPaid ? null : (
        <>
          <GridItem
            xs={12}
            md={4}
            sm={4}
            lg={4}
            style={{ textAlign: "center" }}
          >
            <Button
              style={{
                backgroundColor:
                  payment?.db_state === "DENIED" ? "gray" : "#df4759",
                color: "white",
              }}
              variant="contained"
              color="secondary"
              onClick={() => changeState("DENIED")}
              disabled={payment?.db_state === "DENIED"}
              loading={loading}
            >
              Rechazar cobro
            </Button>
          </GridItem>
          <GridItem
            xs={12}
            md={4}
            sm={4}
            lg={4}
            style={{ textAlign: "center", height: "100%" }}
          >
            <div
              onClick={() => {
                console.log(payment?.type_object);
                setService({
                  type: payment?.type_object,
                  data: partial,
                  payment,
                });
                setPayment(null);
              }}
              style={{ cursor: "pointer" }}
            >
              Id: {partial._id}
            </div>
          </GridItem>
          <GridItem
            xs={12}
            md={4}
            sm={4}
            lg={4}
            style={{ textAlign: "center" }}
          >
            <Button
              style={{
                backgroundColor:
                  payment?.db_state === "APPROVED" ? "gray" : "rgb(75,181,67)",
                color: "white",
              }}
              variant="contained"
              color="secondary"
              onClick={() => changeState("APPROVED")}
              disabled={payment?.db_state === "APPROVED"}
              loading={loading}
            >
              Aprobar cobro
            </Button>
          </GridItem>
        </>
      )}
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <br />
        <Divider />
        <br />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3 style={{ textAlign: "center" }}>Admin</h3>
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Tipo objeto: </b>
        {payment?.type_object}
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Estado: </b>
        {paymentsStateTranslate(payment?.state)}
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Cobro: </b>
        {payment?._id}
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Cobro solicitado el: </b>
        {mapDate(payment?.created_at)}
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Admin: </b>
        {partial?.admin?.names}
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Comprobantes: </b>
        {partial?.paymentBill ? (
          <>
            <a
              style={{ textDecoration: "none", color: "blue" }}
              href={partial.paymentBill}
              target="_blank"
              rel="noopener noreferrer"
            >
              ver
            </a>
            {partial.paymentBill2?.map((item, index) => {
              return (
                <>
                  {" - "}
                  <a
                    style={{ textDecoration: "none", color: "blue" }}
                    href={item}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    adicional{index + 1}
                  </a>
                </>
              );
            })}
          </>
        ) : (
          "Pagado por pasarela"
        )}
      </GridItem>
      <GridItem xs={12} md={4} sm={4} lg={4}>
        <b>Fecha de pago:</b>{" "}
        {mapDate(payment.object.date_inserted_at_sales_excel)}
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <br />
        <Divider />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3 style={{ textAlign: "center" }}>{payment?.type_object}</h3>
        <GridContainer>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Cliente: </b>
            {partial?.client}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Celular: </b>
            {partial?.phone}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Recibido: </b>
            {mapDate(partial?.inserted_at)}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Valor: </b>
            <FormatMoney
              country={{ label: "Colombia" }}
              value={partial?.cost}
            />
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Fecha {payment?.type_object}: </b>
            {mapDate(partial?.event_date)}
          </GridItem>

          {payment?.type_object === "Parcial" && (
            <GridItem xs={6} md={3} sm={3} lg={3}>
              <b>Tiempo {payment?.type_object}: </b>
              {`${partial?.event_time} min`}
            </GridItem>
          )}
          {payment?.type_object === "Tutoría" && (
            <GridItem xs={6} md={3} sm={3} lg={3}>
              <b>Tiempo {payment?.type_object}: </b>
              {`${partial?.event_time} hora/s`}
            </GridItem>
          )}
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Estado: </b>
            {partialStateTranslate(partial?.state)}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Tipo: </b>
            {partialTypeTranslate(partial?.type)}
          </GridItem>
          <GridItem xs={6} md={3} sm={3} lg={3}>
            <b>Utilidad: </b>
            {(
              ((partial?.cost - partial?.debt_to_teacher) * 100) /
              partial?.cost
            ).toFixed(2)}
            %
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <br />
        <Divider />
        <br />
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3 style={{ textAlign: "center" }}>Tutor</h3>
        <GridContainer>
          <GridItem xs={6} md={4} sm={4} lg={4}>
            <b>Nombre: </b>
            {teacher?.names}
          </GridItem>
          <GridItem xs={6} md={4} sm={4} lg={4}>
            <b>Celular: </b>
            {teacher?.phone}
          </GridItem>
          <GridItem xs={6} md={4} sm={4} lg={4}>
            <b>Email: </b>
            {teacher?.email}
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={12} sm={12} lg={12}>
        <form method="post" onSubmit={handleSubmitAddComment}>
          <GridContainer>
            <GridItem xs={12} md={12} sm={12} lg={12}>
              <h3 style={{ textAlign: "center" }}>Comentarios</h3>
              {loading && <SingleLoading />}
              {comments.map((i) => (
                <ItemComment
                  key={i._id}
                  text={i.text}
                  user={i.type_user}
                  date={i.created_at}
                />
              ))}
            </GridItem>
            <GridItem
              xs={12}
              md={8}
              sm={8}
              lg={8}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              <TextareaAutosize
                required
                style={{
                  width: "100%",
                  borderRadius: 10,
                  textAlign: "justify",
                  border: ".5px solid #2b2527",
                  padding: 13,
                }}
                aria-label="minimum height"
                minRows={4}
                maxRows={8}
                placeholder="Escribe alguna información adicional"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={4}
              sm={4}
              lg={4}
              style={{ textAlign: "center", alignSelf: "center" }}
            >
              <Button
                loading={loading1}
                style={{
                  backgroundColor: "#f9004d",
                  color: "white",
                  borderRadius: "10px",
                }}
                variant="contained"
                type="submit"
              >
                Guardar
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </GridItem>

      <GridItem xs={12} md={12} sm={12} lg={12}>
        <h3 style={{ textAlign: "center" }}>Historial de estado</h3>
        <ul>
          {stateLogs.map((i) => (
            <li key={i._id}>
              <b>{i?.user?.names}</b> cambio el estado de{" "}
              <b>{paymentsStateTranslate(i?.previous_state)}</b> a{" "}
              <b>{paymentsStateTranslate(i?.state)}</b> el {mapDate(i?.date)}
            </li>
          ))}
        </ul>
      </GridItem>
    </GridContainer>
  );
};

const ItemComment = ({ text, user, date }) => (
  <>
    <div
      style={{
        backgroundColor: user === "TEACHER" ? "rgb(241, 214, 123)" : "#aec4c1",
        fontSize: 12,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <div>{text}</div>
      <div style={{ textAlign: "right" }}>
        <b style={{ fontSize: 9 }}>
          Agregado por {user === "TEACHER" ? "Tutor" : "tí"} el {mapDate(date)}
        </b>
      </div>
    </div>
    <br />
  </>
);

export default DetailAdminPayment;
