import ForgotPassPage from "views/Pages/ForgotPassPage";
import LoginPage from "views/Pages/LoginPage";
import NewPassPage from "views/Pages/NewPassPage";

var publicRoutes = [
    {
        path: "/login-page",
        name: "Login Page",
        component: LoginPage,
        layout: "/auth",
    },
    {
        path: "/forgot-password",
        name: "Forgot Password Page",
        component: ForgotPassPage,
        layout: "/auth",
    },
    {
        path: "/new-password/:token",
        name: "New Password Page",
        component: NewPassPage,
        layout: "/auth",
    }
];
export default publicRoutes;
