import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepOne from "./paymentStepper/StepOne";
import StepTwo from "./paymentStepper/StepTwo";
import { useParams } from "react-router-dom";
import SingleLoading from "components/Loading/SingleLoading";
import { css } from "@emotion/css";
import "./publicStyle.css";
import PartialService from "services/PartialService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { FakeLoading } from "./paymentStepper/FakeLoading";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import StepRules from "./StepRules";
import StepGarant from "./StepGarant";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 0px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Check", "Guía", "Garanía", "Material"];
}

export default function MaterialStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(-1);
  const steps = getSteps();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [textMaterial, setTextMaterial] = useState("");
  const [textMaterial2, setTextMaterial2] = useState("");
  const [stateStepTwo, setStateStepTwoPure] = useState({
    radio1: "",
    radio2: "",
    radio3: "",
    radio2Text: "",
    listTopis: "",
    nameBook: "",
    file1: null,
    file2: null,
  });

  const clearFilesState = () => {
    setStateStepTwoPure({ ...stateStepTwo, file1: null, file2: null });
  };

  const setStateStepTwo = (name, value) => {
    const obj = {};
    obj[name] = value;
    setStateStepTwoPure({ ...stateStepTwo, ...obj });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const getPartial = async () => {
    setLoading(true);

    const res = await PartialService.findByIdPublic({ idPartial: id });

    if (res?.data?.data) {
      setData(res.data.data);
      setStateStepTwoPure({
        ...stateStepTwo,
        listTopis: res.data.data.tf_textMaterial,
      });
      setLoading(false);
      setActiveStep(0);

      handleTextMaterial(res?.data?.data?.textMaterial);
    } else {
      console.error(res);
    }
  };

  const handleTextMaterial = (text) => {
    try {
      const textArray = text.split("HTML-SEPARATOR");
      setTextMaterial(textArray[1]);
      setTextMaterial2(textArray[0]);
    } catch (error) {
      console.error(error);
    }
  };

  function getStepContent(data) {
    switch (activeStep) {
      case -1:
        return <SingleLoading size={50} />;
      case 0:
        return <StepOne data={data} notLastMessage={true} />;
      case 1:
        return <StepRules />;
      case 2:
        return <StepGarant />;
      case 3:
        return (
          <StepTwo
            data={data}
            textMaterial={textMaterial}
            textMaterial2={textMaterial2}
            setTextMaterial={setTextMaterial}
            setTextMaterial2={setTextMaterial2}
            setState={setStateStepTwo}
            state={stateStepTwo}
            clearFilesState={clearFilesState}
          />
        );
      default:
        return "NO STEP DEFINIED";
    }
  }

  useEffect(() => {
    getPartial();
  }, []); //eslint-disable-line

  if (loading) return <SingleLoading size={50} />;

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={css`
          padding: 10px !important;
        `}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <small className="justText">
                <b>{label}</b>
              </small>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <div>
        {activeStep === steps.length ? (
          <div style={{ textAlign: "center", padding: 20 }}>
            <FakeLoading
              ms={1000}
              onLoading={<SingleLoading />}
              onLoad={
                // <div>
                //   <br />
                //   <br />
                //   <Typography
                //     style={{ color: "green", fontSize: 20, fontWeight: "bold" }}
                //     className="good_font"
                //   >
                //     El material de preparación se ha enviado correctamente
                //   </Typography>
                //   <br /> <br />
                //   <div>
                //     <CheckCircleIcon
                //       fontSize="large"
                //       style={{ color: "green" }}
                //     />
                //   </div>
                //   <br />
                // </div>
                <div>
                  <br />
                  <br />
                  <Typography
                    style={{ color: "#333", fontSize: 20, fontWeight: "bold" }}
                    className="good_font"
                  >
                    <p style={{ color: "#f9004d" }}>¡Ya todo quedó listo!</p>
                    <p style={{ color: "rgb(255, 188, 0" }}>
                      Has sido agendado
                    </p>
                    <br />
                    <div>
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: "green" }}
                      />
                    </div>
                    <br />
                    <small>
                      ¡Te deseamos un excelente día!{" "}
                      <span role="img" aria-label="corazon">
                        ❤️
                      </span>
                      <br />
                      Equipo de Copywrite
                    </small>
                  </Typography>
                  <br /> <br />
                  <br />
                </div>
              }
            />

            <Button
              variant="contained"
              color="primary"
              classes={{ label: "buttonLabel" }}
              onClick={handleBack}
            >
              Regresar
            </Button>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              classes={{ label: "buttonLabel" }}
              onClick={() =>
                window.open(
                  "https://www.copywritecol.com/",
                  "https://www.copywritecol.com/"
                )
              }
              endIcon={<OpenInNewIcon />}
            >
              Agendar algo mas
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent(data)}
            <div
              style={{
                textAlign: "center",
                marginTop: "50px",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 20,
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                classes={{ label: "buttonLabel" }}
              >
                Atrás
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ label: "buttonLabel" }}
                  onClick={handleNext}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ label: "buttonLabel" }}
                  onClick={handleNext}
                >
                  Siguiente
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
