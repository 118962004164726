import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TeacherService from "services/TeacherService";
import useCountry from "hooks/useCountry";

const NewTeacher = ({ user, loading, setLoading, getData }) => {
  const { register, control, handleSubmit } = useForm();
  const { paises, states, cities, onClickCountry, onClickState } = useCountry();

  const onSubmit = async (data) => {
    data.phone = "+" + data.phone;
    if (!data.phone) {
      swal("Error", "Ingrese un numero de celular valido", "error");
      return;
    }
    setLoading(true);
    const res = await TeacherService.createTeacher({ user, data });
    if (res.data.message !== "OK") {
      swal("Error", res.data.message, "error");
      getData();
    } else {
      swal("Ok", "Datos guardados", "success");
      setLoading(false);
      getData();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel>Nombre</InputLabel>
          <Controller
            name="names"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            render={({ field }) => (
              <Input {...field} type="text" required fullWidth />
            )}
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel>Email</InputLabel>
          <Controller
            name="email"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            render={({ field }) => (
              <Input {...field} type="email" required fullWidth />
            )}
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4} style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ marginBottom: 7 }}>Celular</InputLabel>
          <Controller
            name="phone"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            // render={({ field }) => <Input {...field} type="cel" required fullWidth />}
            render={({ field }) => (
              <PhoneInput required country={"co"} {...field} />
            )}
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4} style={{ marginBottom: "1rem" }}>
          <InputLabel>Nombre de Banco: </InputLabel>
          <Controller
            name="bankName"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            render={({ field }) => <Input {...field} type="text" fullWidth />}
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel>Tipo de cuenta: </InputLabel>
          <Controller
            name="accountType"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            render={({ field }) => <Input {...field} type="text" fullWidth />}
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel>Numero de cuenta: </InputLabel>
          <Controller
            name="accountNumber"
            control={control}
            //defaultValue={organizer.urlPlayStore}
            render={({ field }) => <Input {...field} type="text" fullWidth />}
          />
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel style={{ marginBottom: 7 }}>Pais: </InputLabel>
          <Select
            {...register}
            onChange={onClickCountry}
            style={{ width: "100%" }}
            defaultValue="AF"
          >
            {paises.map((data) => (
              <MenuItem key={data.value} value={data.label}>
                {data.value}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel style={{ marginBottom: 7 }}>Estado: </InputLabel>
          <Select
            {...register}
            onChange={onClickState}
            style={{ width: "100%" }}
            defaultValue="none"
          >
            <MenuItem value="none">--- Seleccionar ---</MenuItem>
            {states.map((data) => (
              <MenuItem key={data.value} value={data.label}>
                {data.value}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <GridItem xs={4} md={4} sm={4} lg={4}>
          <InputLabel style={{ marginBottom: 7, marginTop: 5 }}>
            Ciudad:{" "}
          </InputLabel>
          <Select
            {...register("idLocation")}
            style={{ width: "100%" }}
            defaultValue="none"
          >
            <MenuItem value="none">--- Seleccionar ---</MenuItem>
            {cities.map((data) => (
              <MenuItem key={data.value} value={data.label}>
                {data.value}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <GridItem
          xs={12}
          md={12}
          sm={12}
          lg={12}
          customPadding="10px 0px 10px 0px"
        >
          <Button type="submit" color="success" loading={loading}>
            {"Guardar"}
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default NewTeacher;
