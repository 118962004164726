import { getRowsCount } from "functions/comun";
import MaterialTable from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import { mapDate } from "functions/comun";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import ColorModal from "./Pages/ColorModal";
import TutorService from "services/TutorService";
import ColorService from "services/ColorService";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import SingleLoading from "components/Loading/SingleLoading";
import { mapDateFormat } from "functions/comun";
import { tutorshipStateTranslate } from "functions/comun";
import Tutoria from "./Pages/Tutoria";
import { SearchById } from "./Pages/partials/SearchById";

const tableRef = React.createRef();

const Tutorias = () => {
  const user = useSelector((state) => state.user.user);

  // const [tutorships, setTutorships] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState("ALL");
  const [tutorship, setTutorship] = React.useState(null);
  const [colors, setColors] = React.useState([]);
  const [openColorModal, setOpenColorModal] = React.useState(false);
  const [tutorshipToColor, setTutorshipToColor] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [colorsArray, setColorsArray] = React.useState({});
  const [colorSelected, setColorSelected] = React.useState("ALL");

  const getColorsArray = async () => {
    const obj = {};
    const res = await ColorService.findAll({ user });
    const colors = res.data.data;
    setLoading(true);
    colors.forEach((item) => {
      obj[item.color] = item.name;
    });
    setColorsArray(obj);
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
    getColors();
    getColorsArray();
  }, [state]); //eslint-disable-line

  const isRenderLoading = loading || colors !== [] || data !== [];

  const getData = async () => {
    setLoading(true);
    if (state === "ALL") {
      const res = await TutorService.getAll({ user });
      setLoading(false);
      setData(res.data);
    } else {
      const res = await TutorService.getAll({ user, state });
      setLoading(false);
      setData(res.data);
    }
  };

  const getColors = async () => {
    setLoading(true);
    const res = await ColorService.findAll({ user });
    if (res.data.message !== "OK") {
      //console.log("Hubo un error");
    }
    setLoading(false);
    setColors(res.data.data);
  };

  const onRowClick = (e, rowData) => setTutorship(rowData);

  const copyToClipboard = (text) => {
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value =
      `Materia: ${text.subject}` +
      `\nFecha: ${mapDate(text.event_date)}` +
      `\nDuración: ${text.event_time} minutos`;

    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
  };

  const brushTutorship = (tutorship) => {
    setTutorshipToColor(tutorship);
    setOpenColorModal(true);
  };

  const onChangeColorSelected = async (e) => {
    setColorSelected(e.target.value);
    const color = e.target.value;
    if (e.target.value !== "ALL") {
      const res = await TutorService.getFilterColor({ user, state, color });
      setData(res.data);
    } else {
      getData();
    }
  };

  const openWhatsApp = (tutorship) => {
    const url = `https://api.whatsapp.com/send?phone=${
      tutorship.phone
    }&text=🍀 Hola ${
      tutorship.client
    }, te escribimos de CopyWrite.%0D%0D*Materia:* ${
      tutorship.subject
    }.%0D*Fecha:* ${mapDate(tutorship.event_date)}.%0D*Horas de tutoria:* ${
      tutorship.event_time
    } horas.%0D*Personas en la tutoria:* ${
      tutorship.people_in_tutorship
    }.%0D*Costo por hora:* $${tutorship.costHour}.%0D*Costo total:* $${
      tutorship.cost
    }.%0D%0D🌀¿Esta es la información de tu Tutoría?`;
    window.open(url, tutorship._id);
  };

  const sorByDateEvent = (a, b) => {
    return (
      new Date(`${a.event_date}, ${a.event_hour}`).valueOf() -
      new Date(`${b.event_date}, ${b.event_hour}`).valueOf()
    );
  };

  if (tutorship) {
    return (
      <Tutoria
        tutorship={tutorship}
        setTutorship={setTutorship}
        getData={getData}
      />
    );
  }
  return (
    <>
      {isRenderLoading ? (
        <GridContainer>
          <GridItem xs={6} md={6} sm={6} lg={6}>
            <InputLabel>Estado</InputLabel>
            <Select
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={{ width: "50%" }}
            >
              <MenuItem value={"ALL"}>Todos</MenuItem>
              <MenuItem value={"PENDING"}>Pendientes</MenuItem>
              <MenuItem value={"ASSIGNED"}>Asignado</MenuItem>
              <MenuItem value={"WAITING_TEACHER"}>Esperando profesor</MenuItem>
              <MenuItem value={"PAYMENT_SENT"}>Pago enviado</MenuItem>
              <MenuItem value={"PAYMENT_OK"}>Pago revisado OK</MenuItem>
              <MenuItem value={"NOT_PAYMENT"}>No pagado</MenuItem>
            </Select>
          </GridItem>
          <GridItem xs={6} md={6} sm={6} lg={6}>
            <InputLabel>Colores</InputLabel>
            <Select
              value={colorSelected}
              onChange={onChangeColorSelected}
              style={{ width: "50%" }}
            >
              <MenuItem value={"ALL"}>Todos</MenuItem>
              <MenuItem value={"#fff"}>Sin color</MenuItem>
              {colors.map((color) => (
                <MenuItem value={color.color}>
                  <div style={{ backgroundColor: color.color, width: "100%" }}>
                    {color.name}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </GridItem>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <hr></hr>
          </GridItem>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            <MaterialTable
              onRowClick={onRowClick}
              title={"Tutorías"}
              columns={[
                { title: "Cliente", field: "client" },
                { title: "Celular", field: "phone" },
                { title: "Materia", field: "subject", filtering: false },
                {
                  title: "Fecha",
                  field: "event_date_formatted",
                  filtering: false,
                  customSort: sorByDateEvent,
                },
                {
                  title: "Horas de tutoría",
                  field: "event_time",
                  filtering: false,
                },
                {
                  title: "Personas en la tutoría",
                  field: "people_in_tutorship",
                  filtering: false,
                },
                { title: "Precio", field: "cost", filtering: false },
                { title: "Estado", field: "state", filtering: false },
                {
                  title: "Color",
                  field: "color",
                  lookup: colorsArray,
                },
                {
                  title: "Solicitado",
                  field: "inserted_at_formatted",
                  filtering: false,
                },
              ]}
              key={data.length}
              tableRef={tableRef}
              data={data.map((item) => ({
                ...item,
                image_assigment: "Descargar",
                state: tutorshipStateTranslate(item.state),
                cost: item.cost,
                inserted_at_formatted: mapDateFormat(
                  item.inserted_at,
                  "DD MMM YYYY, hh:mm A"
                ),
                event_date_formatted: mapDateFormat(
                  item.event_date,
                  "DD MMM YYYY, hh:mm A"
                ),
              }))}
              localization={{
                toolbar: {
                  searchPlaceholder: "Buscar",
                },
              }}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Recargar",
                  isFreeAction: true,
                  onClick: getData,
                },
                (rowData) => ({
                  icon: "content_copy",
                  tooltip: "Copiar",
                  isFreeAction: false,
                  onClick: () => copyToClipboard(rowData),
                }),
                (rowData) => ({
                  icon: "brush",
                  tooltip: "Colorear",
                  isFreeAction: false,
                  onClick: () => brushTutorship(rowData),
                }),
                (rowData) => ({
                  icon: () => <WhatsappIcon />,
                  tooltip: "WhatsApp",
                  isFreeAction: false,
                  onClick: () => openWhatsApp(rowData),
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100, 1000],
                //pageSize: getRowsCount(data),
                //filtering: true,
                grouping: false,
                search: false,
                sorting: false,
                filtering: true,
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.color,
                }),
              }}
              isLoading={loading}
            />
          </GridItem>
          {openColorModal && (
            <ColorModal
              colors={colors}
              open={openColorModal}
              setOpen={setOpenColorModal}
              setElementToColor={setTutorshipToColor}
              user={user}
              getData={getData}
              elementToColor={tutorshipToColor}
              type="tutorship"
            />
          )}
          <SearchById setService={setTutorship} context="tutorship" />
        </GridContainer>
      ) : (
        <SingleLoading size={50} />
      )}
    </>
  );
};

export default Tutorias;
