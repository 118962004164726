import { URL_API } from "service/config";
import GlobalService from "./GlobalService";

export default class HiringService {
  static async sendData(data) {
    const FormData = require("form-data");
    const dataSend = new FormData();

    for (const property in data) {
      if (property === "files") {
        if (data[property].files) {
          for (const file of data[property].files) {
            dataSend.append("files", file);
          }
        } else {
          dataSend.append("files", []);
        }
      } else dataSend.append(property, data[property]);
    }

    return GlobalService.sentRequest({
      method: "post",
      url: URL_API + "hiring/send-data",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataSend,
    });
  }

  static async getTest(id) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "hiring/get-test/" + id,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async saveAnswer({ file, idSubject, idApplicant }) {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", file.item(0));
    data.append("idSubject", idSubject);
    data.append("idApplicant", idApplicant);
    var config = {
      method: "post",
      url: `${URL_API}hiring/save-answer`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    };

    return GlobalService.sentRequest(config);
  }

  static async validateAnswer(idSubject, idApplicant) {
    return GlobalService.sentRequest({
      method: "get",
      url:
        URL_API +
        "hiring/get-answer/?idSubject=" +
        idSubject +
        "&idApplicant=" +
        idApplicant,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async deleteAnswer(id) {
    return GlobalService.sentRequest({
      method: "delete",
      url: URL_API + "hiring/delete-answer",
      headers: {
        "Content-Type": "application/json",
      },
      data: { id },
    });
  }

  static async updateTest(id) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "hiring/update-test",
      headers: {
        "Content-Type": "application/json",
      },
      data: { id },
    });
  }

  static async getAll() {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "hiring",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async getAswers({ id }) {
    return GlobalService.sentRequest({
      method: "get",
      url: URL_API + "hiring/answers/" + id,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async startTest(id) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "hiring/" + id,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async updateColor(user, color, id) {
    return GlobalService.sentRequest({
      method: "put",
      url: URL_API + "hiring/update-color/" + id,
      headers: {
        "Content-Type": "application/json",
        headers: { Authorization: user.token },
      },
      data: { color },
    });
  }
}
