import Dashboard from "views/Dashboard";
import Parciales from "views/Parciales";
import Talleres from "views/Talleres";
import Tutorias from "views/Tutorias";
import PreRequest from "views/PreRequest";
import Tutores from "views/config/Tutores";
import Subject from "views/config/Subject";
import SMS from "views/tools/SMS";
import PaymentLinks from "views/tools/PaymentLinks";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import FunctionsIcon from "@material-ui/icons/Functions";
import PersonIcon from "@material-ui/icons/Person";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SchoolIcon from "@material-ui/icons/School";
import SettingsIcon from "@material-ui/icons/Settings";
import Colors from "views/config/Colors";
import PerfilAdmin from "views/PerfilAdmin";
import BuildIcon from "@material-ui/icons/Build";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AdminPayments from "views/payments/AdminPayments";
import MakePayments from "views/payments/MakePayments";
import Files from "views/config/Files";
import Feedback from "views/config/Feedback";
import Hiring from "views/Hiring";
import ApplicantDetail from "views/applicants/ApplicantDetail";
import ConfirmPay from "views/confirm/confirm_pay";

var adminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/pre-request",
    name: "Pre solicitudes",
    icon: DashboardIcon,
    component: PreRequest,
    layout: "/admin",
  },
  {
    path: "/parciales",
    name: "Parciales",
    icon: FunctionsIcon,
    component: Parciales,
    layout: "/admin",
  },
  {
    path: "/talleres",
    name: "Talleres",
    icon: MenuBookIcon,
    component: Talleres,
    layout: "/admin",
  },
  {
    path: "/tutorias",
    name: "Tutorias",
    icon: SchoolIcon,
    component: Tutorias,
    layout: "/admin",
  },
  {
    path: "/hiring/:id",
    component: ApplicantDetail,
    layout: "/admin",
    internal: true,
  },
  {
    path: "/hiring",
    name: "Contratacíon",
    icon: AccessibilityIcon,
    component: Hiring,
    layout: "/admin",
  },
  {
    path: "/perfil",
    name: "Perfil",
    icon: PersonIcon,
    component: PerfilAdmin,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Contabilidad",
    icon: AttachMoneyIcon,
    state: "contabilidadCollapse",
    views: [
      {
        path: "/admin-payments",
        name: "Cobros",
        component: AdminPayments,
        layout: "/admin",
      },
      {
        path: "/make-payments",
        name: "Pagos",
        component: MakePayments,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Config",
    icon: SettingsIcon,
    state: "parcialesCollapse",
    views: [
      {
        path: "/tutores",
        name: "Tutores",
        component: Tutores,
        layout: "/admin",
      },
      {
        path: "/materias",
        name: "Materias",
        component: Subject,
        layout: "/admin",
      },
      {
        path: "/colors",
        name: "Colores",
        component: Colors,
        layout: "/admin",
      },
      {
        path: "/files",
        name: "Archivos",
        component: Files,
        layout: "/admin",
      },
      {
        path: "/feedback",
        name: "Feedback",
        component: Feedback,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tools",
    icon: BuildIcon,
    state: "toolsCollapse",
    views: [
      {
        path: "/sms",
        name: "Envio de SMS",
        component: SMS,
        layout: "/admin",
      },
      {
        path: "/payment-links",
        name: "Links de pago",
        component: PaymentLinks,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/confirm/:type/:id",
    component: ConfirmPay,
    layout: "/admin",
    internal: true,
  },
];
export default adminRoutes;
