import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import TutorService from "services/TutorService";
import SingleLoading from "components/Loading/SingleLoading";
import { useEffect } from "react";
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { css } from "@emotion/css";
import StepOneTutor from "./paymentStepperTutor/StepOneTutor";
import StepTwoTutor from "./paymentStepperTutor/StepTwoTutor";
import PaymentDoneTutor from "./paymentStepperTutor/PaymentDoneTutor";
import StepRules from "./StepRules";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import StepThree from "./paymentStepper/StepThree";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 0px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Check", "Guía", "Pago"];
}

const PaymentStepperTutor = () => {
  //console.log("PAGOO DE TUTORIAAA");
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(-1);
  const steps = getSteps();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [textMaterial, setTextMaterial] = useState("");
  const [textMaterial2, setTextMaterial2] = useState("");
  const [stateStepTwo, setStateStepTwoPure] = useState({
    radio1: "",
    radio2: "",
    radio3: "",
    radio2Text: "",
    listTopis: "",
    nameBook: "",
    file1: null,
    file2: null,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const getTutorship = async () => {
    setLoading(true);

    const res = await TutorService.findByIdPublic({ idTutorship: id });

    if (res?.data?.data) {
      setData(res.data.data);
      setLoading(false);
      setActiveStep(0);
    } else {
      console.error(res);
    }
  };

  const setStateStepTwo = (name, value) => {
    const obj = {};
    obj[name] = value;
    setStateStepTwoPure({ ...stateStepTwo, ...obj });
  };

  const clearFilesState = () => {
    setStateStepTwoPure({ ...stateStepTwo, file1: null, file2: null });
  };

  function getStepContent(data) {
    switch (activeStep) {
      case -1:
        return <SingleLoading size={50} />;
      case 0:
        return <StepOneTutor data={data} notLastMessage={false} />;
      case 1:
        return <StepRules addMaterial={true} origen="Tutorship" />;
      case 2:
        return <StepThree data={data} typeObject={"TUTORSHIP"} />;
        {
          /*<StepTwoTutor
            data={data}
            textMaterial={textMaterial}
            textMaterial2={textMaterial2}
            setTextMaterial={setTextMaterial}
            setTextMaterial2={setTextMaterial2}
            setState={setStateStepTwo}
            state={stateStepTwo}
            clearFilesState={clearFilesState}
        />*/
        }
      default:
        return "NO STEP DEFINIED";
    }
  }

  useEffect(() => {
    getTutorship();
  }, []); //eslint-disable-line

  if (loading) return <SingleLoading size={50} />;

  if (data && data.url_payment) return <PaymentDoneTutor data={data} />;

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={css`
          padding: 10px !important;
        `}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <small className="justText">
                <b>{label}</b>
              </small>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <img src={"/icon.png"} style={{ width: 70 }} alt="" />
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            {/* <Typography className={classes.instructions}>
              All steps completed
            </Typography> */}
            <div>
              <br />
              <br />
              <Typography
                style={{ color: "#333", fontSize: 20, fontWeight: "bold" }}
                className="good_font"
              >
                <p style={{ color: "#f9004d" }}>¡Ya todo quedó listo!</p>
                <p style={{ color: "rgb(255, 188, 0" }}>Has sido agendado</p>
                <br />
                <div>
                  <CheckCircleIcon
                    fontSize="large"
                    style={{ color: "green" }}
                  />
                </div>
                <br />
                <small>
                  ¡Te deseamos un excelente día!{" "}
                  <span role="img" aria-label="corazon">
                    ❤️
                  </span>
                  <br />
                  Equipo de Copywrite
                </small>
              </Typography>
              <br /> <br />
              <br />
            </div>
            <Button onClick={handleBack}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(data)}
            <div
              style={{
                textAlign: "center",
                marginTop: "50px",
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 20,
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
                classes={{ label: "buttonLabel" }}
              >
                Atrás
              </Button>
              {activeStep === steps.length - 1 ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ label: "buttonLabel" }}
                  onClick={handleNext}
                >
                  Siguiente
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentStepperTutor;
