import axios from "axios";
import { URL_API } from "service/config";

export default class StateModalService {
  static async updateStateModal({ token, newState, type, id }) {
    if (type === "partial") {
      var config = {
        method: "put",
        url: URL_API + `partial/update-state`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ id, newState }),
      };
      //console.log(config);
      try {
        const resp = await axios(config);
        return resp.data;
      } catch (error) {
        //console.log(error);
        return null;
      }
    } else {
      alert("Pendiente " + type);
    }
  }
}
