import React, { useState } from "react";
import { useSelector } from "react-redux";
import { mapDate } from "functions/comun";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parcial from "./Pages/Parcial";
import ColorModal from "./Pages/ColorModal";
import Snackbar from "components/Snackbar/Snackbar";
import CheckIcon from "@material-ui/icons/Check";
import MaterialTable from "@material-table/core";
//import MaterialTable from "material-table";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import moment from "moment";
import PartialService from "services/PartialService";
import ColorService from "services/ColorService";
import WhatsappIcon from "assets/customIcons/WhatsappIcon";
import SingleLoading from "components/Loading/SingleLoading";
import { mapDateFormat } from "functions/comun";
import StateModal from "./Pages/StateModal";
import { partialState } from "functions/comun";
import SubjectService from "services/SubjectService";

import { SearchById } from "./Pages/partials/SearchById";

const tableRef = React.createRef();

const Parciales = () => {
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [partial, setPartial] = useState(null);
  const [colors, setColors] = useState("");
  const [colorsArray, setColorsArray] = useState({});
  const [openColorModal, setOpenColorModal] = useState(false);
  const [partialColor, setPartialColor] = useState(null);
  const [copied, setCopied] = useState(false);
  // const [exportAllData, setExportAllData] = useState(false);
  const [openStateModal, setOpenStateModal] = useState(false);
  const [partialStateInternal, setPartialStateInternal] = useState(null);
  const [subjectOptions, setSubjectOptions] = useState([]);

  React.useEffect(() => {
    getColorsArray();
    getColors();
    getSubjects();
  }, []); //eslint-disable-line

  const isRenderLoading = loading || colors !== [] || colorsArray !== [];

  const getColors = async () => {
    setLoading(true);
    const res = await ColorService.findAll({ user });
    if (res.data.message !== "OK") {
      return; //console.log("Hubo un error");
    }
    setColors(res.data.data);
    setLoading(false);
  };

  const data = async (query) => {
    const res = (await PartialService.findPagination({ user, query })).data;
    return {
      data: res.data.map((i) => ({
        ...i,
        event_date_formatted: mapDateFormat(
          i.event_date,
          "DD MMM YYYY, hh:mm A"
        ),
        inserted_at: mapDateFormat(i.inserted_at, "DD MMM YYYY, hh:mm A"),
      })),
      page: res.pageNumber - 1,
      totalCount: res.countDocuments,
    };
  };

  const getColorsArray = async () => {
    const obj = {};
    const res = await ColorService.findAll({ user });
    const colors = res.data.data;
    setLoading(true);
    colors.forEach((item) => {
      obj[item.color] = item.name;
    });
    setColorsArray(obj);
    setLoading(false);
  };

  const onRowClick = (e, rowData) => setPartial(rowData);

  const copyToClipboard = (text) => {
    var element = document.createElement("textarea");
    element.style = "position: absolute; left: -1000px; top: -1000px";
    element.value =
      `Materia: ${text.subject}` +
      `\nFecha: ${mapDate(text.event_date)}` +
      `\nDuración del parcial: ${text.event_time} minutos
      ${text.topics ? `Temas: ${text.topics}.` : ""}
      ${text.image_uploaded ? `Archivo: ${text.image_uploaded}` : ""}`;

    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);

    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const brushPartial = (partial) => {
    setPartialColor(partial);
    setOpenColorModal(true);
  };

  const changeState = (partial) => {
    setPartialStateInternal(partial);
    setOpenStateModal(true);
  };

  const openWhatsApp = (partial) => {
    const url = `https://api.whatsapp.com/send?phone=${
      partial.phone
    }&text=🍀 Hola ${
      partial.client
    }, te escribimos de CopyWrite.%0D%0D*Materia:* ${
      partial.subject
    }.%0D*Fecha:* ${mapDate(partial.event_date)}.%0D*Duración del parcial:* ${
      partial.event_time
    } minutos.%0D*Costo:* $${
      partial.cost
    }.%0D%0D🌀¿Esta es la información de tu parcial?`;
    window.open(url, partial._id);
  };

  const updateDataTable = () => {
    if (tableRef.current) tableRef.current.onQueryChange();
  };

  const getSubjects = async () => {
    setLoading(true);
    const res = await SubjectService.findAll({ user });
    if (res?.data) {
      setSubjectOptions(
        res?.data.map((i) => ({ value: i._id, label: i.label }))
      );
    }
    setLoading(false);
  };

  if (partial) {
    return (
      <Parcial
        partial={partial}
        setPartial={setPartial}
        getData={updateDataTable}
        subjectOptions={subjectOptions}
      />
    );
  }

  return (
    <>
      {isRenderLoading ? (
        <GridContainer>
          <GridItem xs={12} md={12} sm={12} lg={12}>
            {/*
                          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                          <span title="Flag for export all data instead of rendered data">¿Exportar todas las filas?</span>
                          <input type="checkbox" value={exportAllData} onChange={(e) => setExportAllData(e.target.checked)} />
                      </div>
                      */}

            <MaterialTable
              key={10}
              onRowClick={onRowClick}
              title={"Parciales"}
              columns={[
                { title: "Cliente", field: "client" },
                { title: "Celular", field: "phone" },
                { title: "Materia", field: "subject", filtering: false },
                {
                  title: "Fecha p.",
                  field: "event_date_formatted",
                  filtering: false,
                },
                { title: "Tiempo p.", field: "event_time", filtering: false },
                {
                  title: "Solicitado",
                  field: "inserted_at",
                  filtering: false,
                },
                {
                  title: "Estado",
                  field: "state",
                  lookup: partialState(),
                },
                {
                  title: "Color",
                  field: "color",
                  lookup: colorsArray,
                },
                { title: "Precio", field: "cost", filtering: false },
              ]}
              tableRef={tableRef}
              data={data}
              localization={{
                toolbar: {
                  searchPlaceholder: "Buscar",
                },
              }}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Recargar",
                  isFreeAction: true,
                  onClick: updateDataTable,
                },
                (rowData) => ({
                  icon: "update",
                  tooltip: "Cambiar estado",
                  isFreeAction: false,
                  onClick: () => changeState(rowData),
                }),
                (rowData) => ({
                  icon: "content_copy",
                  tooltip: "Copiar",
                  isFreeAction: false,
                  onClick: () => copyToClipboard(rowData),
                }),

                (rowData) => ({
                  icon: "brush",
                  tooltip: "Colorear",
                  isFreeAction: false,
                  onClick: () => brushPartial(rowData),
                }),
                (rowData) => ({
                  icon: () => <WhatsappIcon />,
                  tooltip: "WhatsApp",
                  isFreeAction: false,
                  onClick: () => openWhatsApp(rowData),
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                // pageSize: getRowsCount(data),
                pageSizeOptions: [5, 10, 20, 50, 100, 1000],
                grouping: false,
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.color,
                }),
                search: false,
                sorting: false,
                filtering: true,
                // exportAllData,
                exportMenu: [
                  {
                    label: "Export PDF",
                    //// You can do whatever you wish in this function. We provide the
                    //// raw table columns and table data for you to modify, if needed.
                    // exportFunc: (cols, datas) => //console.log({ cols, datas })
                    exportFunc: (cols, datas) =>
                      ExportPdf(
                        cols,
                        datas,
                        `Parciales_PDF_${moment().format("DD_MMMM_YY_hh_mm_A")}`
                      ),
                  },
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(
                        cols,
                        datas,
                        `Parciales_CSV_${moment().format("DD_MMMM_YY_hh_mm_A")}`
                      ),
                  },
                ],
              }}
              isLoading={loading}
            />
          </GridItem>
          {partialColor && (
            <ColorModal
              colors={colors}
              open={openColorModal}
              setOpen={setOpenColorModal}
              setElementToColor={setPartialColor}
              user={user}
              getData={updateDataTable}
              tableRefForGetData={tableRef}
              elementToColor={partialColor}
              type="partial"
            />
          )}
          {partialStateInternal && (
            <StateModal
              colors={colors}
              open={openStateModal}
              setOpen={setOpenStateModal}
              setElementToState={setPartialStateInternal}
              user={user}
              getData={updateDataTable}
              tableRefForGetData={tableRef}
              elementToState={partialStateInternal}
              type="partial"
            />
          )}
          <Snackbar
            place="tr"
            color="success"
            icon={CheckIcon}
            message="Copiado"
            open={copied}
            closeNotification={() => setCopied(false)}
            close
          />
          <SearchById setService={setPartial} context="partial" />
        </GridContainer>
      ) : (
        <SingleLoading size={50} />
      )}
    </>
  );
};

export default Parciales;
