import { Container } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { mapDate } from "functions/comun";
import FormatMoney from "components/Money/FormatMoney";
import "../../../../assets/css/styles.css";

function StepOne({ data, notLastMessage }) {
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        <h3 style={{ textAlign: "center" }} className="textBoldPurple">
          ¡Hola, {data.client}!
        </h3>
        <br />
        <span
          style={{ display: "block", fontSize: 18 }}
          className="textBoldYellow"
        >
          Por favor, confirma la información de tu parcial
        </span>
      </GridItem>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "left" }}
        className="stepone--text"
      >
        <h5 className="noMargin">
          <b>Materia:</b> {data.subject}
        </h5>
        <h5 className="noMargin">
          <b>Valor:</b>{" "}
          <b style={{ color: "green" }}>
            <FormatMoney
              country={{ label: data.country }}
              dolarValue={data.dollarCost}
              value={data.cost}
            />
          </b>
        </h5>
        <h5 className="noMargin">
          <b>Fecha:</b> {mapDate(data.event_date).split(",")[0]}
        </h5>
        <h5 className="noMargin">
          <b>Hora:</b> {mapDate(data.event_date).split(",")[1]}
        </h5>
        <h5 className="noMargin">
          <b>Duración:</b> {data.event_time} min
        </h5>
      </GridItem>
      <br />
      <br />
      {!notLastMessage && (
        <GridItem
          xs={12}
          md={12}
          sm={12}
          lg={12}
          style={{ textAlign: "center" }}
          className="stepone--parrafo"
        >
          <span style={{ display: "block", fontSize: 17 }}>
            Tienes 8 horas para hacer tu pago, luego se deshabilitará el link
          </span>
        </GridItem>
      )}
    </Container>
  );
}

export default StepOne;
