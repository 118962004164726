import SingleLoading from "components/Loading/SingleLoading";
import FormatMoney from "components/Money/FormatMoney";
import { mapDate } from "functions/comun";
import useUserSession from "hooks/useUserSession";
import React, { useEffect, useState } from "react";
import PaymentService from "services/PaymentService";

export default (props) => {
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPay, setTotalPay] = useState(0);

  const { injectToken } = useUserSession();

  const getData = async () => {
    setLoading(true);
    const res = await PaymentService.findBillsByTeacher({ ...injectToken });
    if (res?.data?.data) {
      setData(res?.data?.data);
      setPayValue(res?.data?.data);
      setBalance(res?.data?.balance);
    }
    setLoading(false);
  };

  const setPayValue = (data) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].amount;
    }
    setTotalPay(total);
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  if (loading) return <SingleLoading />;

  return (
    <div>
      <h3
        className="good_font"
        style={{ color: "#d5004d", fontWeight: "bold" }}
      >
        Deuda a la fecha:{" "}
        <FormatMoney
          country={{ label: "Colombia" }}
          value={balance}
          fontColor={"#3c4858"}
        />
      </h3>
      <h3
        className="good_font"
        style={{ color: "#d5004d", fontWeight: "bold" }}
      >
        Pago total recibido:{" "}
        <FormatMoney
          country={{ label: "Colombia" }}
          value={totalPay}
          fontColor={"#3c4858"}
        />
      </h3>
      <h3
        className="good_font"
        style={{ color: "rgb(255, 188, 1)", fontWeight: "bold" }}
      >
        Pagos realizados
      </h3>
      <ul>
        {data.map((item) => (
          <li className="good_font" key={item._id}>
            <b>Admin ({item?.user?.names})</b> pago{" "}
            <b>{item.id_payments.length}</b> cobros por un valor de{" "}
            <b>
              <FormatMoney
                value={item.amount}
                country={{ label: "Colombia" }}
              />
            </b>{" "}
            el <b>{mapDate(item.created_at)}</b>
          </li>
        ))}
      </ul>
    </div>
  );
};
