import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  Grid,
  InputLabel,
  makeStyles,
  Slider,
  TextField,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import PhoneInput from "react-phone-input-2";
import CustomDateTimePicker from "components/DatePicker/CustomDateTimePicker";
import SingleLoading from "components/Loading/SingleLoading";
import GoBack from "components/Navigation/GoBack";
import FormatMoney from "components/Money/FormatMoney";
import FormsService from "services/FormsService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import CustomReactSelect from "components/Select/CustomReactSelect";
import PreRequestService from "services/PreRequestService";
import WhatsAppButton from "components/Buttons/WhatsappButton";
import ScheduleButton from "components/Buttons/ScheduleButton";
import useCountry from "hooks/useCountry";
import ConfigVarService from "services/ConfigVarService";

const useStyles = makeStyles((theme) => ({
  title1: {
    fontSize: 24,
    color: "#ffbc00",
  },
  title2: {
    fontSize: 18,
    textAlign: "center",
  },
  title3: {
    fontSize: 18,
    textAlign: "center",
    color: "#ffbc00",
  },
  label: {
    padding: 10,
    fontWeight: "bold",
  },
}));

const tryToParseInt = (stringNumber) => {
  try {
    return parseInt(stringNumber, 10);
  } catch (error) {}
  return 0;
};

const doFactorMinutes = (subjects, subject, persons, hours) => {
  const tempSaldo = factorPersons(subjects, subject, persons.value);
  // //console.log(tempSaldo, Math.round(tempSaldo));
  return Math.round(factorHours(tempSaldo, hours));
};

const factorHours = (saldo, hours) => {
  switch (hours) {
    case 1:
      return saldo * 1;
    case 2:
      return saldo * 0.9;
    case 3:
      return saldo * 0.82;
    case 4:
      return saldo * 0.77;
    case 5:
      return saldo * 0.76;
    default:
      return saldo * 0.75;
  }
};

const factorPersons = (subjects, subject, persons) => {
  const subjectObject = subjects.find((i) => i._id === subject);
  // //console.log("subjectObject:", subjectObject);
  const saldo = tryToParseInt(subjectObject.partial_base_cost);

  // //console.log("persons:", persons);
  // //console.log("saldo:", saldo);
  switch (persons) {
    case "una":
      return saldo * 0.43;
    // return saldo * 0.43;
    case "dos":
      return saldo * 0.53;
    // return saldo * 0.7;
    case "tres":
      return saldo * 0.63;
    case "cuatro":
      return saldo * 0.73;
    // return saldo * 1.1;
    case "cinco":
      return saldo * 0.83;
    // return saldo * 1.2;
    case "seis":
      return saldo * 1;
    // return saldo * 1.3;
    case "+seis":
      return saldo * 1;
    default:
      break;
  }
  return saldo;
};

const isPhoneValid = (phoneForValidate) => {
  return phoneForValidate && phoneForValidate.length > 10;
};

const isDateTimeValid = (dateTimeForValidate) => {
  return dateTimeForValidate && dateTimeForValidate.isValid();
};

export default () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState();
  const [idPreRequest, setIdPreRequest] = useState(null);
  const { paises } = useCountry();
  const [country, setCountry] = useState("");
  const [TRM, setTMR] = useState();

  const [dataState, setDataState] = useState({
    minutes: 20,
    subject: null,
    names: "",
    phone: "",
    dateTime: "",
    cost: 0,
    persons: { value: "una" },
    hours: 1,
    dollarCost: 0,
    country: "",
  });

  const setCurrencyMethod = (cost) => {
    // const totalNumber = (cost * 1.35) / 4637.87; //Valor variable
    const totalNumber = (cost * 1.35) / TRM; //Valor variable
    const integerPart = Math.trunc(totalNumber);
    if (totalNumber - integerPart > 0.9) {
      return integerPart + 1.9;
    }
    if (totalNumber - integerPart < 0.9) {
      return integerPart + 0.9;
    }
    return totalNumber;
  };

  const realCost = (cop, usd, country) => {
    if (country === "Colombia") {
      //10990
      // const totalCOP = (cost * 1.35) / 4637.87; //Valor variable
      const integerPart = Math.trunc(cop / 1000) * 1000;
      if (cop - integerPart > 990) {
        return integerPart + 1990;
      }
      if (cop - integerPart < 990) {
        return integerPart + 990;
      }
      return cop;
    }
    // const value = (Math.trunc((usd * 4637.87) / 100) + 1) * 100; //valor variable
    const value = (Math.trunc((usd * TRM) / 100) + 1) * 100; //valor variable
    return value;
  };

  const getSubjects = async () => {
    const response = await FormsService.getSubjects({});
    if (response?.data?.data) setSubjects(response?.data?.data);
  };

  useEffect(() => {
    getSubjects();
    getTRM();
  }, []);

  const getTRM = async () => {
    const res = await ConfigVarService.getTRM();
    // //console.log(res);
    setTMR(res?.data?.number_value);
  };

  const fakeLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(3);
      setLoading(false);
    }, 1000);
  };

  const saveData = async () => {
    setLoading(true);

    const dataForSave = {
      ...dataState,
      // dateTime: dataState.dateTime.toString(),
      cost:
        realCost(dataState.cost, dataState.dollarCost, dataState.country) *
        dataState.hours,
      costHour: realCost(
        dataState.cost,
        dataState.dollarCost,
        dataState.country
      ),
      subject: dataState.subject.label,
      persons: dataState.persons.value,
      phone: `+${dataState.phone}`,
    };
    // console.log({ ...dataForSave, hours: dataForSave.hours });
    //console.log(JSON.stringify(dataForSave));
    const res = await FormsService.saveTutorship({
      ...dataForSave,
      hours: dataForSave.hours,
    });
    if (res?.data?.message === "OK") {
      toast.success("¡Datos guardados!");
      setLoading(false);
      setStep(4);
    } else {
      toast.error("Ha ocurrido un error");
    }
  };

  if (loading) return <SingleLoading />;

  return (
    <div>
      <head>
        <meta
          name="title"
          content="Pre Exámenes / Tutorías / Talleres- Universidad"
        />
        <meta
          name="description"
          content="¿Necesitas ayuda con tus trabajos? con Copywrite tendrás Garantía / Calidad / Rapidez cotiza y conoce todos los beneficios que tenemos para ti"
        />
      </head>
      {step === 0 && <Step0 step={step} setStep={setStep} classes={classes} />}
      {step === 1 && (
        <Step1
          step={step}
          setStep={setStep}
          classes={classes}
          dataState={dataState}
          setDataState={setDataState}
          subjects={subjects}
          paises={paises}
          country={country}
          setCountry={setCountry}
        />
      )}
      {step === 2 && (
        <Step2
          step={step}
          setStep={setStep}
          classes={classes}
          fakeLoading={fakeLoading}
          dataState={dataState}
          setDataState={setDataState}
          subjects={subjects}
          idPreRequest={idPreRequest}
          setIdPreRequest={setIdPreRequest}
          country={country}
          setCurrencyMethod={setCurrencyMethod}
          realCost={realCost}
        />
      )}
      {step === 3 && (
        <Step3
          step={step}
          setStep={setStep}
          classes={classes}
          dataState={dataState}
          setDataState={setDataState}
          saveData={saveData}
          idPreRequest={idPreRequest}
          country={country}
        />
      )}
      {step === 4 && <Step4 classes={classes} />}
    </div>
  );
};

export const Step0 = ({ step, setStep, classes }) => {
  return (
    <>
      <div style={{ textAlign: "center", paddingTop: 80 }}>
        <a
          href="https://www.copywritecol.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={"/icon.png"}
            alt=""
            style={{ width: isMobile ? "50%" : 300 }}
          />
        </a>
      </div>
      <div style={{ textAlign: "center", padding: 40 }}>
        <h1 className={`good_font ${classes.title1}`}>
          <span className="black_font">Bienvenido a </span>
          <strong className="black_font">TUTORÍAS</strong>
          <b>
            {" "}
            <a
              href="https://www.copywritecol.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "rgb(255, 188, 0)" }}
            >
              Copywrite®
            </a>
          </b>
        </h1>
      </div>
      <div>
        <p className={`good_font ${classes.title2}`}>
          <span>Sigue los pasos para solicitar ayuda </span>
          <br />
          <span>
            ¡En 5 minutos estarás agendad@!{" "}
            <span role="img" aria-label="">
              👇
            </span>
          </span>
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
          onClick={() => setStep(1)}
        >
          Comenzar
        </Button>
      </div>
    </>
  );
};

export const Step1 = ({
  step,
  setStep,
  classes,
  dataState,
  setDataState,
  subjects,
  paises,
  country,
  setCountry,
}) => {
  const handleSubmit = async () => {
    console.log(dataState);
    if (dataState?.subject?.value) {
      if (dataState?.persons?.value) {
        setStep(2);
      } else {
        swal("Oops", "Selecciona una número de personas", "warning");
      }
    } else {
      swal("Oops", "Selecciona una asignatura valida", "warning");
    }
  };

  return (
    <>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Cotiza con Copywrite
      </h3>
      <GoBack back={() => setStep(step - 1)} />
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿En qué asignatura necesitas ayuda para tu <strong>TUTORIA</strong>?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <br />
          <CustomReactSelect
            required
            options={subjects.map((i) => ({ value: i._id, label: i.label }))}
            onChange={(value) => setDataState({ ...dataState, subject: value })}
            value={dataState.subject}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Para cuántas <strong>PERSONAS</strong> será la{" "}
          <strong>TUTORIA</strong>?
        </InputLabel>
        <div style={{ padding: "0px 20px", textAlign: "center" }}>
          <br />
          <Slider
            // onChangeCommitted={(event, value) => setMinutes(value)}
            onChange={(event, value) => {
              //setDataState({ ...dataState, minutes: value })
              switch (value) {
                case 1:
                  setDataState({ ...dataState, persons: { value: "una" } });
                  break;
                case 2:
                  setDataState({ ...dataState, persons: { value: "dos" } });
                  break;
                case 3:
                  setDataState({ ...dataState, persons: { value: "tres" } });
                  break;
                case 4:
                  setDataState({ ...dataState, persons: { value: "cuatro" } });
                  break;
                case 5:
                  setDataState({ ...dataState, persons: { value: "cinco" } });
                  break;
                case 6:
                  setDataState({ ...dataState, persons: { value: "seis" } });
                  break;
                case 7:
                  setDataState({ ...dataState, persons: { value: "+seis" } });
                  break;
                default:
                  break;
              }
            }}
            defaultValue={1}
            getAriaValueText={(value) => `${value} NOSE`}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            //valueLabelFormat={valueLabelFormat}
            step={1}
            marks
            min={1}
            max={7}
            // scale={(x) => x * 20}
          />
          <span style={{ fontSize: "1.25rem" }}>
            <b>
              {dataState.persons.value === "+seis" ? (
                <>Más de seis personas</>
              ) : (
                <>
                  {" "}
                  {dataState.persons.value === "una" ? (
                    <>
                      {" "}
                      {dataState.persons.value
                        .split("")
                        .map((x, i) => {
                          if (i === 0) return x.toUpperCase();
                          return x;
                        })
                        .join("")}{" "}
                      persona{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      {dataState.persons.value
                        .split("")
                        .map((x, i) => {
                          if (i === 0) return x.toUpperCase();
                          return x;
                        })
                        .join("")}{" "}
                      personas
                    </>
                  )}
                </>
              )}{" "}
            </b>
          </span>
          {/* <CustomReactSelect
            required
            options={[
              { value: "uno", label: "Una" },
              { value: "dos", label: "Dos" },
              { value: "tres", label: "Tres" },
              { value: "cuatro", label: "Cuatro" },
              { value: "cinco", label: "Cinco" },
              { value: "seis", label: "Seis" },
              { value: "+seis", label: "Mas de seis" },
            ]}
            onChange={(value) => console.log(value)}
            // onChange={(value) => setDataState({ ...dataState, persons: value })}
            value={dataState.persons}
          /> */}
        </div>
        <br />
        <div style={{ textAlign: "left", padding: "5px 0px" }}>
          <br />
          <InputLabel className={`${classes.label} good_font`}>
            ¿Cuántas HORAS de TUTORÍA quieres tomar?
          </InputLabel>
          <div style={{ padding: "0px 15px", textAlign: "center" }}>
            {/* <TextField
            type={"number"}
            required
            placeholder="Escribe un número"
            fullWidth
            value={dataState.hours}
            onChange={(e) =>
              setDataState({ ...dataState, hours: e.target.value })
            }
          /> */}
            <Slider
              // onChangeCommitted={(event, value) => setMinutes(value)}
              onChange={(event, value) => {
                //setDataState({ ...dataState, minutes: value })
                // setHours(value);
                setDataState({ ...dataState, hours: value });
              }}
              value={dataState.hours}
              defaultValue={1}
              getAriaValueText={(value) => `${value} NOSE`}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              //valueLabelFormat={valueLabelFormat}
              step={1}
              marks
              min={1}
              max={9}
              // scale={(x) => x * 20}
            />
            <span style={{ fontSize: "1.25rem" }}>
              <b>
                {dataState.hours}{" "}
                {dataState.hours > 1 ? <> horas.</> : <>hora.</>}
              </b>
            </span>
          </div>
        </div>
        <br />
        <div>
          <InputLabel className={`${classes.label} good_font`}>
            ¿Desde que <strong> PAÍS </strong>estas realizando tu cotización?
          </InputLabel>
          <br />
          <div style={{ padding: "0px 20px", textAlign: "center" }}>
            <CustomReactSelect
              required
              options={paises.map((i) => ({ value: i.label, label: i.value }))}
              onChange={(value) => setCountry(value)}
              value={country}
            />
          </div>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
          onClick={handleSubmit}
        >
          Continuar
        </Button>
        <br />
        <br />
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export const Step2 = ({
  step,
  setStep,
  classes,
  fakeLoading,
  dataState,
  setDataState,
  subjects,
  idPreRequest,
  setIdPreRequest,
  country,
  setCurrencyMethod,
  realCost,
}) => {
  const cost = doFactorMinutes(
    subjects,
    dataState.subject.value,
    dataState.persons,
    dataState.hours
  );

  const dollarCost = setCurrencyMethod(cost);

  const onNext = () => {
    // setDataState({
    //   ...dataState,
    //   cost: realCost(cost, dollarCost, country.label),
    //   dollarCost,
    //   country: country.label,
    // });
    updateStateRequest("Confirmado");
    fakeLoading();
  };

  useEffect(() => {
    if (!idPreRequest) createPreRequest();
  }, [idPreRequest]); // eslint-disable-line

  const createPreRequest = async () => {
    if (dataState?.subject?.value) {
      const res = await PreRequestService.createPreRequest({
        ...dataState,
        cost: realCost(cost, dollarCost, country.label),
        request_type: "Tutoria",
        dollarCost,
        country: country.label,
      });
      setDataState({
        ...dataState,
        cost: realCost(cost, dollarCost, country.label),
        request_type: "Tutoria",
        dollarCost,
        country: country.label,
      });
      if (res?.data?.message === "OK" && res?.data?.data?._id) {
        setIdPreRequest(res?.data?.data?._id);
      }
    } else {
      swal("Oops", "Selecciona una asignatura valida", "warning");
    }
  };

  const updateStateRequest = async (state) => {
    await PreRequestService.updatePreRequest({
      idPreRequest,
      state,
      ...dataState,
    });
    setDataState({
      country: country.label,
      dollarCost,
      cost: realCost(cost, dollarCost, country.label),
      idPreRequest,
      state,
      ...dataState,
    });
    // //console.log(res?.data);
  };

  const round = (cop) => {
    const integerPart = Math.trunc(cop / 1000) * 1000;
    if (cop - integerPart > 990) {
      return integerPart + 1990;
    }
    if (cop - integerPart < 990) {
      return integerPart + 990;
    }
    return cop;
  };

  return (
    <>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Cotiza con Copywrite
      </h3>
      <GoBack back={() => setStep(step - 1)} />
      <div style={{ textAlign: "center", padding: 10 }}>
        <span className={`good_font`}>
          <span>Tu </span>
          <strong>TUTORÍA/HORA </strong>
          <span> tiene un costo de: </span>
          <strong style={{ fontSize: "23px" }}>
            <br />
            <FormatMoney
              value={round(cost)}
              dolarValue={dollarCost}
              country={country}
            />
          </strong>
          {/* <span>Pesos Colombianos</span> */}
        </span>
        <br />
        <br />
        <div className={`good_font ${classes.title3}`}>
          <b>¿QUIERES AGENDAR TU TUTORÍA? </b>
        </div>
        {/* <div style={{ padding: 10 }}>
          <span className={`good_font`}>
            <span role="img" aria-label="">
              🔴
            </span>
            Si registras tu solicitud con MENOS de 48 horas de anticipación, se
            agendará como COPYWRITE EXPRESS (Cobrariamos 20% adicional al costo
            de arriba 👆)
          </span>
        </div> */}
      </div>

      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
          onClick={onNext}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
};

export const Step3 = ({
  step,
  setStep,
  classes,
  dataState,
  setDataState,
  saveData,
  idPreRequest,
  country,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPhoneValid(dataState.phone)) {
      if (isDateTimeValid(dataState.dateTime)) {
        saveData();
        updatePreRequest("Finalizado");
      } else {
        swal("Oops", "Selecciona una fecha valida", "warning");
      }
    } else {
      swal("Oops", "Ingrese un número de celular valido", "warning");
    }
  };

  const updatePreRequest = async (state) => {
    await PreRequestService.updatePreRequest({
      ...dataState,
      idPreRequest,
      state,
    });
    // //console.log(res?.data);
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <h3
        className="good_font"
        style={{ textAlign: "center", color: "#ffbc00", fontWeight: "bold" }}
      >
        Completa tu información
      </h3>
      <GoBack back={() => setStep(step - 1)} />
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Como te llamas?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <TextField
            autoFocus
            required
            placeholder="Escribe tu nombre y apellido"
            fullWidth
            value={dataState.names}
            onChange={(e) =>
              setDataState({ ...dataState, names: e.target.value })
            }
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cúal es tu número de WhatsApp?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <PhoneInput
            required
            country={country.value.toLowerCase()}
            value={dataState.phone}
            onChange={(phone) => setDataState({ ...dataState, phone })}
          />
        </div>
      </div>
      <div style={{ textAlign: "left", padding: "5px 20px" }}>
        <br />
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cúal es la fecha y hora de tu <b>TUTORÍA</b>?
        </InputLabel>
        <div style={{ padding: "0px 15px", textAlign: "center" }}>
          <CustomDateTimePicker
            setValue={(dateTime) => setDataState({ ...dataState, dateTime })}
            componentWidth="100%"
          />
        </div>
      </div>
      {/* select */}
      {/*
        <div style={{ textAlign: "center", padding: "5px 0px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cúal es la fecha de tu <b>PARCIAL o QUIZ</b>?
        </InputLabel>
        <div style={{ padding: "0px 40px" }}>
          <CustomDatePicker />
        </div>
      </div>
      <div style={{ textAlign: "center", padding: "5px 0px" }}>
        <InputLabel className={`${classes.label} good_font`}>
          ¿Cúal es la hora de tu <b>PARCIAL o QUIZ</b>?
        </InputLabel>
        <div style={{ padding: "0px 40px" }}>
          <CustomTimePicker />
        </div>
      </div>
      */}

      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          classes={{ label: "buttonLabel" }}
        >
          Guardar
        </Button>
      </div>
    </form>
  );
};

export const Step4 = ({ classes }) => {
  return (
    <>
      <div style={{ textAlign: "center", paddingTop: 80 }}>
        <img
          src={"/icon.png"}
          alt=""
          style={{ width: isMobile ? "40%" : 300 }}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          padding: 40,
          color: "#ffbc00",
          fontWeight: "bold",
          fontSize: 18,
        }}
      >
        <div className="good_font red_font">
          ¡Genial!
          <span role="img" aria-label="">
            ⚡
          </span>{" "}
          Recibimos tu solicitud.
        </div>
        <br />

        <div className="good_font black_font">
          {/* <span role="img" aria-label="">
            🔴
          </span> */}
          Te contactaremos en 5 minutos via WhatsApp ó SMS.
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="good_font">
          <strong>¿Quieres volver a WhatsApp?</strong>
          {/* <span role="img" aria-label="">
            👇
          </span> */}
        </div>
        <br />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <WhatsAppButton
              text="Volver con Maicol"
              link="https://wa.link/tgp6yx"
            ></WhatsAppButton>
          </Grid>
        </Grid>
        {/* <div className="good_font">
          <a href="https://wa.link/tgp6yx">
            <span role="img" aria-label="">
              🔗
            </span>
            Volver con Maicol
          </a>
        </div>
        <div className="good_font">
          <a href="https://wa.link/caqhrq">
            <span role="img" aria-label="">
              🔗
            </span>
            Volver con Julian
          </a>
        </div> */}
        <br />
        <div className="good_font">
          ¿Quieres agendar algo más?
          {/* <span role="img" aria-label="">
            👇
          </span> */}
        </div>
        <br />
        <div className="good_font">
          <b>
            {/* <a href="https://www.copywritecol.com/">¡Agendate aquí!</a> */}
            <ScheduleButton
              text="¡Agendate aquí!"
              link="https://www.copywritecol.com/"
            ></ScheduleButton>
          </b>
        </div>
      </div>
    </>
  );
};
