import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import NextPartialButton from "components/CustomButtons/NextPartialButton";
import PartialService from "services/PartialService";
import { useHistory } from "react-router-dom";
import { mapDateFormat } from "functions/comun";
import SingleLoading from "components/Loading/SingleLoading";

import "../../assets/css/styles.css";
import GoBack from "components/Navigation/GoBack";

const NextPartials = () => {
  const { user } = useSelector((state) => state.user);

  const [partials, setPartials] = useState([]);
  const [acceptedPartials, setAcceptedPartials] = useState([]);
  const [partialsInCalendar, setPartialsInCalendar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  const getPartials = async () => {
    const res = await PartialService.findAllMarketplace({ user });
    if (res?.data?.data) {
      //console.log(res?.data?.data);
      setPartials(
        res?.data?.data.filter((item) => {
          if (!item.assigned_to) {
            return filterByDate(item);
          }
          return false;
        })
      );
      setLoading(false);
    }
  };

  const getAcceptedPartials = async () => {
    setLoading(true);
    const res = await PartialService.findByTeacher({ user });
    if (res?.data?.data) {
      setAcceptedPartials(
        res?.data?.data.filter(
          (item) => filterByDate(item) && !item.is_inserted_at_sales_excel
        )
      );
      setPartialsInCalendar(
        res?.data?.data.filter(
          (item) => filterByDate(item) && item.is_inserted_at_sales_excel
        )
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getPartials();
    getAcceptedPartials();
  }, []); // eslint-disable-line

  const filterByDate = (item) => {
    const now = new Date().getTime();
    const EventDate = new Date(item.event_date).getTime();
    if (now > EventDate) {
      return false;
    }
    return true;
  };

  if (loading) return <SingleLoading size={50} />;

  switch (status) {
    case "disponibles":
      return <Disponibles setStatus={setStatus} partials={partials} />;
    case "aceptados":
      return (
        <Aceptados setStatus={setStatus} acceptedPartials={acceptedPartials} />
      );
    case "agendados":
      return (
        <Agendados
          setStatus={setStatus}
          partialsInCalendar={partialsInCalendar}
        />
      );
    default:
      return (
        <DefaultMenu
          setStatus={setStatus}
          partials={partials}
          acceptedPartials={acceptedPartials}
          partialsInCalendar={partialsInCalendar}
        />
      );
  }
};

const DefaultMenu = ({
  setStatus,
  partials,
  acceptedPartials,
  partialsInCalendar,
}) => {
  return (
    <div>
      <div className="dashboard--items">
        <div
          className="item1"
          style={{ cursor: "pointer" }}
          onClick={() => setStatus("disponibles")}
        >
          <div className="mobile-item">
            <h3 style={{ fontSize: "0.9rem" }}>Disponibles</h3>
            <h2>{partials.length}</h2>
          </div>
        </div>
        <div
          className="item2"
          style={{ cursor: "pointer" }}
          onClick={() => setStatus("aceptados")}
        >
          <div className="mobile-item">
            <h3 style={{ fontSize: "0.9rem" }}>Aceptados</h3>
            <h2>{acceptedPartials.length}</h2>
          </div>
        </div>
        <div
          className="item3"
          style={{ cursor: "pointer" }}
          onClick={() => setStatus("agendados")}
        >
          <div className="mobile-item">
            <h3 style={{ fontSize: "0.9rem" }}>Agendados</h3>
            <h2>{partialsInCalendar.length}</h2>
          </div>
        </div>
      </div>
      <div className="next-partial-text good_font">
        <strong>Disponibles</strong>
        <br />
        Solicitudes que puedes aceptar ó rechazar.
        <br />
        <br />
        <strong>Aceptados</strong>
        <br />
        Solicitudes que tú aceptaste. Puede que se confirmes ó no.
        <br />
        <br />
        <strong>Agendados</strong>
        <br />
        Solicitudes que deberás atender
        <br />
        Debes tenerlos agendados en Calendar.
      </div>
    </div>
  );
};

const Disponibles = ({ setStatus, partials }) => {
  const history = useHistory();
  return (
    <>
      <GoBack
        back={() => setStatus("")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className="container--nextpartials">
        <h3 className="npartials--title">Parciales Disponibles</h3>
        {partials.length === 0 && (
          <span className="npartials--span">No hay parciales disponibles</span>
        )}
        {partials.map((item) => (
          <div key={item._id}>
            <NextPartialButton
              backgroundColor="#f1d67b"
              onHandleClick={() =>
                history.push(`/teacher/parciales/${item._id}`)
              }
              state={item.state}
              id={item._id}
              subject={item.subject}
              durationInMinutes={item.event_time}
              costInCOP={item.cost}
              date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
              time="Minutos"
            />
            <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
          </div>
        ))}
      </div>
    </>
  );
};

const Aceptados = ({ setStatus, acceptedPartials }) => {
  const history = useHistory();
  return (
    <>
      <GoBack
        back={() => setStatus("")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className="container--nextpartials">
        <h3 className="npartials--title">Parciales Aceptados</h3>
        {acceptedPartials.length === 0 && (
          <span className="npartials--span">
            Aún no has aceptado ningun parcial
          </span>
        )}
        {acceptedPartials.map((item) => (
          <div key={item._id}>
            <NextPartialButton
              backgroundColor="#aec4c1"
              onHandleClick={() =>
                history.push(`/teacher/parciales/${item._id}`)
              }
              state={item.state}
              id={item._id}
              subject={item.subject}
              durationInMinutes={item.event_time}
              costInCOP={item.cost}
              date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
              time="Minutos"
            />
            <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
          </div>
        ))}
      </div>
    </>
  );
};

const Agendados = ({ setStatus, partialsInCalendar }) => {
  const history = useHistory();
  return (
    <>
      <GoBack
        back={() => setStatus("")}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className="container--nextpartials">
        <h3 className="npartials--title">Parciales Agendados</h3>
        {partialsInCalendar.length === 0 && (
          <span className="npartials--span">No hay parciales disponibles</span>
        )}
        {partialsInCalendar.map((item) => (
          <div key={item._id}>
            <NextPartialButton
              backgroundColor="#f1d67b"
              onHandleClick={() =>
                history.push(`/teacher/parciales/${item._id}`)
              }
              state={item.state}
              id={item._id}
              subject={item.subject}
              durationInMinutes={item.event_time}
              costInCOP={item.cost}
              date={mapDateFormat(item.event_date, "ddd DD MMM, hh:mm A")}
              time="Minutos"
            />
            <hr style={{ marginTop: 5, marginBottom: 5, width: "80%" }} />
          </div>
        ))}
      </div>
    </>
  );
};

export default NextPartials;
