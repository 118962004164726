import GoBack from "components/Navigation/GoBack";
import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function QYA() {
  const [view, setView] = useState(null);

  if (view) {
    switch (view) {
      case "tutorial":
        return <ViewTutorial setBack={setView} />;
      case "tips":
        return <ViewTips setBack={setView} />;
      case "q&a":
        return <Viewqya setBack={setView} />;
      default:
        break;
    }
  }

  return (
    <div className="container--dashboard">
      <div className="dashboard--items">
        <div
          className="item1"
          style={{ cursor: "pointer" }}
          onClick={() => setView("tutorial")}
        >
          <div className="mobile-item">
            <h3>Tutorial Plataforma</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item2"
          style={{ cursor: "pointer" }}
          onClick={() => setView("tips")}
        >
          <div className="mobile-item">
            <h3>Tips</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
        <div
          className="item3"
          style={{ cursor: "pointer" }}
          onClick={() => setView("q&a")}
        >
          <div className="mobile-item">
            <h3>Preguntas frecuentes</h3>
            <ArrowForwardIcon fontSize="large" className="item-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // padding: isMobile ? "1rem 3rem 3rem 3rem" : "3rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: "bold",
  },
}));

const ViewTutorial = ({ setBack }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <GoBack
        back={() => setBack(null)}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className={classes.root}>
        <Accordion
          style={{ backgroundColor: "#aec4c1", cursor: "none" }}
          expanded={true}
          //   onChange={handleChange("panel1")}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Como recibir, aceptar y gestionar requerimientos en Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ textAlign: "center", width: "100%" }}>
              <iframe
                width={isMobile ? "100%" : "560"}
                height={isMobile ? "" : "315"}
                src="https://www.youtube.com/embed/pQ_GcPH8YPg"
                title="Video instructivo 1.0 (Servicio tutores)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ backgroundColor: "#ef788e", cursor: "none" }}
          expanded={true}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Como cobrar los requerimientos que haga con Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ textAlign: "center", width: "100%" }}>
              <iframe
                width={isMobile ? "100%" : "560"}
                height={isMobile ? "" : "315"}
                src="https://www.youtube.com/embed/-QNi4HJAhww"
                title="Video instructivo 1.1 (Cobros tutores)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ backgroundColor: "#f1d67b", cursor: "none" }}
          expanded={true}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Herramientas que te pueden servir
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ textAlign: "center", width: "100%" }}>
              <iframe
                width={isMobile ? "100%" : "560"}
                height={isMobile ? "" : "315"}
                src="https://www.youtube.com/embed/wR8EDm9ObcU"
                title="Vídeo instructivo 1.2 (Tools-autocotizador tutores)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

const Viewqya = ({ setBack }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = useStyles();
  return (
    <>
      <GoBack
        back={() => setBack(null)}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />{" "}
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cuál es el calendario de pagos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nuestros pagos se efectúan mensualmente, específicamente en el
              último día de cada mes. La fecha de corte para considerar los
              requerimientos realizados es el día 15. Por ejemplo, en el pago
              del día 30 de Junio, se pagarán todos los requerimientos hechos
              desde el 16 de Mayo al 15 Junio.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿A través de qué método realizan los pagos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Los pagos se efectúan utilizando la cuenta bancaria proporcionada
              por el tutor al momento de registrar sus datos. Si deseas
              actualizar esta información, puedes comunicarte con nosotros a
              través de la línea de WhatsApp al número 3224120987, y estaremos
              encantados de realizar los ajustes necesarios.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cuál es el proceso cuando obtengo una garantía por una mala nota?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Una vez que recibas el material de retroalimentación, tómese el
              tiempo para revisarlo detenidamente. Si no tienes ninguna objeción
              sobre el resultado, la garantía se considera válida y se continúa
              con el proceso. Sin embargo, si tienes alguna objeción, se
              enviarán tus inquietudes al estudiante correspondiente, y se
              iniciará un proceso de validación para llegar a un acuerdo sobre
              el error señalado, tanto por parte del estudiante como del tutor.
              Durante este proceso, utilizaremos la regla de 3 para evaluar la
              nota prometida (4.0). Una vez que obtengamos el valor
              correspondiente, lo deduciremos de tu pago inicial utilizando la
              siguiente fórmula: X-(-X*Y/M).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Con qué frecuencia recibiré servicios?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              La frecuencia de los servicios que recibirás puede variar según
              las materias que manejes y tu desempeño en ellas. Si eres
              altamente competente en tu área y tus requerimientos son de alta
              calidad, puedes esperar una recurrencia continua y frecuente en la
              asignación de servicios. Sin embargo, si no cumples con los
              estándares de calidad, presentación y resultados que exigimos, el
              tiempo entre los servicios puede variar. Siempre buscamos la
              excelencia en cada entrega, por lo que la frecuencia dependerá de
              tu habilidad para cumplir con esos criterios.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Se aceptan los servicios a través de la plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Sí, las solicitudes de servicios se gestionan mediante nuestra
              plataforma, a excepción de los talleres. En el caso de los
              talleres, recibirás mensajes directos por parte de nuestros
              asesores de servicio al cliente. Es importante que estés pendiente
              de la plataforma para poder aceptar oportunamente los servicios
              que se te asignen. Mantenerse al tanto de la plataforma es
              fundamental para garantizar una comunicación fluida y una
              aceptación rápida de los servicios.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo se determinan las tarifas de pago para mis servicios?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Las tarifas de pago se determinan teniendo en cuenta la dificultad
              de la materia y el tiempo requerido para completar el servicio. De
              esta manera, se establecen valores fijos para cada servicio. Si
              deseas conocer el monto correspondiente a tus servicios y saber
              cuánto se te pagará, puedes ingresar a{" "}
              <a
                style={{ color: "blue" }}
                href="https://app.copywritecol.com/teacher/cotizador"
                target="_blank"
              >
                Copywritecol.com/ingreso-tutores/Tools/auto-cotizador
              </a>
              . En esta herramienta encontrarás las tarifas de pago. Sin
              embargo, es importante tener en cuenta que en el caso de los
              talleres, el precio inicial lo estableces tú como tutor.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo puedo acceder a la plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Para acceder a la plataforma, sigue estos pasos: <br />
              <br />
              1. Ingresa a{" "}
              <a
                style={{ color: "blue" }}
                href="https://www.copywritecol.com"
                target="_blank"
              >
                copywritecol.com.
              </a>{" "}
              <br />
              2. Busca y selecciona la opción "Ingreso tutores".
              <br /> 3. Introduce tu nombre de usuario y contraseña en los
              campos correspondientes.
              <br /> 4. Una vez ingresados tus datos, se abrirá la interfaz de
              la plataforma, donde podrás visualizar y acceder a los recursos y
              herramientas disponibles. <br />
              <br /> Recuerda que también puedes encontrar información sobre
              cómo acceder a la plataforma en los recursos audiovisuales
              disponibles, así como en el inicio del siguiente video:
              <br />
              <br />
              <a
                style={{ color: "blue" }}
                href="https://www.youtube.com/watch?v=pQ_GcPH8YPg&list=PLQ3Q9GM1SVc47dixqVppZ3S9FAe3LY4R-"
                targe="_blank"
              >
                https://www.youtube.com/watch?v=pQ_GcPH8YPg&list=PLQ3Q9GM1SVc47dixqVppZ3S9FAe3LY4R-
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Existe algún tipo de contrato con los tutores?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite, no manejamos ningún contrato o compromiso vinculante
              con nuestros tutores. Tú tienes la libertad de ingresar y salir en
              cualquier momento, así como de estudiar o tener otro trabajo
              adicional. No existen restricciones en cuanto a tu disponibilidad.
              Nuestra única exigencia para que puedas mantener tu participación
              en Copywrite es mantener altos estándares de calidad, conocimiento
              y servicio. Si cumples con estas bases, podrás obtener
              reconocimiento y notificaciones positivas por tu desempeño.
              Valoramos tu compromiso y habilidades, y te brindamos la
              flexibilidad necesaria para adaptarte a tus necesidades y
              horarios.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Es posible realizar el pago antes de la fecha acordada?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No, lamentablemente no es posible adelantar el pago antes de la
              fecha acordada. Tenemos un gran número de tutores y contamos con
              un equipo de contabilidad dedicado exclusivamente a gestionar los
              pagos de manera eficiente. Si se altera el orden de los pagos,
              podría generarse algún inconveniente en el próximo ciclo de pagos.
              Por esta razón, realizamos los pagos mensuales con fecha de corte
              en el día 15 de cada mes. Esta metodología nos permite mantener un
              proceso ordenado y eficiente para ambas partes involucradas.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo puedo acceder al material de preparación en la plataforma?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Para acceder al material de preparación, tienes dos opciones. La
              primera es a través del enlace que te enviamos por WhatsApp.
              Simplemente haz clic en el enlace y te llevará directamente al
              material correspondiente. La segunda opción es ingresar
              directamente a tu portal en Copywritecol.com. Una vez allí, busca
              el requerimiento que tienes agendado y dentro encontrarás los
              temas, diapositivas y otros documentos adjuntos que forman parte
              del material de preparación. Recuerda que en ambos casos debes
              iniciar sesión con tu usuario y contraseña para poder acceder al
              contenido.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo recibiré las notificaciones de los servicios?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Todas las notificaciones de los servicios te llegarán a través de
              tres medios: SMS, WhatsApp o directamente podrás visualizarlas en
              la plataforma. Dependiendo de la preferencia que hayas establecido
              o la información específica del servicio, recibirás las
              notificaciones por alguno de estos canales. Para obtener una
              información más completa y general sobre el proceso, te
              recomendamos que veas el siguiente video:
              <br />
              <br />
              <a
                style={{ color: "blue" }}
                href="https://www.youtube.com/watch?v=pQ_GcPH8YPg&list=PLQ3Q9GM1SVc47dixqVppZ3S9FAe3LY4R-"
                target="_blank"
              >
                https://www.youtube.com/watch?v=pQ_GcPH8YPg&list=PLQ3Q9GM1SVc47dixqVppZ3S9FAe3LY4R-
              </a>
              <br />
              <br />
              De esta manera, podrás estar al tanto de los servicios asignados y
              asegurarte de estar informado/a de manera oportuna.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

const ViewTips = ({ setBack }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <GoBack
        back={() => setBack(null)}
        gridItemStyle={{ marginLeft: -41, marginTop: -40 }}
      />
      <div className={classes.root}>
        <Accordion
          style={{ backgroundColor: "#aec4c1", cursor: "none", width: "100%" }}
          expanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Como hacer un Parcial Excelente?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ textAlign: "center", width: "100%" }}>
              <iframe
                width={isMobile ? "100%" : "560"}
                height={isMobile ? "" : "315"}
                src="https://www.youtube.com/embed/PdAcJVuT5Y0"
                title="Presentación correcta de parciales"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ backgroundColor: "#ef788e", cursor: "none" }}
          expanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Como ser el mejor tutor en tutorias y talleres?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ textAlign: "center", width: "100%" }}>
              <iframe
                width={isMobile ? "100%" : "560"}
                height={isMobile ? "" : "315"}
                src="https://www.youtube.com/embed/6WuwkOtCz_Q"
                title="Video presentación correcta de talleres y tutorias"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ backgroundColor: "#f1d67b", cursor: "none" }}
          expanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Como ganar más dinero con Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ textAlign: "center", width: "100%" }}>
              <iframe
                width={isMobile ? "100%" : "560"}
                height={isMobile ? "" : "315"}
                src="https://www.youtube.com/embed/XwAG2PFqk2A"
                title="Video de crecimiento en copywrite"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default QYA;
