import { css } from "@emotion/css";
import { Container } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import Wompi from "components/Wompi/Wompi";
import React from "react";
import "../../../../assets/css/styles.css";

const styles = css`
  .payment-selected {
    border-style: solid;
    border-width: 5px;
    border-color: #ffbc00;
    border-radius: 10px;
  }
`;

function StepThree({ data, typeObject }) {
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      className={styles}
    >
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ textAlign: "center", marginBottom: "20px", marginTop: "1rem" }}
      >
        <span
          style={{ display: "block", fontSize: 20, marginTop: 20 }}
          className="textBoldYellow"
        >
          Haz click sobre el método de pago para finalizarlo
        </span>
      </GridItem>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        lg={12}
        style={{ display: "flex", marginTop: "1rem" }}
      >
        <Wompi idObjectCW={data._id} typeObject={typeObject} />
      </GridItem>
      <div className="stepthree">
        <h4 className="good_font">
          Aquí puedes pagar con todas tus tarjetas de Crédito y Débito, PSE,
          Nequi, Bancolombia y Corresponsal Bancario.
        </h4>
      </div>
    </Container>
  );
}

export default StepThree;
