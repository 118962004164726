import { TextareaAutosize } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import SingleLoading from "components/Loading/SingleLoading";
import FormatMoney from "components/Money/FormatMoney";
import GoBack from "components/Navigation/GoBack";
import { mapDate } from "functions/comun";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PaymentService from "services/PaymentService";
import Item from "./Item";
import Button from "components/CustomButtons/Button";
import { mapDateFormat } from "functions/comun";

const translateObject = (object) => {
  switch (object) {
    case "TUTORSHIP":
      return "Tutoria";
    case "HOMEWORK":
      return "Taller";
    case "PARTIAL":
      return "Parcial";

    default:
      return "Error";
  }
};

const InReview = ({ state = "ASKED" }) => {
  const user = useSelector((state) => state.user.user);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  const getData = async () => {
    setLoading(true);
    const res = await PaymentService.findByTeacher({
      token: user.token,
      state,
    });
    // console.log(res?.data?.data);
    if (res?.data?.data) setData(res?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    setDataDetail(null);
  }, [state]); // eslint-disable-line

  if (dataDetail) {
    return <Detail data={dataDetail} setDataDetail={setDataDetail} />;
  }

  return (
    <div>
      {loading && <SingleLoading />}
      {data
        .sort((i, j) => {
          if (
            new Date(i?.object?.event_date).getTime() >
            new Date(j?.object?.event_date).getTime()
          ) {
            return -1;
          }
          if (
            new Date(i?.object?.event_date).getTime() <
            new Date(j?.object?.event_date).getTime()
          ) {
            return 1;
          }
          return 0;
        })
        .map((i) => (
          <Item
            key={i._id}
            title={`${translateObject(i?.type_object)} - ${i?.object?.subject}`}
            eventDate={i?.object?.event_date}
            paymentDate={i?.created_at}
            amount={i?.amount}
            handleOnClick={() => setDataDetail(i)}
          />
        ))}
    </div>
  );
};

const Detail = ({ data, setDataDetail }) => {
  const { object: partial } = data;
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  const getComments = async () => {
    setLoading(true);
    const res = await PaymentService.findComments({
      token: user.token,
      idPayment: data._id,
    });
    if (res?.data?.data) setComments(res.data.data);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading1(true);
    const res = await PaymentService.addComment({
      token: user.token,
      text: comment,
      idPayment: data._id,
    });
    if (res.data.data) {
      toast.success("Comentario agregado correctamente!");
      setComment("");
      getComments();
    }
    setLoading1(false);
  };

  const stateTranslate = (state) => {
    if (state === "ASKED") return "En revisión";
    if (state === "APPROVED") return "Aprovado";
    if (state === "DENIED") return "Rechazado";
    if (state === "PAID") return "Pagado";
    return state;
  };

  useEffect(() => {
    getComments();
  }, []); // eslint-disable-line

  return (
    <form method="post" onSubmit={handleSubmit}>
      <GridContainer>
        <GoBack back={() => setDataDetail(null)} />
        <div style={{ textAlign: "start" }}>
          <h3 style={{ fontSize: "1.25rem" }} className="good_font">
            <b>{`${translateObject(data?.type_object)}`}</b> -{" "}
            {`${partial?.subject}`}
          </h3>
          <h4 style={{ fontSize: "1.25rem" }} className="good_font">
            <b>Valor del cobro: </b>
            <FormatMoney
              value={partial?.debt_to_teacher}
              fontSize={18}
              fontColor="#3c4858"
              country={{ label: "Colombia" }}
            />
          </h4>
          <h4 style={{ fontSize: "1.25rem" }} className="good_font">
            <b>Fecha del evento: </b>
            {/* <p style={{ fontSize: "18px", fontColor: "#3c4858" }}> */}
            {mapDateFormat(partial?.event_date, "DD MMM YYYY, hh:mm A")}
            {/* </p> */}
          </h4>
          <h4 style={{ fontSize: "1.25rem" }} className="good_font">
            <b>Estado: </b>
            {/* <p style={{ fontSize: "18px", fontColor: "#3c4858" }}> */}
            {stateTranslate(data.state)}
            {/* </p> */}
          </h4>
          {data.state === "PAID" && (
            <h4 style={{ fontSize: "1.25rem" }} className="good_font">
              <b>Fecha de pago: </b>
              {/* <p style={{ fontSize: "18px", fontColor: "#3c4858" }}> */}
              {mapDateFormat(data.updated_at, "DD MMM YYYY, hh:mm A")}
              {/* </p> */}
            </h4>
          )}

          <div style={{ paddingTop: "10px" }}>
            <h5 style={{ marginBottom: "-10px" }}>Comentarios: </h5>
            {loading && <SingleLoading />}
            {comments.map((i) => (
              <ItemComment
                key={i._id}
                text={i.text}
                user={i.type_user}
                date={i.created_at}
              />
            ))}
            <br />
          </div>
          <TextareaAutosize
            required
            style={{
              width: "100%",
              borderRadius: 10,
              textAlign: "justify",
              border: ".5px solid #2b2527",
              padding: 13,
            }}
            aria-label="minimum height"
            minRows={4}
            maxRows={8}
            placeholder="Escribe alguna información adicional"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div style={{ display: "grid" }}>
            <Button
              loading={loading1}
              style={{
                backgroundColor: "#f9004d",
                color: "white",
                borderRadius: "10px",
                justifySelf: "center",
                textTransform: "none",
                fontWeight: "bold",
              }}
              variant="contained"
              type="submit"
            >
              Enviar
            </Button>
          </div>
        </div>
      </GridContainer>
    </form>
  );
};

const ItemComment = ({ text, user, date }) => (
  <>
    <div
      style={{
        backgroundColor: user === "TEACHER" ? "rgb(241, 214, 123)" : "#aec4c1",
        fontSize: 12,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <div>{text}</div>
      <div style={{ textAlign: "right" }}>
        <b style={{ fontSize: 9 }}>
          Agregado por {user === "TEACHER" ? "tí" : "ADMIN"} el {mapDate(date)}
        </b>
      </div>
    </div>
    <br />
  </>
);
export default InReview;
