import { css } from "@emotion/css";
import React from "react";

function StepGarant({ isPartial = true }) {
  const styles = css`
    .pdf--container {
      margin: 1rem 2rem;
      border-radius: 1rem;
      padding-bottom: 1rem;
      background-color: #e3e3e3;
      display: grid;
      grid-template-columns: minmax(300px, 2fr);
    }

    .img-pdf {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `;
  return (
    <>
      {isPartial ? (
        <div className={styles}>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/garantiaParcial.png")}
            />
          </div>
        </div>
      ) : (
        <div className={styles}>
          <div className="pdf--container">
            <img
              className="img-pdf"
              alt=""
              src={require("../../../assets/img/rules/garantia.png")}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default StepGarant;
