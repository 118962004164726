import axios from "axios";
import { URL_API } from "service/config";

export default class ColorModalService {
  static async updateColorModal({ token, idClient, type, color }) {
    const colorHome = color.slice(1);
    var config = {
      method: "put",
      url: URL_API + `${type}/user/${idClient}/color/${colorHome}`,
      headers: {
        Authorization: token,
      },
    };
    try {
      const resp = await axios(config);
      return resp.data;
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
}
